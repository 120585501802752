export const GoodFridayIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_1974)">
        <path
          d="M1.5 47.5001H46.6C46.6 47.5001 45.6 38.8001 34 34.6001C27.6 32.3001 20.5 32.2001 14.2 34.6001C9.1 36.5001 3.5 40.2001 1.5 47.5001Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.3998 29.0999L7.6998 22.4999L2.1998 23.9999L1.2998 20.4999L6.89981 18.8999L5.39981 13.2999L8.4998 12.3999L10.2998 18.0999L15.9998 16.7999L16.8998 19.8999L11.1998 21.3999L14.7998 34.3999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 31.3999L11.2 35.8999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.3 15.8V12.3H33.4V8.1H26.3L26.1 0.5H22V7.7L15 7.8V12L22 12.3L21.9 32.7"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.1997 18.3999V32.6999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.1997 26.6999L40.3997 22.2999L45.8997 23.9999L46.5997 20.4999L41.0997 18.8999L42.3997 13.0999L39.3997 12.3999L37.5997 18.3999L32.0997 16.9999L31.1997 20.0999L36.4997 21.4999L33.3997 34.3999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.5998 28.8999L36.7998 35.6999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_1974">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
