import { useEffect } from "react";
import styled from "styled-components";
export const NewCountdownBox = ({
  isExpired,
  data,
  style,
  coloredColor,
}) => {

  useEffect(() => { }, [data])

  return (
    <CountdownBoxStyled style={{ ...style }} coloredColor={coloredColor}>
      <ul>
        <li key="days" className="__date-val">
          <div className="date-val-num">{data.isExpired ? "-" : data.days}</div>
          <div className="date-val-txt">Days</div>
        </li>
        <li key="hours" className="__date-val">
          <div className="date-val-num">{data.isExpired ? "-" : data.hours}</div>
          <div className="date-val-txt">Hours</div>
        </li>
        <li key="minutes" className="__date-val">
          <div className="date-val-num">{data.isExpired ? "-" : data.minutes}</div>
          <div className="date-val-txt">Minutes</div>
        </li>
        <li key="seconds" className="__date-val colored">
          <div className="date-val-num">{data.isExpired ? "-" : data.seconds}</div>
          <div className="date-val-txt">Seconds</div>
        </li>
      </ul>
    </CountdownBoxStyled>
  );
};

const CountdownBoxStyled = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding: 3rem 6rem !important;
  max-width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .date-val {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-num {
    font-size: 10rem;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-txt {
    font-size: 3rem;
  }

  & .colored {
    color: ${(props) => props.coloredColor || "#38d6d6"};
  }

  & ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 5rem;
  }

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
  }

  @media only screen and (max-width: 700px) {
    padding: 0;
    height: fit-content;
    padding: 3rem 6rem;

    & ul {
      flex-wrap: wrap;
      gap: 3rem !important;
      padding-bottom: 3rem 6rem;
      width: 90vw !important;
    }
    & li {
      height: 6rem;
    }
    & .date-val-num {
      font-size: 4rem !important;
    }

    & .date-val-txt {
      font-size: 1.5rem !important;
    }
  }
`;
