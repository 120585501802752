
import { Stopwatch } from "../components/ToolsComponents/Stopwatch/Stopwatch";

const StopWatchPage = () => {
  return (
    <>
      <Stopwatch />
    </>
  );
};

export default StopWatchPage;
