
import { NewSuggestions } from "../components/Suggestions/NewSuggestions";
import { Timer } from "../components/ToolsComponents/Timer/Timer";

const TimerPage = () => {
  return (
    <>
      <Timer />
      <NewSuggestions category={"Timers"} />
    </>
  );
};

export default TimerPage;
