import { useState } from 'react'
import styled from 'styled-components'

export const UserMenuItem = ({ title, icon, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const Icon = icon;
    return <UserMenuItemStyled onClick={onClick} onMouseEnter={() => { setIsHovered(true) }} onMouseLeave={() => { setIsHovered(false) }}>
        <Icon size={'3rem'} color={isHovered ? 'rgb(56, 214, 214)' : 'white'} />
        {title}
    </UserMenuItemStyled>
}

const UserMenuItemStyled = styled.a`
display:flex;
align-items:center;
justify-content:flex-start;
gap:1rem;
padding:1.5rem 1.5rem;
font-size:1.5rem;
width:100%;
&:hover{
  cursor:pointer;
  color:rgb(56, 214, 214);
}
`

      // text-decoration:  solid underline rgb(56, 214, 214) .3rem;
      // text-underline-offset: .3rem;