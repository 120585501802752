import styled from "styled-components";

const Main = ({ children }) => {
  return (
    // <MainOuterContainer>
    //   <MainInnerContainer>{children}</MainInnerContainer>
    // </MainOuterContainer>
    <div className="__to__main-container">{children}</div>
  );
};

const MainOuterContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
`;

const MainInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
`;

export default Main;
