import styled from "styled-components";
export const OnThisDayItem = ({ el }) => {
  return (
    <OnThisDayItemContainer>
      <ItemDate>{"On the Year " + el.year}</ItemDate>
      <ItemText>{el.text}</ItemText>
      {/* <ItemUrls>{el.}</ItemUrls> */}
    </OnThisDayItemContainer>
  );
};

const OnThisDayItemContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width:68rem;
  align-items: flex-start;;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  padding: 1rem;
`;

const ItemDate = styled.div`
  color: rgb(205, 97, 85);
  font-size: 1.7rem;
  padding:1rem 0;
  font-weight:500;
`;

const ItemText = styled.div`
  font-size: 1.4rem;
  color:#f4f4f4
`;

const ItemUrls = styled.ul``;
