export const RaiseHandIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path d="M24 22C28.9706 22 33 17.9706 33 13C33 8.02944 28.9706 4 24 4C19.0294 4 15 8.02944 15 13C15 17.9706 19.0294 22 24 22Z" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linejoin="round" />
      <path d="M4.5 43.9994C4.5 38 11.5 27.9994 24 27.9994C24 27.9994 26.7588 27.9994 29.7821 29.0906C32.7438 30.1596 36.5 31.1481 36.5 27.9994V7.74952C36.5 5.67845 38.1789 3.99951 40.25 3.99951C42.3211 3.99951 44 5.67844 44 7.74951V43.9994" stroke={color} stroke-opacity="0.85" stroke-width="2" />
      <path d="M4.5 43.9994C4.5 38 11.5 27.9994 24 27.9994C24 27.9994 26.7588 27.9994 29.7821 29.0906C32.7438 30.1596 36.5 31.1481 36.5 27.9994V7.74952C36.5 5.67845 38.1789 3.99951 40.25 3.99951C42.3211 3.99951 44 5.67844 44 7.74951V43.9994" stroke={color} stroke-opacity="0.85" stroke-width="2" />
      <path d="M2 44H46" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
