import { HomePageMenuFeatured } from "../HomePageFeturedMenu/HomePageMenuFeatured";
import styled from "styled-components";
import { SideMenusContext } from "../../../contexts/side-menus-context";
import { useContext } from "react";
import { HomePageMenuItem } from "./HomePageMenuItem";
import { useState } from "react";
import { Icon } from "../../ui/IconWrapper/Icon";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "../../../shared/assets/icons/ToolsOasisIcons/chevron-right";
import { CategoriesMenu } from "../HomeCategoriesMenu/CategoriesMenu";
import { useEffect } from "react";

export const HomePageMenu = ({ items, feturedItems }) => {
  const { defaultCatergories, toolsData, searchValue, filteredTools } =
    useContext(SideMenusContext);

  const navigate = useNavigate();
  return (
    <HomePageMenuContainer>
      {true && !searchValue && <HomePageMenuFeatured feturedItems={feturedItems} />}
      <HomePageMenuStyled>
        {defaultCatergories &&
          defaultCatergories.map((element) => {
            if (filteredTools && filteredTools[element.title].length === 0)
              return null;
            return (
              <>
                <CategoryHeader>
                  <CategoryTitle bgcolor={element.bgcolor}>
                    {element.title}
                  </CategoryTitle>
                  {true && <CategorySeeAll bgcolor={element.bgcolor} onClick={() => { navigate(element.path) }}>
                    <></>
                    <a href={`https://toolsoasis.com/${element.path}`} onClick={(e) => e.preventDefault()}>See all{" "}</a>
                    <Icon
                      icon={ChevronRight}
                      size={"4rem"}
                      color={element.bgcolor}
                      strokeWidth={5}
                      // style={{ marginTop: ".6rem" }}
                    />
                  </CategorySeeAll>}
                </CategoryHeader>
                <ToolsOptions>
                  {filteredTools &&
                    filteredTools[element.title].map((el) => (
                      <ToolsOption
                        className="tool-option"
                        onClick={(e) => {
                          navigate(el.path);
                        }}
                      >
                        <ToolOptionIconContainer bgcolor={element.bgcolor}>
                          <Icon
                            icon={el.icon || element.icon}
                            size={"6rem"}
                            color={element.bgcolor}
                          />
                          {el.iconText && <IconText>{el.iconText}</IconText>}
                          {+el.status === 2 && (
                            <UnderConstruction
                              bgcolor={element.bgcolor}
                              className="under-construction"
                            >
                              Under Construction
                            </UnderConstruction>
                          )}
                        </ToolOptionIconContainer>
                        <ToolOptionTitle>
                          <a
                            href={el.path}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(el.path);
                            }}
                          >
                            {el.title}
                          </a>{" "}
                        </ToolOptionTitle>
                      </ToolsOption>
                    ))}
                </ToolsOptions>
              </>
            );
          })}
      </HomePageMenuStyled>
      <CategoriesMenu />
    </HomePageMenuContainer >
  );
};

const HomePageMenuContainer = styled.div`
  max-width: 100vw;
  height: 100%;
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const HomePageMenuStyled = styled.div`
  height: 100%;
  width: 100%;
  padding: 3rem 0;
  /* min-width: 90vw; */
  @media only screen and (min-width: 600px) {
    max-width: 152rem;
    /* min-width: 152rem; */
  }
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CategoryTitle = styled.div`
  margin-top: 1rem;
  color: white;
  padding-left: 0.8rem;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.5rem;
  border-left: 0.5rem solid ${(props) => props.bgcolor};
`;

const CategorySeeAll = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  color: white;
  width: 8rem;
  padding-left: 0.8rem;
  white-space: nowrap;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.5rem;
  gap: 0.3rem;
  text-align: left;
  :hover {
    cursor: pointer;
    color: ${(props) => props.bgcolor};
  }
`;

const ToolsOptions = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  overflow-x: auto;
  padding: 2rem 0rem;
  & .tool-option:first-of-type:hover {
    padding-left: 1rem;
    padding-right: 0rem;
  }

  & .tool-option:last-of-type:hover {
    padding-right: 1rem;
  }
`;

const ToolsOption = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(115%);
  }
  &:hover .under-construction {
    display: block;
    cursor: not-allowed;
  }
`;

const ToolOptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;

  color: ${(props) => props.bgcolor};
  text-align: center;
  min-width: 12rem;
  max-width: 12rem;
  min-height: 10rem;
  max-height: 10rem;
  border-radius: 0.8rem;
  border: 0.2rem solid ${(props) => props.bgcolor};
  position: relative;
`;
const ToolOptionTitle = styled.div`
  min-width: 12rem;
  max-width: 12rem;
  text-align: left;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  color: white;
`;

const IconText = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
`;

const UnderConstruction = styled.div`
  display: block;
  position: absolute;
  bottom: 50%;
  right: 50%;
  color: black;
  width: 100%;
  background-color: yellow;
  font-weight: 600;
  transform: translateX(50%) translateY(50%);
  /* border-top: 2px dashed #333;
  border-bottom: 2px dashed #333; */
  opacity: 0.8;
`;
