import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "../../shared/custom-icons/Hamburger";
import { useContext } from "react";
import { SideMenusContext } from "../../contexts/side-menus-context";
import { UserMenu } from "../../shared/custom-icons/UserMenu";
const Header = () => {
  const { handleShowSidebar, isSideMenuOpen } = useContext(SideMenusContext);
  const showUserMenu = true;
  const navigate = useNavigate();
  return (
    <HeaderContainer className="__to__prevent-user-select">
      <HamburgerIcon
        isSideMenuOpen={isSideMenuOpen}
        styled={{ alignSelf: "flex-start" }}
        onClick={handleShowSidebar}
      />
      <a
        href={"/"}
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
        style={{
          fontSize: "inherit",
          fontStyle: "italic",
          cursor: "pointer",
        }}
      >
        tools<span className="__oasis">oasis</span>
      </a>{" "}
      {/* <div></div> */}
      {showUserMenu && <UserMenu />}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  width: 100vw;
  background: #23222b;
  z-index: 1201;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  height: 6rem;
  font-size: 2.2rem;
  background: #23222b;
  color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  & .__oasis {
    color: #38d6d6;
    font-size: inherit;
    font-weight: inherit;
  }
`;

const ButtonPrimaryContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  gap: 2rem;
`;

const HeaderContentContainer = styled.div`
  background-color: transparent;
  position: relative;
`;

const LoggedUserBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 3rem;
  height: 6rem;
  cursor: pointer;
`;

const TempStoreName = styled.div`
  text-transform: capitalize;
  background: #f8f7fb;
  box-shadow: -0.2rem -0.2rem 0.8rem #ffffff,
    0.2rem 0.2rem 0.4rem rgba(80, 75, 90, 0.16);
  border-radius: 0.8rem;
  width: fit-content;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.8rem 0px 1.2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  color: #504b5a;
`;

export default Header;
