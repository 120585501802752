import styled from "styled-components";
import { HomeSectionsMenuItem } from "./HomeSectionMenuItem";

export const HomeSectionsMenu = ({ cardsData }) => {
  return (
    <HomeSectionsMenuContainer>
      <HomeSectionsMenuStyled>
        {cardsData.map((el) => (
          <HomeSectionsMenuItem {...el}></HomeSectionsMenuItem>
        ))}
      </HomeSectionsMenuStyled>
    </HomeSectionsMenuContainer>
  );
};

const HomeSectionsMenuContainer = styled.section`
  width: 100%;
  max-width: 90vw;
  padding: 1rem 0.5rem;
`;
const HomeSectionsMenuStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:.5rem
`;
