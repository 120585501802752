import { useEffect, useState } from "react";
import styled from "styled-components";
import { DateGeneratorCalendar } from "../components/DateGenerator/DateGeneratorCalendar";
import { Dates } from "../components/ToolsComponents/Dates/Dates";

const DatePage = ({ type }) => {

  return (
    <>
      <Dates type={type} />
    </>
  );
};

export default DatePage;

const DateGeneratorPageStyled = styled.div`
  width: 100%;
  max-height: calc(100vh - 6rem);
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden !important;
  overflow-y: auto !important;
  position: relative;
  input {
    text-align: center;
  }
`;

const DatePageCalendarSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
  height: fit-content;
`;

const DateOptionsSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: white;
  & h2 {
    font-size: 2rem;
  }
`;

const DateOptionsMenu = styled.ul`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
const DateOptionsMenuItem = styled.li`
  background-color: ${(props) => props.color};
  color: white;
  cursor: pointer;
  max-width: 20rem;
  border-radius: 10rem;
  border: 0.2rem solid rgb(56, 214, 214);
  white-space: wrap;
  /* background-color: rgb(60, 144, 189); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 50%;
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  cursor: pointer;
  @media only screen and (min-width: 700px) {
    font-size: 1.75rem;
    padding: 1rem 5rem;
  }
  &:focus {
    box-shadow: white 0px 0px 10px 5px;
  }
  &.selected {
    box-shadow: rgb(56, 214, 214) 0px 0px 10px 5px;
  }
`;
