export const AscensionDayIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_1882)">
        <path
          d="M24.1 8.8999C24.1 8.8999 22.4 11.5999 19.8 10.8999C19.8 10.8999 18.1 20.9999 24.1 21.2999C24.1 21.2999 30 21.6999 28.5 10.6999C28.4 10.6999 26.1 11.6999 24.1 8.8999Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9999 22.3999C14.2999 12.3999 17.9999 6.79985 17.9999 6.79985C20.7999 1.49985 23.9999 5.39985 23.9999 5.39985C23.9999 5.39985 27.7999 1.29985 30.5999 7.19985C30.5999 7.19985 33.3999 11.5999 30.5999 21.8999"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.8997 20.6001C21.8997 20.6001 20.8997 24.7001 24.1997 24.6001C27.4997 24.5001 26.2997 20.6001 26.2997 20.6001"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.6997 12.3C28.6997 12.3 30.8997 11.6 31.0997 13.7C31.0997 13.7 30.9997 15.8 28.6997 15.6"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.5999 12.1999C19.5999 12.1999 17.6999 11.5999 17.2999 13.2999C17.2999 13.2999 16.6999 15.6999 19.5999 15.5999"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.6997 16.1001C19.6997 16.1001 21.2997 16.3001 22.8997 18.3001C22.8997 18.3001 24.1997 17.1001 25.1997 18.3001C25.1997 18.3001 26.2997 16.3001 28.0997 16.1001"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.6998 22.6001C21.6998 22.6001 18.3998 21.8001 17.1998 23.2001C15.9998 24.6001 10.8998 31.5001 6.69984 32.4001C6.69984 32.4001 5.89984 37.9001 10.7998 40.6001C10.7998 40.6001 15.4998 39.4001 16.3998 36.6001V35.3001L16.0998 40.9001L13.1998 43.1001L13.3998 47.2001L26.7998 47.6001"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.0997 21.7001C28.0997 21.7001 25.9997 36.5001 13.1997 43.1001V47.5001H22.1997C22.1997 47.5001 32.8997 37.3001 30.7997 22.3001C30.7997 22.3001 29.6997 21.2001 28.0997 21.7001Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.8999 47.4998H33.9999C33.9999 47.4998 31.1999 38.9998 31.4999 35.7998"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.8999 23.1001C30.8999 23.1001 34.2999 29.1001 41.5999 32.7001C41.5999 32.7001 42.1999 37.8001 37.6999 40.6001C37.6999 40.6001 35.2999 40.5001 31.5999 37.2001"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.59981 34.4999C6.59981 34.4999 3.99981 33.2999 3.29981 32.7999C2.59981 32.2999 1.79981 33.9999 2.59981 35.2999C3.39981 36.5999 1.79981 36.5999 0.799811 37.7999C-0.200189 38.9999 1.79981 41.8999 3.69981 41.0999C5.69981 40.2999 8.09981 38.1999 8.09981 38.1999C8.09981 38.1999 6.59981 35.7999 6.59981 34.4999Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.4997 34.5002C41.4997 34.5002 44.5997 32.4002 45.1997 32.7002C45.7997 32.9002 47.1997 34.8002 44.8997 35.8002C44.8997 35.8002 45.2997 37.2002 47.6997 38.1002C47.6997 38.1002 46.4997 42.5002 44.0997 41.3002C41.7997 40.1002 40.1997 38.2002 40.1997 38.2002C40.1997 38.2002 41.3997 36.0002 41.4997 34.5002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.8 10.4C35.8 10.4 33.4 -2.89998 19.4 1.30002C17.6 1.90002 15.9 2.90002 14.7 4.30002C13.2 6.10002 11.8 9.10002 12.5 13.8C12.5 13.8 12.6 18.8 17.7 21.5"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.0996 21.3001C31.0996 21.3001 34.6996 19.5001 35.7996 13.6001"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_1882">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
