import { useState, useEffect } from "react";
import styled from "styled-components";
import { PregnancyCalculator } from "../../Calculators/PregnancyCalculator";
import { useCountdown } from "../../../hooks/useCountdown";
import { CountdownBox } from "../CountdownBox";

export const PregnancyCountdown = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);

  let data = useCountdown(selectedDate, isStart);

  useEffect(() => {
    setIsPastDate(new Date(selectedDate) - new Date() < 10);
    if (new Date(selectedDate) - new Date() > 10) setIsStart(true);
  }, [selectedDate]);

  const handleDateSelection = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <div style={{ color: "white" }}>
      {<PregnancyCalculator callback={setSelectedDate} />}
      <CountdownContainer className="flex-col flex-c-c">
        <DateTimeStyled className="" isPastDate={isPastDate}>
          {/* {selectedDate && !isPastDate && (
            <div
              onClick={() => {
                setSelectedDate("");
              }}
            >
              {new Date(selectedDate).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </div>
          )} */}
          {(!selectedDate || isPastDate) && (
            <input
              style={{ width: "25rem" }}
              className="__date-time-input"
              type="text"
              onMouseEnter={(e) => (e.target.type = "datetime-local")}
              onBlur={(e) => (e.target.type = "text")}
              onChange={handleDateSelection}
              value={selectedDate}
              placeholder="Enter Date"
            />
          )}
        </DateTimeStyled>

        {selectedDate && (
          <>
            {/* <CountingStyled className="">
                Counting the seconds to get away
              </CountingStyled> */}

            <div className="flex-row">
              <CountdownBox
                {...data}
                style={{
                  maxWidth: "95",
                }}
              />
            </div>
          </>
        )}
        <div
          style={{
            color: "red",
            display: isPastDate ? "block" : "none",
          }}
        >
          Please Select Future Date
        </div>
      </CountdownContainer>
    </div>
  );
};

const DateTimeStyled = styled.div`
  div {
    font-size: 1.5rem;
    padding: 0 3rem;
    font-weight: 600;
  }

  input {
    color: ${(props) => (props.isPastDate ? "red" : "inherit")};
  }
`;

const CountdownContainer = styled.div`
  gap: 4rem;
`;
