export const HanukkahIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill="none"
      focusable="false"
      aria-hidden="true"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_238_1773)">
        <path
          d="M24.3002 0.600098C24.3002 0.600098 20.4002 5.7001 24.3002 6.1001C24.3002 6.1001 28.1002 5.7001 24.3002 0.600098Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9999 4.2002C17.9999 4.2002 14.0999 9.3002 17.9999 9.7002C17.9999 9.7002 21.7999 9.4002 17.9999 4.2002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.7001 4C12.7001 4 8.80013 9.1 12.7001 9.5C12.7001 9.5 16.5001 9.2 12.7001 4Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.30023 4.2002C7.30023 4.2002 3.40023 9.3002 7.30023 9.7002C7.30023 9.7002 11.1002 9.4002 7.30023 4.2002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.30023 4.2002C2.30023 4.2002 -1.59977 9.3002 2.30023 9.7002C2.30023 9.7002 6.10023 9.4002 2.30023 4.2002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.8002 4.2002C30.8002 4.2002 26.9002 9.3002 30.8002 9.7002C30.8002 9.7002 34.6002 9.4002 30.8002 4.2002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.9999 4.2002C35.9999 4.2002 32.0999 9.3002 35.9999 9.7002C35.9999 9.7002 39.7999 9.4002 35.9999 4.2002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.8998 4.2002C40.8998 4.2002 36.9998 9.3002 40.8998 9.7002C40.8998 9.7002 44.6998 9.4002 40.8998 4.2002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M45.8002 4.2002C45.8002 4.2002 41.9002 9.3002 45.8002 9.7002C45.8002 9.7002 49.6002 9.4002 45.8002 4.2002Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.4 37.2003C49.2 35.3003 47.5 11.1003 47.5 11.1003H44.7C45.3 33.5003 25.5 34.6003 25.5 34.6003H25.4V32.2003C43.7 30.8003 42.1 11.1003 42.1 11.1003H39.2C39.8 20.9003 34.9 25.6003 30.7 27.7003C29 28.5003 27.2 28.9003 25.3 29.0003V26.8003C38.2 24.7003 37 11.1003 37 11.1003H34.4C34.6 21.4003 27.9 23.2003 25.3 23.5003V20.3003C32.4 19.1003 31.8 11.1003 31.8 11.1003H29.5C29.6 15.8003 26.8 17.6003 25.3 18.0003V7.30029H23.1V17.8003C19.1 16.6003 19.1 11.1003 19.1 11.1003H16.7C17.1 17.9003 21.1 20.0003 23.1 20.4003V23.4003C14.1 21.7003 14.2 11.1003 14.2 11.1003H11.3C12.1 23.8003 20 26.3003 23.1 26.7003V28.9003C21.4 28.7003 19.8 28.2003 18.3 27.4003C9.9 22.7003 9 11.1003 9 11.1003H6.1C7.5 28.7003 19.1 31.7003 23.1 32.1003V34.4003C22 34.3003 21 34.0003 20 33.7003C3.7 27.8003 3.6 11.1003 3.6 11.1003H0.5C1.1 28.4003 13.6 34.5003 19.7 36.5003C20.8 36.8003 21.9 37.1003 23 37.2003V40.8003C21.2 41.1003 18 42.3003 15.7 46.5003V47.5003H32.2V46.6003C32.2 46.6003 30.5 41.5003 25.2 40.8003L25.4 37.2003ZM25.4 37.2003C25.2 37.9003 25.3 37.2003 25.4 37.2003Z"
          stroke={color}
          stroke-width="1.6"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_238_1773">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
