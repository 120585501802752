import { useEffect, useState } from "react";
import styled from "styled-components";
import { OnThisDayItem } from "./OnthisDayItem";
import { DateGeneratorCalendar } from "../../../DateGenerator/DateGeneratorCalendar";
import { useSearchParams } from "react-router-dom";

const MENU_DATA = [
  { title: "Events" },
  { title: "Births" },
  { title: "Deaths" },
];

export const OnThisDayMain = () => {
  const [data, setData] = useState();
  const [selectedOption, setSelectedOption] = useState(MENU_DATA[0].title);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dataKey, setDataKey] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preset = searchParams.get("dval");
    if (preset) setSelectedDate(new Date(+preset));
  }, []);

  useEffect(() => {
    if (!selectedDate) return;
    setDataKey(
      `historyDates-${selectedDate.getMonth()}-${selectedDate.getDate()}-${selectedDate.getFullYear()}`
    );
  }, [selectedDate]);

  useEffect(() => {
    if (!localStorage.getItem(dataKey)) {
      const sababa = async () => {
        const res = await fetch(
          `https://history.muffinlabs.com/date/${
            selectedDate.getMonth() + 1
          }/${selectedDate.getDate()}`
        );
        const data = await res.json();
        localStorage.setItem(dataKey, JSON.stringify(data));
        setData(data);
      };
      sababa();
    } else {
      setData(JSON.parse(localStorage.getItem(dataKey)));
    }
  }, [dataKey]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);
  return (
    <>
      <DateGeneratorCalendar
        bgcolor={"rgb(205, 97, 85)"}
        date={selectedDate}
        size={1}
        style={{ maxHeight: "fit-content" }}
        withYear={false}
        withWeekday={false}
      />
      <>
        <OptionsMenu>
          {MENU_DATA.map((el) => (
            <OptionMenu
              onClick={() => setSelectedOption(el.title)}
              className={el.title === selectedOption && "selected"}
            >
              {el.title}
            </OptionMenu>
          ))}
        </OptionsMenu>
        <EventsSlider>
          {data &&
            data.data[selectedOption].map((el) => (
              <OnThisDayItem key={el.title} el={el} />
            )).reverse()}
        </EventsSlider>
      </>
    </>
  );
};

const CurrentDateTitle = styled.h1`
  font-size: 1.7rem;
  line-height: 1.7rem;
`;

const OnThisDayContainer = styled.div`
  height: 100%;
  margin-top: 3rem;
  max-width: 106rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  gap: 3rem;
  padding-bottom: 3rem;
`;
const OptionsMenu = styled.ul`
  display: flex;
  gap: 1rem;
`;
const OptionMenu = styled.li`
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  font-size: 1.4rem;
  max-width: 68rem;
  width: 100%;
  cursor: pointer;
  border-radius: 10rem;
  &.selected {
    background-color: rgb(205, 97, 85);
  }
`;

const EventsSlider = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  min-height: 30rem;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  width: 90vw;
  height: 100%;
  max-width:68rem;
  border-radius: 0.8rem;
  padding: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 5vh;
`;
