import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { SideMenusContext } from "../../../contexts/side-menus-context";

export const CategoriesMenu = () => {
  const { timersData, toolsPerCategory } = useContext(SideMenusContext);
  return <CategoryMenuContainer>

  </CategoryMenuContainer>;
};

const CategoryMenuContainer = styled.div`
`;
