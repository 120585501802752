export const ChevronLeft = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
  strokeWidth
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{
        ...style,
      }}
    >
      <path
        // d="M14 17L9 12L14 7"
        d="M9 7L14 12L9 17"
        stroke={color}
        stroke-width={strokeWidth || "2"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
