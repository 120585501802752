import { NewCountdownBox } from "./NewCountdownBox";
import { useCountdown } from "../../hooks/useCountdown";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { BaseModal } from "../ui/Modal/BaseModal";
import { Icon } from "../ui/IconWrapper/Icon";
import {
  BirthdayIcon,
  CalendarsIcon,
  CountdownIcon,
  PregnancyIcon,
  RetirementIcon,
  TripIcon,
  VacationIcon,
  WeddingIcon,
} from "../../shared/assets/icons/ToolsOasisIcons";
import { BackgroundIcon } from "../../shared/assets/icons/ToolsOasisIcons";
import { MONTHS, DAYS } from "../DateGenerator/DateGeneratorCalendar";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const COUNTDOWN_TYPES = {
  custom: {
    color: "rgba(52, 191, 149, 1)",
    icon: CountdownIcon,
    background: BackgroundIcon,
    backgroundSize: { width: "54vh", height: "54vh" },
    datePlaceHolder: "Enter date and time...",
    datePickerType: "datetime-local",
    outterLink: null,
  },
  wedding: {
    color: "rgba(52, 191, 149, 1)",
    icon: WeddingIcon,
    background: WeddingIcon,
    backgroundSize: { width: "54vh", height: "54vh" },
    datePlaceHolder: "Enter wedding date and time...",
    datePickerType: "datetime-local",
    outterLink: null,
  },
  vacation: {
    color: "rgba(52, 191, 149, 1)",
    icon: VacationIcon,
    background: VacationIcon,
    backgroundSize: { width: "54vh", height: "54vh" },
    datePlaceHolder: "Enter vacation date...",
    datePickerType: "datetime-local",
    outterLink: null,
  },
  trip: {
    color: "rgba(52, 191, 149, 1)",
    icon: TripIcon,
    background: TripIcon,
    backgroundSize: { width: "49vh", height: "49vh" },
    datePlaceHolder: "Enter trip date and time...",
    datePickerType: "datetime-local",
    outterLink: null,
  },
  pregnancy: {
    color: "rgba(52, 191, 149, 1)",
    icon: PregnancyIcon,
    background: PregnancyIcon,
    backgroundSize: { width: "60vh", height: "60vh" },
    datePlaceHolder: "Enter due date...",
    datePickerType: "date",
    outterLink: {
      prefix: ` Not sure when the baby is due? 
    check out: `,
      path: "/Calculators/pregnancy-calculator",
      toolName: "\npregnancy due date calculator",
      toolColor: "rgb(118, 73, 191)",
      postfix: "",
    },
  },
  retirement: {
    color: "rgba(52, 191, 149, 1)",
    icon: RetirementIcon,
    background: RetirementIcon,
    backgroundSize: { width: "54vh", height: "54vh" },
    datePlaceHolder: "Enter retirement date...",
    datePickerType: "date",
    outterLink: null,
  },
  birthday: {
    color: "rgba(52, 191, 149, 1)",
    icon: BirthdayIcon,
    background: BirthdayIcon,
    backgroundSize: { width: "54vh", height: "54vh" },
    datePlaceHolder: "Enter birthday date and time..",
    datePickerType: "datetime-local",
    outterLink: null,
  },
};

export const NewCountdown = ({ type = "custom", icon, bgcolor, title }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDateFormatted, setSelectedDateFormatted] = useState("");
  const [showError, setShowError] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [presetVal, setPresetVal] = useSearchParams(false);
  const navigate = useNavigate();
  let data = useCountdown(selectedDate, isStart, isPaused, isReset);

  const handleDateSelection = (e) => {
    setIsStart(false);
    setSelectedDate(e.target.value);
  };

  const handleStart = () => {
    setIsPastDate(new Date(selectedDate) - new Date() < 10);
    setIsReset(false);
    if (!selectedDate || isPastDate) {
      setShowError(true);
      return;
    }
    setIsStart(true);
  };

  const handleReset = () => {
    setSelectedDate("");
    setSelectedDateFormatted("");
    setShowError(false);
    setIsReset(true);
    setIsStart(false);
  };

  useEffect(() => {
    if (selectedDate) {
      setShowError(true);
      let datetimeval = new Date(selectedDate);
      setIsPastDate(datetimeval - new Date() < 10);
      setSelectedDateFormatted(
        `${MONTHS[datetimeval.getMonth()]
        } ${datetimeval.getDate()}, ${datetimeval.getFullYear()}  ${datetimeval.toLocaleTimeString(
          [],
          { hour: "2-digit", minute: "2-digit" }
        )}`
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    const preset = searchParams.get("cdval");
    if (preset) setSelectedDate(new Date(+preset).toISOString());
  }, []);

  useEffect(() => {
    if (selectedDate) handleStart();
  }, [selectedDate]);

  return (
    <CountdownContainerStyled>
      <Icon
        icon={COUNTDOWN_TYPES[type].background}
        color="#222"
        style={{
          zIndex: -1,
          position: "absolute",
          minWidth: COUNTDOWN_TYPES[type].backgroundSize.width,
          maxWidth: COUNTDOWN_TYPES[type].backgroundSize.width,
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />
      <CountdownTitle>
        <Icon icon={COUNTDOWN_TYPES[type].icon} color="white" size="3rem" />
        {`${type} countdown`}
      </CountdownTitle>
      <Icon
        icon={icon || CountdownIcon}
        color={bgcolor || COUNTDOWN_TYPES[type].color}
        size={"10rem"}
      />

      <CountdownInputs isPastDate={isPastDate}>
        <DateInput onClick={() => document.getElementById("aaa").showPicker()}>
          <input
            type="text"
            value={selectedDateFormatted && selectedDateFormatted}
            placeholder={COUNTDOWN_TYPES[type].datePlaceHolder}
            className="__date-time-text"
            disabled={true}
          />
          <input
            id="aaa"
            className="__date-time-input"
            style={{ background: COUNTDOWN_TYPES[type].color }}
            type={COUNTDOWN_TYPES[type].datePickerType}
            onChange={handleDateSelection}
            value={selectedDate && selectedDate.replace("T", " ")}
          />
          <Icon
            icon={CalendarsIcon}
            color="white"
            size="4rem"
            style={{
              position: "absolute",
              right: ".5rem",
              top: ".5rem",
              zIndex: 1,
            }}
          />
        </DateInput>
        <div
          style={{
            color: "red",
            visibility:
              (isPastDate || !selectedDate) && showError ? "visible" : "hidden",
            paddingTop: ".5rem",
          }}
        >
          Please Select Future Date
        </div>
      </CountdownInputs>

      <CountdownMainContainer>
        <div>
          <NewCountdownBox
            data={data}
            coloredColor={COUNTDOWN_TYPES[type].color}
          />
        </div>
        {COUNTDOWN_TYPES[type].outterLink && !selectedDate && (
          // <OutterToolLinkage>
          //   {COUNTDOWN_TYPES[type].outterLink.prefix}
          //   <OutterToolLink
          //     href={COUNTDOWN_TYPES[type].outterLink.path}
          //     onClick={(e) => {
          //       e.preventDefault();
          //       navigate(COUNTDOWN_TYPES[type].outterLink.path);
          //     }}
          //     style={{ color: COUNTDOWN_TYPES[type].outterLink.toolColor }}
          //   >
          //     {COUNTDOWN_TYPES[type].outterLink.toolName}
          //   </OutterToolLink>
          // </OutterToolLinkage>
          <>
            <OutterToolLinkPrefix>
              {COUNTDOWN_TYPES[type].outterLink.prefix}
            </OutterToolLinkPrefix>
            <ToolsOption
              href={COUNTDOWN_TYPES[type].outterLink.path}
              onClick={(e) => {
                e.preventDefault();
                navigate(COUNTDOWN_TYPES[type].outterLink.path);
              }}
            >
              <ToolOptionIconContainer
                bgcolor={COUNTDOWN_TYPES[type].outterLink.toolColor}
              >
                <Icon
                  icon={COUNTDOWN_TYPES[type].background}
                  size={"4rem"}
                  color={COUNTDOWN_TYPES[type].outterLink.toolColor}
                />
                {/* {iconText && <IconText>{iconText}</IconText>} */}
              </ToolOptionIconContainer>
              <ToolOptionTitle>
                {" "}
                {COUNTDOWN_TYPES[type].outterLink.toolName}
              </ToolOptionTitle>
            </ToolsOption>
          </>
        )}

        <CountdownBtns>
          <button
            className={"start-btn"}
            onClick={isStart ? handleReset : handleStart}
          >
            {isStart ? "Reset" : "Start"}
          </button>
        </CountdownBtns>
      </CountdownMainContainer>
    </CountdownContainerStyled>
  );
};

const CountdownContainerStyled = styled.div`
  height: 100vh;
  margin-top: 4rem;
  max-width: 70rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const CountdownMainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

const CountdownTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  padding: 2rem 1rem;
  font-size: 1.7rem;
  font-weight: 600;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
`;

const CountdownInputs = styled.div`
  height: fit-content;
  padding: 2rem;
  gap: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const DateInput = styled.div`
  display: flex;
  width: 90vw;
  max-width: 68rem;
  position: relative;
  & input {
    width: 100%;
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    height: 5rem;
    outline: none;
    padding: 2rem;
    border: ${(props) => (props.isPastDate ? "0.2rem solid red" : "none")};
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.4rem;
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
  }

  & .__date-time-input {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
`;

const OutterToolLinkage = styled.div`
  color: white;
  height: 5rem;
  font-size: 1.2rem;
  white-space: pre-wrap;
`;

const OutterToolLink = styled.a`
  color: rgb(118, 73, 191);
  font-weight: 500;
`;

const CountdownBtns = styled.div`
  max-width: 68rem;
  width: 90vw;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 3rem;

  & button {
    width: 100%;
    border-radius: 0.8rem;
    border: none;
    outline: none;
    height: 7rem;
    /* border-radius: 50%; */
    background-color: rgba(52, 191, 149, 1);
    color: white;
    font-size: 3rem;
    font-weight: 600;
  }
`;

const ToolsOption = styled.a`
  cursor: pointer;
  &:hover {
    transform: scale(115%);
  }
  &:hover .under-construction {
    display: block;
    cursor: not-allowed;
  }
`;

const ToolOptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.bgcolor};
  text-align: center;
  min-width: 9rem;
  max-width: 9rem;
  min-height: 7rem;
  max-height: 7rem;
  border-radius: 0.8rem;
  border: 0.2rem solid ${(props) => props.bgcolor};
  position: relative;
`;
const ToolOptionTitle = styled.div`
  min-width: 9rem;
  max-width: 9rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  color: white;
`;

const OutterToolLinkPrefix = styled.div`
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.4rem;
  width: 100%;
  padding: 0 2rem;
  white-space: pre-wrap;
  gap: 2rem;
`;
