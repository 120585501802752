import HomePage from "./home-page";
import HomePage2 from "./home-page2";
import DatePage from "./date-page";
import CountdownPage from "./countdown-page";
import StopWatchPage from "./stopwatch-page";
import TimerPage from "./timer-page";
import ContactUsPage from "./contact-us";
import CalculatorPage from "./calculator-page";
import { HubPage } from "./hub-page";
export {
  HomePage,
  HomePage2,
  DatePage,
  StopWatchPage,
  TimerPage,
  CountdownPage,
  ContactUsPage,
  CalculatorPage,
  HubPage
};
