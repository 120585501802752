import styled from "styled-components";

export const SlidePagingItem = ({
  activeSlide,
  numberOfSlides,
  currentSlide,
  setActiveFact
}) => {
  const isActive = activeSlide === currentSlide;
  const isFirst = currentSlide - 1 == 0;
  const isLast = currentSlide === numberOfSlides;
  return (
    <>
      <SlideNumberActive
        className={`${isActive ? "active" : ""}`}
        onClick={() => setActiveFact(currentSlide-1)}
      >
        <SlideNumber className={`${isActive ? "active" : ""}`}>
          {currentSlide}
        </SlideNumber>
      </SlideNumberActive>
      {!isLast && <Splitter />}
    </>
  );
};

export default SlidePagingItem;

const SlideNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
  max-width: 5rem;
  min-height: 5rem;
  max-height: 5rem;
  border-radius: 50%;
  font-size: 2rem;
  z-index: 2;
  background: transparent;
  font-weight: 600;
  border: 0.3rem solid rgb(52, 191, 149);
  &.active {
    font-size: 3rem;
    min-width: 5rem;
    max-width: 5rem;
    min-height: 5rem;
    max-height: 5rem;
    background: rgb(52, 191, 149);
    color: black;
  }
`;

const SlideNumberActive = styled.div`
  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    min-width: 7rem;
    max-width: 7rem;
    min-height: 7rem;
    max-height: 7rem;
    border: 0.3rem solid rgb(52, 191, 149);
    border-radius: 50%;
  }
`;
const Splitter = styled.div`
  width: 4rem;
  border: 0.3rem solid rgb(52, 191, 149);
  z-index: 1;
`;
