import { useEffect, useState } from "react";
import styled from "styled-components";

export const StopwatchBox = ({ time, coloredColor, style }) => {
  return (
    <TimerBoxStyled style={{ ...style }} coloredColor={coloredColor}>
      <ul>
        {time.days > 0 && (
          <>
            <li key="days" className="__date-val">
              <div className="date-val-num">{time.days}</div>
            </li>
            <li className="time-val-txt">:</li>
          </>
        )}
        {time.hours ? (
          <>
            <li key="hours" className="__date-val">
              <div className="date-val-num">
                {time.hours < 10 ? "0" + time.hours : time.hours}
              </div>
            </li>
            <li className="time-val-txt">:</li>
          </>
        ) : (
          ""
        )}
        <li key="minutes" className="__date-val">
          <div className="date-val-num">
            {time.minutes < 10 ? "0" + time.minutes : time.minutes}
          </div>
        </li>
        <li className="time-val-txt">:</li>
        <li
          key="seconds"
          className="__date-val colored"
          // style={{ color: seconds < 10 ? "rgb(194, 60, 60, 1)" : "#38d6d6" }}
        >
          <div className="date-val-num">
            {time.seconds < 10 ? "0" + time.seconds : time.seconds}
          </div>
        </li>
        <li className="time-val-txt">:</li>
        <li
          key="miliseconds"
          className="__date-val colored"
          // style={{ color: seconds < 10 ? "rgb(194, 60, 60, 1)" : "#38d6d6" }}
        >
          <div className="date-val-num">
            {time.miliSec < 10 ? "0" + time.miliSec : time.miliSec}
          </div>
        </li>
      </ul>
    </TimerBoxStyled>
  );
};

const TimerBoxStyled = styled.div`
  color: white;
  border-radius: 1rem !important;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3rem 6rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .date-val {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-num {
    font-size: 10rem;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-txt {
    font-size: 3rem;
  }

  & .colored {
    color: ${(props) => props.coloredColor || "#38d6d6"};
  }

  & ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 3rem;
  }

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 6rem;
    min-width: 6rem;
  }

  & .time-val-txt {
    font-size: 4rem !important;
    padding: 0;
  }

  @media only screen and (max-width: 700px) {
    padding: 0;
    height: fit-content;
    padding: 3rem 6rem;
    max-width: 90vw;

    & ul {
      flex-wrap: no-wrap;
      gap: 1rem !important;
      padding-bottom: 3rem 6rem;
      width: 95vw !important;
    }
    & li {
      height: 3rem;
      width: 3rem;
      max-width: 3rem;
      min-width: 3rem;
    }
    & .date-val-num {
      font-size: 4.5rem !important;
    }

    & .date-val-txt {
      font-size: 1.5rem !important;
    }
  }
`;
