import styled from "styled-components";
import { useContext } from "react";
import { SideMenusContext } from "../../contexts/side-menus-context";

export const HomeSectionsMenuItem = ({
  title,
  category,
  icon,
  description,
  bgcolor,
}) => {
  const Icon = icon;
  const { isSideMenuOpen, defaultCatergories, STATUS, toolsPerCategory } =
    useContext(SideMenusContext);
  return (
    <HomeSectionsMenuItemStyled color={bgcolor}>
      <CategoryTitle color={bgcolor}>
        {icon && <Icon />}
        <div>{category}</div>
        <div> {description}</div>
      </CategoryTitle>
      <CategoryDescription color={bgcolor}>
        {toolsPerCategory[category] &&
          toolsPerCategory[category].map((el, index) => index < 4000 && <li>{el.title}</li>)}
      </CategoryDescription>
    </HomeSectionsMenuItemStyled>
  );
};

const HomeSectionsMenuItemStyled = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #111;
  padding: 2rem;
  height: fit-content;
  color: white;
  gap: 2rem;
  svg {
    font-size: 8rem;
    font-weight: 200;
    color: ${(props) => props.color};
  }
  :nth-child(2n) {
    flex-direction: row-reverse;
    background-color: #151515;
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  max-width: 15rem;
  gap: 0.5rem;
  color: ${(props) => props.color};
  > div:first-of-type {
    font-size: 2rem;
  }

  & div:last-child {
    font-size: 0.8rem;
  }
`;

const CategoryDescription = styled.ul`
  display: flex;
  align-items: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 2;
  width: 100%;
  gap: 0.5rem;
  & li {
    border-radius: 0.3rem;
    padding: 0.5rem;
    background-color: ${(props) => props.color};
    color: white;
    width: 100%;
    cursor: pointer;
    max-width: 20rem;
  }
`;
