export const July4thIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 53"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M26.3001 31.8003C29.7243 31.8003 32.5001 28.2186 32.5001 23.8003C32.5001 19.382 29.7243 15.8003 26.3001 15.8003C22.8759 15.8003 20.1001 19.382 20.1001 23.8003C20.1001 28.2186 22.8759 31.8003 26.3001 31.8003Z"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3003 21.5999C20.3003 21.5999 26.7003 21.4999 26.3003 15.8999C26.3003 15.8999 26.7003 22.0999 32.3003 21.5999"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3002 26.1C20.3002 26.1 14.4002 19.9 21.1002 13.9C21.1002 13.9 23.7002 11.1 28.6002 12.4C31.6002 13.2 34.1002 15.4 34.6002 18.5C35.0002 20.5 34.6002 22.9 32.4002 25.4"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.3003 30.8003V33.3003"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2002 18.3003L11.2002 20.3003L18.2002 22.0003"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2999 15.7999L15.3999 9.3999L21.7999 13.2999"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.6001 12.2002L26.4001 5.2002L28.5001 12.3002"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.2002 13.2999L37.5002 9.3999L33.7002 15.7999"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.8 19L41.7 20.6L34.5 22.3"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.4003 36.7003L23.3003 33.3003V30.8003"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.2001 41.5002L46.4001 38.0002L38.8001 37.9002C36.7001 32.3002 30.0001 33.0002 30.0001 33.0002L14.1001 47.5002"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.0999 46.6999L42.6999 47.4999H34.8999L38.6999 37.8999"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.1001 36.2002L34.9001 47.1002"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.3003 36L24.8003 47.5"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8003 47.5H34.7003"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8003 47.5001V44.7001C13.8003 44.2001 13.6003 43.6001 13.3003 43.2001L6.30029 33.7001L11.9003 27.1001L19.3003 32.7001C19.8003 33.1001 20.4003 33.3001 21.1003 33.3001H23.3003"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.0002 16.6001L11.9002 27.1001L7.4002 32.5001L4.7002 23.8001"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3003 8.1001H0.800293V11.3001H10.3003V8.1001Z"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.60023 0.600098C5.60023 0.600098 2.40023 3.8001 2.80023 6.1001C2.80023 6.6001 3.10023 7.0001 3.50023 7.3001C6.20023 9.1001 10.2002 7.6001 7.50023 3.0001C7.20023 2.2001 6.50023 1.5001 5.60023 0.600098Z"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.1001 17.4C4.48081 17.4 5.6001 16.1912 5.6001 14.7C5.6001 13.2088 4.48081 12 3.1001 12C1.71939 12 0.600098 13.2088 0.600098 14.7C0.600098 16.1912 1.71939 17.4 3.1001 17.4Z"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.6003 46.9999C46.1467 46.9999 47.4003 45.7463 47.4003 44.1999C47.4003 42.6535 46.1467 41.3999 44.6003 41.3999C43.0539 41.3999 41.8003 42.6535 41.8003 44.1999C41.8003 45.7463 43.0539 46.9999 44.6003 46.9999Z"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5001 11.3003L7.3001 23.6003H4.2001L3.1001 17.4003"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.7001 23.6001C18.7001 23.6001 15.2001 24.5001 16.6001 30.7001"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.3003 32.6999C19.3003 32.6999 21.6003 32.2999 20.8003 27.3999"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.0001 27.1001C32.0001 27.1001 30.7001 33.8001 36.1001 33.8001C36.1001 33.8001 38.2001 25.1001 34.3001 23.6001"
        stroke={color}
        stroke-width="1.8"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
