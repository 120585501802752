export const BirthdayIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}>
      <path d="M40 40V24H8V40M40 40H8H40ZM40 40H4H8H40ZM40 40H44H40Z" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M40 34L36 32L32 34L28 32L24 34L20 32L16 34L12 32L8 34" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M32 24V15" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M24 24V15" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 24V15" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M32 10V8" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M24 10V8" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 10V8" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 24V40" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M40 24V40" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
