import styled from "styled-components";

export function Backdrop({ onDismissModal, customStyle }) {
  return (
    <BackdropStyled onClick={(e) => onDismissModal(e)} style={customStyle} />
  );
}

const BackdropStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  height: 100vh;
  width: 100vw;
  z-index: 99;
`;
