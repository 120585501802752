export const RamadanIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill="none"
      focusable="false"
      aria-hidden="true"
      style={{ ...style, width: size }}
    >
      <path
        d="M24.5 0.5L31.0362 6.32128C31.5468 6.72979 32.1596 7.03617 32.8745 7.03617H41.5553L41.7596 16.1255C41.7596 16.6362 41.9638 17.2489 42.3723 17.6574L48.5 24.5L42.7809 30.8319C42.2702 31.3426 42.066 32.0574 42.066 32.6702L41.9638 41.4532H33.0787C32.466 41.4532 31.9553 41.6574 31.5468 42.066L24.8064 48.5L17.6574 42.1681C17.1468 41.7596 16.6362 41.5553 16.0234 41.5553L7.34255 41.4532L7.1383 33.283C7.1383 32.6702 6.93404 32.0574 6.52553 31.5468L0.5 24.5L6.4234 17.7596C6.83192 17.2489 7.1383 16.534 7.1383 15.8191L7.24043 7.24043L16.3298 6.93404C16.9426 6.93404 17.5553 6.62766 18.066 6.21915L24.5 0.5Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.738 9.79395H9.99756V16.7386"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.1597 9.79395H39.2065V16.7386"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99756 32.0571V39.1039H16.738"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.2065 32.0571V39.1039H32.1597"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.4829 15.2061L26.951 19.5975H22.0488L25.9297 23.2741L24.0914 28.0741L28.4829 25.4188L32.7722 28.0741L31.2403 23.0699L35.6318 19.8018L30.5254 19.5975L28.4829 15.2061Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.564 12.3471C27.564 12.3471 19.9044 10.4067 15.2066 16.7386C13.164 19.496 12.347 23.0705 12.9598 26.5428C13.3683 29.3003 14.6959 32.4662 17.964 34.5088C23.8874 38.0833 31.8534 36.0407 35.3257 30.0152C35.7342 29.3003 36.1427 28.4833 36.4491 27.5641C36.4491 27.5641 28.7895 38.696 19.5981 30.5258C19.5981 30.5258 12.8576 25.113 19.3938 16.0237C19.4959 16.0237 22.764 12.0407 27.564 12.3471Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
