import {
  Attribution,
  defaults,
  FullScreen,
  Rotate,
  ScaleLine,
} from "ol/control";
import { useState, useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import "ol/ol.css";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { useGeographic } from "ol/proj.js";

const convertCoordinates = (lon, lat) => {
  var x = (lon * 20037508.34) / 180;
  var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
  y = (y * 20037508.34) / 180;
  return [x, y];
};

export const MapItem = ({ lng = 0, lat = 0 }) => {
  //   const mapTargetElement = useRef();
  //   const [map, setMap] = useState();
  //   useEffect(() => {
  //     // Create OpenLayers map so we can display it to the user.
  //     const InitializeMap = new Map({
  //       // The collection of layers associated with this map.
  //       layers: [],
  //       /* The map's view allows to specify the center,
  //        * zoom, resolution, and rotation of the map.
  //        */
  //       view: new View({
  //         // The map view projection.
  //         // projection: WEB_MERCATOR_COORDINATE_SYSTEM_ID, // "EPSG:3857"
  //         center: [0, 0],
  //         zoom: 0,
  //         minZoom: 0,
  //         maxZoom: 28,
  //       }),
  //       /* The map's default controls is a visible widget with a DOM
  //        * element in a fixed position on the map.
  //        */
  //       controls: defaults({ attribution: false }).extend([
  //         new Attribution({
  //           collapsed: true,
  //           collapsible: true,
  //         }),
  //         // Add a fullscreen button control to the map.
  //         new FullScreen(),
  //         // Add scale line control to the map.
  //         new ScaleLine(),
  //         // Add a reset rotation button control to the map.
  //         new Rotate(),
  //       ]),
  //     });

  //     // Set the Initialized map to the map target element.
  //     InitializeMap.setTarget(mapTargetElement.current || "");
  //     // Set the current map, so we can continue working with it.
  //     setMap(InitializeMap);

  //     /* We set map target to "undefined", an empty string to represent a
  //      * nonexistent HTML element ID, when the React component is unmounted.
  //      * This prevents multiple maps being added to the map container on a
  //      * re-render.
  //      */
  //     return () => InitializeMap.setTarget("");
  //   }, []);

  //   return (
  //     <div
  //       ref={mapTargetElement}
  //       className="map"
  //       style={{
  //         width: "100%",
  //         height: "100%",
  //         position: "relative",
  //       }}
  //     ></div>
  //   );

  const [map, setMap] = useState(null);

  useGeographic();
  useEffect(() => {
    const initialMap = new Map({
      target: "map-container",
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new VectorLayer({
          source: new VectorSource({
            features: [new Feature(new Point([lng, lat]))],
          }),
          style: {
            "circle-radius": 6,
            "circle-fill-color": "red",
            "circle-stroke-color": "blue",
            "circle-stroke-width": 3,
          },
        }),
      ],
      view: new View({
        center: [lng, lat],
        zoom: 8,
      }),
    });
    setMap(initialMap);
    return () => setMap(null);
  }, [lng, lat]);

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      id="map-container"
      className="map-container"
    ></div>
  );
};
