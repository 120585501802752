import styled from "styled-components";

const Footer = () => {
  return (
    <FooterContainer isMainMenuCollapsed={false}>
      toolsoasis  &copy; {new Date().getFullYear()}, All rights received
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  height: 3rem;
  background: rgb(35, 34, 43);
  width: 100vw;
  position: sticky;
`;

export default Footer;
