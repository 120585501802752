import { useState } from "react";
import styled from "styled-components";

export const NameInput = () => {
  const [enteredName, setEnteredName] = useState("");
  const handleNameChange = (e) => {
    setEnteredName(e.target.value);
  };
  return (
    <NameInputStyled
      type="text"
      placeholder="Enter Name"
      value={enteredName}
      onChange={handleNameChange}
      maxLength={14}
    />
  );
};

const NameInputStyled = styled.input`
  font-family: ${(props) =>
    props.value ? '"Dancing Script", cursive' : "inherit"};
  font-size: ${(props) => (props.value ? "5rem" : "1.4rem")};
  padding: ${(props) => (props.value ? "1rem 0" : "0 3rem")};
  font-weight: ${(props) => (props.value ? 400 : 500)};
  background-color: ${(props) =>
    props.value ? "transparent" : "rgba(0, 0, 0, 0.7)"};
  border: ${(props) => (props.value ? "none" : "0.2rem solid #ccc")};
  color: ${(props) => (props.value ? "black" : "inherit")};
  text-transform: capitalize;
  min-width: "25rem";
  max-width: "25rem";
`;
