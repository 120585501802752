import { Card } from "../Card/Card";
import styled from "styled-components";
export const CardsMenu = ({ cardsData }) => {
  return (
    <>
    <div className="__to__card_menu __flx-row __flx-c-c">
      {cardsData && cardsData.map((el) => <Card key={el.title} {...el} />)}
    </div>
    {/* <CardsMenuStyled>
      {cardsData && cardsData.map((el) => <MUICard {...el} />)}
    </CardsMenuStyled> */}
    </>
  );
};

const CardsMenuStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
`;
