import { useEffect, useState } from "react";
import styled from "styled-components";
import { addMonths, addDays, addYears } from "date-fns";
import { DateGeneratorCalendar } from "../../DateGenerator/DateGeneratorCalendar";
import {
  CalendarsIcon,
  ChevronDown,
} from "../../../shared/assets/icons/ToolsOasisIcons";
import { MONTHS, DAYS } from "../../DateGenerator/DateGeneratorCalendar";
import { Icon } from "../../ui/IconWrapper/Icon";
import { useNavigate } from "react-router";
const CALC_BASE = {
  0: "Last period",
  1: "Conception Date",
};

// 27 => 279
// 28 =>280
// 29 => 281
// 30 => 282
// 31 => 283
// 32 => 284

const calculateDate = (date, average) => {
  console.log(date, average, 280 + (average - 28));
  const res = addDays(new Date(date), 280 + (average - 28));
  return new Date(res);
};

const formatDate = (datetimeval) => {
  return `${
    MONTHS[datetimeval.getMonth()]
  } ${datetimeval.getDate()}, ${datetimeval.getFullYear()}`;
};

export const NewPregnancyCalculator = ({
  callback = () => {},
  countdownTypeObj,
  calculate,
  setIsFormValid,
}) => {
  const navigate = useNavigate();

  const [average, setAverage] = useState(28);
  const [lastPeriod, setLastPeriod] = useState();
  const [conceptiondate, setConceptionDate] = useState();
  const [calcBase, setCalcBase] = useState(0);
  const [dueDate, setDueDate] = useState(0);
  const [isFutureDate, setIsFutureDate] = useState(false);

  useEffect(() => {
    setIsFormValid(
      (+calcBase === 0 && lastPeriod) || (+calcBase === 1 && conceptiondate)
    );
  }, [lastPeriod, conceptiondate, calcBase, setIsFormValid]);

  useEffect(() => {
    calculate && handleCalculate();
    if (!calculate && dueDate) {
      setDueDate(0);
      setLastPeriod();
      setCalcBase(0);
      setConceptionDate();
    }
  }, [calculate]);

  function handleDateChange(e) {
    if (+calcBase === 0) setLastPeriod(e.target.value);
    else setConceptionDate(e.target.value);
  }

  function handleOptionChange(e) {
    setAverage(e.target.value);
  }

  function handleCalculate() {
    if (+calcBase === 0) setDueDate(calculateDate(lastPeriod, average));
    else setDueDate(calculateDate(conceptiondate, 14));
  }

  let options = [];
  for (let i = 22; i < 46; i++) {
    options.push(i);
  }

  return (
    <>
      {!dueDate && (
        <>
          <CalculationMethodSelectionContainer>
            <CountdownInputs>
              <CalculationMethodTitle>
                Calculation Method{" "}
              </CalculationMethodTitle>
              <CalculationMethodSelection>
                <SelectOption
                  onClick={() => {
                    setCalcBase(0);
                  }}
                  className={calcBase === 0 ? "selected" : ""}
                >
                  {CALC_BASE[0]}
                </SelectOption>
                <SelectOption
                  onClick={() => {
                    setCalcBase(1);
                  }}
                  className={calcBase === 1 ? "selected" : ""}
                >
                  {CALC_BASE[1]}
                </SelectOption>
              </CalculationMethodSelection>
            </CountdownInputs>
            {calcBase === 0 && (
              <CountdownInputs isFutureDate={isFutureDate}>
                <CalculationMethodTitle>
                  Average cycle length{" "}
                </CalculationMethodTitle>

                <DateInput>
                  <select
                    onChange={handleOptionChange}
                    style={{ opacity: 0, position: "absolute" }}
                  >
                    {options.map((el) => {
                      if (el === 28)
                        return (
                          <option value={el} selected>
                            {el + " Days Cycle"}
                          </option>
                        );
                      else
                        return <option value={el}>{el + " Days Cycle"}</option>;
                    })}
                  </select>

                  <input
                    type="text"
                    value={average + " Days Cycle"}
                    placeholder={"Select average cycle length"}
                    className="__date-time-text"
                    disabled={true}
                    style={{ width: "calc(100% - 5rem)" }}
                  />
                  <SelectCustomArrow
                    style={{ position: "absolute", zIndex: -1 }}
                  >
                    <Icon
                      icon={ChevronDown}
                      color="white"
                      size="4rem"
                      style={{ marginLeft: "1.2rem", marginTop: "1.2rem" }}
                      strokeWidth={".3rem"}
                    />
                  </SelectCustomArrow>
                </DateInput>
                {/* <input type="date" onChange={handleDateChange} /> */}

                <CalculationMethodTitle>Last Period </CalculationMethodTitle>
                <DateInput
                  onClick={() => document.getElementById("aaa").showPicker()}
                >
                  <input
                    type="text"
                    value={lastPeriod && formatDate(new Date(lastPeriod))}
                    placeholder="Select first day of last period"
                    className="__date-time-text"
                    disabled={true}
                  />
                  <input
                    id="aaa"
                    className="__date-time-input"
                    style={{ background: countdownTypeObj.color }}
                    type={countdownTypeObj.datePickerType}
                    onChange={handleDateChange}
                  />
                  <Icon
                    icon={CalendarsIcon}
                    color="white"
                    size="4rem"
                    style={{
                      position: "absolute",
                      right: ".5rem",
                      top: ".5rem",
                      zIndex: 1,
                    }}
                  />
                </DateInput>
              </CountdownInputs>
            )}
          </CalculationMethodSelectionContainer>

          {calcBase === 1 && (
            <CountdownInputs>
              <CalculationMethodTitle>
                {"Conception Date"}
              </CalculationMethodTitle>
              <DateInput
                onClick={() => document.getElementById("aaa2").showPicker()}
              >
                <input
                  type="text"
                  value={conceptiondate && formatDate(new Date(conceptiondate))}
                  placeholder="Select conception date"
                  className="__date-time-text"
                  disabled={true}
                />
                <input
                  id="aaa2"
                  className="__date-time-input"
                  style={{ background: countdownTypeObj.color }}
                  type={countdownTypeObj.datePickerType}
                  onChange={handleDateChange}
                />
                <Icon
                  icon={CalendarsIcon}
                  color="white"
                  size="4rem"
                  style={{
                    position: "absolute",
                    right: ".5rem",
                    top: ".5rem",
                    zIndex: 1,
                  }}
                />
              </DateInput>
            </CountdownInputs>
          )}
        </>
      )}

      {dueDate ? (
        <DueDateContainer>
          Your baby is due on
          <DateGeneratorCalendar
            bgcolor={countdownTypeObj.color}
            date={dueDate}
            style={{
              width: "13rem !important",
              height: "fit-content !important",
            }}
          />
          {`Lets count the seconds together \nusing our`}
        </DueDateContainer>
      ) : (
        ""
      )}

      {countdownTypeObj.outterLink && dueDate ? (
        // <OutterToolLinkage>
        //   {countdownTypeObj.outterLink.prefix}
        //   <OutterToolLink
        //     href={countdownTypeObj.outterLink.path}
        //     onClick={(e) => {
        //       e.preventDefault();
        //       navigate(countdownTypeObj.outterLink.path);
        //     }}
        //     style={{ color: countdownTypeObj.outterLink.toolColor }}
        //   >
        //     {countdownTypeObj.outterLink.toolName}
        //   </OutterToolLink>
        // </OutterToolLinkage>

        <ToolsOption
          onClick={() => {
            navigate(
              countdownTypeObj.outterLink.path +
                `?cdval=${new Date(dueDate).getTime()}`
            );
          }}
        >
          <ToolOptionIconContainer
            bgcolor={countdownTypeObj.outterLink.toolColor}
          >
            <Icon
              icon={countdownTypeObj.background}
              size={"6rem"}
              color={countdownTypeObj.outterLink.toolColor}
            />
            {/* {iconText && <IconText>{iconText}</IconText>} */}
          </ToolOptionIconContainer>
          <ToolOptionTitle
            href={"https://toolsoasis.com" + countdownTypeObj.outterLink.path}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {" "}
            {countdownTypeObj.outterLink.toolName}
          </ToolOptionTitle>
        </ToolsOption>
      ) : (
        ""
      )}
    </>
  );
};

const CalculationMethodTitle = styled.div`
  font-size: 1.3rem;
  color: white;
  text-transform: capitalize;
  text-align: left;
  width: 100%;
`;

const CalculationMethodSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const CalculationMethodSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90vw;
  max-width: 68rem;
  gap: 1rem;
`;

const SelectOption = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  font-size: 1.4rem;
  width: 100%;
  cursor: pointer;
  border-radius: 10rem;
  &.selected {
    background-color: rgb(118, 73, 191, 1);
  }
`;

const SelectCustomArrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(118, 73, 191);
  height: 5rem;
  width: 5rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CountdownInputs = styled.div`
  height: 100%;
  padding: 2rem;
  gap: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  select {
    width: 100%;
    cursor: pointer;
    border-radius: 0.8rem;
    padding: 0 3rem;
    height: 5rem;
    outline: none;
    border: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
    font-weight: 500;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    cursor: pointer;
  }

  option {
    background: #000;
    font-size: 1.4rem;
    border: 0px;
    outline: 0px;
    cursor: pointer;
  }
`;

const DateInput = styled.div`
  display: flex;
  width: 90vw;
  max-width: 68rem;
  position: relative;
  & input {
    width: 100%;
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    height: 5rem;
    outline: none;
    padding: 2rem;
    border: ${(props) => (props.isFutureDate ? "0.2rem solid red" : "none")};
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.4rem;
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
  }

  & .__date-time-input {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
`;

const DueDateContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.7rem;
  width: 100%;
  padding: 0 2rem;
  white-space: pre-wrap;
  gap: 2rem;
`;

const ToolsOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
    transform: scale(115%);
  }
  &:hover .under-construction {
    display: block;
    cursor: not-allowed;
  }
`;

const ToolOptionIconContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.bgcolor};
  text-align: center;
  min-width: 12rem;
  max-width: 12rem;
  min-height: 10rem;
  max-height: 10rem;
  border-radius: 0.8rem;
  border: 0.2rem solid ${(props) => props.bgcolor};
  position: relative;
  background: rgba(0, 0, 0, 0.9);
`;
const ToolOptionTitle = styled.a`
  min-width: 9rem;
  max-width: 9rem;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.5rem;
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  color: white;
  text-transform: capitalize;
`;
