import styled from "styled-components";
import { HomePageMenuFeaturedMenuItem } from "./HomePageMenuFeaturedItem";

export const HomePageMenuFeatured = ({ feturedItems }) => {


  return (
    <HomePageMenuFeaturedStyled>
      <HomePageMenuFeaturedHeader>
        <TitleItem>Featured</TitleItem>
        <SeeAllItem></SeeAllItem>
      </HomePageMenuFeaturedHeader>
      <HomePageMenuFeaturedMenuItems>
        {feturedItems && feturedItems.map((el) => (
          <HomePageMenuFeaturedMenuItem {...el} key={el.title} />
        ))}
      </HomePageMenuFeaturedMenuItems>
    </HomePageMenuFeaturedStyled>
  );
};

const HomePageMenuFeaturedStyled = styled.section`
  /* width: 100%; */
  max-width: 152rem;
  width:100vw;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  @media only screen and (max-width: 600px) {
    max-width: 100vw;
  }
`;

const HomePageMenuFeaturedHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

const TitleItem = styled.div`
  font-size: 1.7rem;
  line-height: 2.2rem;
  padding: 0.8rem 0;
  color: #ffffff;
`;

const SeeAllItem = styled.a``;

const HomePageMenuFeaturedMenuItems = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  max-width: 100%;
  height: 100%;
  gap: 2rem;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`;
