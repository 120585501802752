import styled from "styled-components";
import { useNavigate } from "react-router-dom";
export const HomePageMenuFeaturedMenuItem = ({
  title,
  description,
  icon,
  bgcolor,
  path,
  backgroundImage,
  subIcons,
}) => {
  const Icon = icon;
  const SubIcon1 = subIcons && subIcons[0] && subIcons[0];
  const SubIcon2 = subIcons && subIcons[1] && subIcons[1];
  const SubIcon3 = subIcons && subIcons[2] && subIcons[2];
  const SubIcon4 = subIcons && subIcons[3] && subIcons[3];
  const navigate = useNavigate();
  return (
    <HomePageMenuFeaturedItemStyled onClick={() => navigate(path)}>
      {!Array.isArray(bgcolor) && (
        <>
          <IconContainer bgcolor={bgcolor} backgroundImage={backgroundImage}>
            {icon && <Icon color={"#000000"} size={"7rem"} />}
          </IconContainer>
          <DetailsContainer>
            <Title>
              <AnchorWrapper
                href={"/" + path}
                onClick={(e) => e.preventDefault()}
              >
                {title}
              </AnchorWrapper>
            </Title>
            <Description>{description}</Description>
          </DetailsContainer>
        </>
      )}
      {Array.isArray(bgcolor) && bgcolor.length === 2 && (
        <AnchorWrapper href={"/" + path} onClick={(e) => e.preventDefault()}>
          <MultiIconContainer>
            <SubIcon bgcolor={bgcolor[0]}>
              {subIcons && subIcons[0] && (
                <SubIcon1 color={"#000000"} size={"4rem"} />
              )}
            </SubIcon>
            {icon && (
              <Icon
                color={"#fff"}
                size={"7rem"}
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />
            )}
            <SubIcon bgcolor={bgcolor[1]}>
              {subIcons && subIcons[1] && (
                <SubIcon2 color={"#000000"} size={"4rem"} />
              )}
            </SubIcon>
          </MultiIconContainer>
          <DetailsContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </DetailsContainer>
        </AnchorWrapper>
      )}
    </HomePageMenuFeaturedItemStyled>
  );
};

const HomePageMenuFeaturedItemStyled = styled.li`
  height: 25rem;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

const AnchorWrapper = styled.a``;

const IconContainer = styled.div`
  width: 16rem;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  background: linear-gradient(
    180deg,
    ${(props) => props.bgcolor} 0%,
    rgba(121, 57, 255, 0) 100%
  );
  // background: linear-gradient(-34deg, #ff4e0a 50%, #ffd61a 0);
  border-radius: 0.8rem;
`;

const MultiIconContainer = styled.div`
  width: 16rem;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2rem;
  border-radius: 0.8rem;
  background: linear-gradient(180deg, black 0%, rgba(121, 57, 255, 0) 100%);
  & > div:first-of-type {
    // border-top-left-radius: 0.8rem !important;
    // border-bottom-left-radius: 0.8rem !important;
    border-radius: 50%;
  }
  & > div:nth-of-type(2) {
    // border-top-right-radius: 0.8rem !important;
    // border-bottom-right-radius: 0.8rem !important;
    border-radius: 50%;
  }
`;

const SubIcon = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    ${(props) => props.bgcolor} 0%,
    rgba(121, 57, 255, 0) 100%
  );
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.5rem 0;
`;

const Description = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0.5rem 0;
  color: #a4a4a4;
`;
