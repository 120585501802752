export const DicesIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M36 7.5H12C9.51472 7.5 7.5 9.51472 7.5 12V36C7.5 38.4853 9.51472 40.5 12 40.5H36C38.4853 40.5 40.5 38.4853 40.5 36V12C40.5 9.51472 38.4853 7.5 36 7.5Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 26.25C25.2426 26.25 26.25 25.2426 26.25 24C26.25 22.7574 25.2426 21.75 24 21.75C22.7574 21.75 21.75 22.7574 21.75 24C21.75 25.2426 22.7574 26.25 24 26.25Z"
        fill={color}
      />
      <path
        d="M17.25 19.5C18.4926 19.5 19.5 18.4926 19.5 17.25C19.5 16.0074 18.4926 15 17.25 15C16.0074 15 15 16.0074 15 17.25C15 18.4926 16.0074 19.5 17.25 19.5Z"
        fill={color}
      />
      <path
        d="M17.25 33C18.4926 33 19.5 31.9926 19.5 30.75C19.5 29.5074 18.4926 28.5 17.25 28.5C16.0074 28.5 15 29.5074 15 30.75C15 31.9926 16.0074 33 17.25 33Z"
        fill={color}
      />
      <path
        d="M30.75 19.5C31.9926 19.5 33 18.4926 33 17.25C33 16.0074 31.9926 15 30.75 15C29.5074 15 28.5 16.0074 28.5 17.25C28.5 18.4926 29.5074 19.5 30.75 19.5Z"
        fill={color}
      />
      <path
        d="M30.75 33C31.9926 33 33 31.9926 33 30.75C33 29.5074 31.9926 28.5 30.75 28.5C29.5074 28.5 28.5 29.5074 28.5 30.75C28.5 31.9926 29.5074 33 30.75 33Z"
        fill={color}
      />
    </svg>
  );
};
