import { useState } from "react";
import { CardsMenu } from "../components/ui/CardsMenu/CardsMenu";
import styled from "styled-components";
import { HomeSectionsMenu } from "../components/HomeSectionsMenu/HomeSectionsMenu";
// import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
// import AvTimerIcon from "@mui/icons-material/AvTimer";
// import TodayIcon from "@mui/icons-material/Today";
// import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
// import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
// import { HandshakeTwoTone } from "@mui/icons-material";

const MOCK_DATA = [
  {
    title: "Timer",
    description:
      "A timer is a specialized type of clock used for measuring specific time intervals",
    img: null,
    imgAlt: null,
    // icon: AccessAlarmIcon,
    path: "Timer",
    category: "Timers",
    bgcolor: "rgba(60, 144, 189)",
    color: "white",
  },

  {
    title: "Countdown",
    description:
      "an audible backward counting in fixed units (such as seconds) from an arbitrary starting number to mark the time remaining before an event",
    img: null,
    imgAlt: null,
    // icon: AvTimerIcon,
    path: "Countdown",
    category: "Countdowns",
    bgcolor: "rgba(52, 191, 149)",
    color: "white",
  },
  {
    title: "Stopwatch",
    description:
      "a timepiece designed to measure the amount of time that elapses between its activation and deactivationr",
    img: null,
    imgAlt: null,
    // icon: CrisisAlertOutlinedIcon,

    path: "Stopwatch",
    category: "Stopwatch",
    bgcolor: "rgba(180, 146, 252)",
    color: "white",
  },
  {
    title: "DnD Dices",
    description: "Custom dice roller",
    img: null,
    imgAlt: null,
    // icon: CasinoOutlinedIcon,
    path: "Stopwatch",
    category: "Dices",
    bgcolor: "rgba(205, 97, 85)",
    color: "white",
  },
  {
    title: "Date Generator",
    description: "Random Fan Facts",
    img: null,
    imgAlt: null,
    // icon: TodayIcon,
    path: "Stopwatch",
    category: "Dates",
    bgcolor: "rgba(19, 141, 117 )",
    color: "white",
  },
  {
    title: "Fun Facts",
    description: "Some App",
    img: null,
    imgAlt: null,
    // icon: HandshakeTwoTone,
    path: "Stopwatch",
    category: "Funzies",
    bgcolor: "rgba(170, 28, 100 )",
    color: "white",
  },
  {
    title: "Fun Facts",
    description: "Some App",
    img: null,
    imgAlt: null,
    // icon: HandshakeTwoTone,
    path: "Stopwatch",
    category: "Funzies",
    bgcolor: "rgba(170, 28, 100 )",
    color: "white",
  },
];

const HomePage2 = () => {
  const [toolOptions, setToolsOptions] = useState(MOCK_DATA);

  const handleSearch = (e) => {
    setToolsOptions(
      MOCK_DATA.filter(
        (el) =>
          el.description.toLowerCase().includes(e.target.value.toLowerCase()) ||
          el.title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <HomePageStyled>
      <Summary>
        <p>
          <span
            style={{
              fontSize: "1.6rem",
              fontStyle: "italic",
            }}
          >
            tools<span className="__oasis">oasis</span>
          </span>{" "}
          is a user-friendly, ever growing, free toolkit that provides dozens of
          tools for various needs.
        </p>
        <p>
          Couldn't find the tool you were looking for?
          <a
            href="mailto:toolsoasis@gmail.com?subject=Request%20New%20Tool"
            style={{ paddingLeft: ".5rem" }}
          >
            Contact us{" "}
          </a>
          {/* <a href="#" onClick={navigate("/ContactUs")}> */} and we'll put it
          on our to-do list!
        </p>
      </Summary>
      <div>
        <input
          type="search"
          placeholder="Search a tool..."
          onChange={handleSearch}
        />
      </div>
      {/* <CardsMenu cardsData={toolOptions} /> */}
      <HomeSectionsMenu cardsData={MOCK_DATA} />
    </HomePageStyled>
  );
};

const HomePageStyled = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 108rem;
  & input {
    max-width: 60rem;
  }

  & .__oasis {
    color: #38d6d6;
    font-size: inherit;
    font-weight: inherit;
  }
`;

const Summary = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 2rem;
  text-align: center;
  & p {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export default HomePage2;
