export const FunziesIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M31.7959 28.4995C31.0052 29.8672 29.8686 31.0028 28.5002 31.7922C27.1318 32.5817 25.5798 32.9973 24 32.9974C22.4202 32.9974 20.8683 32.5818 19.4999 31.7923C18.1315 31.0029 16.9948 29.8673 16.2041 28.4996"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.2909 41.2097C25.454 42.3863 21.3338 42.2455 17.5862 40.8097C13.8386 39.3739 10.6791 36.7256 8.61064 33.2865C6.54218 29.8474 5.68371 25.8152 6.17188 21.8318C6.66006 17.8483 8.46683 14.1427 11.3045 11.3049C14.1422 8.46705 17.8478 6.66011 21.8312 6.17174C25.8146 5.68338 29.8468 6.54166 33.286 8.60995C36.7252 10.6782 39.3736 13.8376 40.8096 17.5852C42.2456 21.3327 42.3866 25.4529 41.2102 29.2898L29.2909 41.2097Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.25 22.5C18.4926 22.5 19.5 21.4926 19.5 20.25C19.5 19.0074 18.4926 18 17.25 18C16.0074 18 15 19.0074 15 20.25C15 21.4926 16.0074 22.5 17.25 22.5Z"
        fill={color}
      />
      <path
        d="M30.75 22.5C31.9926 22.5 33 21.4926 33 20.25C33 19.0074 31.9926 18 30.75 18C29.5074 18 28.5 19.0074 28.5 20.25C28.5 21.4926 29.5074 22.5 30.75 22.5Z"
        fill={color}
      />
    </svg>
  );
};
