import styled from "styled-components";
import { Icon } from "../IconWrapper/Icon";
import { TOOLS } from "./ToolsTypes";
import { useEffect, useState } from "react";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";

export const ToolBase = ({
  toolTitle = "",
  toolDescription = "",
  toolInfo = "",
  type,
  typeFam,
  showButtons = true,
  showMainIcon = true,
  isPrimaryMainButton,
  isSecondaryMainButton,
  isPicMode = true,
  primaryBtn = {
    mainTitle: "mainTitle",
    alternativeTitle: "alternativeTitle",
    clickHandler: { main: () => {}, secondary: () => {} },
  },
  secondaryBtn = {
    mainTitle: "mainTitle",
    alternativeTitle: "alternativeTitle",
    clickHandler: () => {},
  },
  main,
}) => {
  let routerLocation = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  let background = TOOLS[typeFam][type]?.backgroundImg?.pc;
  let showPicMode = isPicMode && background;

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    document.title = toolTitle;
    const descEl = document.querySelector("meta[name='description']");
    descEl.setAttribute(
      "content",
      toolDescription ? toolDescription : TOOLS[typeFam][type].toolDescription
    );
    const canonicalEl = document.querySelector("link[rel='canonical']");
    canonicalEl.setAttribute(
      "href",
      window.location.href.replace("www.", "").replace("http://", "https://")
    );
  }, [routerLocation, toolTitle, toolDescription]);

  return (
    <div
      className="__to__tool-container"
      style={
        showPicMode
          ? {
              backgroundImage: `url(${
                !isMobile
                  ? TOOLS[typeFam][type]?.backgroundImg?.pc
                  : TOOLS[typeFam][type]?.backgroundImg?.mobile
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
              padding: "2rem",
            }
          : {
            paddingTop: "7rem",
            width: "100%",

          }
      }
    >
      {TOOLS[typeFam] && (
        <>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,.6)",
              color: "white",
              width: "100%",
              padding: "1rem",
              display: "none",
            }}
          >
            <Icon
              icon={TOOLS[typeFam][type].background}
              color="#222"
              style={{
                zIndex: -1,
                position: "absolute",
                minWidth: TOOLS[typeFam][type].backgroundSize.width,
                maxWidth: TOOLS[typeFam][type].backgroundSize.width,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
            <div className="__to__tool-title">
              <Icon
                icon={TOOLS[typeFam][type].icon}
                color={showPicMode ? "white" : "white"}
                size="3rem"
              />
              <h1
              // style={
              //   showPicMode
              //     ? {
              //         color: "black",
              //       }
              //     : {}
              // }
              >
                {toolTitle}
              </h1>
            </div>
          </div>
          <Breadcrumbs
            showBG={true}
            options={[
              { title: "Home", path: "/" },
              { title: typeFam, path: `/featured/${typeFam}` },
              {
                title: <h1 style={{ fontSize: "inherit" }}>{toolTitle}</h1>,
                icon: TOOLS[typeFam][type].icon,
              },
            ]}
            emphasisColor={TOOLS[typeFam][type].color}
          />
          {showMainIcon && (
            <div className="__to__tool-icon">
              <Icon
                icon={TOOLS[typeFam].mainIcon}
                color={TOOLS[typeFam][type].color}
                size={"7rem"}
              />
            </div>
          )}

          <div className="__to__tool-main">{main}</div>
          {showButtons && (
            <div className="__to__tool-buttons-container">
              {primaryBtn && (
                <button
                  style={{
                    backgroundColor: TOOLS[typeFam][type].color,
                    maxWidth: showPicMode ? "90vw" : "90vw",
                    textAlign: "center",
                  }}
                  className={"start-btn"}
                  onClick={
                    isPrimaryMainButton
                      ? primaryBtn.clickHandler.main
                      : primaryBtn.clickHandler.secondary
                  }
                >
                  {isPrimaryMainButton
                    ? primaryBtn.mainTitle
                    : primaryBtn.secondaryTitle}
                </button>
              )}
              {secondaryBtn && (
                <button
                  style={{
                    backgroundColor: TOOLS[typeFam][type].color,
                  }}
                  className={"start-btn"}
                  onClick={
                    isSecondaryMainButton
                      ? secondaryBtn.clickHandler.main
                      : secondaryBtn.clickHandler.secondary
                  }
                >
                  {isSecondaryMainButton
                    ? secondaryBtn.mainTitle
                    : secondaryBtn.secondaryTitle}
                </button>
              )}
              {/* <div className="__to__tool-suggestions">{suggestions}</div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};
