import styled from "styled-components";

import { CountdownBox } from "../CountdownBox";
import { useCountdown } from "../../../hooks/useCountdown";
import { useState, useEffect } from "react";
import { FlowersImg } from "./FlowersImg";
import { NameInput } from "./NameInput";
export const WeddingCountdown = ({ type }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);

  let data = useCountdown(selectedDate, isStart);

  useEffect(() => {
    setIsPastDate(new Date(selectedDate) - new Date() < 10);
    if (new Date(selectedDate) - new Date() > 10) setIsStart(true);
  }, [selectedDate]);

  const handleDateSelection = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <WeddingCountdownStyled className="__flex-col  __layout__styled __no-top-bottom texture-pattern-2">
      <FlowersImg position={"top"} />
      <NamesStyled>
        <NameInput />
        <div>&</div>
        <NameInput />
      </NamesStyled>
      <DateTimeStyled className="" isPastDate={isPastDate}>
        {selectedDate && !isPastDate && (
          <div onClick={()=>{setSelectedDate("")}}>
            {new Date(selectedDate).toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
               minute: "2-digit",
              hour12: true,
            })}
          </div>
        )}
        {(!selectedDate || isPastDate) && (
          <input
            style={{ width: "25rem" }}
            className="__date-time-input"
            type="text"
            onMouseEnter={(e) => (e.target.type = "datetime-local")}
            onBlur={(e) => (e.target.type = "text")}
            onChange={handleDateSelection}
            value={selectedDate.replace("T", " ")}
            placeholder="Enter Date"
          />
        )}
      </DateTimeStyled>
      <div
        style={{
          color: "red",
          display: isPastDate ? "block" : "none",
        }}
      >
        Please Select Future Date
      </div>
      <CountingStyled className="">
        Counting the seconds to become one
      </CountingStyled>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CountdownBox
          {...data}
          style={{
            maxWidth: "95",
          }}
          coloredColor={"#e6c509"}
        />
      </div>
      <FlowersImg position={"bottom"} />
    </WeddingCountdownStyled>
  );
};

const WeddingCountdownStyled = styled.div`
  max-width: 100vw;
  min-width: 100vw;
  background-color: #faf6eb;
  padding: 6rem 1rem;
  position: relative;
  gap: 3rem;

  background-image: repeating-linear-gradient(
    -45deg,
    rgba(256, 180, 80, 0.15),
    rgba(244, 180, 80, 0.15) 0.1rem,
    transparent 0.1rem,
    transparent 0.6rem
  );
`;

const NamesStyled = styled.div`
  width: 100%;
  height: fit-content;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    font-family: "Dancing Script", cursive;
    font-size: 5rem;
    font-weight: 500;
    padding: 1rem 0;
  }
`;

const CountingStyled = styled.div`
  font-size: 1.6rem;
  padding: 0 3rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const DateTimeStyled = styled.div`
  div {
    font-size: 1.6rem;
    padding: 0 3rem;
    font-weight: 600;
  }

  input {
    color: ${(props) => (props.isPastDate ? "red" : "inherit")};
  }
`;
