export const HoliIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_240_1785)">
        <path
          d="M45.4424 16.9771C42.7354 16.4095 44.2257 15.5916 44.2139 14.1152C44.2502 12.7264 41.9591 12.5009 41.0237 14.9534C40.0883 17.406 36.587 18.3009 35.257 16.2932C32.6536 12.5888 26.3116 10.594 23.8496 15.2532C23.7705 15.5248 23.8272 15.8359 24.0111 15.9631C25.7537 17.0599 32.3566 21.1929 33.0355 21.3907C33.8021 21.5404 35.5672 22.0546 37.5835 20.948C39.6875 19.7933 40.3696 21.2441 39.8725 23.4561C39.4855 25.0373 38.0628 33.4608 42.4493 32.3082C46.8358 31.1556 47.4675 25.4476 44.8331 22.1024C42.1988 18.7573 45.4264 20.0658 47.0782 19.9578C48.73 19.8497 48.0136 17.5052 45.4424 16.9771Z"
          fill={color}
        />
        <path
          d="M42.0641 12.8997C42.3271 12.7554 41.8236 12.4614 41.6792 12.1984C41.5349 11.9354 41.2633 11.8563 41.0003 12.0007C40.7373 12.145 40.6582 12.4165 40.8026 12.6795C40.8988 12.8549 41.8011 13.0441 42.0641 12.8997Z"
          fill={color}
        />
        <path
          d="M23.9587 11.0871C23.3589 10.6177 22.6009 10.6915 22.0835 11.2036C21.6141 11.8034 22.107 14.1565 22.7068 14.6258C23.3066 15.0952 23.6936 13.5139 24.1629 12.9142C24.6322 12.3144 24.5584 11.5564 23.9587 11.0871Z"
          fill={color}
        />
        <path
          d="M40.359 13.9499C40.5824 13.9414 41.0603 13.565 41.0999 13.4293C41.0913 13.2058 40.6925 13.3106 40.5568 13.271C40.421 13.2315 40.158 13.3758 40.1184 13.5116C40.0789 13.6474 40.2232 13.9104 40.359 13.9499Z"
          fill={color}
        />
        <path
          d="M39.5407 12.4595C39.6284 12.4114 39.3568 12.3323 39.2606 12.1569C39.2125 12.0693 38.9891 12.0778 38.9014 12.1259C38.8137 12.1741 38.8223 12.3975 38.8704 12.4852C39.0543 12.6124 39.4531 12.5076 39.5407 12.4595Z"
          fill={color}
        />
        <path
          d="M41.5194 33.7312C39.2283 33.5056 39.7617 29.9049 37.8758 32.0806C36.0776 34.2081 36.531 36.697 38.8702 37.0102C41.2094 37.3234 45.0838 35.6533 45.3799 32.8672C45.676 30.0811 43.8981 33.9086 41.5194 33.7312Z"
          fill={color}
        />
        <path
          d="M31.641 22.3838L10.8679 10.514C10.2286 10.1804 9.34338 10.4381 9.14561 11.117L8.13966 14.0646C7.98144 14.6077 8.18245 15.1818 8.686 15.4758L9.14145 15.6821L2.2087 32.1486C1.7565 33.1953 2.11896 34.4793 3.12607 35.0673L13.5735 41.425C14.5806 42.013 15.8251 41.7863 16.5969 40.9064L28.0446 26.6389L28.4124 26.8933C28.9159 27.1873 29.5381 27.074 29.8802 26.6581L31.8933 24.2985C32.4503 23.6506 32.3284 22.805 31.641 22.3838Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M8.58349 12.7942L6.98516 11.9604C6.98516 11.9604 2.30373 19.4346 13.3595 26.4851C13.3595 26.4851 25.7197 34.873 32.3693 26.2045L31.2093 25.1301"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_240_1785">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
