import styled from "styled-components";
import { Icon } from "../../../ui/IconWrapper/Icon";
import {
  CopyIcon,
  DatesIcon,
} from "../../../../shared/assets/icons/ToolsOasisIcons";
import { useNavigate } from "react-router";
export const RandomDateResult = ({ el, copyToClipBoard }) => {
  const navigate = useNavigate();
  return (
    <RandomDateResultStyled>
      <div id={el.date} className="copy-target">{el.date}</div>
      <div
        
        onClick={() => {
          copyToClipBoard(el.date.toString());
          // alert("ib");
        }}
      >
        <Icon
          icon={CopyIcon}
          color={"rgba(256,256,256,0.5)"}
          size={"3rem"}
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
        />
      </div>
      <ToolsOption
        href={`/Dates/on-this-day`}
        onClick={(e) => {
          e.preventDefault();
          let dval = el.dateMS;
          navigate(`/Dates/on-this-day?dval=${dval}`);
        }}
      >
        <ToolOptionIconContainer bgcolor={"rgb(205, 97, 85)"}>
          <Icon icon={DatesIcon} size={"2.2rem"} color={"rgb(205, 97, 85)"} />
          <IconText>on This Day</IconText>
        </ToolOptionIconContainer>
        {/* <ToolOptionTitle> on this date</ToolOptionTitle> */}
      </ToolsOption>
    </RandomDateResultStyled>
  );
};

const RandomDateResultStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 90vw;
  max-width: 68rem;
  cursor: pointer;
  border-radius: 0.8rem;
  min-height: 5rem;
  outline: none;
  padding: 2rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  -webkit-user-select: none !important; /* Safari */
  -ms-user-select: none !important; /* IE 10 and IE 11 */
  user-select: none !important; /* Standard syntax */
  .copy-target {
    font-size: 1.4rem;
  }
`;

const ToolsOption = styled.a`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 6rem;
  &:hover {
    transform: scale(115%);
  }
  &:hover .under-construction {
    display: block;
    cursor: not-allowed;
  }
`;

const ToolOptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.bgcolor};
  text-align: center;
  min-width: 5rem;
  max-width: 5rem;
  min-height: 4.1rem;
  max-height: 4.1rem;
  border-radius: 0.8rem;
  border: 0.2rem solid ${(props) => props.bgcolor};
  position: relative;
`;
const ToolOptionTitle = styled.div`
  min-width: 5rem;
  max-width: 5rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  color: white;
`;

const IconText = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
`;
