import { ChipsFilterItem } from "./ChipsFilterItem";
import { useEffect, useState } from "react";
import styled from "styled-components";

export const ChipsFilter = ({ options, bgcolor, handleFilterSelection }) => {
  const [filterOptions, setFilterOptions] = useState(null);
  useEffect(() => {
    options &&
      setFilterOptions(
        options.map((el) => {
          return { title: el.title, isSelected: el.isSelected };
        })
      );
  }, [options]);

  return (
    filterOptions && (
      <ChipsFilterStyled>
        {filterOptions.map((el) => (
          <ChipsFilterItem
            key={el.title}
            filterOption={el}
            bgcolor={bgcolor}
            onClick={() => handleFilterSelection && handleFilterSelection(el)}
          />
        ))}
      </ChipsFilterStyled>
    )
  );
};

const ChipsFilterStyled = styled.div`
  width: 100%;
  padding: 2rem;
  gap: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  // background-color: rgba(0, 0, 0, 0.7); 
  border-radius: 0.8rem;
  color: white;
`;
