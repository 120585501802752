export const CopyIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
  strokeWidth,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M31.5 31.4985H40.5V7.49854H16.5V16.4985"
        stroke={color}
        stroke-width={strokeWidth || "2"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.4998 16.499H7.49976V40.499H31.4998V16.499Z"
        stroke={color}
        stroke-width={strokeWidth || "2"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
