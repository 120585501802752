export const LaborDayIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M3.40004 13.8999C3.40004 13.8999 0.30004 11.2999 0.60004 8.49991C0.60004 7.99991 1.20004 7.59991 1.70004 7.89991L5.30004 9.69991C6.00004 10.0999 6.90004 9.79991 7.30004 9.09991L8.40004 6.99991C8.80004 6.29991 8.60004 5.39991 7.90004 4.89991L4.50004 2.49991C4.00004 2.19991 4.00004 1.49991 4.50004 1.29991C6.10004 0.599911 9.20004 -0.300089 12.5 2.49991C12.5 2.49991 14.6 4.39991 14.7 6.99991C14.7 7.59991 15 8.19991 15.6 8.49991L18.6 10.1999"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.1001 15.0998C6.1001 15.0998 8.8001 15.3998 10.6001 14.5998C11.1001 14.3998 11.6001 14.3998 12.1001 14.6998L13.7001 15.6998"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.2998 27.5997C33.2998 27.5997 35.3998 36.9997 43.0998 33.5997C43.6998 33.3997 43.6998 32.5997 43.1998 32.2997L40.0998 30.3997C39.3998 29.8997 39.1998 28.9997 39.5998 28.2997L40.7998 26.2997C41.2998 25.3997 42.4998 25.0997 43.3998 25.5997L46.6998 27.1997C46.9998 27.3997 47.4998 27.0997 47.3998 26.6997C47.2998 24.2997 46.1998 17.3997 37.0998 19.8997"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 14.9997L19 9.69969C19.5 8.39969 21 7.89969 22.2 8.59969L22.9 8.99969C23.9 9.59969 24.3 10.7997 23.9 11.8997L22.3 16.5997L24.3 12.7997C24.9 11.5997 26.5 11.1997 27.6 11.9997C28.7 12.6997 29.2 14.0997 28.7 15.2997L26 23.6997C26 23.6997 22.3 26.6997 20.9 22.2997"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.1998 18.6997L34.2998 26.5997C34.2998 26.5997 32.7998 27.7997 31.4998 26.6997C30.8998 26.1997 30.6998 25.2997 30.8998 24.4997L31.6998 21.6997L31.2998 21.3997L30.1998 24.6997C29.9998 25.3997 29.2998 25.9997 28.5998 26.0997C27.5998 26.2997 26.1998 25.9997 25.7998 23.5997C25.7998 23.5997 28.6998 14.1997 30.0998 13.7997C31.5998 13.3997 33.3998 14.7997 33.4998 15.6997C33.5998 16.6997 32.5998 18.4997 32.5998 18.4997C32.5998 18.4997 33.9998 15.3997 34.9998 16.1997C36.1998 17.0997 37.3998 18.2997 37.1998 18.6997Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.3 20.8999C16.3 20.8999 22.1 24.1999 24.7 19.2999C25.1 18.5999 24.7 17.6999 24 17.3999C21.4 16.3999 16.2 14.8999 15.5 14.9999C14.7 15.0999 12.2 17.5999 11.6 20.0999C11 22.5999 9.6 24.9999 10.6 27.0999C11.6 29.1999 16.3 35.5999 16.5 36.8999C16.7 38.1999 16.4 47.4999 16.4 47.4999"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6002 28.0001L17.7002 21.6001"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.7001 33.5L31.9001 36.2C31.7001 36.8 31.6001 37.5 31.6001 38.1V47.5"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
