const DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const MONTHS = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const MONTHS_TO_INDEX = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const holidays = {
  halloween: {
    isFixed: true,
    fixedDateData: {
      dateDay: 31,
      month: MONTHS_TO_INDEX.October,
    },
    Dates: [],
  },
  easter: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 9, month: MONTHS_TO_INDEX.April, year: 2023 },
      { dateDay: 31, month: MONTHS_TO_INDEX.March, year: 2024 },
      { dateDay: 20, month: MONTHS_TO_INDEX.April, year: 2025 },
      { dateDay: 5, month: MONTHS_TO_INDEX.April, year: 2026 },
      { dateDay: 28, month: MONTHS_TO_INDEX.March, year: 2027 },
      { dateDay: 16, month: MONTHS_TO_INDEX.April, year: 2028 },
      { dateDay: 1, month: MONTHS_TO_INDEX.April, year: 2029 },
      { dateDay: 21, month: MONTHS_TO_INDEX.April, year: 2030 },
      { dateDay: 13, month: MONTHS_TO_INDEX.April, year: 2031 },
      { dateDay: 28, month: MONTHS_TO_INDEX.March, year: 2032 },
      { dateDay: 17, month: MONTHS_TO_INDEX.April, year: 2033 },
    ],
  },
  boxing_day: {
    isFixed: true,
    fixedDateData: {
      dateDay: 26,
      month: MONTHS_TO_INDEX.December,
    },
    Dates: [],
  },
  holi: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 8, month: MONTHS_TO_INDEX.March, year: 2023 },
      { dateDay: 25, month: MONTHS_TO_INDEX.March, year: 2024 },
      { dateDay: 14, month: MONTHS_TO_INDEX.March, year: 2025 },
      { dateDay: 3, month: MONTHS_TO_INDEX.March, year: 2026 },
      { dateDay: 22, month: MONTHS_TO_INDEX.March, year: 2027 },
      { dateDay: 11, month: MONTHS_TO_INDEX.March, year: 2028 },
      { dateDay: 1, month: MONTHS_TO_INDEX.March, year: 2029 },
      { dateDay: 20, month: MONTHS_TO_INDEX.March, year: 2030 },
      { dateDay: 9, month: MONTHS_TO_INDEX.March, year: 2031 },
      { dateDay: 27, month: MONTHS_TO_INDEX.March, year: 2032 },
      { dateDay: 16, month: MONTHS_TO_INDEX.March, year: 2033 },
    ],
  },
  nye: {
    isFixed: true,
    fixedDateData: {
      dateDay: 31,
      month: MONTHS_TO_INDEX.December,
    },
    Dates: [],
  },
  hanukkah: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 15, month: MONTHS_TO_INDEX.December, year: 2023 },
      { dateDay: 25, month: MONTHS_TO_INDEX.December, year: 2024 },
      { dateDay: 14, month: MONTHS_TO_INDEX.December, year: 2025 },
      { dateDay: 4, month: MONTHS_TO_INDEX.December, year: 2026 },
      { dateDay: 24, month: MONTHS_TO_INDEX.December, year: 2027 },
      { dateDay: 12, month: MONTHS_TO_INDEX.December, year: 2028 },
      { dateDay: 1, month: MONTHS_TO_INDEX.December, year: 2029 },
      { dateDay: 20, month: MONTHS_TO_INDEX.December, year: 2030 },
      { dateDay: 9, month: MONTHS_TO_INDEX.December, year: 2031 },
      { dateDay: 27, month: MONTHS_TO_INDEX.November, year: 2032 },
      { dateDay: 16, month: MONTHS_TO_INDEX.December, year: 2033 },
      { dateDay: 6, month: MONTHS_TO_INDEX.December, year: 2034 },
      { dateDay: 25, month: MONTHS_TO_INDEX.December, year: 2035 },
      { dateDay: 13, month: MONTHS_TO_INDEX.December, year: 2036 },
      { dateDay: 2, month: MONTHS_TO_INDEX.December, year: 2037 },
      { dateDay: 21, month: MONTHS_TO_INDEX.December, year: 2038 },
      { dateDay: 11, month: MONTHS_TO_INDEX.December, year: 2039 },
      { dateDay: 29, month: MONTHS_TO_INDEX.November, year: 2040 },
      { dateDay: 17, month: MONTHS_TO_INDEX.December, year: 2041 },
      { dateDay: 7, month: MONTHS_TO_INDEX.December, year: 2042 },
      { dateDay: 26, month: MONTHS_TO_INDEX.December, year: 2043 },
      { dateDay: 14, month: MONTHS_TO_INDEX.December, year: 2044 },
      { dateDay: 3, month: MONTHS_TO_INDEX.December, year: 2045 },
      { dateDay: 23, month: MONTHS_TO_INDEX.December, year: 2046 },
      { dateDay: 12, month: MONTHS_TO_INDEX.December, year: 2047 },
      { dateDay: 29, month: MONTHS_TO_INDEX.November, year: 2048 },
      { dateDay: 19, month: MONTHS_TO_INDEX.December, year: 2049 },
      { dateDay: 9, month: MONTHS_TO_INDEX.December, year: 2050 },
    ],
  },
  day_of_the_dead: {
    isFixed: true,
    fixedDateData: {
      dateDay: 1,
      month: MONTHS_TO_INDEX.November,
    },
    Dates: [],
  },
  samhain: {
    isFixed: true,
    fixedDateData: {
      dateDay: 31,
      month: MONTHS_TO_INDEX.October,
    },
    Dates: [],
  },
  valentines_day: {
    isFixed: true,
    fixedDateData: {
      dateDay: 14,
      month: MONTHS_TO_INDEX.February,
    },
    Dates: [],
  },
  july_4th: {
    isFixed: true,
    fixedDateData: {
      dateDay: 4,
      month: MONTHS_TO_INDEX.July,
    },
    Dates: [],
  },
  veterans_day: {
    isFixed: true,
    fixedDateData: {
      dateDay: 11,
      month: MONTHS_TO_INDEX.November,
    },
    type: "federal holiday",
    country: "US",
  },

  diwali_amavasya: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 12, month: MONTHS_TO_INDEX.November, year: 2023 },
      { dateDay: 1, month: MONTHS_TO_INDEX.November, year: 2024 },
      { dateDay: 21, month: MONTHS_TO_INDEX.October, year: 2025 },
      { dateDay: 8, month: MONTHS_TO_INDEX.November, year: 2026 },
      { dateDay: 29, month: MONTHS_TO_INDEX.October, year: 2027 },
      { dateDay: 17, month: MONTHS_TO_INDEX.October, year: 2028 },
      { dateDay: 5, month: MONTHS_TO_INDEX.November, year: 2029 },
      { dateDay: 26, month: MONTHS_TO_INDEX.October, year: 2030 },
      { dateDay: 14, month: MONTHS_TO_INDEX.November, year: 2031 },
      { dateDay: 2, month: MONTHS_TO_INDEX.November, year: 2032 },
      { dateDay: 22, month: MONTHS_TO_INDEX.October, year: 2033 },
    ],
  },
  diwali_krisna_chaturdasi: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 12, month: MONTHS_TO_INDEX.November, year: 2023 },
      { dateDay: 31, month: MONTHS_TO_INDEX.October, year: 2024 },
      { dateDay: 20, month: MONTHS_TO_INDEX.October, year: 2025 },
      { dateDay: 8, month: MONTHS_TO_INDEX.November, year: 2026 },
      { dateDay: 28, month: MONTHS_TO_INDEX.October, year: 2027 },
      { dateDay: 17, month: MONTHS_TO_INDEX.October, year: 2028 },
      { dateDay: 5, month: MONTHS_TO_INDEX.November, year: 2029 },
      { dateDay: 26, month: MONTHS_TO_INDEX.October, year: 2030 },
      { dateDay: 13, month: MONTHS_TO_INDEX.November, year: 2031 },
      { dateDay: 2, month: MONTHS_TO_INDEX.November, year: 2032 },
      { dateDay: 22, month: MONTHS_TO_INDEX.October, year: 2033 },
    ],
  },
  mothers_day: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 14, month: MONTHS_TO_INDEX.May, year: 2023 },
      { dateDay: 12, month: MONTHS_TO_INDEX.May, year: 2024 },
      { dateDay: 11, month: MONTHS_TO_INDEX.May, year: 2025 },
      { dateDay: 10, month: MONTHS_TO_INDEX.May, year: 2026 },
      { dateDay: 9, month: MONTHS_TO_INDEX.May, year: 2027 },
      { dateDay: 14, month: MONTHS_TO_INDEX.May, year: 2028 },
      { dateDay: 13, month: MONTHS_TO_INDEX.May, year: 2029 },
      { dateDay: 12, month: MONTHS_TO_INDEX.May, year: 2030 },
      { dateDay: 11, month: MONTHS_TO_INDEX.May, year: 2031 },
      { dateDay: 9, month: MONTHS_TO_INDEX.May, year: 2032 },
      { dateDay: 8, month: MONTHS_TO_INDEX.May, year: 2033 },
    ],
  },
  labor_day: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 4, month: MONTHS_TO_INDEX.September, year: 2023 },
      { dateDay: 2, month: MONTHS_TO_INDEX.September, year: 2024 },
      { dateDay: 1, month: MONTHS_TO_INDEX.September, year: 2025 },
      { dateDay: 7, month: MONTHS_TO_INDEX.September, year: 2026 },
      { dateDay: 6, month: MONTHS_TO_INDEX.September, year: 2027 },
      { dateDay: 4, month: MONTHS_TO_INDEX.September, year: 2028 },
      { dateDay: 3, month: MONTHS_TO_INDEX.September, year: 2029 },
      { dateDay: 2, month: MONTHS_TO_INDEX.September, year: 2030 },
      { dateDay: 1, month: MONTHS_TO_INDEX.September, year: 2031 },
      { dateDay: 6, month: MONTHS_TO_INDEX.September, year: 2032 },
      { dateDay: 5, month: MONTHS_TO_INDEX.September, year: 2033 },
    ],
  },
  ramadan: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 23, month: MONTHS_TO_INDEX.March, year: 2023 },
      { dateDay: 11, month: MONTHS_TO_INDEX.March, year: 2024 },
      { dateDay: 1, month: MONTHS_TO_INDEX.March, year: 2025 },
      { dateDay: 18, month: MONTHS_TO_INDEX.February, year: 2026 },
      { dateDay: 8, month: MONTHS_TO_INDEX.February, year: 2027 },
      { dateDay: 28, month: MONTHS_TO_INDEX.January, year: 2028 },
      { dateDay: 16, month: MONTHS_TO_INDEX.January, year: 2029 },
      { dateDay: 6, month: MONTHS_TO_INDEX.January, year: 2030 },
    ],
  },
  good_friday: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 7, month: MONTHS_TO_INDEX.April, year: 2023 },
      { dateDay: 29, month: MONTHS_TO_INDEX.March, year: 2024 },
      { dateDay: 18, month: MONTHS_TO_INDEX.April, year: 2025 },
      { dateDay: 3, month: MONTHS_TO_INDEX.April, year: 2026 },
      { dateDay: 26, month: MONTHS_TO_INDEX.March, year: 2027 },
      { dateDay: 14, month: MONTHS_TO_INDEX.April, year: 2028 },
      { dateDay: 30, month: MONTHS_TO_INDEX.March, year: 2029 },
      { dateDay: 19, month: MONTHS_TO_INDEX.April, year: 2030 },
      { dateDay: 11, month: MONTHS_TO_INDEX.April, year: 2031 },
      { dateDay: 26, month: MONTHS_TO_INDEX.March, year: 2032 },
      { dateDay: 15, month: MONTHS_TO_INDEX.April, year: 2033 },
    ],
  },
  thanksgiving_us: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 23, month: MONTHS_TO_INDEX.November, year: 2023 },
      { dateDay: 28, month: MONTHS_TO_INDEX.November, year: 2024 },
      { dateDay: 27, month: MONTHS_TO_INDEX.November, year: 2025 },
      { dateDay: 26, month: MONTHS_TO_INDEX.November, year: 2026 },
      { dateDay: 25, month: MONTHS_TO_INDEX.November, year: 2027 },
      { dateDay: 23, month: MONTHS_TO_INDEX.November, year: 2028 },
      { dateDay: 22, month: MONTHS_TO_INDEX.November, year: 2029 },
      { dateDay: 28, month: MONTHS_TO_INDEX.November, year: 2030 },
      { dateDay: 27, month: MONTHS_TO_INDEX.November, year: 2031 },
      { dateDay: 25, month: MONTHS_TO_INDEX.November, year: 2032 },
      { dateDay: 24, month: MONTHS_TO_INDEX.November, year: 2033 },
    ],
  },
  thanksgiving_ca: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 9, month: MONTHS_TO_INDEX.October, year: 2023 },
      { dateDay: 14, month: MONTHS_TO_INDEX.October, year: 2024 },
      { dateDay: 13, month: MONTHS_TO_INDEX.October, year: 2025 },
      { dateDay: 12, month: MONTHS_TO_INDEX.October, year: 2026 },
      { dateDay: 11, month: MONTHS_TO_INDEX.October, year: 2027 },
      { dateDay: 9, month: MONTHS_TO_INDEX.October, year: 2028 },
      { dateDay: 8, month: MONTHS_TO_INDEX.October, year: 2029 },
      { dateDay: 14, month: MONTHS_TO_INDEX.October, year: 2030 },
      { dateDay: 13, month: MONTHS_TO_INDEX.October, year: 2031 },
      { dateDay: 11, month: MONTHS_TO_INDEX.October, year: 2032 },
      { dateDay: 10, month: MONTHS_TO_INDEX.October, year: 2033 },
    ],
  },
  ascension_day: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 18, month: MONTHS_TO_INDEX.May, year: 2023 },
      { dateDay: 9, month: MONTHS_TO_INDEX.May, year: 2024 },
      { dateDay: 29, month: MONTHS_TO_INDEX.May, year: 2025 },
      { dateDay: 14, month: MONTHS_TO_INDEX.May, year: 2026 },
      { dateDay: 6, month: MONTHS_TO_INDEX.May, year: 2027 },
      { dateDay: 25, month: MONTHS_TO_INDEX.May, year: 2028 },
      { dateDay: 10, month: MONTHS_TO_INDEX.May, year: 2029 },
      { dateDay: 30, month: MONTHS_TO_INDEX.May, year: 2030 },
      { dateDay: 22, month: MONTHS_TO_INDEX.May, year: 2031 },
      { dateDay: 6, month: MONTHS_TO_INDEX.May, year: 2032 },
      { dateDay: 26, month: MONTHS_TO_INDEX.May, year: 2033 },
    ],
  },
  pentecost: {
    isFixed: false,
    fixedDateData: null,
    rule: null,
    Dates: [
      { dateDay: 28, month: MONTHS_TO_INDEX.May, year: 2023 },
      { dateDay: 19, month: MONTHS_TO_INDEX.May, year: 2024 },
      { dateDay: 8, month: MONTHS_TO_INDEX.June, year: 2025 },
      { dateDay: 24, month: MONTHS_TO_INDEX.May, year: 2026 },
      { dateDay: 16, month: MONTHS_TO_INDEX.May, year: 2027 },
      { dateDay: 4, month: MONTHS_TO_INDEX.June, year: 2028 },
      { dateDay: 20, month: MONTHS_TO_INDEX.May, year: 2029 },
      { dateDay: 9, month: MONTHS_TO_INDEX.June, year: 2030 },
      { dateDay: 1, month: MONTHS_TO_INDEX.June, year: 2031 },
      { dateDay: 16, month: MONTHS_TO_INDEX.May, year: 2032 },
      { dateDay: 5, month: MONTHS_TO_INDEX.June, year: 2033 },
    ],
  },
};

/*

Federal Holidays

New Year’s Day January 1
Martin Luther King’s Birthday 3rd Monday in January
Washington’s Birthday 3rd Monday in February
Memorial Day last Monday in May
Juneteenth National Independence Day June 19
Independence Day July 4
Labor Day 1st Monday in September
Columbus Day 2nd Monday in October
Veterans’ Day November 11
Thanksgiving Day 4th Thursday in November
Christmas Day December 25

//
Valentine's Day
St. Patrick's Day+
Easter Sunday 
Tax Day


*/
