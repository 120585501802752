import { MONTHS, DAYS } from "../../../DateGenerator/DateGeneratorCalendar";

export const DATE_FORMAT = {
  1: {
    format: {
      type: "en-GB",
      options: { time: {}, date: {} },
      formatFunc: (el, type, options) =>
        el.toLocaleDateString(type, options.date),
    },
    str: "31/01/2023",
  },
  2: {
    format: {
      type: "en-US",
      options: { time: {}, date: {} },
      formatFunc: (el, type, options) =>
        el.toLocaleDateString(type, options.date),
    },
    str: "1/31/2023",
  },
  3: {
    format: {
      type: "en-GB",
      options: { time: {}, date: {} },
      formatFunc: (el, type, options) =>
        el.toLocaleDateString(type, options).toString().replace(/\//g, "-"),
    },
    str: "2023-01-31 (ISO 8601)",
  },
  4: {
    format: {
      formatFunc: (el) => {
        let date = new Date(el);
        return `${date.getDate()} ${MONTHS[date.getMonth()].slice(
          0,
          3
        )} ${date.getFullYear()}`;
      },
    },
    str: "31 Jan 2023",
  },
  5: {
    format: {
      formatFunc: (el) => {
        let date = new Date(el);
        return `${MONTHS[date.getMonth()].slice(
          0,
          3
        )} ${date.getDate()}, ${date.getFullYear()}`;
      },
    },
    str: "Jan 31, 2023",
  },
  6: {
    format: {
      formatFunc: (el) => {
        let date = new Date(el);
        return `${date.getFullYear()} ${MONTHS[date.getMonth()].slice(
          0,
          3
        )} ${date.getDate()}`;
      },
    },
    str: "2023 Jan 31",
  },
  7: {
    format: {
      type: "en-GB",
      options: {
        date: {},
        time: { hour12: false },
      },
      formatFunc: (el, type, options) =>
        el.toLocaleDateString(type, options.date) +
        " " +
        el.toLocaleTimeString(type, options.time),
    },
    str: "31/01/2023 12:08:15",
  },
  8: {
    format: {
      type: "en-US",
      options: {
        date: {},
        time: { hour12: false },
      },
      formatFunc: (el, type, options) =>
        el.toLocaleDateString(type, options.date) +
        " " +
        el.toLocaleTimeString(type, options.time),
    },
    str: "1/31/2023 12:08:15",
  },
  9: {
    format: {
      type: "en-GB",
      options: {
        date: {},
        time: { hour12: false },
      },
      formatFunc: (el, type, options) => {
        let date = new Date(el);
        return `${date.getDate()} ${MONTHS[date.getMonth()].slice(
          0,
          3
        )} ${date.getFullYear()} ${el.toLocaleTimeString(type, options.time)}`;
      },
    },
    str: "31 Jan 2023 12:08:15",
  },
  10: {
    format: {
      type: "en-GB",
      options: {
        date: {},
        time: { hour12: false },
      },
      formatFunc: (el, type, options) =>
        el.toLocaleDateString(type, options).toString().replace(/\//g, "-") +
        " " +
        el.toLocaleTimeString(type, options.time),
    },
    str: "2023-01-31 12:08:15",
  },
  11: {
    format: {
      type: "en-GB",
      options: {
        date: {},
        time: { hour12: false },
      },
      formatFunc: (el, type, options) => {
        let date = new Date(el);
        return `${MONTHS[date.getMonth()].slice(
          0,
          3
        )} ${date.getDate()}, ${date.getFullYear()} ${el.toLocaleTimeString(
          type,
          options.time
        )}`;
      },
    },
    str: "Jan 31, 2023 12:08:15",
  },
  12: {
    type: "en-GB",
    options: {
      date: {},
      time: { hour12: false },
    },
    formatFunc: (el, type, options) => {
      let date = new Date(el);
      return `${date.getFullYear()} ${MONTHS[date.getMonth()].slice(
        0,
        3
      )} ${date.getDate()} ${el.toLocaleTimeString(type, options.time)}`;
    },
    str: "2023 Jan 31 12:08:15",
  },
  13: {
    format: {
      type: "en-GB",
      options: {
        date: {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        time: { hour12: false },
      },
      formatFunc: (el, type, options) =>
        el.toLocaleDateString(type, options.date),
    },
    str: "Tuesday, 31 Jan 2023",
  },
  14: {
    format: {
      type: "en-GB",
      options: {
        date: {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        time: { hour12: false },
      },
      formatFunc: (el, type, options) =>
        `${el.toLocaleDateString(type, options.date)} ${el.toLocaleTimeString(
          type,
          options.time
        )}`,
    },
    str: "Tuesday, 31 Jan 2023 12:08:15",
  },
};

export const DAYS_OPTIONS = [
  { id: 0, isSelected: true, str: "Sunday" },
  { id: 1, isSelected: true, str: "Monday" },
  { id: 2, isSelected: true, str: "Tuesday" },
  { id: 3, isSelected: true, str: "Wednesday" },
  { id: 4, isSelected: true, str: "Thursday" },
  { id: 5, isSelected: false, str: "Friday" },
  { id: 6, isSelected: false, str: "Saturday" },
];
