export const BugIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
   <path d="M24 42C36 42 38 31.5324 38 28C38 24.8379 38 20.1712 38 14H10C10 17.4423 10 22.109 10 28C10 31.4506 12 42 24 42Z" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linejoin="round"/>
<path d="M4 8L10 14" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44 8L38 14" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 27H10" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44 27H38" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 44L13 38" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41 44L35 38" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24 42V14" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.9204 39.0407C17.0024 40.783 19.9244 41.9998 23.9999 41.9998C28.1112 41.9998 31.0487 40.7712 33.1341 39.0137" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32 12.3333C32 7.73096 28.4183 4 24 4C19.5817 4 16 7.73096 16 12.3333V14H32V12.3333Z" stroke={color} stroke-opacity="0.85" stroke-width="2" stroke-linejoin="round"/>
</svg>
  );
};
