export const SamhainIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill="none"
      focusable="false"
      aria-hidden="true"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_1985)">
        <path
          d="M24 47.5C36.9787 47.5 47.5 36.9787 47.5 24C47.5 11.0213 36.9787 0.5 24 0.5C11.0213 0.5 0.5 11.0213 0.5 24C0.5 36.9787 11.0213 47.5 24 47.5Z"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.5 23.2002H47.5"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.3999 47.3001V0.600098"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.7998 4.0998L24.3998 1.2998L26.5998 4.0998"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 40.3002L24.2 23.2002L41.7 39.5002"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.7002 26.7002L24.4002 28.7002L28.0002 26.7002"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 18.1001H27.6"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.7002 20.5H26.3002"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.6002 11.2002L28.4002 10.6002C28.3002 15.2002 24.5002 15.0002 24.5002 15.0002C20.0002 15.2002 20.4002 10.2002 20.4002 10.2002L22.2002 11.1002"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.4999 41.4002C27.0404 41.4002 29.0999 39.3407 29.0999 36.8002C29.0999 34.2597 27.0404 32.2002 24.4999 32.2002C21.9594 32.2002 19.8999 34.2597 19.8999 36.8002C19.8999 39.3407 21.9594 41.4002 24.4999 41.4002Z"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.1998 29.1001H13.7998V33.5001H18.1998V29.1001Z"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.1998 29.1001H30.7998V33.5001H35.1998V29.1001Z"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_1985">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
