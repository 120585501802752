import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SideMenusContext } from "../../contexts/side-menus-context";
import { Icon } from "../ui/IconWrapper/Icon";
const generateTitleAndPathList = (currValue, segment, tool, minVal, maxVal) => {
  const optionsArr = [];
  let max = 1;
  let min = 1;

  switch (segment) {
    case "minute":
      max = maxVal ? maxVal : 120;
      min = minVal ? minVal : 1;
      break;
    case "second":
      max = maxVal ? maxVal : 120;
      min = minVal ? minVal : 1;
      break;
    case "hour":
      max = maxVal ? maxVal : 48;
      min = minVal ? minVal : 1;
      break;
    default:
      return;
  }

  for (let i = min; i <= max; i++) {
    optionsArr.push({
      title: `${i} ${i > 1 ? segment + "s" : segment} ${tool}`,
      path: `/timer/${i}-${
        i > 1 ? segment + "s" : segment
      }-${tool.toLowerCase()}`,
    });
  }

  return optionsArr;
};

export const NewSuggestions = ({
  icon,
  category,
  description,
  bgcolor,
  type = "Timers",
}) => {
  const [data, setData] = useState();
  const routerLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let location = window.location.pathname;
    let value = location.replace(/[\D]/g, "");

    let metaTitle = "";
    let segment = "";
    if (
      location.includes("second") ||
      location.includes("seconds") ||
      location.includes("minute") ||
      location.includes("minutes") ||
      location.includes("hour") ||
      location.includes("hours")
    ) {
      if (location.includes("second") || location.includes("seconds")) {
        segment = "second";
      } else if (location.includes("minute") || location.includes("minutes")) {
        segment = "minute";
      } else if (location.includes("hour") || location.includes("hours")) {
        segment = "hour";
      }
      setData(generateTitleAndPathList(value, segment, "Timer"));
    } else {
      setData([
        ...generateTitleAndPathList(value, "second", "Timer", 1, 59),
        ...generateTitleAndPathList(value, "minute", "Timer", 1, 59),
        ...generateTitleAndPathList(value, "hour", "Timer"),
      ]);
    }
  }, [routerLocation]);

  useEffect(() => {
    let location = window.location.pathname;
    let value = location.replace(/[\D]/g, "");
    const El = document.getElementById(
      `${location.replace(value.toString(), parseInt(value) + 2)}`
    );
    El && El.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  const { defaultCatergories, toolsData, searchValue, filteredTools } =
    useContext(SideMenusContext);
  const [element, setElement] = useState(
    defaultCatergories.find((el) => el.title === type)
  );

  useEffect(() => {
    console.log(element);
  }, [element]);

  return (
    <HomeSectionsMenuItemStyled color={bgcolor}>
      {/* <CategoryDescription color={bgcolor}>
        {data &&
          data.map((el) => (
            <li
              key={el.path}
              id={el.path}
              className={window.location.pathname === el.path ? "selected" : ""}
            >
              <a
                href={el.path}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(el.path);
                }}
              >
                {el.title}
              </a>
            </li>
          ))}
      </CategoryDescription> */}
      <ToolsOptions>
        {toolsData &&
          toolsData[element.title].map((el) => (
            <ToolsOption
              className="tool-option"
              onClick={(e) => {
                navigate(el.path);
              }}
            >
              <ToolOptionIconContainer bgcolor={element.bgcolor}>
                <Icon
                  icon={el.icon || element.icon}
                  size={"6rem"}
                  color={element.bgcolor}
                />
                {el.iconText && <IconText>{el.iconText}</IconText>}
                {+el.status === 2 && (
                  <UnderConstruction
                    bgcolor={element.bgcolor}
                    className="under-construction"
                  >
                    Under Construction
                  </UnderConstruction>
                )}
              </ToolOptionIconContainer>
              <ToolOptionTitle>
                <a
                  href={el.path}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(el.path);
                  }}
                >
                  {el.title}
                </a>{" "}
              </ToolOptionTitle>
            </ToolsOption>
          ))}
      </ToolsOptions>
    </HomeSectionsMenuItemStyled>
  );
};

const HomeSectionsMenuItemStyled = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  padding: 0 2rem;
  background-color: #151515;
  box-shadow: #151515 0px 0px 10px 5px;
  width: 100vw;
  overflow-x: visible;
  color: white;
  svg {
    font-size: 8rem;
    font-weight: 200;
    color: ${(props) => props.color};
  }
  :nth-child(2n) {
    flex-direction: row-reverse;
    background-color: #151515;
  }
  // @media only screen and (min-width: 700px) {
  //   bottom: 3rem;
  //   height: fit-content;
  // }
`;

const CategoryDescription = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  gap: 1rem;
  overflow: hidden;
  width: 100vw;
  overflow-x: auto;
  scroll-behavior: smooth;
  height: fit-content;
  padding: 2rem 1rem;

  & li {
    padding: 0.5rem;
    background-color: ${(props) => props.color};
    color: white;
    cursor: pointer;
    max-width: 20rem;
    /* border-radius: 50%; */
    border-radius: 50%;
    border: 0.2rem solid rgb(60, 144, 189);
    min-width: 7rem;
    min-height: 7rem;
    max-width: 7rem;
    max-height: 7rem;
    /* border-radius: 2rem; */
    white-space: wrap;
    /* width: 30%; */
    /* background-color: rgb(60, 144, 189); */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    cursor: pointer;

    & a {
      text-decoration: none;
      color: white;
      font-weight: 400;
      font-size: inherit;
      white-space: pre-wrap !important;
      text-align: center;
    }

    @media only screen and (min-width: 700px) {
      min-width: 13rem;
      min-height: 13rem;
      max-width: 13rem;
      max-height: 13rem;
      font-size: 1.75rem;
    }
    &:focus {
      box-shadow: white 0px 0px 10px 5px;
    }
    &.selected {
      box-shadow: rgb(56, 214, 214) 0px 0px 10px 5px;
    }
  }
`;

const ToolsOptions = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  overflow-x: auto;
  padding: 2rem 0rem;
  & .tool-option:first-of-type:hover {
    padding-left: 1rem;
    padding-right: 0rem;
  }

  & .tool-option:last-of-type:hover {
    padding-right: 1rem;
  }
`;

const ToolsOption = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(115%);
  }
  &:hover .under-construction {
    display: block;
    cursor: not-allowed;
  }
`;

const ToolOptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.bgcolor};
  text-align: center;
  min-width: 10rem;
  max-width: 10rem;
  min-height: 8rem;
  max-height: 8rem;
  border-radius: 0.8rem;
  border: 0.2rem solid ${(props) => props.bgcolor};
  position: relative;
`;
const ToolOptionTitle = styled.div`
  min-width: 10rem;
  max-width: 10rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  color: white;
`;

const IconText = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2rem;
  padding-bottom: 0.3rem;
  font-weight: 500;
`;

const UnderConstruction = styled.div`
  display: block;
  position: absolute;
  bottom: 50%;
  right: 50%;
  color: black;
  width: 100%;
  background-color: yellow;
  font-weight: 600;
  transform: translateX(50%) translateY(50%);
  /* border-top: 2px dashed #333;
  border-bottom: 2px dashed #333; */
  opacity: 0.8;
`;
