export const ValentinesDayIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 53"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_1911)">
        <path
          d="M12.6 14.3001C12.6 14.3001 7.5 7.5001 0.5 11.0001C0.5 11.0001 4.8 22.9001 12.1 19.1001C12.1 19.1001 16.6 16.4001 15.1 11.9001C13.6 7.4001 11.8 5.1001 11.8 5.1001C11.8 5.1001 7.3 8.2001 7.7 10.6001"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.5 10.2001V8.20007C1.5 7.40007 2 6.60007 2.7 6.30007L6.9 4.10007C7.6 3.80007 8.3 3.80007 9 4.20007L11.2 5.60007"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.8001 24.6L12.1001 19"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.3999 14.2C35.3999 14.2 40.4999 7.4 47.4999 10.9C47.4999 10.9 43.1999 22.8 35.8999 19C35.8999 19 31.3999 16.3 32.8999 11.8C34.3999 7.3 36.1999 5 36.1999 5C36.1999 5 40.6999 8.1 40.2999 10.5"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.4997 10.1001V8.00007C46.4997 7.20007 46.0997 6.60007 45.3997 6.20007L41.1997 4.10007C40.4997 3.70007 39.5997 3.70007 38.7997 4.20007L36.6997 5.50007"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.1001 24.5999L35.9001 18.8999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.7999 12.3002C26.7999 12.3002 25.2999 3.8002 17.2999 3.7002C17.2999 3.7002 15.6999 16.5002 24.1999 16.4002C24.1999 16.4002 29.5999 16.0002 30.1999 11.3002C30.7999 6.6002 30.3999 3.7002 30.3999 3.7002C30.3999 3.7002 24.9999 4.6002 24.2999 7.0002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6001 3.5L19.5001 1.6C19.8001 0.9 20.5001 0.5 21.3001 0.5H26.1001C27.0001 0.5 27.8001 1 28.2001 1.8L29.1001 3.6"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.1001 22.0999L24.2001 16.3999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.2002 27.0998C11.2002 27.0998 22.7002 16.1998 36.8002 27.0998L27.3002 39.4998C26.8002 40.0998 26.7002 40.8998 26.8002 41.5998L27.7002 47.4998H20.3002L21.1002 41.8998C21.2002 40.8998 21.0002 39.9998 20.4002 39.1998L11.2002 27.0998Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.6001 30.4999L30.3001 23.3999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.7 33L24 40.5"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.7002 40.5H28.2002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_1911">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
