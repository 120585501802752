import { useEffect, useState } from "react";
import styled from "styled-components";
import { addMonths, addDays, addYears } from "date-fns";
import { DateGeneratorCalendar } from "../DateGenerator/DateGeneratorCalendar";

const CALC_BASE = {
  0: "Last Period",
  1: "Conception Date",
};

// 27 => 279
// 28 =>280
// 29 => 281
// 30 => 282
// 31 => 283
// 32 => 284
const calculateDate = (date, average) => {
  console.log(date, average, 280 + (average - 28));
  const res = addDays(new Date(date), 280 + (average - 28));
  return new Date(res);
};

export const PregnancyCalculator = ({ callback = () => {} }) => {
  const [average, setAverage] = useState(28);
  const [lastPeriod, setLastPeriod] = useState();
  const [calcBase, setCalcBase] = useState(0);
  const [dueDate, setDueDate] = useState(0);

  useEffect(() => {
    callback(dueDate);
  }, [dueDate]);

  function handleLastPeriodChange(e) {
    setLastPeriod(e.target.value);
  }

  function handleOptionChange(e) {
    setAverage(e.target.value);
  }

  function handeleCalculate() {
    if (+calcBase === 0) setDueDate(calculateDate(lastPeriod, average));
    else setDueDate(calculateDate(lastPeriod, 14));
  }

  let options = [];
  for (let i = 22; i < 46; i++) {
    options.push(i);
  }

  return (
    <PregnancyCalculatorStyled className={"flex-col flex-t-c"}>
      <h1>Pregnancy Due Date Claculator</h1>
      {!dueDate && (
        <>
          <div className={""} style={{ gap: "2rem", width: "90vw" }}>
            <CalcOption
              onClick={() => {
                setCalcBase(0);
              }}
              className={`flex-row flex-c-c ${
                calcBase === 0 ? "selected" : ""
              }`}
            >
              {CALC_BASE[0]}
            </CalcOption>
            <CalcOption
              onClick={() => {
                setCalcBase(1);
              }}
              className={`flex-row flex-c-c ${
                calcBase === 1 ? "selected" : ""
              }`}
            >
              {CALC_BASE[1]}
            </CalcOption>
          </div>
          {calcBase === 0 && (
            <>
              <select onChange={handleOptionChange}>
                {options.map((el) => {
                  if (el === 28)
                    return (
                      <option value={el} selected>
                        {el}
                      </option>
                    );
                  else return <option value={el}>{el}</option>;
                })}
              </select>
              <input type="date" onChange={handleLastPeriodChange} />
            </>
          )}

          {calcBase === 1 && (
            <input type="date" onChange={handleLastPeriodChange} />
          )}

          {!dueDate && (
            <ButtonStyled>
              <button className="start-btn" onClick={handeleCalculate}>
                Calculate
              </button>
            </ButtonStyled>
          )}
        </>
      )}

      {dueDate ? (
        <DueDateStyled>
          Your baby is due on
          <DateGeneratorCalendar
            date={dueDate}
            style={{
              width: "13rem !important",
              height: "fit-content !important",
            }}
          />
        </DueDateStyled>
      ) : (
        ""
      )}
    </PregnancyCalculatorStyled>
  );
};

const PregnancyCalculatorStyled = styled.div`
  height: inherit !important;
  gap: 5rem;
  padding-top: 3rem;
  display: flex;
  h1 {
    font-size: 2rem;
    width: fit-content;
    text-align: center;
    color: rgb(56, 214, 214);
    border-radius: 10rem;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  option,
  select {
    width: fit-content;
    height: 5rem;
    max-width: fit-content;
  }
`;

const CalcOption = styled.div`
  height: 5rem;
  width: 15rem;
  text-align: center;
  padding: 0 0.5rem;
  border-radius: 10rem;
  font-size: 1.4rem;
  outline: none;
  border: 0.2rem solid #cccccc;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  &.selected {
    border-color: rgb(56, 214, 214);
    color: rgb(56, 214, 214);
    box-shadow: 0px 0px 10px 5px #38d6d6;
  }
`;

const ButtonStyled = styled.div`
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 3rem;

  & button {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    background-color: transparent;
    color: white;
    font-size: 2.5rem;
    border-color: rgb(56, 214, 214);
    font-weight: 600;
    &:hover {
      box-shadow: 0px 0px 10px 5px #38d6d6;
      cursor: pointer;
    }
  }
`;

const DueDateStyled = styled.div`
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  color: white;
`;
