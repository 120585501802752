import styled from "styled-components";
import { BreadcrumbsItem } from "./BreadcrumbsItem";

export const Breadcrumbs = ({ options, showBG, emphasisColor }) => {
  return (
    <BreadcrumbsStyled
      style={
        showBG && {
          top: "0",
          left: "0",
          background: "rgb(0,0,0,0.3)",
          position: "absolute",
          paddingLeft: "2rem",
          height: "5rem",
        }
      }
    >
      {options.map((el, index) => (
        <BreadcrumbsItem
          emphasisColor={emphasisColor}
          breadcurmb={el}
          onClick={el?.onClick}
          showSeperator={index + 1 < options.length}
        />
      ))}
    </BreadcrumbsStyled>
  );
};

const BreadcrumbsStyled = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 4rem;
  width: 100%;
  gap: 0.5rem;
  padding: 2rem 0;
  cursor: pointer;
`;
