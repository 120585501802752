import styled from 'styled-components'
export const PageNotFound = () => {
  return (
    <PageNotFoundStyled>
      <TitleContainer>
        <h1>404 </h1>
        <h2>Page Not Found</h2>
      </TitleContainer>
      <br />
      <p>OOOOPS...</p>
      <p>Nope! No such page.</p>
      <br />
      <p>If you were looking for a tool and couldn't find it <a href='/'>contact us</a></p>
    </PageNotFoundStyled>

  );
};

const PageNotFoundStyled = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
  flex-direction:column;
  gap:1rem;
  height:100%;
  width:100%;
  color:white;
  font-size:3rem;
  padding:2rem;
  h1{
    font-size:8rem;
  }
  h1,h2,a{
    color:rgb(56, 214, 214)
  }
  p,h2{
    font-size:inherit;
  }
  p:last-child{
    font-size:1.5rem;
  }
`
const TitleContainer = styled.section`
  // background:rgba(0, 0, 0, 0.7);
  width:30rem;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  border-radius:1rem;
  height: fit-content;
  & h2{
    font-size:2.2rem;
  }
`