import { CountdownBox } from "./CountdownBox";
import { useCountdown } from "../../hooks/useCountdown";
import styled from "styled-components";
import { useEffect, useState } from "react";

export const Countdown = ({ type }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  let data = useCountdown(selectedDate, isStart);

  const handleDateSelection = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleStart = () => {
    setIsStart(true);
  };

  useEffect(() => {
    setIsPastDate(new Date(selectedDate) - new Date() < 10);
  }, [selectedDate]);

  return (
    <CountdownContainerStyled>
      <CountdownTitle>Countdowns</CountdownTitle>
      <div>
        <CountdownInputs isPastDate={isPastDate}>
          <div>
            <input
              style={{ width: "25rem" }}
              className="__date-time-input"
              type="text"
              onMouseEnter={(e) =>
                (e.target.type = type === "wedding" ? "date" : "datetime-local")
              }
              onBlur={(e) => (e.target.type = "text")}
              onChange={handleDateSelection}
              value={selectedDate.replace("T", " ")}
              placeholder="mm/dd/yyyy --:-- "
            />
          </div>

          <div
            style={{
              color: "red",
              visibility: isPastDate ? "visible" : "hidden",
              paddingTop: ".5rem",
            }}
          >
            Please Select Future Date
          </div>
        </CountdownInputs>
        <CountdownBox {...data} />
        <CountdownBtns>
          {selectedDate && !isPastDate && !isStart && (
            <button className="start-btn" onClick={handleStart}>
              start
            </button>
          )}
        </CountdownBtns>
      </div>
    </CountdownContainerStyled>
  );
};

const CountdownContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const CountdownTitle = styled.div`
  width: fit-content;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: rgba(52, 191, 149);
  color: white;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  z-index: 1300;
  border-radius: 10rem;
  transform: translateY(50%);
`;

const CountdownInputs = styled.div`
  width: 100%;
  height: fit-content;
  padding: 2rem;
  gap: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .__date-time-input {
    min-width: 15rem;
    ::placeholder {
      color: white;
    }
    ::placeholder {
      content: "&#128197";
    }
  }

  & input {
    width: fit-content;
    min-width: fit-content;
    height: 5rem;
    border-radius: 10rem;
    outline: none;
    padding: 2rem;
    border: ${(props) =>
      props.isPastDate ? "0.2rem solid red" : "0.2rem solid #ccc"};
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.4rem;
  }
`;

const CountdownBtns = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 3rem;

  & button {
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    background-color: transparent;
    color: white;
    font-size: 3rem;
    font-weight: 600;
  }

  & .start-btn {
    border: 0.2rem solid #38d6d6;
    &:hover {
      box-shadow: 0px 0px 10px 5px #38d6d6;
      cursor: pointer;
    }
  }

  & .stop-btn {
    border: 0.2rem solid red;
    &:hover {
      box-shadow: 0px 0px 10px 5px red;
      cursor: pointer;
    }
  }
`;

const InputsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
  input {
    width: 9rem;
    text-align: center;
  }
`;
