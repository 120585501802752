export const BoxingdayIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M40.5 15H7.5C6.67157 15 6 15.6716 6 16.5V22.5C6 23.3284 6.67157 24 7.5 24H40.5C41.3284 24 42 23.3284 42 22.5V16.5C42 15.6716 41.3284 15 40.5 15Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39 24V37.5C39 37.8978 38.842 38.2794 38.5607 38.5607C38.2794 38.842 37.8978 39 37.5 39H10.5C10.1022 39 9.72064 38.842 9.43934 38.5607C9.15804 38.2794 9 37.8978 9 37.5V24"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 15V39"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.4853 12.8789C30.364 15.0002 24 15.0002 24 15.0002C24 15.0002 24 8.63626 26.1213 6.51494C26.9654 5.67182 28.1097 5.19839 29.3028 5.19873C30.4958 5.19907 31.6399 5.67315 32.4835 6.51675C33.3271 7.36035 33.8012 8.50442 33.8015 9.69745C33.8018 10.8905 33.3284 12.0348 32.4853 12.8789V12.8789Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5147 12.8789C17.636 15.0002 24 15.0002 24 15.0002C24 15.0002 24 8.63626 21.8787 6.51494C21.0346 5.67182 19.8902 5.19839 18.6972 5.19873C17.5042 5.19907 16.3601 5.67315 15.5165 6.51675C14.6729 7.36035 14.1988 8.50442 14.1985 9.69745C14.1981 10.8905 14.6716 12.0348 15.5147 12.8789V12.8789Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
