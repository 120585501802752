export const CoffeeIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path d="M16.5 4.5V10.5" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22.5 4.5V10.5" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M28.5 4.5V10.5" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6 40.5H39" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.6181 40.5007C12.7474 39.183 10.3153 37.0691 8.61037 34.4101C6.90547 31.7511 5.99949 28.6586 6 25.5V16.5H39V25.5C39.0005 28.6587 38.0945 31.7511 36.3896 34.4101C34.6847 37.0692 32.2525 39.1831 29.3818 40.5008" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M38.9999 16.5C40.5912 16.5 42.1173 17.1321 43.2426 18.2574C44.3678 19.3826 44.9999 20.9087 44.9999 22.5V24C44.9999 25.5913 44.3678 27.1174 43.2426 28.2426C42.1173 29.3679 40.5912 30 38.9999 30H38.3665" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
