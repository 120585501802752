export const StopwatchIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M24 40.5C33.1127 40.5 40.5 33.1127 40.5 24C40.5 14.8873 33.1127 7.5 24 7.5C14.8873 7.5 7.5 14.8873 7.5 24C7.5 33.1127 14.8873 40.5 24 40.5Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M24 24L31.4246 16.5754"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 1.5H28.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
