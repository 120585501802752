export const TimerIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    focusable="false"
    aria-hidden="true"
    fill="none"
    style={{ ...style, width: size }}
    >
      <path
        d="M24 15V24"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.7942 28.5L24 24"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4694 18.6967H5.96942V11.1967"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.3334 35.6673C14.6409 37.9748 17.581 39.5463 20.7817 40.183C23.9823 40.8196 27.2999 40.4929 30.3149 39.244C33.3299 37.9952 35.9068 35.8803 37.7199 33.1669C39.5329 30.4535 40.5006 27.2634 40.5006 24C40.5006 20.7366 39.5329 17.5465 37.7199 14.8331C35.9068 12.1197 33.3299 10.0048 30.3149 8.756C27.2999 7.50715 23.9823 7.18039 20.7817 7.81705C17.581 8.45371 14.6409 10.0252 12.3334 12.3327L5.96942 18.6967"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
