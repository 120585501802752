import { useEffect, useState } from "react";

let defDate = new Date(Date.now() + 0 * 60 * 1000);
export const useCountdown = (target, isStart, isPaused, isReset) => {
  if (!target) {
    target = defDate;
  }
  const countDownDate = new Date(target).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    if ((!isStart || isPaused) && !isReset) {
      return
    }

    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () =>
      interval && clearInterval(interval);
  }, [countDownDate, isStart, isPaused, isReset]);
  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  const isExpired = seconds < 0;
  return { days, hours, minutes, seconds, isExpired };
};
