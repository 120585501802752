import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { SideMenusContext } from "../../contexts/side-menus-context";
import {
  AddUserIcon,
  BugIcon,
  ConceptSharingIcon,
  RaiseHandIcon,
  UserIcon,
} from "../assets/icons/ToolsOasisIcons";
import { CoffeeIcon } from "../assets/icons/ToolsOasisIcons/coffee-icon";
import { UserMenuItem } from "./UserMenuItem";
import { el } from "date-fns/locale";

const toggleX = (x) => {
  x.classList.toggle("change");
};

const toe = "mailto:toolsOasis@gmail.com";

const MOCK = [
  {
    title: "Invite a friend",
    icon: AddUserIcon,
    onClick: (e) => {
      window.location.href =
        "mailto:" +
        "?subject=Have you heard about wwwv.ToolsOasis.com?&body=Awesome site, go and check them out!%0D%0A";
    },
  },
  {
    title: "Request new tool",
    icon: RaiseHandIcon,
    onClick: (e) => {
      window.location.href =
        toe +
        `?subject=New Tool Request&body= Hi,%0D%0A%0D%0A I have an awesome tool I'd like you to add:%0D%0A%0D%0A ... Add Description Here ... %0D%0A%0D%0A`;
    },
  },
  {
    title: "Suggest improvment",
    icon: ConceptSharingIcon,
    onClick: (e) => {
      window.location.href =
        toe +
        `?subject=Improvment Suggeston&body= Hi,%0D%0A%0D%0A I have an awesome idea for improvment! %0D%0A%0D%0A ... Add Description Here ... %0D%0A%0D%0A`;
    },
  },
  {
    title: "Report a bug",
    icon: BugIcon,
    onClick: (e) => {
      window.location.href =
        toe +
        `?subject=I've found a Bug!&body= Hi,%0D%0A%0D%0A I've found a bug in ${window.location
          .toString()
          .split("/")
          [
            window.location.toString().split("/").length - 1
          ].toUpperCase()}%0D%0A%0D%0A ... Add Description Here ... %0D%0A%0D%0A`;
    },
  },
  // {
  //   title: "Buy me a coffee",
  //   icon: CoffeeIcon,
  //   onClick: () => {
  //     alert("bug");
  //   },
  // },
  // {
  //   title: 'Contact us',
  //   icon: CoffeeIcon,
  //   onClick: () => { alert('bug') },

  // }
];

// <li>
// <UserIcon size={'2rem'} color={'white'} />

// </li>
// <li>
// <UserIcon size={'2rem'} color={'white'} />
// Request new tool
// </li>
// <li>
// <UserIcon size={'2rem'} color={'white'} />
// Suggest a feature
// </li>
// <li>
// <UserIcon size={'2rem'} color={'white'} />
// Report a bug
// </li>
export const UserMenu = ({ onClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState();

  return (
    <UserMenuStyled>
      <IconContainer onClick={() => setIsMenuOpen((prev) => !prev)}>
        <UserIcon
          size={"3rem"}
          color={"white"}
          style={{
            marginTop: ".3rem",
            padding: ".2rem",
            borderRadius: "50%",
            border: ".2rem solid rgb(56, 214, 214)",
          }}
        />
      </IconContainer>
      {isMenuOpen && (
        <MenuStyled>
          {MOCK.map((el) => (
            <li>
              {" "}
              <UserMenuItem
                key={el.title}
                title={el.title}
                icon={el.icon}
                onClick={el.onClick}
              />
            </li>
          ))}
        </MenuStyled>
      )}
    </UserMenuStyled>
  );
};

const UserMenuStyled = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center; 
`;

const IconContainer = styled.div`
  transform: translatey(8%);
  cursor: pointer;
`; 

const MenuStyled = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  background: black;
  min-width: 10rem;
  right: 0;
  top: 6rem;
  border-radius: .8rem;
`;
