import React from "react";
import styled from "styled-components";
import useWindowDimensions from "../../../hooks/useWidowDimentions";

const ToolButton = ({
  bgcolor,
  color,
  icon,
  size = 8,
  onClick,
  text,
  showBorder = false,
  className,
}) => {
  const Icon = icon;
  const windowDimensions = useWindowDimensions();

  return (
    <Container
      className={"btn-61"}
      onClick={onClick}
      color={color || "white"}
      bgcolor={bgcolor || "rgba(0,0,0,0.9)"}
      size={size}
      showBorder={showBorder}
    >
      <Icon
        color={color || "white"}
        size={windowDimensions.width < 800 ? size / 2 + "rem" : size /1.5 + "rem"}
      />
      {text}
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(p) => (p.showBorder ? `0.1rem solid ${(p) => p.color}` : "none")};
  // min-width: ${(p) => p.size + 2 + "rem"};
  // max-width: ${(p) => p.size + 2 + "rem"};
  // min-height: ${(p) => p.size + "rem"};
  // max-height: ${(p) => p.size + "rem"};
  font-size: ${(p) => (p.size * 4) / 10 + "rem"};
  padding: 1rem 2rem;
  background-color: ${(p) => p.bgcolor};
  border-radius: 0.8rem;
  color: ${(p) => p.color};
  gap: 1rem;
  margin: 1rem;
  text-transform: capitalize;
  -webkit-animation: rubberBand 1.8s;
  animation: rubberBand 1.8s;
  animation-iteration-count: 5;
  cursor: pointer;
  &:hover {
    -webkit-animation: rubberBand 1.8s;
    animation: rubberBand 1.8s;
    animation-iteration-count: infinite;
  }

  @-webkit-keyframes rubberBand {
    0% {
      transform: scaleX(1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    to {
      transform: scaleX(1);
    }
  }
  @keyframes rubberBand {
    0% {
      transform: scaleX(1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    to {
      transform: scaleX(1);
    }
  }

  @media only screen and (max-width: 1020px) {
    font-size: ${(p) => (p.size * 3) / 10 + "rem"};
    padding: 0.5rem 1rem;
  }
`;

export default ToolButton;
