export const TripIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M4.5 40.5H31.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.17379 26.3191L10.2417 32.9453C11.1834 33.8281 12.3847 34.3837 13.6672 34.5296C14.9497 34.6755 16.2451 34.4039 17.3611 33.7551L45.75 17.2499L42.2551 12.9784C41.3484 11.8703 40.0754 11.1229 38.6659 10.8712C37.2564 10.6196 35.8033 10.8803 34.5692 11.6062L26.25 16.4999L15 12.7499L11.9064 14.0757C11.675 14.1749 11.4722 14.3308 11.3168 14.5289C11.1615 14.7271 11.0585 14.9612 11.0174 15.2096C10.9763 15.458 10.9984 15.7128 11.0817 15.9505C11.1649 16.1881 11.3067 16.4009 11.4938 16.5694L17.25 21.7499L12 24.7499L6.74996 22.4999L3.60883 23.8461C3.37976 23.9443 3.17876 24.098 3.02399 24.2933C2.86922 24.4887 2.76557 24.7195 2.72239 24.965C2.67921 25.2104 2.69787 25.4628 2.77669 25.6992C2.8555 25.9356 2.99198 26.1487 3.17379 26.3191V26.3191Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
