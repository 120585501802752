import styled from "styled-components";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FeaturedMenuItem } from "./FeaturedMenuItem";
import { SideMenusContext } from "../../contexts/side-menus-context";

export const FeaturedMenu = ({ type }) => {
  const { hubData, defaultCatergories, toolsDB } = useContext(SideMenusContext);

  let routerLocation = useLocation();

  useEffect(() => {
    document.title = defaultCatergories.find(el => el.title === type).featuredMeta.title;
    const descEl = document.querySelector("meta[name='description']");
    descEl.setAttribute(
      "content",
      defaultCatergories.find(el => el.title === type).featuredMeta.description
    );
    const canonicalEl = document.querySelector("link[rel='canonical']");
    canonicalEl.setAttribute(
      "href",
      window.location.href.replace("www.", "").replace("http://", "https://")
    );
  }, [defaultCatergories, routerLocation]);


  return (
    hubData[type] && (
      <FeaturedMenuStyled>
        {hubData[type].map((el) => {
          return <FeaturedMenuItem
            {...defaultCatergories.find((el) => el.title === type)}
            isCollection={defaultCatergories.find(el => el.title === type).isCollection}
            toolDB={defaultCatergories.find(el2 => el2.title === el.subCategory)}
            parentType={type}
            title={el.subCategory}
            categoryData={defaultCatergories.find(el => el.title === type).subCategories[el.subCategory]}
            subCategoryData={hubData[type].find(
              (scd) => scd.subCategory === el.subCategory
            )}
          />
        })}
      </FeaturedMenuStyled>
    )
  );
};

const FeaturedMenuStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  margin: 2rem;
  gap: 4rem;
  padding:2rem;
`;
