import { useContext } from "react";
import { BaseModal } from "../../ui/Modal/BaseModal";
import { ModalContext } from "../../../contexts/modal-context";
import { useState } from "react";
import styled from "styled-components";
import { SlidePaging } from "../../ui/SlidePaging/SlidePaging";
import { useEffect } from "react";

export const CountdownModal = ({ slides = [] }) => {
  const { countdownModal, dismissCountdownModal } = useContext(ModalContext);
  const [activeFact, setActiveFact] = useState(0);
  useEffect(() => {
    return dismissCountdownModal();
  }, []);

  return (
    countdownModal.show && (
      <BaseModal
        onDismissModal={() => {
          dismissCountdownModal();
          setActiveFact(0);
        }}
        title={
          <SlidePaging
            slides={slides}
            activeSlide={activeFact + 1}
            setActiveFact={setActiveFact}
          />
        }
        footer={
          <ButtonsContainer
            style={{
              justifyContent: activeFact === 0 ? "flex-end" : "space-between",
            }}
          >
            {activeFact > 0 && (
              <button onClick={() => setActiveFact((prev) => prev - 1)}>
                &#9664; Back
              </button>
            )}
            {activeFact < slides.length - 1 && (
              <button onClick={() => setActiveFact((prev) => prev + 1)}>
                Next &#9654;
              </button>
            )}
            {activeFact === slides.length - 1 && (
              <button onClick={dismissCountdownModal}>Close</button>
            )}
          </ButtonsContainer>
        }
      >
        <Info>{slides[activeFact].text}</Info>
      </BaseModal>
    )
  );
};

const Facts = styled.div`
  color: white;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.activeFact === 0 ? "flex-end" : "space-between"};
  width: 100%;
  padding-top: 3rem;
  button {
    width: 100%;
    border-radius: 0.8rem;
    border: none;
    outline: none;
    height: 5rem;
    color: rgb(52, 191, 149);
    font-size: 2rem;
    font-weight: 600;
    text-transform: capitalize;
    max-width: 10rem;
    white-space: nowrap;
    text-align: center;

    &:hover {
      background-color: rgb(52, 191, 149);
      color: rgb(21, 21, 21);
    }
    /* 
    &:nth-child(2) {
      background-color: rgb(52, 191, 149);
      color: white;
    } */
    @media only screen and (max-width: 600px) {
      max-width: 10rem !important;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 3rem;
  line-height: 1.5;
  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`;

const InfoNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  border-radius: 50%;
  color: black;
  font-size: 2rem;
  background-color: rgb(52, 191, 149);
`;
