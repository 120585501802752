import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";

export const ChipsFilterItem = ({ filterOption, bgcolor, onClick }) => {
  return (
    filterOption && (
      <ChipsFilterItemStyled
        onClick={onClick}
        bgcolor={bgcolor}
        className={filterOption?.isSelected ? "selected" : ""}
      >
        {filterOption?.title}
      </ChipsFilterItemStyled>
    )
  );
};

const ChipsFilterItemStyled = styled.div`
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  background: black;
  border: 0.1rem solid ${(props) => props.bgcolor};
  &:hover {
    cursor: pointer;
  }
  &.selected {
    background-color: ${(props) => props.bgcolor};
  }
`;
