import UC from "./../shared/assets/images/Under-Construction.jpg";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const UnderConstruction = () => {
  let routerLocation = useLocation();

  useEffect(() => {
    document.title = "Page Is Under Construction";
    const descEl = document.querySelector("meta[name='description']");
    descEl.setAttribute(
      "content",
      "tool is under construction"
    );
    const canonicalEl = document.querySelector("link[rel='canonical']");
    canonicalEl.setAttribute("href", window.location.href);
  }, [routerLocation]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        fontSize: "2rem",
        color: "white",
      }}
    >
      <img src={UC} width={'100%'} />
      <div style={{ fontSize: '2rem', padding: '2rem' }}>
        Check again soon...</div>
    </div>
  );
};
