export const WeddingIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M31.0911 19.8509C31.8389 18.5221 32.4625 17.323 32.8471 16.4104C34.7147 11.9789 32.868 6.88154 28.3539 4.80225C23.8397 2.72296 19.3068 4.87899 17.3174 9.04185C13.5131 6.4314 8.43836 6.81478 5.63977 10.8885C2.84119 14.9622 3.71951 20.2841 7.55258 23.1881C9.29221 24.5061 12.7327 26.4484 15.9719 28.1769M32.5944 23.4999C31.7502 18.964 27.8907 15.6467 23.0313 16.5483C18.1718 17.4499 15.0298 21.8342 15.6867 26.598C16.2145 30.4254 19.1278 39.4054 20.2528 42.788C20.4063 43.2496 20.4831 43.4804 20.6351 43.6413C20.7674 43.7815 20.9435 43.8831 21.1311 43.9277C21.3464 43.9788 21.5847 43.9299 22.0612 43.832C25.553 43.115 34.7866 41.148 38.3652 39.6914C42.8193 37.8784 45.1179 32.9682 43.3962 28.306C41.6745 23.6437 36.9447 21.963 32.5944 23.4999Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
