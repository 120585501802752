import { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ToolBase } from "../../ui/ToolBase/ToolBase";
import { TOOLS } from "../../ui/ToolBase/ToolsTypes";
import { CountdownMain } from "./CountdownMain";
import { useCountdown } from "../../../hooks/useCountdown";
import { MONTHS, DAYS } from "../../DateGenerator/DateGeneratorCalendar";
import { holidays } from "./holidays";
import { ModalContext } from "../../../contexts/modal-context";
import { CountdownModal } from "../Modals/ConutdownModal";

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth();
const todaysDay = today.getDate();

const isNextYear = (month, dateDay) => {
  return month < todaysMonth || (month === todaysMonth && dateDay < todaysDay);
};

const getHolidayDate = (type) => {
  let data = holidays[type];
  if (!data) return;
  let fixedDateData = data?.fixedDateData;
  let predefinedDate = new Date();
  if (data.isFixed) {
    predefinedDate.setFullYear(todaysYear);
    predefinedDate.setMonth(fixedDateData?.month);
    predefinedDate.setDate(fixedDateData?.dateDay);
    if (isNextYear(fixedDateData?.month, fixedDateData?.dateDay))
      predefinedDate.setFullYear(todaysYear + 1);
  } else {
    let thisYearDate =
      holidays && holidays[type].Dates.find((el) => el.year === todaysYear);
    let nextYearDate =
      holidays && holidays[type].Dates.find((el) => el.year === todaysYear + 1);
    if (isNextYear(thisYearDate.month, thisYearDate.dateDay)) {
      predefinedDate.setFullYear(nextYearDate?.year);
      predefinedDate.setMonth(nextYearDate?.month);
      predefinedDate.setDate(nextYearDate?.dateDay);
    } else {
      predefinedDate.setFullYear(thisYearDate?.year);
      predefinedDate.setMonth(thisYearDate?.month);
      predefinedDate.setDate(thisYearDate?.dateDay);
    }
  }
  // let tmp = predefinedDate.toLocaleDateString().replaceAll('/','-')
  predefinedDate.setHours(0, 0, 0, 0);
  let ans = new Date(predefinedDate).getTime();
  return ans;
};

export const Countdown = ({
  typeFam = "countdowns",
  type = "",
  toolFamily = "",
}) => {
  const [isStart, setIsStart] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDateFormatted, setSelectedDateFormatted] = useState("");
  const [showError, setShowError] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  const [hasPreset, setHasPreset] = useState(false);
  const [currType, setCurrType] = useState(type);
  const [predefinedDate, setPredefinedDate] = useState();
  const [tabsOptions, setTabsOptions] = useState(
    TOOLS[typeFam][type].tabsOptions
  );
  const { countdownModal, openCountdownModal } = useContext(ModalContext);
  let data = useCountdown(selectedDate, isStart, isPaused, isReset);

  const handleDateSelection = (e) => {
    setIsStart(false);
    setSelectedDate(e.target.value);
  };

  const handleStart = () => {
    setIsPastDate(new Date(selectedDate) - new Date() < 10);
    setIsReset(false);
    if (!selectedDate || isPastDate) {
      setShowError(true);
      return;
    }
    setIsStart(true);
  };

  const handleReset = () => {
    setSelectedDate("");
    setSelectedDateFormatted("");
    setShowError(false);
    setIsReset(true);
    setIsStart(false);
  };

  useEffect(() => {
    let tmpData =
      type.toLowerCase() === "thanksgiving"
        ? type + "_us"
        : type.toLowerCase() === "diwali"
        ? type + "_amavasya"
        : type;
    setTabsOptions(TOOLS[typeFam][type].tabsOptions);
    setPredefinedDate(getHolidayDate(tmpData));
  }, [type]);

  useEffect(() => {
    if (tabsOptions)
      setPredefinedDate(
        getHolidayDate(tabsOptions.find((el) => el.isSelected).value)
      );
  }, [tabsOptions]);

  useEffect(() => {
    if (selectedDate) {
      setShowError(true);
      let datetimeval = new Date(selectedDate);
      setIsPastDate(datetimeval - new Date() < 10);
      setSelectedDateFormatted(
        `${
          MONTHS[datetimeval.getMonth()]
        } ${datetimeval.getDate()}, ${datetimeval.getFullYear()}  ${datetimeval.toLocaleTimeString(
          [],
          { hour: "2-digit", minute: "2-digit" }
        )}`
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    const preset = searchParams.get("cdval") || +predefinedDate;
    if (preset) {
      setSelectedDate(new Date(+preset).toISOString());
      setHasPreset(true);
    }
  }, [predefinedDate]);

  useEffect(() => {
    if (hasPreset) handleStart();
  }, [selectedDate]);

  return (
    <>
      <ToolBase
        showButtons={toolFamily !== "holidays"}
        showMainIcon={false}
        toolTitle={
          TOOLS[typeFam][type].toolTitle
            ? `${TOOLS[typeFam][type].toolTitle.prefix} ${TOOLS[typeFam][type].toolTitle.main} ${TOOLS[typeFam][type].toolTitle.postfix} `
            : `${type} ${TOOLS[typeFam][type].toolTitle.main}`
        }
        absolutePath={TOOLS[typeFam][type].absolutePath}
        type={type}
        typeFam={typeFam}
        isPrimaryMainButton={!isStart}
        isPicMode={true}
        primaryBtn={{
          mainTitle: "Start",
          secondaryTitle: "Reset",
          clickHandler: {
            main: handleStart,
            secondary: handleReset,
          },
        }}
        secondaryBtn={null}
        main={
          <CountdownMain
            isPicMode={true}
            toolFamily={toolFamily}
            countdownObj={TOOLS[typeFam][type]}
            holidayData={holidays[type]}
            type={type}
            data={data}
            selectedDate={selectedDate}
            selectedDateFormatted={selectedDateFormatted}
            showError={showError}
            isPastDate={isPastDate}
            setSelectedDate={setSelectedDate}
            tabsOptions={tabsOptions}
            handleDateSelection={handleDateSelection}
            setTabsOptions={setTabsOptions}
            hasModal={TOOLS[typeFam][type]?.slidesInfo?.length > 0}
          />
        }
      />
      {/* <button onClick={() => openCountdownModal()}>MORE</button> */}
      {countdownModal && (
        <CountdownModal slides={TOOLS[typeFam][type]?.slidesInfo} />
      )}
    </>
  );
};
