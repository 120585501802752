export const CalculatorsIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M33 12H15V21H33V12Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39 40.5015V7.50146C39 6.67304 38.3284 6.00146 37.5 6.00146L10.5 6.00146C9.67157 6.00146 9 6.67304 9 7.50146L9 40.5015C9 41.3299 9.67157 42.0015 10.5 42.0015H37.5C38.3284 42.0015 39 41.3299 39 40.5015Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 30C17.7426 30 18.75 28.9926 18.75 27.75C18.75 26.5074 17.7426 25.5 16.5 25.5C15.2574 25.5 14.25 26.5074 14.25 27.75C14.25 28.9926 15.2574 30 16.5 30Z"
        fill={color}
      />
      <path
        d="M16.5 37.5C17.7426 37.5 18.75 36.4926 18.75 35.25C18.75 34.0074 17.7426 33 16.5 33C15.2574 33 14.25 34.0074 14.25 35.25C14.25 36.4926 15.2574 37.5 16.5 37.5Z"
        fill={color}
      />
      <path
        d="M24 30C25.2426 30 26.25 28.9926 26.25 27.75C26.25 26.5074 25.2426 25.5 24 25.5C22.7574 25.5 21.75 26.5074 21.75 27.75C21.75 28.9926 22.7574 30 24 30Z"
        fill={color}
      />
      <path
        d="M24 37.5C25.2426 37.5 26.25 36.4926 26.25 35.25C26.25 34.0074 25.2426 33 24 33C22.7574 33 21.75 34.0074 21.75 35.25C21.75 36.4926 22.7574 37.5 24 37.5Z"
        fill={color}
      />
      <path
        d="M31.5 30C32.7426 30 33.75 28.9926 33.75 27.75C33.75 26.5074 32.7426 25.5 31.5 25.5C30.2574 25.5 29.25 26.5074 29.25 27.75C29.25 28.9926 30.2574 30 31.5 30Z"
        fill={color}
      />
      <path
        d="M31.5 37.5C32.7426 37.5 33.75 36.4926 33.75 35.25C33.75 34.0074 32.7426 33 31.5 33C30.2574 33 29.25 34.0074 29.25 35.25C29.25 36.4926 30.2574 37.5 31.5 37.5Z"
        fill={color}
      />
    </svg>
  );
};
