import { createContext, useState, useEffect } from "react";
import {
  TimerIcon,
  CountdownIcon,
  StopwatchIcon,
  CalculatorsIcon,
  DatesIcon,
  CalendarsIcon,
  FunziesIcon,
  WeatherIcon,
  PregnancyIcon,
  RandomDateIcon,
  VacationIcon,
  BirthdayIcon,
  DicesIcon,
  RetirementIcon,
  WeddingIcon,
  TripIcon,
  HalloweenIcon,
  EasterIcon,
  DiwaliIcon,
  HoliIcon,
  BoxingdayIcon,
  HanukkahIcon,
  MothersDayIcon,
  July4thIcon,
  NewYearIcon,
  DayOfTheDeadIcon,
  LaborDayIcon,
  ValentinesDayIcon,
  PentecostIcon,
  AscensionDayIcon,
  SamhainIcon,
  ThanksgivingIcon,
  RamadanIcon,
  GoodFridayIcon,
} from "../shared/assets/icons/ToolsOasisIcons/index";

import { holidays } from "../components/ToolsComponents/Countdown/holidays";
import { TOOLS } from "../components/ui/ToolBase/ToolsTypes";

const TimersInMainMenu = [
  "Timer",
  "10 Seconds Timer",
  "15 Seconds Timer",
  "30 Seconds Timer",
  "1 Minute Timer",
  "5 Minutes Timer",
  "10 Minutes Timer",
  "15 Minutes Timer",
  "30 Minutes Timer",
  "1 Hour Timer",
  "2 Hours Timer",
];

const DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const MONTHS = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

// const generateSeeAll = (obj){
//   obj.
// }

const generateTitleAndPathList = (currValue, segment, tool, minVal, maxVal) => {
  const optionsArr = [];
  let max = 1;
  let min = 1;

  switch (segment) {
    case "minute":
      max = maxVal ? maxVal : 120;
      min = minVal ? minVal : 1;
      break;
    case "second":
      max = maxVal ? maxVal : 120;
      min = minVal ? minVal : 1;
      break;
    case "hour":
      max = maxVal ? maxVal : 48;
      min = minVal ? minVal : 1;
      break;
    default:
      return;
  }

  for (let i = min; i <= max; i++) {
    let title = `${i} ${
      i > 1
        ? segment.slice(0, 1).toUpperCase() + segment.slice(1) + "s"
        : segment.slice(0, 1).toUpperCase() + segment.slice(1)
    } ${tool}`;
    optionsArr.push({
      iconText: `${i}${segment.slice(0, 1)}`,
      title,
      icon: TimerIcon,
      status: 1,
      isSelected: false,
      isShowInMainMenu: TimersInMainMenu.includes(title),
      path: `/Timer/${i}-${
        i > 1 ? segment + "s" : segment
      }-${tool.toLowerCase()}`,
    });
  }

  return optionsArr;
};
export const SideMenusContext = createContext();
const thisYear = new Date().getFullYear();
const thisMonth = new Date().getMonth();
const nextMonth = thisMonth === 11 ? 0 : thisMonth + 1;
const prevMonth = thisMonth === 0 ? 11 : thisMonth - 1;

const STATUS = {
  0: null,
  1: "New!",
  2: "Coming Soon...",
};

const defaultCatergories = [
  {
    title: "Timers",
    isOpen: false,
    isSelected: false,
    path: "Timer",
    bgcolor: "rgba(60, 144, 189, 1)",
    icon: TimerIcon,
    featuredMeta: {
      title: "Featured Timers | ToolsOasis",
      description:
        "A collection of all our timers, sorted by seconds, minutes and hours.",
    },
    seeAllMeta: {
      title: "All our timers in one place | ToolsOasis",
      description:
        "Timers for ever need! Starting from 1 second all the way up to 120 hours.",
    },
    subCategories: {
      Custom: {
        title: "Custom",
        description:
          "Tailor the timer to the amount of hours, minutes and seconds of your choosing. You know... like a timer.",
      },
      Seconds: {
        title: "Seconds",
        description: "All our timers from 1 to 120 seconds.",
      },
      Minutes: {
        title: "Minutes",
        description: "All our timers from 1 to 120 minutes.",
      },
      Hours: {
        title: "Hours",
        description: "All our timers from 1 to 120 hours.",
      },
    },
  },
  {
    title: "Countdowns",
    isOpen: false,
    isSelected: false,
    path: "Countdown",
    bgcolor: "rgba(52, 191, 149, 1)",
    featuredMeta: {
      title: "Featured Countdowns | ToolsOasis",
      description:
        "Count the time down to holidays, special events in your life, and more!",
    },
    seeAllMeta: {
      title: "All the countdowns in one place | ToolsOasis",
      description:
        "Count down the hours and the minutes to important holidays and life events.",
    },
    icon: CountdownIcon,
    subCategories: {
      Custom: {
        title: "Custom",
        description: "Countdown to a time and date of your choosing.",
      },
      Holidays: {
        title: "Holidays",
        description:
          "Use our countdowns for specific holidays and learn a little more about them.",
      },
      "My Life": {
        title: "My Life",
        description:
          "Custom countdowns for you to tailor to your to specific life events like a vaction or retirement.",
      },
    },
  },
  {
    title: "Stopwatch",
    isOpen: false,
    isSelected: false,
    path: "Stopwatch",
    bgcolor: "rgba(180, 146, 252, 1)",
    featuredMeta: {
      title: "Featured Stopwatches | ToolsOasis",
      description: "A stopwatch, including lap timing, on your browser.",
    },
    seeAllMeta: {
      title: "Featured Stopwatches | ToolsOasis",
      description: "A stopwatch, including lap timing, on your browser.",
    },
    icon: StopwatchIcon,
    subCategories: {
      Stopwatch: {
        title: "Stopwatch",
        description:
          "Count up accurately and measure laps for whatever you need.",
      },
    },
  },
  {
    title: "Dates",
    isOpen: false,
    isSelected: false,
    path: "Dates",
    bgcolor: "rgb(205, 97, 85)",
    featuredMeta: {
      title: "Featured Date Tools | ToolsOasis",
      description: "View all our date related tools.",
    },
    seeAllMeta: {
      title: "Featured Date Tools | ToolsOasis",
      description: "Learn what happened on this day in history and more!",
    },
    icon: DatesIcon,
    subCategories: {
      Random: {
        title: "Random",
        description:
          "Get a random date and time (or several of them) in a window of time and date format of your choosing.",
      },
      Predefined: {
        title: "Predefined to",
        description:
          "Find what important events in history happend on this day.",
      },
    },
  },
  {
    title: "Calendars",
    isOpen: false,
    isSelected: false,
    path: "Calendars",
    bgcolor: "rgb(19, 141, 117)",
    featuredMeta: {
      title: "",
      description: "",
    },
    seeAllMeta: {
      title: "",
      description: "",
    },
    icon: CalendarsIcon,
  },
  {
    title: "Weather",
    isOpen: false,
    isSelected: false,
    path: "Weather",
    bgcolor: "rgba(65, 105, 225, 1)",
    featuredMeta: {
      title: "",
      description: "",
    },
    seeAllMeta: {
      title: "",
      description: "",
    },
    icon: WeatherIcon,
  },
  {
    title: "Funzies",
    isOpen: false,
    isSelected: false,
    path: "Funzies",
    bgcolor: "rgb(240, 98, 146)",
    featuredMeta: {
      title: "",
      description: "",
    },
    seeAllMeta: {
      title: "",
      description: "",
    },
    icon: FunziesIcon,
  },
  {
    title: "Calculators",
    isOpen: false,
    isSelected: false,
    path: "Calculators",
    bgcolor: "rgba(118, 73, 191,1)",
    featuredMeta: {
      title: "Featured Calculators | ToolsOasis",
      description: "All our handy calculators.",
    },
    seeAllMeta: {
      title: "All our calculators in one place | ToolsOasis",
      description:
        "Calculators to help you hack your life into something easier.",
    },
    icon: CalculatorsIcon,
    subCategories: {
      Pregnancy: {
        title: "Due date calculater",
        description:
          "Calculate your due date based on your last period or conception date.",
      },
    },
  },
  {
    title: "Pregnancy",
    isOpen: false,
    isSelected: false,
    path: "/",
    bgcolor: "#c3c3c3",
    featuredMeta: {
      title: "Featured Pregnancy Tools | ToolsOasis",
      description: "Pregnancy related tools that will make your life easier.",
    },
    seeAllMeta: {
      title: "",
      description: "",
    },
    icon: PregnancyIcon,
    isCollection: true,
    subCategories: {
      Calculators: {
        title: "Due date Calculater",
        description:
          "Calculate your due date based on your last period or conception date.",
        icon: CalculatorsIcon,
        bgcolor: "rgba(118, 73, 191,1)",
        hubPath: "Calculators?hval=Pregnancy",
        mainCategory: "Calculators",
      },
      Countdowns: {
        title: "Due date Countdown",
        description: "A countdown to the approximate day of your baby's birth.",
        icon: CountdownIcon,
        hubPath: "Countdown?hval=My Life",
        bgcolor: "rgba(52, 191, 149, 1)",
        mainCategory: "Countdowns",
      },
    },
  },
];

const toolsPerCategory = {
  Timers: [
    {
      title: "Timer",
      isSelected: false,
      status: 1,
      path: "Timer/online-timer",
    },
    // {
    //   title: "5 Seconds Timer",
    //   isSelected: false,
    //   status: 1,
    //   path: "Timer/5-seconds-timer",
    // },
    {
      title: "10 Seconds Timer",
      isSelected: false,
      status: 1,
      path: "Timer/10-seconds-timer",
    },
    {
      title: "15 Seconds Timer",
      isSelected: false,
      status: 1,
      path: "Timer/15-seconds-timer",
    },
    {
      title: "30 Seconds Timer",
      isSelected: false,
      status: 1,
      path: "Timer/30-seconds-timer",
    },
    // {
    //   title: "45 Seconds Timer",
    //   isSelected: false,
    //   status: 1,
    //   path: "Timer/45-seconds-timer",
    // },
    {
      title: "1 Minute Timer",
      isSelected: false,
      status: 1,
      path: "Timer/1-minute-timer",
    },
    {
      title: "5 Minutes Timer",
      isSelected: false,
      status: 1,
      path: "Timer/5-minutes-timer",
    },
    {
      title: "10 Minutes Timer",
      isSelected: false,
      status: 1,
      path: "Timer/10-minutes-timer",
    },

    {
      title: "15 Minutes Timer",
      isSelected: false,
      status: 1,
      path: "Timer/15-minutes-timer",
    },
    {
      title: "30 Minutes Timer",
      isSelected: false,
      status: 1,
      path: "Timer/30-minutes-timer",
    },
    // {
    //   title: "45 Minutes Timer",
    //   isSelected: false,
    //   status: 1,
    //   path: "Timer/45-minutes-timer",
    // },
    {
      title: "1 Hour Timer",
      isSelected: false,
      status: 1,
      path: "Timer/1-hour-timer",
    },
    {
      title: "2 Hours Timer",
      isSelected: false,
      status: 1,
      path: "Timer/2-hour-timer",
    },
  ],
  Countdowns: [
    {
      title: "Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/online-countdown",
      icon: CountdownIcon,
      isShowInMainMenu: true,
      subCategory: "Custom",
    },
    {
      title: "Halloween Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/halloween-countdown",
      icon: HalloweenIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Easter Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/easter-countdown",
      icon: EasterIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Boxing Day Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/boxing-day-countdown",
      icon: BoxingdayIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Holi Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/holi-countdown",
      icon: HoliIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "New Year's Eve Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/nye-countdown",
      icon: NewYearIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Hanukkah Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/hanukkah-countdown",
      icon: HanukkahIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Diwali Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/diwali-countdown",
      icon: DiwaliIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Day Of The Dead Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/day-of-the-dead-countdown",
      icon: DayOfTheDeadIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Thanksgiving Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/thanksgiving-countdown",
      icon: ThanksgivingIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Ascension Day Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/ascension-day-countdown",
      icon: AscensionDayIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Pentecost Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/pentecost-countdown",
      icon: PentecostIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Samhain Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/samhain-countdown",
      icon: SamhainIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Labor Day Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/labor-day-countdown",
      icon: LaborDayIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Ramadan Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/ramadan-countdown",
      icon: RamadanIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Good Friday Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/good-friday-countdown",
      icon: GoodFridayIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "July 4th Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/july-4th-countdown",
      icon: July4thIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Mother's Day Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/mother's-day-countdown",
      icon: MothersDayIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Valentine's Day Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/valentine's-day-countdown",
      icon: ValentinesDayIcon,
      isShowInMainMenu: true,
      subCategory: "Holidays",
    },
    {
      title: "Wedding Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/wedding-countdown",
      icon: WeddingIcon,
      isShowInMainMenu: true,
      subCategory: "My Life",
    },
    {
      title: "Vacation Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/vacation-countdown",
      icon: VacationIcon,
      isShowInMainMenu: true,
      subCategory: "My Life",
    },
    {
      title: "Trip Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/trip-countdown",
      icon: TripIcon,
      isShowInMainMenu: true,
      subCategory: "My Life",
    },
    {
      title: "Pregnancy Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/pregnancy-countdown",
      icon: PregnancyIcon,
      isShowInMainMenu: true,
      subCategory: "My Life",
    },
    {
      title: "Retirement Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/retirement-countdown",
      icon: RetirementIcon,
      isShowInMainMenu: true,
      subCategory: "My Life",
    },
    {
      title: "Birthday Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/birthday-countdown",
      icon: BirthdayIcon,
      isShowInMainMenu: true,
      subCategory: "My Life",
    },
  ],
  Stopwatch: [
    {
      title: "Stopwatch",
      isSelected: false,
      status: 1,
      path: "Stopwatch/online-stopwatch",
      isShowInMainMenu: true,
      subCategory: "Stopwatch",
    },
  ],
  // Dices: [
  //   { title: "D&D dice", isSelected: false, status: 2 },
  //   { title: "Backgammon dices", isSelected: false, status: 2 },
  //   { title: "Custom dice", isSelected: false, status: 2 },
  // ],

  Dates: [
    {
      title: "Random Date Generator",
      isSelected: false,
      status: 1,
      path: "Dates/random-date",
      icon: RandomDateIcon,
      isShowInMainMenu: true,
      subCategory: "Random",
    },
    {
      title: "On This Day",
      isSelected: false,
      status: 1,
      path: "Dates/on-this-day",
      isShowInMainMenu: true,
      subCategory: "Predefined",
    },
    // {
    //   title: "History Calendar",
    //   isSelected: false,
    //   status: 2,
    //   path: "DateGenerator",
    // },
    // {
    //   title: "History Quiz",
    //   isSelected: false,
    //   status: 2,
    //   path: "DateGenerator",
    // },
  ],
  Calendars: [
    {
      title: "Previous Year Calendar",
      isSelected: false,
      status: 2,
      iconText: thisYear - 1,
      isShowInMainMenu: true,
    },
    {
      title: "This Year Calendar",
      isSelected: false,
      status: 2,
      iconText: thisYear,
      isShowInMainMenu: true,
    },
    {
      title: "Next Year Calendar",
      isSelected: false,
      status: 2,
      iconText: thisYear + 1,
      isShowInMainMenu: true,
    },
    {
      title: "Previous Month Calendar",
      isSelected: false,
      status: 2,
      iconText:
        MONTHS[prevMonth].slice(0, 3) +
        " " +
        (thisMonth === 0 ? thisYear - 1 : thisYear),
      isShowInMainMenu: true,
    },
    {
      title: "This Month Calendar",
      isSelected: false,
      status: 2,
      iconText: MONTHS[thisMonth].slice(0, 3) + " " + thisYear,
      isShowInMainMenu: true,
    },
    {
      title: "Next Month Calendar",
      isSelected: false,
      status: 2,
      iconText:
        MONTHS[nextMonth].slice(0, 3) +
        " " +
        (thisMonth === 11 ? thisYear + 1 : thisYear),
      isShowInMainMenu: true,
    },
    {
      title: "Custom Calendar",
      isSelected: false,
      status: 2,
      iconText: "Custom",
      isShowInMainMenu: true,
    },
  ],
  Funzies: [
    {
      title: "Fun Facts",
      isSelected: false,
      status: 2,
      isShowInMainMenu: true,
    },
    {
      title: "Dice",
      isSelected: false,
      status: 2,
      icon: DicesIcon,
      isShowInMainMenu: true,
    },
    {
      title: "When Will You Die",
      isSelected: false,
      status: 2,
      isShowInMainMenu: true,
    },
  ],
  Weather: [
    {
      title: "Todays Weather Forecast",
      isSelected: false,
      status: 2,
      iconText: "Today",
      isShowInMainMenu: true,
    },
    {
      title: "Weekly Weather Forecast",
      isSelected: false,
      status: 2,
      iconText: "This Week",
      isShowInMainMenu: true,
    },
    {
      title: "Monthly Weather Forecast",
      isSelected: false,
      status: 2,
      iconText: "This Month",
      isShowInMainMenu: true,
    },
    {
      title: "Custom Weather Forecast",
      isSelected: false,
      status: 2,
      iconText: "Custom",
      isShowInMainMenu: true,
    },
  ],
  Calculators: [
    {
      title: "Pregnancy Due Date Calculator",
      isSelected: false,
      status: 1,
      path: "Calculators/pregnancy-calculator",
      icon: PregnancyIcon,
      isShowInMainMenu: true,
      subCategory: "Pregnancy",
    },
  ],
  Pregnancy: [
    {
      title: "Pregnancy Due Date Calculator",
      isSelected: false,
      status: 1,
      path: "Calculators/pregnancy-calculator",
      icon: CalculatorsIcon,
      isShowInMainMenu: true,
      subCategory: "Calculators",
    },
    {
      title: "Pregnancy Countdown",
      isSelected: false,
      status: 1,
      path: "Countdown/pregnancy-countdown",
      icon: CountdownIcon,
      isShowInMainMenu: true,
      subCategory: "Countdowns",
    },
  ],
};

let hubDataset = [];
for (let key in toolsPerCategory) {
  let arg = Array.from(
    new Set(toolsPerCategory[key].map((el) => el.subCategory))
  );
  let tmp = [];
  for (let subCategoryItem of arg) {
    if (subCategoryItem)
      tmp.push({
        subCategory: subCategoryItem,
        data: toolsPerCategory[key].filter(
          (el) => el.subCategory === subCategoryItem
        ),
      });
  }
  if (tmp.length > 0) {
    hubDataset[`${key}`] = [...tmp];
  }
}

export const SideMenusProvider = ({ children }) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [timersData, setTimersData] = useState();
  const [toolsData, setToolsData] = useState();
  const [hubData, setHubData] = useState(hubDataset);
  const [searchValue, setSearchValue] = useState("");
  const [filteredTools, setFilteredTools] = useState();

  useEffect(() => {
    let TimerSecondsData = [
      ...generateTitleAndPathList(null, "second", "Timer", 1, 120),
    ];

    let TimerMinutesData = [
      ...generateTitleAndPathList(null, "minute", "Timer", 1, 120),
    ];

    let TimerHoursData = [
      ...generateTitleAndPathList(null, "hour", "Timer", 1, 120),
    ];

    let Timer = {
      iconText: "Custom",
      title: "Timer",
      path: "/Timer/online-timer",
      isShowInMainMenu: true,
    };

    setTimersData([
      { subCategory: "Custom", data: [Timer] },
      { subCategory: "Seconds", data: TimerSecondsData },
      { subCategory: "Minutes", data: TimerMinutesData },
      { subCategory: "Hours", data: TimerHoursData },
    ]);

    setToolsData({
      ...toolsPerCategory,
      Timers: [
        Timer,
        ...TimerSecondsData.slice(0, 58),
        ...TimerMinutesData.slice(0, 58),
        ...TimerHoursData.slice(0, 58),
      ],
    });
  }, []);

  const handleShowSidebar = () => {
    setIsSideMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setHubData({
      ...hubData,
      Timers: timersData,
    });
  }, [timersData]);

  useEffect(() => {
    for (const category of defaultCatergories) {
      const arr = toolsData
        ? toolsData[category.title].filter((el) => {
            return !searchValue
              ? el.isShowInMainMenu
              : el.title.toLowerCase().includes(searchValue);
          })
        : [];
      setFilteredTools((prev) => {
        return { ...prev, [category.title]: arr };
      });
    }
  }, [searchValue, toolsData]);

  return (
    <SideMenusContext.Provider
      value={{
        isSideMenuOpen,
        handleShowSidebar,
        defaultCatergories,
        STATUS,
        toolsPerCategory,
        setIsSideMenuOpen,
        toolsData,
        timersData,
        setSearchValue,
        searchValue,
        filteredTools,
        hubData,
        toolsDB: TOOLS,
      }}
    >
      {children}
    </SideMenusContext.Provider>
  );
};
