import asertionDayBG from "./asertionday.png";
import asertionDayMobileBG from "./asertionday-mobile.png";
import birthdayBG from "./birthday.jpg";
import birthdayMobileBG from "./birthday-mobile.jpg";
import boxingDayBG from "./boxing-day.jpg";
import boxingDayMobileBG from "./boxing-day-mobile.jpg";
import countdownBG from "./countdown.jpg";
import countdownMobileBG from "./countdown-mobile.jpg";
import dayOfTheDeadBG from "./day-of-the-dead.jpeg";
import dayOfTheDeadMobileBG from "./day-of-the-dead-mobile.jpeg";
import diwaliBG from "./diwali.png";
import diwaliMobileBG from "./diwali-mobile.png";
import easterBG from "./easter.jpg";
import easterMobileBG from "./easter-mobile.jpg";
import goodFridayBG from "./good-friday.jpg";
import goodFridayMobileBG from "./good-friday-mobile.jpg";
import halloweenBG from "./halloween.png";
import halloweenMobileBG from "./halloween-mobile.png";
import hanukkahBG from "./hanukkah.png";
import hanukkahMobileBG from "./hanukkah-mobile.png";
import holiBG from "./holi.png";
import holiMobileBG from "./holi-mobile.png";
import july4thBG from "./july-4th.png";
import july4thMobileBG from "./july-4th-mobile.png";
import laborDayBG from "./labor-day.png";
import laborMobileDayBG from "./labor-day-mobile.png";
import mothersDayBG from "./mothers-day.png";
import mothersDayMobileBG from "./mothers-day-mobile.png";
import nyeBG from "./nye.jpg";
import nyeMobileBG from "./nye-mobile.jpg";
import pentecostBG from "./pentecost.jpg";
import pentecostMobileBG from "./pentecost-mobile.jpg";
import pregnancyBG from "./pregnancy.png";
import pregnancyMobileBG from "./pregnancy-mobile.png";
import ramadanBG from "./ramadan.png";
import ramadanMobileBG from "./ramadan-mobile.png";
import retirementBG from "./retirement.png";
import retirementMobileBG from "./retirement-mobile.png";
import smahainBG from "./samhain.jpg";
import samhainMobileBG from "./samhain-mobile.jpg";
import thanksgivinBG from "./thanksgiving.jpg";
import thanksgiviMobilenBG from "./thanksgiving-mobile.jpg";
import tripBG from "./trip.png";
import tripMobileBG from "./trip-mobile.png";
import vacationBG from "./vacation.png";
import vacationMobileBG from "./vacation-mobile.png";
import valentinesBG from "./valentines.png";
import valentinesMobileBG from "./valentines-mobile.png";
import weddingBG from "./wedding.jpg";
import weddingMobileBG from "./wedding-mobile.jpg";
import calendarBG from "./onthisday.png";
import calendarMobileBG from "./onthisday-mobile.png";
import onThisDayBG from "./onthisday.png";
import onThisDayMobileBG from "./onthisday-mobile.png";

export {
  asertionDayBG,
  asertionDayMobileBG,
  birthdayBG,
  birthdayMobileBG,
  boxingDayBG,
  boxingDayMobileBG,
  dayOfTheDeadBG,
  dayOfTheDeadMobileBG,
  diwaliBG,
  diwaliMobileBG,
  easterBG,
  easterMobileBG,
  goodFridayBG,
  goodFridayMobileBG,
  halloweenBG,
  halloweenMobileBG,
  hanukkahBG,
  hanukkahMobileBG,
  holiBG,
  holiMobileBG,
  july4thBG,
  july4thMobileBG,
  laborDayBG,
  laborMobileDayBG,
  mothersDayBG,
  mothersDayMobileBG,
  nyeBG,
  nyeMobileBG,
  pentecostBG,
  pentecostMobileBG,
  pregnancyBG,
  pregnancyMobileBG,
  ramadanBG,
  ramadanMobileBG,
  retirementBG,
  retirementMobileBG,
  smahainBG,
  samhainMobileBG,
  thanksgivinBG,
  thanksgiviMobilenBG,
  tripBG,
  tripMobileBG,
  vacationBG,
  vacationMobileBG,
  valentinesBG,
  valentinesMobileBG,
  weddingBG,
  weddingMobileBG,
  calendarBG,
  calendarMobileBG,
  onThisDayBG,
  onThisDayMobileBG,
  countdownBG,
  countdownMobileBG,
};
