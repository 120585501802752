export const BackgroundIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 510 510"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <circle opacity="0.1" cx="255" cy="255" r="255" fill="#616170" />
      <circle opacity="0.2" cx="255" cy="255" r="204" fill="black" />
      <circle opacity="0.3" cx="255" cy="255" r="149" fill="#060606" />
      <circle cx="255" cy="255" r="98" fill="#212127" />
    </svg>
  );
};
