export const AddUserIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path d="M33 10.5H42" stroke={color}stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.5 6V15" stroke={color}stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.81055 40.4982C7.65472 37.3062 10.3064 34.6556 13.4993 32.8128C16.6922 30.9701 20.3138 30 24.0003 30C27.6868 30 31.3084 30.9702 34.5013 32.813C37.6941 34.6559 40.3458 37.3065 42.1899 40.4986" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.2596 22.1593C34.4575 24.325 33.0471 26.2132 31.1979 27.5968C29.3487 28.9804 27.1394 29.8007 24.8353 29.9592C22.5312 30.1176 20.2303 29.6074 18.2092 28.4899C16.188 27.3724 14.5325 25.6951 13.4414 23.6595C12.3504 21.6239 11.8703 19.3166 12.0588 17.0148C12.2473 14.713 13.0964 12.5145 14.5041 10.6835C15.9117 8.85257 17.8181 7.46697 19.9942 6.69319C22.1702 5.9194 24.5234 5.79035 26.771 6.32153" stroke={color}stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
