// import { NewCalculatorBox } from "./NewCalculatorBox";
import styled from "styled-components";
import { Icon } from "../../ui/IconWrapper/Icon";
import { NewPregnancyCalculator } from "./NewPregnancyCalculator";
import { useState } from "react";
import { ToolBase } from "../../ui/ToolBase/ToolBase";
import { TOOLS } from "../../ui/ToolBase/ToolsTypes";

export const Calculator = ({ typeFam = "calculators", type = "" }) => {
  const [calculate, setCalculate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const handleClick = () => {
    if (isFormValid) setCalculate((prev) => !prev);
  };

  return (
    <>
      <ToolBase
        toolTitle={
          TOOLS[typeFam][type].toolTitle
            ? `${TOOLS[typeFam][type].toolTitle.prefix} ${TOOLS[typeFam][type].toolTitle.main}  ${TOOLS[typeFam][type].toolTitle.postfix} `
            : `${type} Calcualtor`
        }
        absolutePath={TOOLS[typeFam][type].absolutePath}
        type={type}
        typeFam={typeFam}
        isPrimaryMainButton={!calculate}
        primaryBtn={{
          mainTitle: "Calculate",
          secondaryTitle: "Reset",
          clickHandler: {
            main: handleClick,
            secondary: () => {
              alert("click");
            },
          },
        }}
        secondaryBtn={null}
        main={
          type === "pregnancy" && (
            <NewPregnancyCalculator
              countdownTypeObj={TOOLS[typeFam][type]}
              calculate={calculate}
              setIsFormValid={setIsFormValid}
            />
          )
        }
      />
    </>
  );
};
