import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { Badge } from "../../components/ui/Badge/Badge";
import { SideMenusContext } from "../../contexts/side-menus-context";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const {
    isSideMenuOpen,
    setIsSideMenuOpen,
    defaultCatergories,
    STATUS,
    toolsPerCategory,
  } = useContext(SideMenusContext);

  const [categories, setCategories] = useState([]);
  useState(() => {
    setCategories(defaultCatergories);
  }, []);

  useEffect(() => {
    setCategories(defaultCatergories);
  }, [defaultCatergories, isSideMenuOpen]);

  return (
    <SidebarStyled showSidebar={isSideMenuOpen}>
      <CategoriesMenuStyled>
        {categories.map((categoryEl) => (
          <>
            <CategoryStyled
              key={categoryEl.title}
              bgColor={categoryEl.bgcolor}
              isOpen={categoryEl.isOpen}
              onClick={() => {
                setCategories((prevState) => {
                  let tmp = [...prevState];
                  let index = tmp.findIndex(
                    (el) => el.title === categoryEl.title
                  );
                  tmp[index] = { ...tmp[index], isOpen: !tmp[index].isOpen };
                  return tmp;
                });
              }}
            >
              <CategoryIdentifier bgColor={categoryEl.bgcolor} />
              {categoryEl.title}
            </CategoryStyled>
            {categoryEl.isOpen && (
              <ToolsMenu>
                {toolsPerCategory[`${categoryEl.title}`].map((el) => (
                  <ToolMenuItem
                    key={el.title}
                    bgColor={categoryEl.bgcolor}

                  >
                    <a href={`/${el.path}`} onClick={(e) => {
                      e.preventDefault()
                      if (el.status === 2) return;
                      navigate(`/${el.path}`);
                      //@TODO: close only in mobile
                      //@TODO: fix hamburger issue (not connected to the state)
                      setIsSideMenuOpen(false);
                    }}>
                      {el.title}
                      <Badge
                        title={STATUS[el.status]}
                        // background={
                        //   el.status === 1 ? categoryEl.bgcolor : "#f4f4f4"
                        // }
                        background={categoryEl.bgcolor}
                        color={el.status === 1 ? "#fff" : "black"}
                      />
                    </a>
                  </ToolMenuItem>
                ))}
              </ToolsMenu>
            )}
          </>
        ))}
      </CategoriesMenuStyled>
    </SidebarStyled>
  );
};

const SidebarStyled = styled.aside`
  height: calc(100vh - 9rem);
  z-index: 1300;
  width: 50rem;
  max-width: 100vw;
  top: 6rem;
  left: ${(props) => (props.showSidebar ? 0 : "-80rem")};
  z-index: 1300;
  position: absolute;
  background: #2b2a33;
  background-color: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: 0.6s ease-in-out;
  gap: 3rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  overflow-y: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const CategoriesMenuStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b2a33;
  flex-direction: column;
  width: 100%;
  opacity: 1;
`;

const CategoryStyled = styled.li`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border-radius: 0.5rem; */
  font-size: 1.5rem;
  min-height: 6rem;
  max-height: 6rem;
  width: 100%;
  gap: 1rem;
  padding: 0.05rem 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 2px 4px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px -1px;
  color: ${(props) => (props.isOpen ? props.bgColor : "white")};

  &:hover {
    /* color: rgb(56, 214, 214); */
    /* background: #111; */
    cursor: pointer;
  }
`;

const CategoryIdentifier = styled.div`
  height: 100%;
  width: 0.8rem;
  background: ${(props) => props.bgColor};
`;

const ToolsMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  opacity: 0.8;
  background: #111;
`;

const ToolMenuItem = styled.li`
width: 100%;
height: 4rem;

 a{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border-radius: 0.5rem; */
  padding-left: 2rem !important;
  font-size: 1.5rem;
  height: 4rem;
  width: 100%;
  gap: 1rem;
  padding: 0.05rem 0;
  color: white;
}
 
  &:hover {
    cursor: pointer;
    background: ${(props) => props.bgColor};
    /* color: ${(props) => props.bgColor}; */
    /* color: #111; */
  }
`;

export default Sidebar;
