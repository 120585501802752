import { el } from "date-fns/locale";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useCountdown } from "../../../hooks/useCountdown";
import { CountdownBox } from "../CountdownBox";
import { capitalsData } from "./capitals";
import { countriesData } from "./countries";
import { cities } from "./cities";
import vacation from "../../../shared/assets/images/vacation.jpg";
import { MapItem } from "../../Map/Map";

export const VacationCountdown = () => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [enteredCountry, setEnteredCountry] = useState();
  const [enteredCity, setEnteredCity] = useState();
  const [filteredCountryValues, setFilteredCountryValues] = useState([]);
  const [filteredCityValues, setFilteredCityValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  const [isMapOnTop, setIsMapOnTop] = useState(false);
  const [isShowMap, setIsShowMap] = useState(true);
  let data = useCountdown(selectedDate, isStart);

  useEffect(() => {
    setIsPastDate(new Date(selectedDate) - new Date() < 10);
    if (new Date(selectedDate) - new Date() > 10) setIsStart(true);
  }, [selectedDate]);

  useEffect(() => {
    if (!enteredCountry) setFilteredCountryValues([]);
    else
      setFilteredCountryValues(
        countriesData.filter((el) =>
          el.name.toLowerCase().startsWith(enteredCountry)
        )
      );
  }, [enteredCountry]);

  useEffect(() => {
    if (!enteredCity) setFilteredCityValues([]);
    else
      setFilteredCityValues(
        selectedCountry.cities.filter((el) =>
          el.toLowerCase().startsWith(enteredCity)
        )
      );
  }, [enteredCity]);

  const handleDateSelection = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleCountryInput = (e) => {
    setSelectedCountry("");
    setEnteredCountry(e.target.value.toLowerCase());
  };

  const handleCountrySelection = (el) => {
    setFilteredCountryValues(null);
    setSelectedCountry(el);
    // console.log(capitals.filter((el) => el.name === selectedCountry.name))
  };

  const handleCityInput = (e) => {
    setEnteredCity(e.target.value.toLowerCase());
  };

  const handleCitySelection = (el) => {
    setFilteredCityValues(null);
    setSelectedCity(el);
  };

  return (
    <VacationCountdownStyled className="flex-col flex-c-c">
      <BackgroundAbs>
        {/* <img src={vacation} height="100%" width="auto" /> */}
      </BackgroundAbs>
      <InputsContainer className="flex-col flex-t-c">
        {!selectedCountry && (
          <input
            type="search"
            placeholder="Enter destination country..."
            onChange={handleCountryInput}
            value={selectedCountry ? selectedCountry.name : enteredCountry}
          />
        )}
        {!selectedCity && selectedCountry && (
          <input
            type="search"
            placeholder="Enter destination city..."
            onChange={handleCityInput}
            value={selectedCity ? selectedCity : enteredCity}
          />
        )}
      </InputsContainer>

      {selectedCountry && selectedCity && selectedDate && (
        <CountryInfo>
          <SelectedCountryStyled className="flex-row flex-l-c">
            <img src={selectedCountry.flag} alt={"country flag"} />
            <span>{selectedCountry.name}</span>
            {selectedCity && <span>, {selectedCity}.</span>}
          </SelectedCountryStyled>
          {isShowMap &&
            selectedCountry &&
            cities[selectedCountry.name][selectedCity] && (
              <MapContainer isMapOnTop={isMapOnTop}>
                <MapItem
                  lng={
                    cities[selectedCountry.name][selectedCity] &&
                    +cities[selectedCountry.name][selectedCity].lng
                  }
                  lat={
                    cities[selectedCountry.name][selectedCity] &&
                    +cities[selectedCountry.name][selectedCity].lat
                  }
                />
              </MapContainer>
            )}
          <SelecteCityDetails className="flex-col">
            {/* <div className="flex-row flex-l-c">
              <img src={el.image} alt={"flag"} />
            </div> */}
            <div className="flex-col flex-l-c">
              <div className="flex-row flex-l-c">
                <span>capital:</span> {selectedCountry.capital}
              </div>
              <div className="flex-row flex-l-c">
                <span>currency:</span> {selectedCountry.currency}
              </div>
              <div className="flex-row flex-l-c">
                <span>Dial Code:</span> {selectedCountry.dialCode}
              </div>
              {cities[selectedCountry.name][selectedCity] && (
                <div className="flex-row flex-l-c">
                  <span>Target City Population ({cities.last_update}):</span>{" "}
                  {(+cities[selectedCountry.name][selectedCity]
                    .population).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </div>
              )}
              {cities[selectedCountry.name][selectedCity] && (
                <div className="flex-row flex-l-c">
                  <span>Target City Administration:</span>{" "}
                  {cities[selectedCountry.name][selectedCity].admin_name}
                </div>
              )}
            </div>
          </SelecteCityDetails>
        </CountryInfo>
      )}

      {selectedCity && selectedCountry && (
        <CountdownContainer className="flex-col flex-c-c">
          <DateTimeStyled className="" isPastDate={isPastDate}>
            {selectedDate && !isPastDate && (
              <div
                onClick={() => {
                  setSelectedDate("");
                }}
              >
                {new Date(selectedDate).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </div>
            )}
            {selectedCountry &&
              selectedCity &&
              (!selectedDate || isPastDate) && (
                <input
                  style={{ width: "25rem" }}
                  className="__date-time-input"
                  type="text"
                  onMouseEnter={(e) => (e.target.type = "datetime-local")}
                  onBlur={(e) => (e.target.type = "text")}
                  onChange={handleDateSelection}
                  value={selectedDate.replace("T", " ")}
                  placeholder="Enter Date"
                />
              )}
          </DateTimeStyled>
          <div
            style={{
              color: "red",
              display: isPastDate ? "block" : "none",
            }}
          >
            Please Select Future Date
          </div>
        </CountdownContainer>
      )}

      <OptionsContainer className="flex-col flex-c-c">
        {filteredCountryValues && (
          <ContriesOptions className="flex-col flex-t-c">
            {filteredCountryValues.map((el) => (
              <li
                className="flex-row flex-l-c"
                onClick={() => handleCountrySelection(el)}
              >
                <img src={el.flag} />
                <span>{el.name}</span>
              </li>
            ))}
          </ContriesOptions>
        )}
        {filteredCityValues && (
          <ContriesOptions className="flex-col flex-t-l">
            {filteredCityValues.map((el) => (
              <li
                className="flex-row flex-l-c"
                onClick={() => handleCitySelection(el)}
              >
                <span>&#127961;{el}</span>
              </li>
            ))}
          </ContriesOptions>
        )}
        {selectedDate && (
          <>
            {/* <CountingStyled className="">
                Counting the seconds to get away
              </CountingStyled> */}

            <div className="flex-row">
              <CountdownBox
                {...data}
                style={{
                  maxWidth: "95",
                }}
              />
            </div>
          </>
        )}
      </OptionsContainer>
      {selectedCountry && selectedCity && selectedDate && (
        <div
          style={{
            position: "absolute",
            bottom: "10rem",
            right: "3rem",
            zindex: 2,
          }}
        >
          {isShowMap && (
            <button
              onClick={() => setIsMapOnTop((prev) => !prev)}
              style={{
                background: "black",
                padding: "1rem",
                color: "#fff",
                zindex: 2,
              }}
            >
              {isMapOnTop ? "Map as Background" : "Interactive Map"}
            </button>
          )}
          <button
            onClick={() => setIsShowMap((prev) => !prev)}
            style={{
              background: "black",
              padding: "1rem",
              color: "#fff",
              zindex: 2,
            }}
          >
            {isShowMap ? "Hide Map" : "Show Map"}
          </button>
        </div>
      )}
    </VacationCountdownStyled>
  );
};

const VacationCountdownStyled = styled.div`
  offset: auto;
  padding: 0 3rem;
  width: 100vw;
  max-width: 50rem;
  height: calc(100vh - 6rem);
  position: realtive;
  padding-top: 6rem;
  input {
    width: 80vw;
    max-width: 50rem;
  }
`;

const ContriesOptions = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1rem;
  max-width: 40rem;
  height: 100%;
  color: white;
  gap: 2rem;
  text-align: left;
  li {
    height: 3rem;
    width: 100%;
    align-items: center;
    gap: 1rem;
    img {
      width: 6rem;
      height: 3.5rem !important;
    }

    span {
      font-size: 1.5rem;
    }
  }
`;

const SelectedCountryStyled = styled.div`
  width: 90vw;
  color: white;
  padding: 2rem 2rem;
  gap: 2rem;
  text-align: left;
  height: 3rem;
  align-items: center;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.7);
  img {
    width: 6rem;
    height: 3.5rem !important;
  }

  span {
    font-size: 1.8rem;
  }
`;

const SelecteCityDetails = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 90vw;
  color: white;
  padding-left: 2rem;
  text-align: left;
  height: 3rem;
  height: fit-content;

  div {
    gap: 1rem;
    font-size: 1.4rem;
    color: rgb(56, 214, 214);
  }
  span {
    color: white;
    text-transform: capitalize;
    font-size: 1.4rem;
  }
`;

const CountryInfo = styled.div`
  max-width: 50rem;
  align-self: center;
`;

const CountingStyled = styled.div`
  font-size: 1.6rem;
  padding: 0 3rem;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
`;

const DateTimeStyled = styled.div`
  div {
    font-size: 1.5rem;
    padding: 0 3rem;
    font-weight: 600;
  }

  input {
    color: ${(props) => (props.isPastDate ? "red" : "inherit")};
  }
`;

const CountdownContainer = styled.div`
  gap: 4rem;
`;

const BackgroundAbs = styled.div`
  width: 100vw !important;
  position: absolute;
  top: 0;
  max-height: 40rem !important;
  z-index: -1;
  background-image: url("../../../shared/assets/images/vacation.jpg") center
    center/cover;
`;

const InputsContainer = styled.div`
  max-height: 8rem;
`;

const OptionsContainer = styled.div`
  flex-grow: 1;
`;

const MapContainer = styled.section`
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.isMapOnTop ? 0 : -1)};
`;
