import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { SideMenusContext } from "../../contexts/side-menus-context";
const toggleX = (x) => {
  x.classList.toggle("change");
};

export const HamburgerIcon = ({ onClick }) => {
  const { isSideMenuOpen } = useContext(SideMenusContext);

  const [change, setChange] = useState(isSideMenuOpen);
  useEffect(() => {
    setChange(isSideMenuOpen);
  }, [isSideMenuOpen]);

  return (
    <HamburgerIconStyled
      className={`container`}
      onClick={() => {
        setChange((prevState) => !prevState);
        onClick();
      }}
    >
      <div className={`bar1 ${change ? "change" : ""}`}></div>
      <div className={`bar2 ${change ? "change" : ""}`}></div>
      <div className={`bar3 ${change ? "change" : ""}`}></div>
    </HamburgerIconStyled>
  );
};

const HamburgerIconStyled = styled.div`
  cursor: pointer;
  & .container {
    display: inline-block;
    cursor: pointer;
  }

  & .bar1,
  .bar2,
  .bar3 {
    width: 2.5rem;
    height: 0.2rem;
    background-color: white;
    margin: 0.6rem 0;
    transition: 0.5s;
    border-radius: 8rem;
  }

  /* Rotate first bar */
  & .bar1.change {
    transform: translate(0, 0.8rem) rotate(315deg);
  }

  /* Fade out the second bar */
  & .bar2.change {
    opacity: 0;
  }

  /* Rotate last bar */
  & .bar3.change {
    transform: translate(0, -0.8rem) rotate(405deg);
  }
`;
