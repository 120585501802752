import "./App.css";
import { Layout } from "./Layout/Layout";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import {
  HomePage,
  DatePage,
  CountdownPage,
  StopWatchPage,
  TimerPage,
  ContactUsPage,
  HomePage2,
  CalculatorPage,
  HubPage,
} from "./pages/index.js";
import { UnderConstruction } from "./pages/under-construction";
import { PageNotFound } from "./pages/not-found";
import { SideMenusProvider } from "./contexts/side-menus-context";
import { FeaturedMenu } from "./components/FeaturedMenu/FeaturedIMenu";
import { ModalContextProvider } from "./contexts/modal-context";

function App() {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <div className="App">
      <Router>
        <SideMenusProvider>
          <ModalContextProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/2" element={<HomePage2 />} />
                <Route path="/3" />
                <Route path="/Featured" element={<FeaturedMenu />} />
                <Route path="/Timer">
                  <Route path="" element={<HubPage type="Timers" />} />
                  <Route path="online-timer" element={<TimerPage />} />
                  <Route path="1-second-timer" element={<TimerPage />} />
                  <Route path="2-seconds-timer" element={<TimerPage />} />
                  <Route path="2-second-timer" element={<TimerPage />} />
                  <Route path="3-seconds-timer" element={<TimerPage />} />
                  <Route path="4-seconds-timer" element={<TimerPage />} />
                  <Route path="5-seconds-timer" element={<TimerPage />} />
                  <Route path="6-seconds-timer" element={<TimerPage />} />
                  <Route path="7-seconds-timer" element={<TimerPage />} />
                  <Route path="8-seconds-timer" element={<TimerPage />} />
                  <Route path="9-seconds-timer" element={<TimerPage />} />
                  <Route path="10-seconds-timer" element={<TimerPage />} />
                  <Route path="11-seconds-timer" element={<TimerPage />} />
                  <Route path="12-seconds-timer" element={<TimerPage />} />
                  <Route path="13-seconds-timer" element={<TimerPage />} />
                  <Route path="14-seconds-timer" element={<TimerPage />} />
                  <Route path="15-seconds-timer" element={<TimerPage />} />
                  <Route path="16-seconds-timer" element={<TimerPage />} />
                  <Route path="17-seconds-timer" element={<TimerPage />} />
                  <Route path="18-seconds-timer" element={<TimerPage />} />
                  <Route path="19-seconds-timer" element={<TimerPage />} />
                  <Route path="20-seconds-timer" element={<TimerPage />} />
                  <Route path="21-seconds-timer" element={<TimerPage />} />
                  <Route path="22-seconds-timer" element={<TimerPage />} />
                  <Route path="23-seconds-timer" element={<TimerPage />} />
                  <Route path="24-seconds-timer" element={<TimerPage />} />
                  <Route path="25-seconds-timer" element={<TimerPage />} />
                  <Route path="26-seconds-timer" element={<TimerPage />} />
                  <Route path="27-seconds-timer" element={<TimerPage />} />
                  <Route path="28-seconds-timer" element={<TimerPage />} />
                  <Route path="29-seconds-timer" element={<TimerPage />} />
                  <Route path="30-seconds-timer" element={<TimerPage />} />
                  <Route path="31-seconds-timer" element={<TimerPage />} />
                  <Route path="32-seconds-timer" element={<TimerPage />} />
                  <Route path="33-seconds-timer" element={<TimerPage />} />
                  <Route path="34-seconds-timer" element={<TimerPage />} />
                  <Route path="35-seconds-timer" element={<TimerPage />} />
                  <Route path="36-seconds-timer" element={<TimerPage />} />
                  <Route path="37-seconds-timer" element={<TimerPage />} />
                  <Route path="38-seconds-timer" element={<TimerPage />} />
                  <Route path="39-seconds-timer" element={<TimerPage />} />
                  <Route path="40-seconds-timer" element={<TimerPage />} />
                  <Route path="42-seconds-timer" element={<TimerPage />} />
                  <Route path="41-seconds-timer" element={<TimerPage />} />
                  <Route path="43-seconds-timer" element={<TimerPage />} />
                  <Route path="44-seconds-timer" element={<TimerPage />} />
                  <Route path="45-seconds-timer" element={<TimerPage />} />
                  <Route path="46-seconds-timer" element={<TimerPage />} />
                  <Route path="47-seconds-timer" element={<TimerPage />} />
                  <Route path="48-seconds-timer" element={<TimerPage />} />
                  <Route path="49-seconds-timer" element={<TimerPage />} />
                  <Route path="50-seconds-timer" element={<TimerPage />} />
                  <Route path="51-seconds-timer" element={<TimerPage />} />
                  <Route path="52-seconds-timer" element={<TimerPage />} />
                  <Route path="53-seconds-timer" element={<TimerPage />} />
                  <Route path="54-seconds-timer" element={<TimerPage />} />
                  <Route path="55-seconds-timer" element={<TimerPage />} />
                  <Route path="56-seconds-timer" element={<TimerPage />} />
                  <Route path="57-seconds-timer" element={<TimerPage />} />
                  <Route path="58-seconds-timer" element={<TimerPage />} />
                  <Route path="59-seconds-timer" element={<TimerPage />} />
                  <Route path="60-seconds-timer" element={<TimerPage />} />
                  <Route path="61-seconds-timer" element={<TimerPage />} />
                  <Route path="62-seconds-timer" element={<TimerPage />} />
                  <Route path="63-seconds-timer" element={<TimerPage />} />
                  <Route path="64-seconds-timer" element={<TimerPage />} />
                  <Route path="65-seconds-timer" element={<TimerPage />} />
                  <Route path="66-seconds-timer" element={<TimerPage />} />
                  <Route path="67-seconds-timer" element={<TimerPage />} />
                  <Route path="68-seconds-timer" element={<TimerPage />} />
                  <Route path="69-seconds-timer" element={<TimerPage />} />
                  <Route path="70-seconds-timer" element={<TimerPage />} />
                  <Route path="71-seconds-timer" element={<TimerPage />} />
                  <Route path="72-seconds-timer" element={<TimerPage />} />
                  <Route path="73-seconds-timer" element={<TimerPage />} />
                  <Route path="74-seconds-timer" element={<TimerPage />} />
                  <Route path="75-seconds-timer" element={<TimerPage />} />
                  <Route path="76-seconds-timer" element={<TimerPage />} />
                  <Route path="77-seconds-timer" element={<TimerPage />} />
                  <Route path="78-seconds-timer" element={<TimerPage />} />
                  <Route path="79-seconds-timer" element={<TimerPage />} />
                  <Route path="80-seconds-timer" element={<TimerPage />} />
                  <Route path="81-seconds-timer" element={<TimerPage />} />
                  <Route path="82-seconds-timer" element={<TimerPage />} />
                  <Route path="83-seconds-timer" element={<TimerPage />} />
                  <Route path="84-seconds-timer" element={<TimerPage />} />
                  <Route path="85-seconds-timer" element={<TimerPage />} />
                  <Route path="86-seconds-timer" element={<TimerPage />} />
                  <Route path="87-seconds-timer" element={<TimerPage />} />
                  <Route path="88-seconds-timer" element={<TimerPage />} />
                  <Route path="89-seconds-timer" element={<TimerPage />} />
                  <Route path="90-seconds-timer" element={<TimerPage />} />
                  <Route path="91-seconds-timer" element={<TimerPage />} />
                  <Route path="92-seconds-timer" element={<TimerPage />} />
                  <Route path="93-seconds-timer" element={<TimerPage />} />
                  <Route path="94-seconds-timer" element={<TimerPage />} />
                  <Route path="95-seconds-timer" element={<TimerPage />} />
                  <Route path="96-seconds-timer" element={<TimerPage />} />
                  <Route path="97-seconds-timer" element={<TimerPage />} />
                  <Route path="98-seconds-timer" element={<TimerPage />} />
                  <Route path="99-seconds-timer" element={<TimerPage />} />
                  <Route path="100-seconds-timer" element={<TimerPage />} />
                  <Route path="101-seconds-timer" element={<TimerPage />} />
                  <Route path="102-seconds-timer" element={<TimerPage />} />
                  <Route path="103-seconds-timer" element={<TimerPage />} />
                  <Route path="104-seconds-timer" element={<TimerPage />} />
                  <Route path="105-seconds-timer" element={<TimerPage />} />
                  <Route path="106-seconds-timer" element={<TimerPage />} />
                  <Route path="107-seconds-timer" element={<TimerPage />} />
                  <Route path="108-seconds-timer" element={<TimerPage />} />
                  <Route path="109-seconds-timer" element={<TimerPage />} />
                  <Route path="110-seconds-timer" element={<TimerPage />} />
                  <Route path="111-seconds-timer" element={<TimerPage />} />
                  <Route path="112-seconds-timer" element={<TimerPage />} />
                  <Route path="113-seconds-timer" element={<TimerPage />} />
                  <Route path="114-seconds-timer" element={<TimerPage />} />
                  <Route path="115-seconds-timer" element={<TimerPage />} />
                  <Route path="116-seconds-timer" element={<TimerPage />} />
                  <Route path="117-seconds-timer" element={<TimerPage />} />
                  <Route path="118-seconds-timer" element={<TimerPage />} />
                  <Route path="119-seconds-timer" element={<TimerPage />} />
                  <Route path="120-seconds-timer" element={<TimerPage />} />
                  <Route path="1-minute-timer" element={<TimerPage />} />
                  <Route path="2-minutes-timer" element={<TimerPage />} />
                  <Route path="2-minute-timer" element={<TimerPage />} />
                  <Route path="3-minutes-timer" element={<TimerPage />} />
                  <Route path="4-minutes-timer" element={<TimerPage />} />
                  <Route path="5-minutes-timer" element={<TimerPage />} />
                  <Route path="6-minutes-timer" element={<TimerPage />} />
                  <Route path="7-minutes-timer" element={<TimerPage />} />
                  <Route path="8-minutes-timer" element={<TimerPage />} />
                  <Route path="9-minutes-timer" element={<TimerPage />} />
                  <Route path="10-minutes-timer" element={<TimerPage />} />
                  <Route path="11-minutes-timer" element={<TimerPage />} />
                  <Route path="12-minutes-timer" element={<TimerPage />} />
                  <Route path="13-minutes-timer" element={<TimerPage />} />
                  <Route path="14-minutes-timer" element={<TimerPage />} />
                  <Route path="15-minutes-timer" element={<TimerPage />} />
                  <Route path="16-minutes-timer" element={<TimerPage />} />
                  <Route path="17-minutes-timer" element={<TimerPage />} />
                  <Route path="18-minutes-timer" element={<TimerPage />} />
                  <Route path="19-minutes-timer" element={<TimerPage />} />
                  <Route path="20-minutes-timer" element={<TimerPage />} />
                  <Route path="21-minutes-timer" element={<TimerPage />} />
                  <Route path="22-minutes-timer" element={<TimerPage />} />
                  <Route path="23-minutes-timer" element={<TimerPage />} />
                  <Route path="24-minutes-timer" element={<TimerPage />} />
                  <Route path="25-minutes-timer" element={<TimerPage />} />
                  <Route path="26-minutes-timer" element={<TimerPage />} />
                  <Route path="27-minutes-timer" element={<TimerPage />} />
                  <Route path="28-minutes-timer" element={<TimerPage />} />
                  <Route path="29-minutes-timer" element={<TimerPage />} />
                  <Route path="30-minutes-timer" element={<TimerPage />} />
                  <Route path="31-minutes-timer" element={<TimerPage />} />
                  <Route path="32-minutes-timer" element={<TimerPage />} />
                  <Route path="33-minutes-timer" element={<TimerPage />} />
                  <Route path="34-minutes-timer" element={<TimerPage />} />
                  <Route path="35-minutes-timer" element={<TimerPage />} />
                  <Route path="36-minutes-timer" element={<TimerPage />} />
                  <Route path="37-minutes-timer" element={<TimerPage />} />
                  <Route path="38-minutes-timer" element={<TimerPage />} />
                  <Route path="39-minutes-timer" element={<TimerPage />} />
                  <Route path="40-minutes-timer" element={<TimerPage />} />
                  <Route path="41-minutes-timer" element={<TimerPage />} />
                  <Route path="42-minutes-timer" element={<TimerPage />} />
                  <Route path="43-minutes-timer" element={<TimerPage />} />
                  <Route path="44-minutes-timer" element={<TimerPage />} />
                  <Route path="45-minutes-timer" element={<TimerPage />} />
                  <Route path="46-minutes-timer" element={<TimerPage />} />
                  <Route path="47-minutes-timer" element={<TimerPage />} />
                  <Route path="48-minutes-timer" element={<TimerPage />} />
                  <Route path="49-minutes-timer" element={<TimerPage />} />
                  <Route path="50-minutes-timer" element={<TimerPage />} />
                  <Route path="51-minutes-timer" element={<TimerPage />} />
                  <Route path="52-minutes-timer" element={<TimerPage />} />
                  <Route path="53-minutes-timer" element={<TimerPage />} />
                  <Route path="54-minutes-timer" element={<TimerPage />} />
                  <Route path="55-minutes-timer" element={<TimerPage />} />
                  <Route path="56-minutes-timer" element={<TimerPage />} />
                  <Route path="57-minutes-timer" element={<TimerPage />} />
                  <Route path="58-minutes-timer" element={<TimerPage />} />
                  <Route path="59-minutes-timer" element={<TimerPage />} />
                  <Route path="60-minutes-timer" element={<TimerPage />} />
                  <Route path="61-minutes-timer" element={<TimerPage />} />
                  <Route path="62-minutes-timer" element={<TimerPage />} />
                  <Route path="63-minutes-timer" element={<TimerPage />} />
                  <Route path="64-minutes-timer" element={<TimerPage />} />
                  <Route path="65-minutes-timer" element={<TimerPage />} />
                  <Route path="66-minutes-timer" element={<TimerPage />} />
                  <Route path="67-minutes-timer" element={<TimerPage />} />
                  <Route path="68-minutes-timer" element={<TimerPage />} />
                  <Route path="69-minutes-timer" element={<TimerPage />} />
                  <Route path="70-minutes-timer" element={<TimerPage />} />
                  <Route path="71-minutes-timer" element={<TimerPage />} />
                  <Route path="72-minutes-timer" element={<TimerPage />} />
                  <Route path="73-minutes-timer" element={<TimerPage />} />
                  <Route path="74-minutes-timer" element={<TimerPage />} />
                  <Route path="75-minutes-timer" element={<TimerPage />} />
                  <Route path="76-minutes-timer" element={<TimerPage />} />
                  <Route path="77-minutes-timer" element={<TimerPage />} />
                  <Route path="78-minutes-timer" element={<TimerPage />} />
                  <Route path="79-minutes-timer" element={<TimerPage />} />
                  <Route path="80-minutes-timer" element={<TimerPage />} />
                  <Route path="81-minutes-timer" element={<TimerPage />} />
                  <Route path="82-minutes-timer" element={<TimerPage />} />
                  <Route path="83-minutes-timer" element={<TimerPage />} />
                  <Route path="84-minutes-timer" element={<TimerPage />} />
                  <Route path="85-minutes-timer" element={<TimerPage />} />
                  <Route path="86-minutes-timer" element={<TimerPage />} />
                  <Route path="87-minutes-timer" element={<TimerPage />} />
                  <Route path="88-minutes-timer" element={<TimerPage />} />
                  <Route path="89-minutes-timer" element={<TimerPage />} />
                  <Route path="90-minutes-timer" element={<TimerPage />} />
                  <Route path="91-minutes-timer" element={<TimerPage />} />
                  <Route path="92-minutes-timer" element={<TimerPage />} />
                  <Route path="93-minutes-timer" element={<TimerPage />} />
                  <Route path="94-minutes-timer" element={<TimerPage />} />
                  <Route path="95-minutes-timer" element={<TimerPage />} />
                  <Route path="96-minutes-timer" element={<TimerPage />} />
                  <Route path="97-minutes-timer" element={<TimerPage />} />
                  <Route path="98-minutes-timer" element={<TimerPage />} />
                  <Route path="99-minutes-timer" element={<TimerPage />} />
                  <Route path="100-minutes-timer" element={<TimerPage />} />
                  <Route path="101-minutes-timer" element={<TimerPage />} />
                  <Route path="102-minutes-timer" element={<TimerPage />} />
                  <Route path="103-minutes-timer" element={<TimerPage />} />
                  <Route path="104-minutes-timer" element={<TimerPage />} />
                  <Route path="105-minutes-timer" element={<TimerPage />} />
                  <Route path="106-minutes-timer" element={<TimerPage />} />
                  <Route path="107-minutes-timer" element={<TimerPage />} />
                  <Route path="108-minutes-timer" element={<TimerPage />} />
                  <Route path="109-minutes-timer" element={<TimerPage />} />
                  <Route path="110-minutes-timer" element={<TimerPage />} />
                  <Route path="111-minutes-timer" element={<TimerPage />} />
                  <Route path="112-minutes-timer" element={<TimerPage />} />
                  <Route path="113-minutes-timer" element={<TimerPage />} />
                  <Route path="114-minutes-timer" element={<TimerPage />} />
                  <Route path="115-minutes-timer" element={<TimerPage />} />
                  <Route path="116-minutes-timer" element={<TimerPage />} />
                  <Route path="117-minutes-timer" element={<TimerPage />} />
                  <Route path="118-minutes-timer" element={<TimerPage />} />
                  <Route path="119-minutes-timer" element={<TimerPage />} />
                  <Route path="120-minutes-timer" element={<TimerPage />} />
                  <Route path="1-hour-timer" element={<TimerPage />} />
                  <Route path="2-hours-timer" element={<TimerPage />} />
                  <Route path="2-hour-timer" element={<TimerPage />} />
                  <Route path="3-hours-timer" element={<TimerPage />} />
                  <Route path="4-hours-timer" element={<TimerPage />} />
                  <Route path="5-hours-timer" element={<TimerPage />} />
                  <Route path="6-hours-timer" element={<TimerPage />} />
                  <Route path="7-hours-timer" element={<TimerPage />} />
                  <Route path="8-hours-timer" element={<TimerPage />} />
                  <Route path="9-hours-timer" element={<TimerPage />} />
                  <Route path="10-hours-timer" element={<TimerPage />} />
                  <Route path="11-hours-timer" element={<TimerPage />} />
                  <Route path="12-hours-timer" element={<TimerPage />} />
                  <Route path="13-hours-timer" element={<TimerPage />} />
                  <Route path="14-hours-timer" element={<TimerPage />} />
                  <Route path="15-hours-timer" element={<TimerPage />} />
                  <Route path="16-hours-timer" element={<TimerPage />} />
                  <Route path="17-hours-timer" element={<TimerPage />} />
                  <Route path="18-hours-timer" element={<TimerPage />} />
                  <Route path="19-hours-timer" element={<TimerPage />} />
                  <Route path="20-hours-timer" element={<TimerPage />} />
                  <Route path="21-hours-timer" element={<TimerPage />} />
                  <Route path="22-hours-timer" element={<TimerPage />} />
                  <Route path="23-hours-timer" element={<TimerPage />} />
                  <Route path="24-hours-timer" element={<TimerPage />} />
                  <Route path="25-hours-timer" element={<TimerPage />} />
                  <Route path="26-hours-timer" element={<TimerPage />} />
                  <Route path="27-hours-timer" element={<TimerPage />} />
                  <Route path="28-hours-timer" element={<TimerPage />} />
                  <Route path="29-hours-timer" element={<TimerPage />} />
                  <Route path="30-hours-timer" element={<TimerPage />} />
                  <Route path="31-hours-timer" element={<TimerPage />} />
                  <Route path="32-hours-timer" element={<TimerPage />} />
                  <Route path="33-hours-timer" element={<TimerPage />} />
                  <Route path="34-hours-timer" element={<TimerPage />} />
                  <Route path="35-hours-timer" element={<TimerPage />} />
                  <Route path="36-hours-timer" element={<TimerPage />} />
                  <Route path="37-hours-timer" element={<TimerPage />} />
                  <Route path="38-hours-timer" element={<TimerPage />} />
                  <Route path="39-hours-timer" element={<TimerPage />} />
                  <Route path="40-hours-timer" element={<TimerPage />} />
                  <Route path="41-hours-timer" element={<TimerPage />} />
                  <Route path="42-hours-timer" element={<TimerPage />} />
                  <Route path="43-hours-timer" element={<TimerPage />} />
                  <Route path="44-hours-timer" element={<TimerPage />} />
                  <Route path="45-hours-timer" element={<TimerPage />} />
                  <Route path="46-hours-timer" element={<TimerPage />} />
                  <Route path="47-hours-timer" element={<TimerPage />} />
                  <Route path="48-hours-timer" element={<TimerPage />} />
                  <Route path="49-hours-timer" element={<TimerPage />} />
                  <Route path="50-hours-timer" element={<TimerPage />} />
                  <Route path="51-hours-timer" element={<TimerPage />} />
                  <Route path="52-hours-timer" element={<TimerPage />} />
                  <Route path="53-hours-timer" element={<TimerPage />} />
                  <Route path="54-hours-timer" element={<TimerPage />} />
                  <Route path="55-hours-timer" element={<TimerPage />} />
                  <Route path="56-hours-timer" element={<TimerPage />} />
                  <Route path="57-hours-timer" element={<TimerPage />} />
                  <Route path="58-hours-timer" element={<TimerPage />} />
                  <Route path="59-hours-timer" element={<TimerPage />} />
                  <Route path="60-hours-timer" element={<TimerPage />} />
                  <Route path="61-hours-timer" element={<TimerPage />} />
                  <Route path="62-hours-timer" element={<TimerPage />} />
                  <Route path="63-hours-timer" element={<TimerPage />} />
                  <Route path="64-hours-timer" element={<TimerPage />} />
                  <Route path="65-hours-timer" element={<TimerPage />} />
                  <Route path="66-hours-timer" element={<TimerPage />} />
                  <Route path="67-hours-timer" element={<TimerPage />} />
                  <Route path="68-hours-timer" element={<TimerPage />} />
                  <Route path="69-hours-timer" element={<TimerPage />} />
                  <Route path="70-hours-timer" element={<TimerPage />} />
                  <Route path="71-hours-timer" element={<TimerPage />} />
                  <Route path="72-hours-timer" element={<TimerPage />} />
                  <Route path="73-hours-timer" element={<TimerPage />} />
                  <Route path="74-hours-timer" element={<TimerPage />} />
                  <Route path="75-hours-timer" element={<TimerPage />} />
                  <Route path="76-hours-timer" element={<TimerPage />} />
                  <Route path="77-hours-timer" element={<TimerPage />} />
                  <Route path="78-hours-timer" element={<TimerPage />} />
                  <Route path="79-hours-timer" element={<TimerPage />} />
                  <Route path="80-hours-timer" element={<TimerPage />} />
                  <Route path="81-hours-timer" element={<TimerPage />} />
                  <Route path="82-hours-timer" element={<TimerPage />} />
                  <Route path="83-hours-timer" element={<TimerPage />} />
                  <Route path="84-hours-timer" element={<TimerPage />} />
                  <Route path="85-hours-timer" element={<TimerPage />} />
                  <Route path="86-hours-timer" element={<TimerPage />} />
                  <Route path="87-hours-timer" element={<TimerPage />} />
                  <Route path="88-hours-timer" element={<TimerPage />} />
                  <Route path="89-hours-timer" element={<TimerPage />} />
                  <Route path="90-hours-timer" element={<TimerPage />} />
                  <Route path="91-hours-timer" element={<TimerPage />} />
                  <Route path="92-hours-timer" element={<TimerPage />} />
                  <Route path="93-hours-timer" element={<TimerPage />} />
                  <Route path="94-hours-timer" element={<TimerPage />} />
                  <Route path="95-hours-timer" element={<TimerPage />} />
                  <Route path="96-hours-timer" element={<TimerPage />} />
                  <Route path="97-hours-timer" element={<TimerPage />} />
                  <Route path="98-hours-timer" element={<TimerPage />} />
                  <Route path="99-hours-timer" element={<TimerPage />} />
                  <Route path="100-hours-timer" element={<TimerPage />} />
                  <Route path="101-hours-timer" element={<TimerPage />} />
                  <Route path="102-hours-timer" element={<TimerPage />} />
                  <Route path="103-hours-timer" element={<TimerPage />} />
                  <Route path="104-hours-timer" element={<TimerPage />} />
                  <Route path="105-hours-timer" element={<TimerPage />} />
                  <Route path="106-hours-timer" element={<TimerPage />} />
                  <Route path="107-hours-timer" element={<TimerPage />} />
                  <Route path="108-hours-timer" element={<TimerPage />} />
                  <Route path="109-hours-timer" element={<TimerPage />} />
                  <Route path="110-hours-timer" element={<TimerPage />} />
                  <Route path="111-hours-timer" element={<TimerPage />} />
                  <Route path="112-hours-timer" element={<TimerPage />} />
                  <Route path="113-hours-timer" element={<TimerPage />} />
                  <Route path="114-hours-timer" element={<TimerPage />} />
                  <Route path="115-hours-timer" element={<TimerPage />} />
                  <Route path="116-hours-timer" element={<TimerPage />} />
                  <Route path="117-hours-timer" element={<TimerPage />} />
                  <Route path="118-hours-timer" element={<TimerPage />} />
                  <Route path="119-hours-timer" element={<TimerPage />} />
                  <Route path="120-hours-timer" element={<TimerPage />} />
                  <Route path="timer-1-second" element={<TimerPage />} />
                  <Route path="timer-2-seconds" element={<TimerPage />} />
                  <Route path="timer-2-second" element={<TimerPage />} />
                  <Route path="timer-3-seconds" element={<TimerPage />} />
                  <Route path="timer-4-seconds" element={<TimerPage />} />
                  <Route path="timer-5-seconds" element={<TimerPage />} />
                  <Route path="timer-6-seconds" element={<TimerPage />} />
                  <Route path="timer-7-seconds" element={<TimerPage />} />
                  <Route path="timer-8-seconds" element={<TimerPage />} />
                  <Route path="timer-9-seconds" element={<TimerPage />} />
                  <Route path="timer-10-seconds" element={<TimerPage />} />
                  <Route path="timer-11-seconds" element={<TimerPage />} />
                  <Route path="timer-12-seconds" element={<TimerPage />} />
                  <Route path="timer-13-seconds" element={<TimerPage />} />
                  <Route path="timer-14-seconds" element={<TimerPage />} />
                  <Route path="timer-15-seconds" element={<TimerPage />} />
                  <Route path="timer-16-seconds" element={<TimerPage />} />
                  <Route path="timer-17-seconds" element={<TimerPage />} />
                  <Route path="timer-18-seconds" element={<TimerPage />} />
                  <Route path="timer-19-seconds" element={<TimerPage />} />
                  <Route path="timer-20-seconds" element={<TimerPage />} />
                  <Route path="timer-21-seconds" element={<TimerPage />} />
                  <Route path="timer-22-seconds" element={<TimerPage />} />
                  <Route path="timer-23-seconds" element={<TimerPage />} />
                  <Route path="timer-24-seconds" element={<TimerPage />} />
                  <Route path="timer-25-seconds" element={<TimerPage />} />
                  <Route path="timer-26-seconds" element={<TimerPage />} />
                  <Route path="timer-27-seconds" element={<TimerPage />} />
                  <Route path="timer-28-seconds" element={<TimerPage />} />
                  <Route path="timer-29-seconds" element={<TimerPage />} />
                  <Route path="timer-30-seconds" element={<TimerPage />} />
                  <Route path="timer-31-seconds" element={<TimerPage />} />
                  <Route path="timer-32-seconds" element={<TimerPage />} />
                  <Route path="timer-33-seconds" element={<TimerPage />} />
                  <Route path="timer-34-seconds" element={<TimerPage />} />
                  <Route path="timer-35-seconds" element={<TimerPage />} />
                  <Route path="timer-36-seconds" element={<TimerPage />} />
                  <Route path="timer-37-seconds" element={<TimerPage />} />
                  <Route path="timer-38-seconds" element={<TimerPage />} />
                  <Route path="timer-39-seconds" element={<TimerPage />} />
                  <Route path="timer-40-seconds" element={<TimerPage />} />
                  <Route path="timer-41-seconds" element={<TimerPage />} />
                  <Route path="timer-42-seconds" element={<TimerPage />} />
                  <Route path="timer-43-seconds" element={<TimerPage />} />
                  <Route path="timer-44-seconds" element={<TimerPage />} />
                  <Route path="timer-45-seconds" element={<TimerPage />} />
                  <Route path="timer-46-seconds" element={<TimerPage />} />
                  <Route path="timer-47-seconds" element={<TimerPage />} />
                  <Route path="timer-48-seconds" element={<TimerPage />} />
                  <Route path="timer-49-seconds" element={<TimerPage />} />
                  <Route path="timer-50-seconds" element={<TimerPage />} />
                  <Route path="timer-51-seconds" element={<TimerPage />} />
                  <Route path="timer-52-seconds" element={<TimerPage />} />
                  <Route path="timer-53-seconds" element={<TimerPage />} />
                  <Route path="timer-54-seconds" element={<TimerPage />} />
                  <Route path="timer-55-seconds" element={<TimerPage />} />
                  <Route path="timer-56-seconds" element={<TimerPage />} />
                  <Route path="timer-57-seconds" element={<TimerPage />} />
                  <Route path="timer-58-seconds" element={<TimerPage />} />
                  <Route path="timer-59-seconds" element={<TimerPage />} />
                  <Route path="timer-1-minute" element={<TimerPage />} />
                  <Route path="timer-2-minutes" element={<TimerPage />} />
                  <Route path="timer-2-minute" element={<TimerPage />} />
                  <Route path="timer-3-minutes" element={<TimerPage />} />
                  <Route path="timer-4-minutes" element={<TimerPage />} />
                  <Route path="timer-5-minutes" element={<TimerPage />} />
                  <Route path="timer-6-minutes" element={<TimerPage />} />
                  <Route path="timer-7-minutes" element={<TimerPage />} />
                  <Route path="timer-8-minutes" element={<TimerPage />} />
                  <Route path="timer-9-minutes" element={<TimerPage />} />
                  <Route path="timer-10-minutes" element={<TimerPage />} />
                  <Route path="timer-11-minutes" element={<TimerPage />} />
                  <Route path="timer-12-minutes" element={<TimerPage />} />
                  <Route path="timer-13-minutes" element={<TimerPage />} />
                  <Route path="timer-14-minutes" element={<TimerPage />} />
                  <Route path="timer-15-minutes" element={<TimerPage />} />
                  <Route path="timer-16-minutes" element={<TimerPage />} />
                  <Route path="timer-17-minutes" element={<TimerPage />} />
                  <Route path="timer-18-minutes" element={<TimerPage />} />
                  <Route path="timer-19-minutes" element={<TimerPage />} />
                  <Route path="timer-20-minutes" element={<TimerPage />} />
                  <Route path="timer-21-minutes" element={<TimerPage />} />
                  <Route path="timer-22-minutes" element={<TimerPage />} />
                  <Route path="timer-23-minutes" element={<TimerPage />} />
                  <Route path="timer-24-minutes" element={<TimerPage />} />
                  <Route path="timer-25-minutes" element={<TimerPage />} />
                  <Route path="timer-26-minutes" element={<TimerPage />} />
                  <Route path="timer-27-minutes" element={<TimerPage />} />
                  <Route path="timer-28-minutes" element={<TimerPage />} />
                  <Route path="timer-29-minutes" element={<TimerPage />} />
                  <Route path="timer-30-minutes" element={<TimerPage />} />
                  <Route path="timer-31-minutes" element={<TimerPage />} />
                  <Route path="timer-32-minutes" element={<TimerPage />} />
                  <Route path="timer-33-minutes" element={<TimerPage />} />
                  <Route path="timer-34-minutes" element={<TimerPage />} />
                  <Route path="timer-35-minutes" element={<TimerPage />} />
                  <Route path="timer-36-minutes" element={<TimerPage />} />
                  <Route path="timer-37-minutes" element={<TimerPage />} />
                  <Route path="timer-38-minutes" element={<TimerPage />} />
                  <Route path="timer-39-minutes" element={<TimerPage />} />
                  <Route path="timer-40-minutes" element={<TimerPage />} />
                  <Route path="timer-41-minutes" element={<TimerPage />} />
                  <Route path="timer-42-minutes" element={<TimerPage />} />
                  <Route path="timer-43-minutes" element={<TimerPage />} />
                  <Route path="timer-44-minutes" element={<TimerPage />} />
                  <Route path="timer-45-minutes" element={<TimerPage />} />
                  <Route path="timer-46-minutes" element={<TimerPage />} />
                  <Route path="timer-47-minutes" element={<TimerPage />} />
                  <Route path="timer-48-minutes" element={<TimerPage />} />
                  <Route path="timer-49-minutes" element={<TimerPage />} />
                  <Route path="timer-50-minutes" element={<TimerPage />} />
                  <Route path="timer-51-minutes" element={<TimerPage />} />
                  <Route path="timer-52-minutes" element={<TimerPage />} />
                  <Route path="timer-53-minutes" element={<TimerPage />} />
                  <Route path="timer-54-minutes" element={<TimerPage />} />
                  <Route path="timer-55-minutes" element={<TimerPage />} />
                  <Route path="timer-56-minutes" element={<TimerPage />} />
                  <Route path="timer-57-minutes" element={<TimerPage />} />
                  <Route path="timer-58-minutes" element={<TimerPage />} />
                  <Route path="timer-59-minutes" element={<TimerPage />} />
                  <Route path="timer-60-minutes" element={<TimerPage />} />
                  <Route path="timer-61-minutes" element={<TimerPage />} />
                  <Route path="timer-62-minutes" element={<TimerPage />} />
                  <Route path="timer-63-minutes" element={<TimerPage />} />
                  <Route path="timer-64-minutes" element={<TimerPage />} />
                  <Route path="timer-65-minutes" element={<TimerPage />} />
                  <Route path="timer-66-minutes" element={<TimerPage />} />
                  <Route path="timer-67-minutes" element={<TimerPage />} />
                  <Route path="timer-68-minutes" element={<TimerPage />} />
                  <Route path="timer-69-minutes" element={<TimerPage />} />
                  <Route path="timer-70-minutes" element={<TimerPage />} />
                  <Route path="timer-71-minutes" element={<TimerPage />} />
                  <Route path="timer-72-minutes" element={<TimerPage />} />
                  <Route path="timer-73-minutes" element={<TimerPage />} />
                  <Route path="timer-74-minutes" element={<TimerPage />} />
                  <Route path="timer-75-minutes" element={<TimerPage />} />
                  <Route path="timer-76-minutes" element={<TimerPage />} />
                  <Route path="timer-77-minutes" element={<TimerPage />} />
                  <Route path="timer-78-minutes" element={<TimerPage />} />
                  <Route path="timer-79-minutes" element={<TimerPage />} />
                  <Route path="timer-80-minutes" element={<TimerPage />} />
                  <Route path="timer-81-minutes" element={<TimerPage />} />
                  <Route path="timer-82-minutes" element={<TimerPage />} />
                  <Route path="timer-83-minutes" element={<TimerPage />} />
                  <Route path="timer-84-minutes" element={<TimerPage />} />
                  <Route path="timer-85-minutes" element={<TimerPage />} />
                  <Route path="timer-86-minutes" element={<TimerPage />} />
                  <Route path="timer-87-minutes" element={<TimerPage />} />
                  <Route path="timer-88-minutes" element={<TimerPage />} />
                  <Route path="timer-89-minutes" element={<TimerPage />} />
                  <Route path="timer-90-minutes" element={<TimerPage />} />
                  <Route path="timer-91-minutes" element={<TimerPage />} />
                  <Route path="timer-1-hour" element={<TimerPage />} />
                  <Route path="timer-2-hours" element={<TimerPage />} />
                  <Route path="timer-2-hour" element={<TimerPage />} />
                  <Route path="timer-3-hours" element={<TimerPage />} />
                  <Route path="timer-4-hours" element={<TimerPage />} />
                  <Route path="timer-5-hours" element={<TimerPage />} />
                  <Route path="timer-6-hours" element={<TimerPage />} />
                  <Route path="timer-7-hours" element={<TimerPage />} />
                  <Route path="timer-8-hours" element={<TimerPage />} />
                  <Route path="timer-9-hours" element={<TimerPage />} />
                  <Route path="timer-10-hours" element={<TimerPage />} />
                  <Route path="timer-11-hours" element={<TimerPage />} />
                  <Route path="timer-12-hours" element={<TimerPage />} />
                  <Route path="timer-13-hours" element={<TimerPage />} />
                  <Route path="timer-14-hours" element={<TimerPage />} />
                  <Route path="timer-15-hours" element={<TimerPage />} />
                  <Route path="timer-16-hours" element={<TimerPage />} />
                  <Route path="timer-17-hours" element={<TimerPage />} />
                  <Route path="timer-18-hours" element={<TimerPage />} />
                  <Route path="timer-19-hours" element={<TimerPage />} />
                  <Route path="timer-20-hours" element={<TimerPage />} />
                  <Route path="timer-21-hours" element={<TimerPage />} />
                  <Route path="timer-22-hours" element={<TimerPage />} />
                  <Route path="timer-23-hours" element={<TimerPage />} />
                  <Route path="timer-24-hours" element={<TimerPage />} />
                  <Route path="timer-25-hours" element={<TimerPage />} />
                  <Route path="timer-26-hours" element={<TimerPage />} />
                  <Route path="timer-27-hours" element={<TimerPage />} />
                  <Route path="timer-28-hours" element={<TimerPage />} />
                  <Route path="timer-29-hours" element={<TimerPage />} />
                  <Route path="timer-30-hours" element={<TimerPage />} />
                  <Route path="timer-31-hours" element={<TimerPage />} />
                  <Route path="timer-32-hours" element={<TimerPage />} />
                  <Route path="timer-33-hours" element={<TimerPage />} />
                  <Route path="timer-34-hours" element={<TimerPage />} />
                  <Route path="timer-35-hours" element={<TimerPage />} />
                  <Route path="timer-36-hours" element={<TimerPage />} />
                  <Route path="timer-37-hours" element={<TimerPage />} />
                  <Route path="timer-38-hours" element={<TimerPage />} />
                  <Route path="timer-39-hours" element={<TimerPage />} />
                  <Route path="timer-40-hours" element={<TimerPage />} />
                  <Route path="timer-42-hours" element={<TimerPage />} />
                  <Route path="timer-43-hours" element={<TimerPage />} />
                  <Route path="timer-44-hours" element={<TimerPage />} />
                  <Route path="timer-45-hours" element={<TimerPage />} />
                  <Route path="timer-46-hours" element={<TimerPage />} />
                  <Route path="timer-47-hours" element={<TimerPage />} />
                  <Route path="timer-48-hours" element={<TimerPage />} />
                  <Route path="timer-49-hours" element={<TimerPage />} />
                  <Route path="timer-50-hours" element={<TimerPage />} />
                  <Route path="timer-51-hours" element={<TimerPage />} />
                  <Route path="timer-52-hours" element={<TimerPage />} />
                  <Route path="timer-53-hours" element={<TimerPage />} />
                  <Route path="timer-54-hours" element={<TimerPage />} />
                  <Route path="timer-55-hours" element={<TimerPage />} />
                  <Route path="timer-56-hours" element={<TimerPage />} />
                  <Route path="timer-57-hours" element={<TimerPage />} />
                  <Route path="timer-58-hours" element={<TimerPage />} />
                  <Route path="timer-59-hours" element={<TimerPage />} />
                  <Route path="timer-60-hours" element={<TimerPage />} />
                </Route>
                <Route
                  path="/Countdown/online-countdown"
                  element={<CountdownPage type={"custom"} />}
                />
                <Route
                  path="/Countdown/calendar-countdown"
                  element={<CountdownPage type={"calendar"} />}
                />
                <Route
                  path="/Countdown/countdown-calendar"
                  element={<CountdownPage type={"calendar"} />}
                />
                <Route
                  path="/Countdown/wedding-countdown"
                  element={<CountdownPage type={"wedding"} />}
                />
                <Route
                  path="/Countdown/vacation-countdown"
                  element={<CountdownPage type={"vacation"} />}
                />
                {/* <Route
                path="/Countdown/holidays-countdown"
                element={<CountdownPage type={"holidays"} />}
              /> */}
                <Route
                  path="/Countdown/halloween-countdown"
                  element={
                    <CountdownPage type={"halloween"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/easter-countdown"
                  element={
                    <CountdownPage type={"easter"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/boxing-day-countdown"
                  element={
                    <CountdownPage
                      type={"boxing_day"}
                      toolFamily={"holidays"}
                    />
                  }
                />{" "}
                <Route
                  path="/Countdown/holi-countdown"
                  element={
                    <CountdownPage type={"holi"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/nye-countdown"
                  element={
                    <CountdownPage type={"nye"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/hanukkah-countdown"
                  element={
                    <CountdownPage type={"hanukkah"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/diwali-countdown"
                  element={
                    <CountdownPage type={"diwali"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/day-of-the-dead-countdown"
                  element={
                    <CountdownPage
                      type={"day_of_the_dead"}
                      toolFamily={"holidays"}
                    />
                  }
                />{" "}
                <Route
                  path="/Countdown/thanksgiving-countdown"
                  element={
                    <CountdownPage
                      type={"thanksgiving"}
                      toolFamily={"holidays"}
                    />
                  }
                />{" "}
                <Route
                  path="/Countdown/ascension-day-countdown"
                  element={
                    <CountdownPage
                      type={"ascension_day"}
                      toolFamily={"holidays"}
                    />
                  }
                />{" "}
                <Route
                  path="/Countdown/pentecost-countdown"
                  element={
                    <CountdownPage type={"pentecost"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/samhain-countdown"
                  element={
                    <CountdownPage type={"samhain"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/labor-day-countdown"
                  element={
                    <CountdownPage type={"labor_day"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/ramadan-countdown"
                  element={
                    <CountdownPage type={"ramadan"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/good-friday-countdown"
                  element={
                    <CountdownPage
                      type={"good_friday"}
                      toolFamily={"holidays"}
                    />
                  }
                />{" "}
                <Route
                  path="/Countdown/july-4th-countdown"
                  element={
                    <CountdownPage type={"july_4th"} toolFamily={"holidays"} />
                  }
                />{" "}
                <Route
                  path="/Countdown/mother's-day-countdown"
                  element={
                    <CountdownPage
                      type={"mothers_day"}
                      toolFamily={"holidays"}
                    />
                  }
                />{" "}
                <Route
                  path="/Countdown/valentine's-day-countdown"
                  element={
                    <CountdownPage
                      type={"valentines_day"}
                      toolFamily={"holidays"}
                    />
                  }
                />{" "}
                <Route
                  path="/Countdown/holidays-countdown"
                  element={
                    <CountdownPage type={"holidays"} toolFamily={"holidays"} />
                  }
                />
                <Route
                  path="/Countdown/pregnancy-countdown"
                  element={<CountdownPage type={"pregnancy"} />}
                />
                <Route
                  path="/Countdown/birthday-countdown"
                  element={<CountdownPage type={"birthday"} />}
                />
                u
                <Route
                  path="/Countdown/retirement-countdown"
                  element={<CountdownPage type={"retirement"} />}
                />
                <Route
                  path="/Countdown/trip-countdown"
                  element={<CountdownPage type={"trip"} />}
                />
                <Route
                  path="/Stopwatch"
                  element={<HubPage type="Stopwatch" />}
                />
                <Route
                  path="/Stopwatch/online-stopwatch"
                  element={<StopWatchPage />}
                />
                <Route
                  path="/Dates/on-this-day"
                  element={<DatePage type={"onThisDay"} />}
                />{" "}
                <Route
                  path="/Dates/on-history-today"
                  element={<DatePage type={"onThisDay"} />}
                />
                <Route
                  path="/Dates/random-date"
                  element={<DatePage type={"randomDate"} />}
                />
                <Route path="/Calendars" element={<UnderConstruction />} />
                <Route
                  path="/Calculators/pregnancy-calculator"
                  element={<CalculatorPage type="pregnancy" />}
                />
                {/* <Route
                path="/calculators/pregnancy-calculator2"
                element={<PregnancyCalculator />}
              /> */}
                <Route path="/Weather" element={<UnderConstruction />} />
                {/* <Route path="/weather2" element={<Weather />} /> */}
                <Route path="/Contact-us" element={<UnderConstruction />} />
                <Route path="/Funzies" element={<UnderConstruction />} />
                <Route
                  path="/calculators"
                  element={<HubPage type="Calculators" />}
                />
                <Route
                  path="/countdown"
                  element={<HubPage type="Countdowns" />}
                />
                <Route path="/calendars" element={<UnderConstruction />} />
                <Route path="/dates" element={<HubPage type="Dates" />} />
                <Route path="/funzies" element={<UnderConstruction />} />
                <Route path="/weather" element={<UnderConstruction />} />
                <Route
                  path="/stopwatches"
                  element={<HubPage type="Stopwatches" />}
                />
                <Route
                  path="/featured/calculators"
                  element={<FeaturedMenu type={"Calculators"} />}
                />
                <Route
                  path="/featured/countdowns"
                  element={<FeaturedMenu type={"Countdowns"} />}
                />
                <Route
                  path="/featured/calendars"
                  element={<UnderConstruction />}
                />
                <Route
                  path="/featured/dates"
                  element={<FeaturedMenu type={"Dates"} />}
                />
                <Route
                  path="/featured/funzies"
                  element={<UnderConstruction />}
                />
                <Route
                  path="/featured/timers"
                  element={<FeaturedMenu type={"Timers"} />}
                />
                <Route
                  path="/featured/weather"
                  element={<UnderConstruction />}
                />
                <Route
                  path="/featured/stopwatches"
                  element={<FeaturedMenu type={"Stopwatch"} />}
                />
                <Route
                  path="/featured/pregnancy"
                  element={<FeaturedMenu type={"Pregnancy"} />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Layout>
          </ModalContextProvider>
        </SideMenusProvider>
      </Router>
    </div>
  );
}

export default App;
