export const DiwaliIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      aria-hidden="true"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_2032)">
        <path
          d="M24.0001 17.2002C24.0001 17.2002 19.2001 23.9002 3.0001 24.5002C1.8001 24.5002 0.700102 25.5002 0.700102 26.6002C0.400102 29.3002 1.1001 34.5002 7.3001 41.0002C7.3001 41.0002 14.4001 47.6002 23.4001 47.5002C23.4001 47.5002 33.3001 48.5002 42.5001 38.9002C42.5001 38.9002 47.8001 32.4002 47.4001 27.0002C47.3001 25.4002 45.9001 24.2002 44.3001 24.3002C40.0001 24.4002 30.3001 23.7002 24.0001 17.2002Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.800293 28.6C0.800293 28.6 13.3003 31.9 24.0003 22.5C24.0003 22.5 33.2003 31.7 47.4003 28.7"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 11.6001V17.2001"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.8 0.600098C22.8 0.600098 24.0001 4.3001 20.7001 6.6001C18.3001 8.3001 17.3 14.7001 24 14.8001C24 14.8001 28.4 14.5001 28.4 9.9001C28.4 8.3001 27.8 6.8001 26.9 5.5001C26.1 4.3001 24.8 2.6001 22.8 0.600098Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 27.3003V28.4003"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.2998 29.5005H26.4998"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.5 29.5005H22.7"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.2002 30.1001V31.2001"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.3999 30.3003V31.4003"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.7998 32.5005H19.8998"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 32.5005H16.1"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.3999 33.2002V34.3002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.6001 31.2002V32.3002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.6001 34.6001V35.7001"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 33.5H12.1"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.2002 33.5H8.3002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.9004 33.2002V34.3002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.9004 30.1001V31.2001"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.2002 32.3003H33.3002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.4004 32.3003H29.6004"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.4004 34V35.1"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.4004 31.2002V32.3002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.7002 33.5H40.8002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.9004 33.5H37.1004"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_2032">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
