export const RandomDateIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M39 7.5H9C8.17157 7.5 7.5 8.17157 7.5 9V39C7.5 39.8284 8.17157 40.5 9 40.5H39C39.8284 40.5 40.5 39.8284 40.5 39V9C40.5 8.17157 39.8284 7.5 39 7.5Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33 4.5V10.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 4.5V10.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 14.5H40.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.96 22.17C25 22.17 25.835 22.455 26.465 23.025C27.105 23.585 27.425 24.36 27.425 25.35C27.425 26.33 27.11 27.08 26.48 27.6C25.85 28.11 25.015 28.365 23.975 28.365L23.915 29.52H22.07L21.995 26.97H22.67C23.54 26.97 24.21 26.855 24.68 26.625C25.15 26.395 25.385 25.975 25.385 25.365C25.385 24.925 25.255 24.575 24.995 24.315C24.745 24.055 24.4 23.925 23.96 23.925C23.5 23.925 23.14 24.05 22.88 24.3C22.63 24.55 22.505 24.895 22.505 25.335H20.525C20.515 24.725 20.645 24.18 20.915 23.7C21.185 23.22 21.58 22.845 22.1 22.575C22.63 22.305 23.25 22.17 23.96 22.17ZM22.985 33.105C22.605 33.105 22.29 32.99 22.04 32.76C21.8 32.52 21.68 32.225 21.68 31.875C21.68 31.525 21.8 31.235 22.04 31.005C22.29 30.765 22.605 30.645 22.985 30.645C23.355 30.645 23.66 30.765 23.9 31.005C24.14 31.235 24.26 31.525 24.26 31.875C24.26 32.225 24.14 32.52 23.9 32.76C23.66 32.99 23.355 33.105 22.985 33.105Z"
        fill={color}
      />
    </svg>
  );
};
