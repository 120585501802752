import { TimerBox } from "./TimerBox";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Icon } from "../../ui/IconWrapper/Icon";
import { ChevronDown } from "../../../shared/assets/icons/ToolsOasisIcons";


let minSecOptions = [];
for (let i = 0; i < 60; i++) {
  minSecOptions.push(i);
}

let hoursOptions = [];
for (let i = 0; i < 1000; i++) {
  hoursOptions.push(i);
}

export const TimerMain = ({
  minutes = 0,
  hours = 0,
  seconds = 0,
  isStart = false,
  isPaused = false,
  isExpired = false,
  isRestart = false,
  milisecondsToAdd = 0,
  data = null,
  setSeconds = () => { },
  setMinutes = () => { },
  setHours = () => { },
  setIsExpired = () => { },
  setIsStart = () => { },
  setIsRestart = () => { },
}) => {
  const [isInputDisabled, setIsInputDisabled] = useState(false);



  const handleOptionChange = (e, type) => {
    switch (type) {
      case "HH":
        setHours(+e.target.value);
        break;
      case "MM":
        setMinutes(+e.target.value);
        break;
      case "SS":
        setSeconds(+e.target.value);
        break;
      default:
        break;
    }
  };

  const handleTimeSelection = (e) => {
    switch (e.target.name) {
      case "hours":
        setHours(e.target.value);
        break;
      case "minutes":
        setMinutes(e.target.value > 59 ? 59 : e.target.value);
        break;
      case "seconds":
        setSeconds(e.target.value > 59 ? 59 : e.target.value);
        break;
      default:
        return;
    }
  };

  return (
    <>
      <CountdownInputs>
        <DateInput>
          <select
            onChange={(e) => handleOptionChange(e, "HH")}
            style={{ opacity: 0, position: "absolute" }}
          >
            {hoursOptions.map((el) => (
              <option value={el}>{el}</option>
            ))}
          </select>

          <input
            type="text"
            value={hours < 10 ? "0" + hours : hours}
            placeholder={"HH"}
            className="__date-time-text"
            disabled={true}
            style={{ width: "calc(100% - 5rem)" }}
          />
          <SelectCustomArrow style={{ position: "absolute", zIndex: -1 }}>
            <Icon
              icon={ChevronDown}
              color="white"
              size="4rem"
              style={{ marginLeft: "1.2rem", marginTop: "1.2rem" }}
              strokeWidth={".3rem"}
            />
          </SelectCustomArrow>
        </DateInput>
        <DateInput>
          <select
            onChange={(e) => handleOptionChange(e, "MM")}
            style={{ opacity: 0, position: "absolute" }}
          >
            {minSecOptions.map((el) => (
              <option value={el}>{el}</option>
            ))}
          </select>

          <input
            type="text"
            value={minutes < 10 ? "0" + minutes : minutes}
            placeholder={"MM"}
            className="__date-time-text"
            disabled={true}
            style={{ width: "calc(100% - 5rem)" }}
          />
          <SelectCustomArrow style={{ position: "absolute", zIndex: -1 }}>
            <Icon
              icon={ChevronDown}
              color="white"
              size="4rem"
              style={{ marginLeft: "1.2rem", marginTop: "1.2rem" }}
              strokeWidth={".3rem"}
            />
          </SelectCustomArrow>
        </DateInput>
        <DateInput>
          <select 
            onChange={(e) => handleOptionChange(e, "SS")}
            style={{ opacity: 0, position: "absolute" }}
          >
            {minSecOptions.map((el) => (
              <option value={el}>{el}</option>
            ))}
          </select>

          <input
            type="text"
            value={seconds < 10 ? "0" + seconds : seconds}
            placeholder={"SS"}
            className="__date-time-text"
            disabled={true}
            style={{ width: "calc(100% - 5rem)" }}
          />
          <SelectCustomArrow style={{ position: "absolute", zIndex: -1 }}>
            <Icon
              icon={ChevronDown}
              color="white"
              size="4rem"
              style={{ marginLeft: "1.2rem", marginTop: "1.2rem" }}
              strokeWidth={".3rem"}
            />
          </SelectCustomArrow>
        </DateInput>
      </CountdownInputs>

      {data && <TimerBox
        {...data}
        isExpired={isExpired}
        coloredColor={"rgba(60, 144, 189, 1)"}
      />}
      <Outlet />
    </>
  );
};

const SelectCustomArrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(60, 144, 189);
  height: 5rem;
  width: 5rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateInput = styled.div`
  display: flex;
  max-width: 68rem;
  position: relative;
  & input {
    width: 100%;
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    height: 5rem;
    outline: none;
    padding: 2rem;
    border: ${(props) => (props.isFutureDate ? "0.2rem solid red" : "none")};
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    text-align:center;
    font-size: 2rem !important;
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
  }

  & .__date-time-input {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
`;

const CountdownInputs = styled.div`
  padding: 2rem;
  gap: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  select {
    width: 100%;
    cursor: pointer;
    border-radius: 0.8rem;
    padding: 0 3rem;
    height: 5rem;
    outline: none;
    border: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
    font-weight: 500;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    cursor: pointer;
  }

  option {
    background: #000;
    font-size: 1.4rem;
    border: 0px;
    outline: 0px;
    cursor: pointer;
  }
`;
