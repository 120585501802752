import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "../../../shared/assets/icons/ToolsOasisIcons";
import { Icon } from "../IconWrapper/Icon";

export const BreadcrumbsItem = ({
  breadcurmb,
  showSeperator,
  emphasisColor,
  onClick,
}) => {
  const navigate = useNavigate();
  const handleNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <>
      <BreadcrumbsItemStyled
        onClick={onClick}
        showSeperator={showSeperator}
        emphasisColor={emphasisColor}
      >
        <TitleContainer>
          <Icon icon={breadcurmb.icon} size={"3rem"} color={"white"} />
          {showSeperator ? (
            <a
              href={breadcurmb.path}
              onClick={(e) => handleNavigation(e, breadcurmb.path)}
            >
              {breadcurmb.title}
            </a>
          ) : (
            <span> {breadcurmb.title}</span>
          )}
        </TitleContainer>
      </BreadcrumbsItemStyled>
      {showSeperator && (
        <ChevronRight
          color={emphasisColor || "white"}
          strokeWidth={3}
          size={"2.5rem"}
        />
      )}
    </>
  );
};

const BreadcrumbsItemStyled = styled.div`
  color: white;
  /* text-decoration: ${(props) => !props.showSeperator && "underline"};
  text-decoration-color: ${(props) => props.emphasisColor};
  text-underline-offset: .5rem; */
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.emphasisColor};
    text-underline-offset: 0.5rem;
  }
  a,
  span {
    font-size: 1.7rem;
    text-transform: capitalize;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
