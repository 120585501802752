export const ChevronDown = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
  strokeWidth,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M26 12L16 22L6 12"
        stroke={color}
        stroke-width={strokeWidth || "2"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
