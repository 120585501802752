import { useState, useContext } from "react";
import { CardsMenu } from "../components/ui/CardsMenu/CardsMenu";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker ";
import styled from "styled-components";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import { HomePageMenu } from "../components/HomePage/HomePageMenu/HomePageMenu";
import {
  StopwatchIcon,
  TimerIcon,
  CountdownIcon,
  DatesIcon,
  CalendarsIcon,
  FunziesIcon,
  WeatherIcon,
  CalculatorsIcon,
  PregnancyIcon,
} from "../shared/assets/icons/ToolsOasisIcons/index";
import { SideMenusContext } from "../contexts/side-menus-context";
const MOCK_DATA = [
  {
    title: "Timers",
    description:
      "Use our online timer to set a specific duration for your tasks or activities, and hear the beep when it's over.",
    img: null,
    imgAlt: null,
    icon: TimerIcon,
    path: "featured/timers",
    see_all_path: "timers",
    category: "Timers",
    subCategories: {
      Custom: {
        title: "Custom",
        description:
          "Tailor the timer to the amount of hours, minutes and seconds of your choosing. You know... like a timer.",
      },
      Seconds: {
        title: "Seconds",
        description: "All our timers from 1 to 120 seconds.",
      },
      Minutes: {
        title: "Minutes",
        description: "All our timers from 1 to 120 minutes.",
      },
      Hours: {
        title: "Hours",
        description: "All our timers from 1 to 120 hours.",
      },
    },
    bgcolor: "rgba(60, 144, 189)",
    color: "white",
  },

  {
    title: "Countdown",
    description: "Countdowns for many holidays and important life events.",
    img: null,
    imgAlt: null,
    icon: CountdownIcon,
    path: "featured/countdowns",
    see_all_path: "countdowns",
    category: "Countdowns",
    subCategories: {
      Custom: {
        title: "Custom",
        description: "Countdown to a time and date of your choosing.",
      },
      Holidays: {
        title: "Holidays",
        description:
          "Use our countdowns for specific holidays and learn a little more about them.",
      },
      "My Life": {
        title: "My Life",
        description:
          "Custom countdowns for you to tailor to your to specific life events like a vaction or retirement.",
      },
    },
    bgcolor: "rgba(52, 191, 149)",
    color: "white",
  },
  {
    title: "Stopwatch",
    description:
      "Measure your running laps, your time to solve a Rubik's cube, or how long you can hold your breath.",
    img: null,
    imgAlt: null,
    icon: StopwatchIcon,
    path: "featured/stopwatches",
    see_all_path: "stopwatches",
    category: "Stopwatch",
    subCategories: {
      Stopwatch: {
        title: "Stopwatch",
        description:
          "Count up accurately and measure laps for whatever you need.",
      },
    },
    bgcolor: "rgba(180, 146, 252)",
    color: "white",
  },
  {
    title: "Dates",
    description:
      "Receive a random date and time, and learn what events happened on that day in history, or on today’s date.",
    img: null,
    imgAlt: null,
    icon: DatesIcon,
    path: "featured/dates",
    see_all_path: "dates",
    category: "Dates",
    subCategories: {
      Random: {
        title: "Random",
        description:
          "Get a random date and time (or several of them) in a window of time and date format of your choosing.",
      },
      Predefined: {
        title: "Predefined to",
        description:
          "Find what important events in history happend on this day.",
      },
    },
    bgcolor: "rgba(205, 97, 85)",
    color: "white",
  },
  {
    title: "Calendars",
    description: "Soon...",
    img: null,
    imgAlt: null,
    icon: CalendarsIcon,
    path: "featured/calendars",
    see_all_path: "calendars",
    category: "Calendars",
    subCategories: {},
    bgcolor: "rgba(19, 141, 117 )",
    color: "white",
  },
  {
    title: "Funzies",
    description: "Soon...",
    img: null,
    imgAlt: null,
    icon: FunziesIcon,
    path: "featured/funzies",
    see_all_path: "funzies",
    category: "FunziesIcon",
    bgcolor: "rgba(170, 28, 100 )",
    color: "white",
  },
  {
    title: "Weather",
    description: "Soon...",
    img: null,
    imgAlt: null,
    icon: WeatherIcon,
    path: "featured/weather",
    see_all_path: "weather",
    category: "Weather",
    bgcolor: "rgba(65, 105, 225, 1)",
    color: "white",
  },
  {
    title: "Calculators",
    description: "Handy little calculators.",
    img: null,
    imgAlt: null,
    icon: CalculatorsIcon,
    path: "featured/calculators",
    see_all_path: "calculators",
    category: "Calculators",
    subCategories: {
      Calculator: {
        title: "Due date calculater",
        description:
          "Calculate your due date based on your last period or conception date.",
      },
    },
    bgcolor: "rgb(118, 73, 191)",
    color: "white",
  },
  {
    title: "Pregnancy",
    description: "Useful things for your pregnancy period.",
    img: null,
    imgAlt: null,
    icon: PregnancyIcon,
    subIcons: [CountdownIcon, CalculatorsIcon],
    subTools: [],
    path: "featured/pregnancy",
    see_all_path: "pregnancy",
    category: "Pregnancy",
    subCategories: {
      Calculator: {
        title: "Calculator",
        description:
          "Calculate your due date based on your last period or conception date.",
      },
      Countdown: {
        title: "Countdown",
        description: "A countdown to the approximate day of your baby's birth.",
      },
    },
    bgcolor: ["#34BF95", "#7649BF"],
    backgroundImage: "linear-gradient(to right top,#34BF95,#7649BF)",
    color: "white",
  },
];

const HomePage = () => {
  document.title = "toolsoasis | Working To Have Everything You Need";
  const el = document.querySelector("meta[name='description']");
  el.setAttribute(
    "content",
    "A suite of online tools, always growing, always improving. Check us out and let us know if there's something more that we can do for you."
  );
  const gaEventTracker = useAnalyticsEventTracker(document.title);
  const { setSearchValue, searchValue } = useContext(SideMenusContext);

  const handleSearch = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  return (
    <HomePageStyled>
      <Summary>
        <p>
          <h1
            style={{
              fontSize: "1.6rem",
              fontStyle: "italic",
              display: "inline",
            }}
          >
            tools<span className="__oasis">oasis</span>
          </h1>{" "}
          is a user-friendly, ever growing, free toolkit that provides dozens of
          tools for various needs.
        </p>
        <p>
          Couldn't find the tool you were looking for?
          <a
            href="mailto:toolsoasis@gmail.com?subject=Request%20New%20Tool"
            style={{ paddingLeft: ".5rem" }}
          >
            Contact us{" "}
          </a>
          {/* <a href="#" onClick={navigate("/ContactUs")}> */} and we'll put it
          on our to-do list!
        </p>
      </Summary>
      <div>
        <input
          style={{
            minWidth: "20vw",
            maxWidth: "60rem",
            width: "90vw",
            borderRadius: ".8rem",
            border: "none",
            background: "",
          }}
          type="search"
          placeholder="Search a tool..."
          onChange={handleSearch}
        />
      </div>
      {/* <CardsMenu cardsData={toolOptions} /> */}
      <HomePageMenu feturedItems={MOCK_DATA} searchValue={searchValue} />
    </HomePageStyled>
  );
};

const HomePageStyled = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;

  & input {
    max-width: 60rem;
  }

  & .__oasis {
    color: #38d6d6;
    font-size: inherit;
    font-weight: inherit;
  }
`;

const Summary = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 2rem;
  text-align: center;
  & p {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

export default HomePage;
