import styled from "styled-components";
import {
  CalendarsIcon,
  ChevronDown,
} from "../../../../shared/assets/icons/ToolsOasisIcons";
import { Icon } from "../../../ui/IconWrapper/Icon";
import { useState, useEffect } from "react";
import { RandomDateResults } from "./RandomDatesResults";
import { DATE_FORMAT, DAYS_OPTIONS } from "./data";

let options = [];
for (let i = 1; i < 26; i++) {
  options.push(i);
}
//https://en.wikipedia.org/wiki/ISO_8601

export const RandomDateMain = ({ calculate, setCalculate, results, setResults }) => {
  const [dateRange, setDateRange] = useState("");
  const [selectedWeekDays, setSelectedWeekDays] = useState(
    DAYS_OPTIONS.filter((el) => el.isSelected)
  );
  const [numberOfResults, setNumberOfResults] = useState(1);
  const [daysOptions, setDaysOptions] = useState(DAYS_OPTIONS);
  const [dateFormat, setDateFormat] = useState(DATE_FORMAT[1]);
  const [errorMsg, setErrorMsg] = useState([]);

  const handleGenerate = () => {
    const results = [];
    const originalResults = [];
    const start = new Date(dateRange.dateFrom).getTime();
    const end = new Date(dateRange.dateTo).getTime();
    if (
      end < start ||
      !(
        start &&
        end &&
        dateFormat &&
        numberOfResults &&
        selectedWeekDays.length > 0
      )
    ) {
      if (!end) setErrorMsg((prev) => [...prev, "End date is required"]);
      if (!start) setErrorMsg((prev) => [...prev, "Start date is required"]);
      if (selectedWeekDays.length === 0)
        setErrorMsg((prev) => [...prev, "At least 1 weekday is required"]);
      return;
    }
    let i = 0;
    let limit = 5000;
    while (results.length < numberOfResults) {
      if (i > limit) break;
      i++;
      const randomDate = Math.random() * (end - start) + start;
      let formattedDate = new Date(randomDate);
      let originalDate = new Date(randomDate);
      if (!selectedWeekDays.map((el) => el.id).includes(formattedDate.getDay()))
        continue;
      if (dateFormat.format.formatFunc) {
        formattedDate = dateFormat.format.formatFunc(
          formattedDate,
          dateFormat?.format?.type,
          dateFormat?.format?.options
        );
      }

      if (!results.includes(formattedDate)) {
        results.push(formattedDate);
        originalResults.push({
          date: formattedDate,
          originalDate,
          dateMS: originalDate.getTime(),
        });
      }
    }
    // console.log(results);
    setResults(originalResults);
  };

  const handleDateFormat = (e) => {
    setDateFormat(DATE_FORMAT[e.target.value]);
  };

  const handleDateChange = (e) => {
    setDateRange((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleNumberOfResults = (e) => {
    setNumberOfResults(e.target.value);
  };

  const handleDaySelection = (el) => {
    let tmp = [...daysOptions];
    tmp.find((day) => el.id === day.id).isSelected = !el.isSelected;
    setDaysOptions(tmp);
  };

  const handleReset = () => {
    setDaysOptions(DAYS_OPTIONS);
    setDateRange("");
    setNumberOfResults(1);
    setDateFormat(DATE_FORMAT[1]);
    setResults(null);
  };

  useEffect(() => {
    if (dateRange.dateFrom) {
      setErrorMsg((prev) =>
        prev.filter((el) => el !== "Start date is required")
      );
    }
    if (dateRange.dateTo) {
      setErrorMsg((prev) => prev.filter((el) => el !== "End date is required"));
    }
    if (dateRange.dateTo < dateRange.dateFrom) {
      setErrorMsg((prev) => [...prev, "Start date cannot exceed end date"]);
    } else {
      setErrorMsg((prev) =>
        prev.filter((el) => el !== "Start date cannot exceed end date")
      );
    }
  }, [dateRange]);

  useEffect(() => {
    setSelectedWeekDays(daysOptions.filter((el) => el.isSelected));
  }, [daysOptions]);

  useEffect(() => {
    if (selectedWeekDays.length > 0)
      setErrorMsg((prev) =>
        prev.filter((el) => el !== "At least 1 weekday is required")
      );
  }, [selectedWeekDays]);

  useEffect(() => {
    if (calculate && !results) {
      handleGenerate();
    } else if ((calculate && results)) {
      handleReset();
    }
  }, [calculate])

  useEffect(() => {
    setCalculate(false)
  }, [errorMsg])

  return (
    <>
      {!results && (
        <div>
          {errorMsg.length > 0 && (
            <div
              style={{
                color: "white",
                width: "100%",
                fontSize: "1.4rem",
                padding: "0.5rem",
                background: "rgb(194,20, 60, 1)",
              }}
            >
              {"Error: " + errorMsg[0] + "!"}
            </div>
          )}
          <DatesInputs>
            <DateInput
              onClick={() => document.getElementById("dateFrom").showPicker()}
            >
              <input
                type="text"
                value={(dateRange && dateRange.dateFrom) || ""}
                placeholder="Random Date From"
                className="__date-time-text"
                disabled={true}
              />
              <input
                id="dateFrom"
                className="__date-time-input"
                style={{ background: "rgb(205, 97, 85)" }}
                type={"date"}
                name="dateFrom"
                onChange={handleDateChange}
              />
              <Icon
                icon={CalendarsIcon}
                color="white"
                size="4rem"
                style={{
                  position: "absolute",
                  right: ".5rem",
                  top: ".5rem",
                  zIndex: 1,
                }}
              />
            </DateInput>
            <DateInput
              onClick={() => document.getElementById("dateTo").showPicker()}
            >
              <input
                type="text"
                value={(dateRange && dateRange.dateTo) || ""}
                placeholder="Random Date To"
                className="__date-time-text"
                disabled={true}
              />
              <input
                id="dateTo"
                name="dateTo"
                className="__date-time-input"
                style={{ background: "rgb(205, 97, 85)" }}
                type={"date"}
                onChange={handleDateChange}
              />
              <Icon
                icon={CalendarsIcon}
                color="white"
                size="4rem"
                style={{
                  position: "absolute",
                  right: ".5rem",
                  top: ".5rem",
                  zIndex: 1,
                }}
              />
            </DateInput>
            <DateInput className="sbs-input">
              <select
                name="format"
                onChange={handleDateFormat}
                style={{ opacity: 0, position: "absolute" }}
                value={dateFormat?.str}
              >
                {Object.entries(DATE_FORMAT).map((el) => (
                  <option key={el[0]} value={el[0]}>
                    {el[1].str}
                  </option>
                ))}
              </select>

              <input
                type="text"
                value={dateFormat.str}
                placeholder={"Date Format"}
                className="__date-time-text"
                disabled={true}
                style={{ width: "calc(100% - 5rem)" }}
              />
              <SelectCustomArrow style={{ position: "absolute", zIndex: -1 }}>
                <Icon
                  icon={ChevronDown}
                  color="white"
                  size="4rem"
                  style={{ marginLeft: "1.2rem", marginTop: "1.2rem" }}
                  strokeWidth={".3rem"}
                />
              </SelectCustomArrow>
            </DateInput>
            {/* <DatesInputsFlex> */}
            <DateInput className="sbs-input">
              <select
                name="numberOfResults"
                onChange={handleNumberOfResults}
                value={numberOfResults}
                style={{ opacity: 0, position: "absolute" }}
              >
                {options.map((el) => {
                  return (
                    <option key={el} value={el}>
                      {el + `${el === 1 ? " Random date" : " Random dates"}`}
                    </option>
                  );
                })}
              </select>

              <input
                type="text"
                value={
                  numberOfResults +
                  `${numberOfResults === 1 ? " Random date" : " Random dates"}`
                }
                placeholder={"Number of random dates"}
                className="__date-time-text"
                disabled={true}
                style={{ width: "calc(100% - 5rem)" }}
              />
              <SelectCustomArrow style={{ position: "absolute", zIndex: -1 }}>
                <Icon
                  icon={ChevronDown}
                  color="white"
                  size="4rem"
                  style={{ marginLeft: "1.2rem", marginTop: "1.2rem" }}
                  strokeWidth={".3rem"}
                />
              </SelectCustomArrow>
            </DateInput>
            {/* </DatesInputsFlex> */}
          </DatesInputs>
          <WeekdaysSelection>
            {daysOptions.map((el) => (
              <div
                key={el.id}
                className={el.isSelected ? "selected" : ""}
                onClick={() => handleDaySelection(el)}
              >
                {el.str}
              </div>
            ))}
          </WeekdaysSelection>
        </div>
      )}
      {results && <RandomDateResults results={results} />}
      {/* <DatesBtns bgColor={"rgb(205, 97, 85)"}>
        <button
          className={"start-btn"}
          onClick={results ? handleReset : handleGenerate}
        >
          {results ? "Reset" : "Generate"}
        </button>
      </DatesBtns> */}
    </>
  );
};

const RandomDateContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const DatesInputs = styled.div`
  width: 90vw;
  max-width: 68rem;
  padding: 2rem;
  gap: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto !important;
  select {
    width: 100%;
    cursor: pointer;
    border-radius: 0.8rem;
    padding: 0 3rem;
    height: 5rem;
    outline: none;
    border: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
    font-weight: 500;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    cursor: pointer;
  }

  option {
    background: #000;
    font-size: 1.4rem;
    border: 0px;
    outline: 0px;
    cursor: pointer;
  }
`;

const DateInput = styled.div`
  display: flex;
  position: relative;
  width: 90vw;
  max-width: 68rem;
  & input {
    width: 100%;
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    height: 5rem;
    outline: none;
    padding: 2rem;
    border: ${(props) => (props.isFutureDate ? "0.2rem solid red" : "none")};
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.4rem;
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
  }

  & .__date-time-input {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
`;

const CalculationMethodSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90vw;
  max-width: 68rem;
  gap: 1rem;
`;

const SelectOption = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  font-size: 1.4rem;
  width: 100%;
  cursor: pointer;
  border-radius: 10rem;
  &.selected {
    background-color: rgb(205, 97, 85);
  }
`;

const SelectCustomArrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(205, 97, 85);
  height: 5rem;
  width: 5rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WeekdaysSelection = styled.div`
  width: 90vw;
  max-width: 68rem;
  padding: 2rem;
  gap: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.8rem;
  color: white;
  flex-wrap: wrap;
  div {
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    border: 0.1rem solid rgb(205, 97, 85);
  }

  div.selected {
    background-color: rgb(205, 97, 85);
  }
`;

const DatesInputsFlex = styled.div`
  width: 90vw;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .sbs-input:first-of-type {
    flex: 2;
  }
  .sbs-input:last-of-type {
    flex: 1;
  }
`;

const DatesBtns = styled.div`
  max-width: 68rem;
  width: 90vw;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 4rem;
  /* position: fixed;
  bottom:8rem; */
  & button {
    width: 100%;
    border-radius: 0.8rem;
    border: none;
    outline: none;
    height: 7rem;
    /* border-radius: 50%; */
    background-color: ${(props) => props.bgColor};
    color: white;
    font-size: 3rem;
    font-weight: 600;
  }
`;
