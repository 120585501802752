export const UserIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
    <path d="M24 30C30.6274 30 36 24.6274 36 18C36 11.3726 30.6274 6 24 6C17.3726 6 12 11.3726 12 18C12 24.6274 17.3726 30 24 30Z" stroke={color} stroke-width="2" stroke-miterlimit="10"/>
<path d="M5.81055 40.4982C7.65472 37.3062 10.3064 34.6556 13.4993 32.8128C16.6922 30.9701 20.3138 30 24.0003 30C27.6868 30 31.3084 30.9702 34.5013 32.813C37.6941 34.6559 40.3458 37.3065 42.1899 40.4986" stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
