// import { NewCalculatorBox } from "./NewCalculatorBox";
import styled from "styled-components";
import { Icon } from "../ui/IconWrapper/Icon";
import {
  BirthdayIcon,
  CalendarsIcon,
  CalculatorsIcon,
  PregnancyIcon,
  RetirementIcon,
  TripIcon,
  VacationIcon,
  WeddingIcon,
} from "../../shared/assets/icons/ToolsOasisIcons";
import { BackgroundIcon } from "../../shared/assets/icons/ToolsOasisIcons";
import { useNavigate } from "react-router";
import { NewPregnancyCalculator } from "./NewPregnancyCalculator";
import { useState } from "react";

const CALCULATOR_TYPES = {
  custom: {
    toolTitle: null,
    color: "rgb(118, 73, 191)",
    icon: CalculatorsIcon,
    background: BackgroundIcon,
    backgroundSize: { width: "54vh", height: "54vh" },
    datePlaceHolder: "Enter date and time...",
    datePickerType: "datetime-local",
    outterLink: null,
  },
  pregnancy: {
    toolTitle: { prefix: "Pregnancy due date", postfix: "" },
    color: "rgb(118, 73, 191)",
    icon: PregnancyIcon,
    background: PregnancyIcon,
    backgroundSize: { width: "60vh", height: "60vh" },
    datePlaceHolder: "Enter conception date...",
    datePickerType: "date",
    outterLink: {
      prefix: `Countdown to due date`,
      path: "/Countdown/pregnancy-countdown",
      toolName: "\nPregnancy Due Date Countdown",
      toolColor: "rgba(52, 191, 149, 1)",
      postfix: "",
    },
  },
  retirement: {
    color: "rgb(118, 73, 191)",
    icon: RetirementIcon,
    background: RetirementIcon,
    backgroundSize: { width: "54vh", height: "54vh" },
    datePlaceHolder: "Enter retirement date...",
    datePickerType: "date",
    outterLink: null,
  },
};

export const NewCalculator = ({ type = "pregnancy", icon, bgcolor, title }) => {
  const [calculate, setCalculate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleClick = () => {
    if (isFormValid) setCalculate((prev) => !prev);
  };

  return (
    <CalculatorContainerStyled>
      <Icon
        icon={CALCULATOR_TYPES[type].background}
        color="#222"
        style={{
          zIndex: -1,
          position: "absolute",
          minWidth: CALCULATOR_TYPES[type].backgroundSize.width,
          maxWidth: CALCULATOR_TYPES[type].backgroundSize.width,
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />
      <CalculatorTitle>
        <Icon icon={CALCULATOR_TYPES[type].icon} color="white" size="3rem" />

        {CALCULATOR_TYPES[type].toolTitle
          ? `${CALCULATOR_TYPES[type].toolTitle.prefix} calcualtor ${CALCULATOR_TYPES[type].toolTitle.postfix} `
          : `${type} calcualtor`}
      </CalculatorTitle>
      <div style={{ paddingBottom: "0rem" }}>
        <Icon
          icon={icon || CalculatorsIcon}
          color={bgcolor || CALCULATOR_TYPES[type].color}
          size={"7rem"}
        />
      </div>

      <CalculatorMainContainer>
        <NewPregnancyCalculator
          countdownTypeObj={CALCULATOR_TYPES[type]}
          calculate={calculate}
          setIsFormValid={setIsFormValid}
        />
        <CalculatorBtns>
          <button className={"start-btn"} onClick={handleClick}>
            {calculate ? "Reset" : "Calculate"}
          </button>
        </CalculatorBtns>
      </CalculatorMainContainer>
    </CalculatorContainerStyled>
  );
};

const CalculatorContainerStyled = styled.div`
  height: 100%;
  margin-top: 4rem;
  max-width: 70rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const CalculatorMainContainer = styled.div`
  width: 100%;
  min-height: 64vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
`;

const CalculatorTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  padding: 2rem 1rem;
  font-size: 1.7rem;
  font-weight: 600;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
`;

const OutterToolLinkage = styled.div`
  color: white;
  height: 5rem;
  font-size: 1.2rem;
  white-space: pre-wrap;
`;

const OutterToolLink = styled.a`
  color: rgb(118, 73, 191);
  font-weight: 500;
`;

const CalculatorBtns = styled.div`
  max-width: 68rem;
  width: 90vw;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 1rem;

  & button {
    width: 100%;
    border-radius: 0.8rem;
    border: none;
    outline: none;
    height: 7rem;
    /* border-radius: 50%; */
    background-color: rgb(118, 73, 191);
    color: white;
    font-size: 3rem;
    font-weight: 600;
  }
`;
