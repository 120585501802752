export const ConceptSharingIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return ( 
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path d="M28.3669 36H24L15 31.0396L4.00879 35.0817L7.01068 41.0257L14.0833 38.0728C20.7 42.0242 24.7272 44 26.1651 44C27.603 44 33.5479 41.3334 44 36" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.9923 26.9878C28.9923 24.097 28.9923 22.5402 28.9923 22.3171C30.0923 21.861 31.535 21.193 32.364 20.364C33.9926 18.7353 35 16.4853 35 14C35 9.02944 30.9706 5 26 5C21.0294 5 17 9.02944 17 14C17 16.4853 18.0074 18.7353 19.636 20.364C20.465 21.193 21.9 21.861 23 22.3171C23.0056 22.6525 23.0056 24.2094 23 26.9878H28.9923Z" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linejoin="round" />
      <path d="M12 21L13 20" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linecap="round" />
      <path d="M40 21L39 20" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linecap="round" />
      <path d="M15 5L14 4" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linecap="round" />
      <path d="M37 5L38 4" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linecap="round" />
      <path d="M41 12H40" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linecap="round" />
      <path d="M12 12H11" stroke={color} stroke-opacity="0.85" stroke-width="3" stroke-linecap="round" />
    </svg>
  );
};
