import { useNavigate } from "react-router-dom";

export const Card = ({
  title,
  img,
  path,
  icon,
  description,
  bgcolor,
  category,
}) => {
  const Icon = icon;
  const navigate = useNavigate();
  return (
    <a
      className={"__to__card"}
      href={path}
      styled={{ whiteSpace: "pre-wrap", textDecoration: "none", width: "100%" }}
      onClick={(e) => {
        e.preventDefault();
        navigate(path);
      }}
    >
      <div
        className={"__to__card__icon"}
        style={{ color: bgcolor, background: "" }}
      >
        {icon && <Icon color={bgcolor} size="4rem" />}
        {category}
      </div>
      <div className={"__to__card__content"}>
        <h1 style={{ color: bgcolor }}>{title}</h1>
        <h3>{description}</h3>
      </div>
      {/* <div className="__to__card__category">{category}</div> */}
    </a>
  );
};
