export const DatesIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M39 7.5H9C8.17157 7.5 7.5 8.17157 7.5 9V39C7.5 39.8284 8.17157 40.5 9 40.5H39C39.8284 40.5 40.5 39.8284 40.5 39V9C40.5 8.17157 39.8284 7.5 39 7.5Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33 4.5V10.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 4.5V10.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 16.5H40.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.25 23.9995H22.5L19.5 27.7495C19.9933 27.7495 20.4789 27.8711 20.914 28.1036C21.349 28.3361 21.72 28.6723 21.9942 29.0824C22.2683 29.4925 22.437 29.9639 22.4855 30.4548C22.5339 30.9457 22.4606 31.4409 22.272 31.8967C22.0834 32.3525 21.7853 32.7547 21.4041 33.0678C21.0229 33.3809 20.5704 33.5951 20.0866 33.6916C19.6029 33.7881 19.1028 33.7637 18.6307 33.6208C18.1586 33.4779 17.729 33.2207 17.38 32.8721"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27 26.2495L30 23.9995V33.7495"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
