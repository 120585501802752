export const WeatherIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M16.5003 27.75C16.5003 25.2283 17.2481 22.7632 18.649 20.6665C20.05 18.5698 22.0413 16.9356 24.3711 15.9705C26.7008 15.0055 29.2644 14.753 31.7377 15.245C34.2109 15.737 36.4828 16.9513 38.2659 18.7344C40.049 20.5175 41.2633 22.7893 41.7553 25.2626C42.2473 27.7359 41.9948 30.2995 41.0297 32.6292C40.0647 34.959 38.4305 36.9503 36.3338 38.3512C34.2371 39.7522 31.772 40.5 29.2503 40.5H14.2503C13.0524 40.499 11.8691 40.2372 10.7827 39.7327C9.69624 39.2282 8.73268 38.4932 7.95897 37.5787C7.18527 36.6642 6.61999 35.5922 6.30242 34.4372C5.98484 33.2822 5.9226 32.0719 6.12 30.8904C6.3174 29.7089 6.76972 28.5846 7.44553 27.5956C8.12133 26.6066 9.00439 25.7766 10.0334 25.1633C11.0623 24.55 12.2125 24.1681 13.4039 24.0443C14.5954 23.9204 15.7995 24.0574 16.9326 24.4459"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9377 10.6369L14.1562 6.20523"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.1282 14.3359L5.44202 11.7548"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.63671 21.0586L3.20508 21.84"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6589 12.1285L24.24 8.44232"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.68308 25.3788C8.83866 24.3999 8.21498 23.2507 7.85447 22.0092C7.49397 20.7678 7.40509 19.4632 7.59389 18.1844C7.78269 16.9055 8.24474 15.6823 8.9486 14.598C9.65245 13.5136 10.5816 12.5936 11.6729 11.9005C12.7641 11.2075 13.9918 10.7576 15.2725 10.5814C16.5532 10.4053 17.8568 10.5071 19.0946 10.8799C20.3324 11.2527 21.4755 11.8877 22.4459 12.7418C23.4163 13.5958 24.1914 14.6489 24.7184 15.8294"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
