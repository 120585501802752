import { style } from "@mui/system";
import { useState, useEffect } from "react";
import styled from "styled-components";

export const DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const MONTHS = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const DateGeneratorCalendar = ({
  date,
  withDate = true,
  withWeekday = true,
  withMonth = true,
  withYear = true,
  bgcolor,
  size,
}) => {
  const [showDate, setShowDate] = useState(withDate);
  const [showWeekday, setShowWeekday] = useState(withWeekday);
  const [showMonth, setShowMonth] = useState(withMonth);
  const [showYear, setShowYear] = useState(withYear);

  useEffect(() => {}, []);
  return (
    <DateGeneratorCalendarStyled style={{ transform: `scale(${size})`, ...style }}>
      <DateGeneratorCalendarTopStyled
        bgColor={bgcolor || "rgba(56, 214, 214, 0.9)"}
      >
        <DateGeneratorCalendarMonth>
          {showMonth && MONTHS[date.getMonth()]}
        </DateGeneratorCalendarMonth>
        <DateGeneratorCalendarYear>
          {showYear && date.getFullYear()}
        </DateGeneratorCalendarYear>
      </DateGeneratorCalendarTopStyled>

      <DateGeneratorCalendarBottomStyled
        bgColor={bgcolor || "rgba(56, 214, 214, 0.9)"}
      >
        <DateGeneratorCalendarDay>
          {showDate && date.getDate()}
        </DateGeneratorCalendarDay>
        <DateGeneratorCalendarWeekday>
          {showWeekday && DAYS[date.getDay()]}
        </DateGeneratorCalendarWeekday>
      </DateGeneratorCalendarBottomStyled>
    </DateGeneratorCalendarStyled>
  );
};

const DateGeneratorCalendarStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 13rem;
  width: 5rem !important;
`;

const DateGeneratorCalendarTopStyled = styled.div`
  width: 100%;
  /* height: 8rem; */
  height: 4.2rem;
  padding: 2rem;
  border: 0.1rem solid ${(props) => props.bgColor};
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.4rem;
  font-family: cursive;
  color: #f3f3f3;
`;

const DateGeneratorCalendarBottomStyled = styled.div`
  width: 100%;
  /* height: 22rem; */
  height: 11rem;
  /* border: 0.5rem  solid ${(props) => props.bgColor};*/
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
`;

const DateGeneratorCalendarDay = styled.div`
  /* font-size: 14rem;
  line-height: 12rem;
  font-weight: 600;
  padding: 1rem;
  color:  ${(props) => props.bgColor}; */
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 600;
  /* color: solid ${(props) => props.bgColor}; */
  color: #111;
`;

const DateGeneratorCalendarMonth = styled.div`
  /* font-size: 4rem;
  line-height: 4rem;
  font-weight: 600;
  font-family: cursive; */
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  font-family: cursive;
`;

const DateGeneratorCalendarYear = styled.div`
  /* font-size: 3rem;
  line-height: 3rem;
  font-weight: 600;
  font-family: cursive; */
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 600;
  font-family: cursive;
`;

const DateGeneratorCalendarWeekday = styled.div`
  /* font-size: 3rem;
  line-height: 3rem;
  font-weight: 500;s
  font-family: cursive;
  color:  ${(props) => props.bgColor}; */
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 600;
  font-family: cursive;
  color: #111;
`;
