import styled from "styled-components";
import { useState } from "react";
import { RandomDateResult } from "./RandomDateResult";
import { CopyIcon } from "../../../../shared/assets/icons/ToolsOasisIcons";
import { Icon } from "../../../ui/IconWrapper/Icon";
import { useEffect } from "react";

const OPTIONS = [
  { title: "Grouped", icon: null },
  { title: "Singles", icon: null },
];

function copyToClipBoard(targetId) {
  const textContent = document.getElementById(targetId).innerHTML;
  navigator.clipboard.writeText(textContent);
  alert("copied:\n" + textContent);
}

export const RandomDateResults = ({ results }) => {
  const [selectedOption, setSelectedOption] = useState(OPTIONS[1].title);
  const [groupedString, setGroupedString] = useState("");

  useEffect(() => {
    setGroupedString(results.map((el) => el.date).join("\n"));
  }, [results]);

  return (
    <RandomDateResultsStyled>
      <OptionsMenu>
        {OPTIONS.map((el) => (
          <OptionMenu
            onClick={() => setSelectedOption(el.title)}
            className={el.title === selectedOption && "selected"}
          >
            {el.title}
          </OptionMenu>
        ))}
      </OptionsMenu>
      {selectedOption === "Singles" &&
        results.map((el) => (
          <RandomDateResult el={el} copyToClipBoard={copyToClipBoard} />
        ))}
      {selectedOption === "Grouped" && (
        <GroupedDates>
          <div id={"groupedString"}>{groupedString}</div>
          <div onClick={() => copyToClipBoard("groupedString")}>
            <Icon
              icon={CopyIcon}
              color={"rgba(256,256,256,0.5)"}
              size={"3rem"}
              style={{ position: "absolute", top: "1rem", right: "1rem" }}
            />
          </div>
        </GroupedDates>
      )}
    </RandomDateResultsStyled>
  );
};

const RandomDateResultsStyled = styled.div`
  width: 90vw;
  max-width: 68rem;
  padding: 2rem;
  gap: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70%;
`;

const OptionsMenu = styled.ul`
  display: flex;
  width: 100%;
  gap: 3rem;
  padding: 3rem 0;
`;
const OptionMenu = styled.li`
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  font-size: 1.4rem;
  max-width: 68rem;
  width: 100%;
  cursor: pointer;
  border-radius: 10rem;
  &.selected {
    background-color: rgb(205, 97, 85);
  }
`;

const GroupedDates = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  width: 90vw;
  max-width: 62rem;
  border-radius: 0.8rem;
  position: relative;
  height: fit-content;
  white-space: pre;
  div {
    color: white;
    text-align: left;
    line-height: 3rem;
    font-size: 1.4rem;
  }
`;
