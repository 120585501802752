export const CountdownIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M24 24L12.6 15.45C12.4137 15.3103 12.2625 15.1291 12.1584 14.9208C12.0542 14.7125 12 14.4829 12 14.25V7.5C12 7.10218 12.158 6.72064 12.4393 6.43934C12.7206 6.15804 13.1022 6 13.5 6H34.5C34.8978 6 35.2794 6.15804 35.5607 6.43934C35.842 6.72064 36 7.10218 36 7.5V14.1827C36 14.4146 35.9462 14.6434 35.8429 14.851C35.7396 15.0586 35.5895 15.2395 35.4045 15.3793L24 24Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 24L12.6 32.55C12.4137 32.6897 12.2625 32.8709 12.1584 33.0792C12.0542 33.2875 12 33.5171 12 33.75V40.5C12 40.8978 12.158 41.2794 12.4393 41.5607C12.7206 41.842 13.1022 42 13.5 42H34.5C34.8978 42 35.2794 41.842 35.5607 41.5607C35.842 41.2794 36 40.8978 36 40.5V33.8173C36 33.5854 35.9462 33.3566 35.8429 33.149C35.7396 32.9414 35.5895 32.7605 35.4045 32.6207L24 24Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9993 16.4992H33.9235"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 31.4992V24"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
