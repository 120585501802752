import { Countdown } from "../components/Countdown/Countdown";
import { WeddingCountdown } from "../components/Countdown/WeddingCountdown/WeddingCountdown";
import styled from "styled-components";
import WED from "../shared/assets/images/wedding2.jpg";
import UC from "../shared/assets/images/under-construction.png";
import { BaseModal } from "../components/ui/Modal/BaseModal";
import { useState } from "react";
import { VacationCountdown } from "../components/Countdown/VacationCountdown/VacationCountdown";
import { HolidaysCountdown } from "../components/Countdown/HolidaysCountdown/HolidaysCountdown";
import { PregnancyCountdown } from "../components/Countdown/PregnancyCountdown/PregnancyCountdown";
import { NewCountdown } from "../components/Countdown/NewCountdown";
import { NewCalculator } from "../components/Calculators/NewCalculator";
import { Calculator } from "../components/ToolsComponents/Calculator/Calculator";

const CalculatorPage = ({ type }) => {


  return (
    <>
      <Calculator type="pregnancy" />
    </>
  );
};

export default CalculatorPage;
