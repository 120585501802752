export const HalloweenIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 53"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_1853)">
        <path
          d="M17.5859 16.6214C17.5859 16.6214 18.7046 11.4183 24.196 11.0102C24.196 11.0102 29.9925 10.3981 30.8061 16.6214"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.50146 26.4154C6.50146 26.4154 7.72178 11.7241 16.5691 10.7039C16.5691 10.7039 19.2131 10.2958 20.5351 12.4383"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.0776 12.6423C29.0776 12.6423 30.298 8.96944 33.8572 10.6018C37.4165 12.2342 42.3995 18.6616 42.1961 26.4153"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5181 41.0049C13.5181 41.0049 14.0265 48.4526 24.3992 48.3505C24.3992 48.3505 33.8567 49.4728 35.5855 41.0049"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.789 37.1279C41.789 37.1279 38.2297 47.5343 32.7383 46.0039"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.9082 37.1279C6.9082 37.1279 10.0607 46.8201 16.1623 45.7999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.0777 13.7651C11.0777 13.7651 0.908404 13.7651 0.399937 27.7423C0.399937 27.7423 0.298244 43.9639 11.0777 44.0659"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.1281 14.2752C38.1281 14.2752 48.5008 12.7449 48.3991 29.7827C48.3991 29.7827 48.2974 42.5356 38.0264 43.9639"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.5522 11.7244C23.6878 3.76666 18.9082 3.15452 18.9082 3.15452L20.5353 0.399902C20.5353 0.399902 26.7386 1.7262 29.586 11.6224"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5688 21.9268L13.9248 26.6198H19.5179L16.5688 21.9268Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.1284 22.1304L29.4844 26.8234H35.0775L32.1284 22.1304Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.1621 29.8845L24.3994 27.1299L26.6366 29.8845"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.7051 30.4966C12.7051 30.4966 14.7389 38.5564 22.2642 39.1685V37.4341H26.4337V39.1685C26.4337 39.1685 34.6708 37.8422 35.9928 30.4966C35.9928 30.4966 32.1285 32.1289 30.9082 32.1289V34.8836H26.6371V32.6391H21.9592V34.7815H17.7897V32.0269C17.7897 32.0269 15.4508 31.6188 12.7051 30.4966Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_1853">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
