
import { useState, useEffect } from "react";
import { ToolBase } from "../../ui/ToolBase/ToolBase";
import { TOOLS } from "../../ui/ToolBase/ToolsTypes";
import { StopwatchMain } from "./StopwatchMain";

const getReturnValues = (countDown) => {
  // calculate time left
  const days = parseInt((countDown / (1000 * 60 * 60 * 24)) % 24);
  const hours = parseInt((countDown / (1000 * 60 * 60)) % 24);
  const minutes = parseInt((countDown / (1000 * 60)) % 60);
  const seconds = parseInt((countDown / 1000) % 60);
  const miliSec = parseInt((countDown % 1000) / 10);
  return { days, hours, minutes, seconds, miliSec };
};

const getTimeFormat = ({ days, hours, minutes, seconds, miliSec }) => {
  return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}:${miliSec < 10 ? "0" + miliSec : miliSec
    }`;
};

export const Stopwatch = ({ typeFam = "stopwatches", type = "stopwatch" }) => {
  const [isStart, setIsStart] = useState(false);
  const [isStop, setIsStop] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [time, setTime] = useState(getReturnValues(0));
  const [lapTime, setLapTime] = useState(getReturnValues(0));
  const [lapStartTime, setLapStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [laps, setLaps] = useState([]);

  useEffect(() => {
    if (!isStart) {
      return;
    }
    const interval = setInterval(() => {
      setTime(getReturnValues(Date.now() - startTime));
      setLapTime(getReturnValues(Date.now() - lapStartTime));
    }, 100);

    if (isStop) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isStart, isStop, startTime, time]);

  const handleStart = () => {
    setStartTime(Date.now());
    setLapStartTime(Date.now());
    setIsStop(false);
    setIsStart(true);
  };
  const handleLap = () => {
    setLapStartTime(Date.now());
    setLaps((prev) => {
      let tmp = [
        ...prev,
        {
          lapTime: getTimeFormat(
            getReturnValues(Date.now() - lapStartTime)
          ).toString(),
          lapTimeInMiliseconds: Date.now() - lapStartTime,
          totalTime: getTimeFormat(
            getReturnValues(Date.now() - startTime)
          ).toString(),
        },
      ];

      let minVal = Math.min(...tmp.map((el) => +el.lapTimeInMiliseconds));
      let maxVal = Math.max(...tmp.map((el) => +el.lapTimeInMiliseconds));

      return tmp
        .map((el, index) => {
          return {
            ...el,
            isBestLap:
              tmp.length > 1 && minVal && el.lapTimeInMiliseconds === minVal,
            isWorstLap:
              tmp.length > 1 && maxVal && el.lapTimeInMiliseconds === maxVal,
            lapNo: index + 1,
          };
        })
        .reverse();
    });
  };

  useEffect(() => {
    console.log(laps);
  }, [laps]);

  const handleStop = () => {
    setElapsedTime(Date.now() - startTime);
    setIsStart(false);
    setIsStop(true);
  };

  const handleResume = () => {
    setLapStartTime(Date.now());
    setStartTime(Date.now() - elapsedTime);
    setIsStop(false);
    setIsStart(true);
  };

  const handleReset = () => {
    setIsStop(false);
    setIsStart(false);
    setElapsedTime(0);
    setLapTime(getReturnValues(0));
    setTime(getReturnValues(0));
    setStartTime(null);
    setLaps([]);
  };
  return (
    <>
      <ToolBase
        toolTitle={
          TOOLS[typeFam][type].toolTitle
            ? `${TOOLS[typeFam][type].toolTitle.prefix} ${TOOLS[typeFam][type].toolTitle.main} ${TOOLS[typeFam][type].toolTitle.postfix} `
            : `${type} ${TOOLS[typeFam][type].toolTitle.main}`
        }
        type={type}
        typeFam={typeFam}
        isPrimaryMainButton={!isStart}
        isSecondaryMainButton={isStart}
        primaryBtn={{
          mainTitle: startTime ? "resume" : "start",
          secondaryTitle: "Stop",
          clickHandler: {
            main: () => { startTime ? handleResume() : handleStart() },
            secondary: handleStop,
          },
        }}
        secondaryBtn={{
          mainTitle: "Lap",
          secondaryTitle: "Reset",
          clickHandler: {
            main: handleLap,
            secondary: handleReset,
          },
        }
        }
        main={<StopwatchMain time={time} lapTime={lapTime} laps={laps} />}
      />
    </>
  );
};
