export const MothersDayIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_1825)">
        <path
          d="M36.3998 9.69971C36.3998 9.69971 35.3998 10.8997 31.9998 10.8997C28.5998 10.8997 28.5998 9.59971 26.7998 11.7997"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29 20.3997C29 20.3997 30.4 20.2997 31.5 18.5997C32.6 16.8997 34.1 17.4997 34.2 16.9997C34.3 16.4997 33.4 15.0997 33.7 14.5997C34 13.9997 36.3 11.9997 35.5 10.1997"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6997 6.9998C25.8997 -1.9002 31.4997 0.9998 31.4997 0.9998C39.2997 3.4998 37.7997 9.5998 37.7997 9.6998C37.7997 9.6998 38.9997 12.2998 36.3997 16.5998C33.7997 20.8998 37.9997 21.7998 37.8997 25.0998C37.8997 25.0998 37.7997 28.9998 33.6997 32.9998"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.8997 12.9998C23.8997 12.9998 25.4997 9.79984 27.3997 12.9998C27.3997 12.9998 27.8997 15.9998 25.5997 15.4998C25.5997 15.4998 26.3997 22.4998 18.2997 22.4998C18.2997 22.4998 16.6997 22.4998 15.6997 20.2998C15.6997 20.2998 15.2997 19.2998 14.1997 18.5998C14.1997 18.5998 9.69971 15.1998 14.1997 9.69984C14.1997 9.69984 18.6997 4.69984 23.7997 9.09984C23.7997 9.09984 24.4997 9.89984 25.1997 11.3998"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.6996 22.5997C26.6996 22.5997 25.8996 21.9997 23.2996 22.8997C20.6996 23.7997 16.9996 24.8997 16.3996 24.0997C15.7996 23.2997 15.7996 22.4997 14.7996 22.8997C13.5996 23.2997 12.5996 25.8997 14.3996 26.9997C16.1996 28.0997 22.2996 30.1997 26.9996 25.5997C26.9996 25.5997 28.3996 23.9997 26.6996 22.5997Z"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.2998 18.2998C25.2998 18.2998 35.8998 21.7998 33.1998 35.2998"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.8999 20.6997C15.8999 20.6997 15.3999 21.8997 15.3999 22.5997"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.8999 27.6997C15.8999 27.6997 17.0999 37.0997 29.3999 38.7997C29.3999 38.7997 32.0999 43.2997 33.6999 40.3997C35.2999 37.4997 35.2999 37.9997 35.0999 36.7997C34.7999 35.5997 33.4999 35.2997 30.7999 35.0997C30.7999 35.0997 22.4999 33.1997 23.0999 27.9997"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.7998 19.0996C14.7998 19.0996 7.79977 30.9996 9.19977 47.3996H31.5998L30.9998 40.5996"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.7998 39.3997C29.7998 39.3997 26.0998 40.4997 22.8998 38.9997C22.8998 38.9997 19.5998 41.7997 16.4998 41.8997C13.3998 41.9997 10.9998 41.3997 11.7998 40.0997C12.5998 38.7997 14.6998 38.7997 15.3998 38.6997C16.0998 38.5997 16.9998 36.9997 20.3998 35.6997"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.7998 22C21.7998 22 21.8998 22.8 21.9998 23.4"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.5 11.7996C34.5 11.7996 33.6 10.6996 32.5 10.9996"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.2 12.5C31.2 12.5 31.1 13.9 32.7 13.9"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.3999 16.5996C29.3999 16.5996 29.5999 17.3996 30.2999 17.7996"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.8999 13.8996L19.8999 13.5996"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.5996 16L15.0996 16.9"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.2998 18.6999C16.2998 18.6999 17.3998 18.5999 17.4998 17.8999"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.2998 16C20.2998 16 21.2998 15.9 21.1998 15"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.8999 19.4997C19.8999 19.4997 20.8999 19.5997 20.6999 18.6997"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1997 10.1997C14.1997 10.1997 15.3997 10.5997 15.1997 11.7997C14.9997 12.9997 14.8997 14.3997 16.5997 13.6997"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1998 9.20001C14.1998 9.20001 13.2998 5.10001 15.4998 6.10001C16.8998 6.60001 15.9998 8.20001 14.1998 9.20001Z"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.4997 9.69995C13.4997 9.69995 12.4997 12.3 10.9997 10.2C9.49965 8.09995 13.4997 7.69995 13.4997 9.69995Z"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.8999 39.4C23.8999 39.4 22.4999 38.4 21.8999 36.5"
          stroke={color}
          stroke-width="1.8"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_221_1825">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
