// import { NewCalculatorBox } from "./NewCalculatorBox";
import { useState, useEffect } from "react";
import { ToolBase } from "../../ui/ToolBase/ToolBase";
import { TOOLS } from "../../ui/ToolBase/ToolsTypes";
import { TimerMain } from "./TimerMain";
import { useLocation } from "react-router-dom";
import AlertSound from "../../../shared/assets/sounds/timer-alarm.mp3";

let audio = new Audio(AlertSound);

const getReturnValues = (countDown) => {
  // calculate time left
  const hours = Math.floor(countDown / (1000 * 60 * 60)) || 0;
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)) || 0;
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000 || 0);
  const isExpired = seconds < 0;
  return { hours, minutes, seconds, isExpired };
};

export const Timer = ({ typeFam = "timers", type = "timer" }) => {
  let routerLocation = useLocation();
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isRestart, setIsRestart] = useState(false);
  const [milisecondsToAdd, setMilisecondsToAdd] = useState(0);

  const [data, setData] = useState(
    getReturnValues(
      hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
    )
  );

  useEffect(() => {
    setIsStart(false);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
    let pathName = routerLocation.pathname;
    let value = pathName && pathName.replace(/[\D]/g, "");
    let location = window.location.href;
    let includeSec = pathName.includes("second") ||
      pathName.includes("seconds")
    let includeMin = pathName.includes("minute") ||
      pathName.includes("minutes")
    let includeHrs = pathName.includes("hour") ||
      pathName.includes("hours")
    let isPreDefined = includeSec || includeMin || includeHrs
    let metaTitle =
      "Do you need to increase your focus or productivity? Click on our timer and get to work until you hear the beep!";
    if (isPreDefined) {
      if (includeSec) {
        setSeconds(value);
        metaTitle = `${value} ${value === 1 ? " Second" : " Seconds"} Timer`;
      }
      else if (includeMin) {
        setMinutes(value);
        metaTitle = `${value} ${value === 1 ? "Minute" : "Minutes"} Timer`;
      }
      else if (includeHrs) {
        setHours(value);
        metaTitle = `${value} ${location.includes("hour") ? " Hour" : " Hours"} Timer`;
      }
    }
    setPageTitle(isPreDefined ? metaTitle : "Online Timer");
    setPageDescription(isPreDefined
      ? "Use our handsome online timer, preloaded for " +
      metaTitle +
      ". Ideal for time management, focus sessions, and more. One click and it will start!"
      : metaTitle)

  }, [routerLocation]);

 
  useEffect(() => {
    let milisec = hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
    setMilisecondsToAdd(milisec);
  }, [hours, minutes, seconds]);

  useEffect(() => {
    setIsExpired(milisecondsToAdd < 0);
    if (isStart && milisecondsToAdd < 1000) {
      audio.play();
    }
    setData(getReturnValues(milisecondsToAdd));
  }, [milisecondsToAdd, isStart]);

  useEffect(() => {
    if (!isStart) return;
    // if (isExpired) {
    //   var audio = new Audio(AlertSound);
    //   audio.play();
    // }
    const interval = setInterval(() => {
      setMilisecondsToAdd((prevState) => prevState - 1000);
    }, 1000);

    if (isPaused || isExpired) {
      clearInterval(interval);
      return;
    }
    return () => clearInterval(interval);
  }, [isStart, isPaused, isExpired]);

  const handleStart = () => {
    let t = new Date();
    t.setTime(t.getTime() + milisecondsToAdd);
    setIsStart(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  const handlePause = () => {
    setIsPaused(true);
  };

  const handleRestart = () => {
    audio.pause();
    audio.currentTime = 0;
    window.location.reload();
  };

  const handleSilence = () => {
    audio.currentTime = 0;
    // window.location.reload();
  };

  return (
    <>
      <ToolBase
        toolTitle={pageTitle}
        toolDescription={pageDescription}
        absolutePath={TOOLS[typeFam][type].absolutePath}
        type={type}
        typeFam={typeFam}
        isPrimaryMainButton={(!isStart || isExpired) && !isPaused}
        secondaryBtn={null}
        isPicMode={false}
        primaryBtn={{
          mainTitle: !isStart ? "start" : isExpired && "restart",
          secondaryTitle: isPaused && isStart ? "resume" : "pause",
          clickHandler: {
            main: () => { !isStart ? handleStart() : isExpired ? handleRestart() : handleResume() },
            secondary: () => { isPaused && isStart ? handleResume() : handlePause() }
          },
        }
        }
        main={
          <TimerMain
            minutes={minutes}
            hours={hours}
            seconds={seconds}
            isStart={isStart}
            isPaused={isPaused}
            isExpired={isExpired}
            isRestart={isRestart}
            milisecondsToAdd={milisecondsToAdd}
            data={data}
            setSeconds={setSeconds}
            setMinutes={setMinutes}
            setHours={setHours}
            setIsExpired={setIsExpired}
            setIsStart={setIsStart}
            setIsRestart={setIsRestart}
          />}
      />
    </>
  );
};
