import flower3 from "../../../shared/assets/images/flowers/flower-3.png";
import flower1 from "../../../shared/assets/images/flowers/flower-1.png";
import leaf3 from "../../../shared/assets/images/flowers/leaf-3.png";
export const FlowersImg = ({ position }) => {
  return (
    <>
      <img
        src={leaf3}
        width={"60rem"}
        style={{
          position: "absolute",
          [position]: "-1.7rem",
          [position === "bottom" ? "right" : "left"]: "6rem",
          transform: [
            position === "bottom" ? "rotate(270deg)" : "rotate(90deg)",
          ],
        }}
        alt={"leaf"}
      />
      <img
        src={flower1}
        width={"60rem"}
        style={{
          position: "absolute",
          [position]: "-1rem",
          [position === "bottom" ? "right" : "left"]: "-1rem",
        }}
        alt={"flower"}
      />
      <img
        src={flower3}
        width={"60rem"}
        style={{
          position: "absolute",
          [position]: "-2rem",
          [position === "bottom" ? "right" : "left"]: "2rem",
          transform: [
            position === "bottom" ? "rotateX(180deg)" : "rotateX(180deg)",
          ],
        }}
        alt={"flower"}
      />
      <img
        src={flower3}
        width={"60rem"}
        style={{
          position: "absolute",
          [position]: "-2rem",
          [position === "bottom" ? "right" : "left"]: "2rem",
          transform: [
            position === "bottom" ? "rotateX(180deg)" : "rotateX(180deg)",
          ],
        }}
        alt={"flower"}
      />
      <img
        src={leaf3}
        width={"60rem"}
        style={{
          position: "absolute",
          [position]: "5rem",
          [position === "bottom" ? "right" : "left"]: "0",
          transform: [
            position === "bottom" ? "rotateX(360deg)" : "rotateX(180deg)",
          ],
        }}
        alt={"leaf"}
      />
      <img
        src={flower3}
        width={"60rem"}
        style={{
          position: "absolute",
          [position]: "3rem",
          [position === "bottom" ? "right" : "left"]: "-1rem",
        }}
        alt={"flower"}
      />
      <img
        src={flower1}
        width={"60rem"}
        style={{
          position: "absolute",
          [position]: "1rem",
          [position === "bottom" ? "right" : "left"]: "1rem",
        }}
        alt={"flower"}
      />
    </>
  );
};
