import { Feature } from "ol";
import styled from "styled-components";
import { Icon } from "../ui/IconWrapper/Icon";
import { TimerIcon } from "../../shared/assets/icons/ToolsOasisIcons";
import { useNavigate } from "react-router-dom";

export const FeaturedMenuItem = ({
  title,
  bgcolor = "red",
  path,
  icon,
  toolDB,
  subCategoryData,
  defaultCatergories,
  isCollection,
  parentType,
  categoryData,
}) => {
  const navigate = useNavigate();
  console.log("description=", toolDB);
  const Icon = icon;
  const SubCategoryIcon = toolDB?.icon;
  return (
    <FeaturedMenuItemStyled>
      <FeatureBallon
        color={isCollection ? toolDB?.bgcolor : bgcolor}
        onClick={() => {
          navigate(isCollection ? "/" + categoryData.hubPath : "/" + path + "?hval=" + title);
        }}
      >
        <Icon size="6rem" color={isCollection ? 'white' : bgcolor} />
        {isCollection && <SubIcon bgcolor={toolDB?.bgcolor}>
          <SubCategoryIcon size="3rem" color={isCollection ? toolDB?.bgcolor : bgcolor} />
        </SubIcon>}
      </FeatureBallon>
      <FeatureDescription>
        <FeatureTitle onClick={e => { e.preventDefault(); navigate(isCollection ? "/" + categoryData.hubPath : "/" + path + "?hval=" + title); }} href={isCollection ? "/" + categoryData.hubPath : "/" + path + "?hval=" + title}>{isCollection ? categoryData.title : title}</FeatureTitle>
        <FeatureDetails>{categoryData.description}</FeatureDetails>
      </FeatureDescription>
    </FeaturedMenuItemStyled >
  );
};

const FeaturedMenuItemStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 64rem;
  max-height: fit-content;
`;

const FeatureBallon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  min-height: 10.5rem;
  max-height: 10.5rem;
  width: 100%;
  border: 0.1rem solid ${(props) => props.color};
  border-radius: 0.8rem;
`;

const FeatureTitle = styled.a`
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.5rem 0 0.5rem 0;
  width: 100%;
`;

const FeatureDetails = styled.div`
  color: white;
  text-align: left;
  font-style: normal;
  font-size: 1.2rem;
  width: 100%;
`;

const FeatureDescription = styled.div`
  width: 100%;
  padding-left:1rem;

`;

const SubIcon = styled.div`
height: 5rem;
width:5rem;
border-radius:50% !important;
display:flex;
align-items:center;
justify-content:center;
background: linear-gradient(
  180deg,
  ${(props) => props.bgcolor} 0%,
  rgba(121, 57, 255, 0) 100%
);
`
