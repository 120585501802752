import styled from "styled-components";

export const Badge = ({ title, color = "white", background, style }) => {
  return (
    title && (
      <BadgeStyled style={{ color, background, ...style }}>{title}</BadgeStyled>
    )
  );
};

const BadgeStyled = styled.div`
  padding: 0.3rem 0.6rem;
  border-radius: 8rem;
  font-size: 0.7rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.04rem;
`;
