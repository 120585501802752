export const CalendarsIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M39 7.5H9C8.17157 7.5 7.5 8.17157 7.5 9V39C7.5 39.8284 8.17157 40.5 9 40.5H39C39.8284 40.5 40.5 39.8284 40.5 39V9C40.5 8.17157 39.8284 7.5 39 7.5Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33 4.5V10.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 4.5V10.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 14.5H40.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="13.5" cy="20.5" r="1.5" fill={color} />
      <circle cx="13.5" cy="27.5" r="1.5" fill={color} />
      <circle cx="13.5" cy="34.5" r="1.5" fill={color} />
      <circle cx="20.5" cy="20.5" r="1.5" fill={color} />
      <circle cx="20.5" cy="27.5" r="1.5" fill={color} />
      <circle cx="20.5" cy="34.5" r="1.5" fill={color} />
      <circle cx="27.5" cy="20.5" r="1.5" fill={color} />
      <circle cx="27.5" cy="27.5" r="1.5" fill={color} />
      <circle cx="34.5" cy="20.5" r="1.5" fill={color} />
      <circle cx="34.5" cy="27.5" r="1.5" fill={color} />
    </svg>
  );
};
