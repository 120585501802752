import styled from "styled-components";
import { SlidePagingItem } from "./SlidePagingItem";

export const SlidePaging = ({ slides, activeSlide, setActiveFact }) => {
  return (
    <SlidePagingContainer>
      {slides.map((el, index) => (
        <SlidePagingItem
          key={index + 1}
          activeSlide={activeSlide}
          numberOfSlides={slides.length}
          currentSlide={index + 1}
          setActiveFact={setActiveFact}
        />
      ))}
    </SlidePagingContainer>
  );
};

export default SlidePaging;

const SlidePagingContainer = styled.div`
  max-width: fit-content;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
