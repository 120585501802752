export const PregnancyIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M19.6158 37.567C18.0952 36.5535 16.5535 36.8808 15.9727 37.1711C16.5271 37.3031 17.4775 37.3295 18.7447 38.1213C19.7959 38.7782 22.3086 41.0511 24.2094 42.3972C25.7301 43.474 27.6678 43.796 28.407 43.6641C25.5558 43.7433 21.5166 38.834 19.6158 37.567Z"
        fill={color}
      />
      <path
        d="M34.9806 37.0919C35.2974 40.8293 31.6542 42.8195 29.8326 43.4265C30.7338 42.925 32.6132 41.0827 32.6046 37.0919C32.596 33.1012 28.6938 30.2559 26.7438 29.3321C27.2718 28.9626 28.3595 27.8593 28.4862 26.4023C28.6446 24.5811 23.8134 22.5224 22.5462 21.1763C21.5325 20.0994 22.0446 16.9269 22.3878 15.4752C21.8334 15.0001 21.5958 12.5454 21.675 12.6246C23.0214 16.0295 24.6658 16.779 26.5666 15.9872C28.0873 15.3537 28.6446 12.9414 28.7238 12.0704C29.991 11.2469 30.4134 8.50715 30.4662 7.24023C30.7286 7.55568 30.783 8.2696 30.783 8.2696C31.0998 11.1202 29.3574 12.3079 29.3574 12.3871C29.5158 16.0295 26.1102 16.9005 25.1598 16.9797C24.3995 17.043 23.4702 16.531 23.1006 16.267C22.071 18.2466 22.1219 19.7809 22.6254 20.8596C23.1798 22.0473 29.5518 23.9477 29.8326 26.0064C30.0227 27.4 29.0142 28.6986 28.4862 29.1737C29.4366 29.5696 34.5846 32.4202 34.9806 37.0919Z"
        fill={color}
      />
      <path
        d="M15.1016 14.9999C12.1712 17.8505 12.4088 23.1821 12.9632 25.5312C10.112 18.2464 14.468 13.2579 14.7848 13.0204C14.7056 8.50697 15.4184 5.02298 19.5368 1.53897C22.8315 -1.24825 26.6648 0.377626 28.1696 1.53897C31.9711 2.33079 32.1295 5.49808 31.8919 6.52745C31.7019 7.35094 30.8095 7.71518 30.3871 7.79436C29.2467 10.2648 24.4472 11.6479 22.2296 11.991C22.7048 15.2375 17.0816 18.088 15.4976 19.355C14.2304 20.3685 14.5208 25.2409 14.864 27.3524C14.3096 29.2791 12.6464 34.162 14.1512 37.6461C15.9801 41.8805 24.1304 45.0892 28.5655 44.6933C33.1591 44.2833 32.3671 41.4468 34.5847 40.8134C33.0007 42.0803 32.7631 45.0892 30.3871 45.7227C21.2792 47.3063 14.2121 41.7645 12.6464 37.9628C10.9832 33.9245 13.2008 27.7483 13.2008 26.6397C13.2008 23.0765 12.8588 20.4635 15.9728 18.5631C19.5843 16.0926 20.6984 13.6011 20.804 12.7037C19.6001 12.7037 17.768 14.1289 17.0024 14.8416C17.0288 14.4985 17.6677 13.4321 18.428 12.5453C20.0586 10.4549 26.5064 9.69472 30.3871 7.24009C30.5983 6.13154 30.5297 3.77191 28.5655 3.20179C26.6014 2.63168 23.576 4.75904 22.3088 5.89399C22.6889 4.37369 25.3712 2.99064 26.6648 2.48916C17.6043 0.968857 15.7616 11.8855 15.9728 17.5338L15.1016 14.9999Z"
        fill={color}
      />
      <path
        d="M18.8238 19.9883C19.4838 20.1466 20.8513 20.7801 21.0414 22.047C21.2315 23.3139 21.2824 24.1058 21.0414 25.1351C20.1778 28.8242 17.3842 35.6567 17.3972 36.5161C17.3976 36.5232 17.3979 36.5303 17.3982 36.5374C17.3977 36.5307 17.3973 36.5236 17.3972 36.5161C17.348 35.424 17.5445 33.0451 18.111 31.7864C18.8238 30.2028 20.1702 25.0631 20.1702 23.3139C20.1702 21.176 19.2198 20.5162 18.8238 19.9883Z"
        fill={color}
      />
    </svg>
  );
};
