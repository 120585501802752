export const PentecostIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M22.1064 37.1C22.1064 37.1 32.6064 38.5 36.8064 29"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.7063 25.8999C36.7063 25.8999 35.5063 20.1999 44.2063 18.1999L39.7063 16.7999C39.7063 16.7999 38.7063 10.9999 32.4063 12.8999C32.4063 12.8999 29.8063 13.7999 30.4063 17.3999C30.5063 18.2999 30.5063 19.1999 30.1063 19.9999C29.6063 21.0999 28.1063 21.0999 27.4063 20.1999C26.1063 18.1999 22.3063 13.7999 14.6063 12.6999C13.0063 12.4999 11.5063 11.7999 10.3063 10.7999C9.40635 9.7999 8.60635 8.7999 8.50635 7.3999C8.50635 7.3999 2.60635 15.2999 9.70635 21.7999C11.3063 23.2999 13.4063 24.3999 15.5063 24.9999C17.6063 25.6999 20.7063 26.8999 20.4063 28.3999C19.9063 30.5999 15.6063 36.1999 3.40635 33.1999C3.40635 33.1999 0.606349 40.4999 9.70635 44.2999C9.70635 44.2999 12.2063 37.1999 19.4063 36.7999"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7061 11.6C11.7061 11.6 10.7061 6.6 13.6061 4C13.6061 4 15.2061 8 20.1061 9.8C25.0061 11.6 28.9061 13.7 28.8061 20.6"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.3063 16.8998C35.7482 16.8998 36.1063 16.5416 36.1063 16.0998C36.1063 15.658 35.7482 15.2998 35.3063 15.2998C34.8645 15.2998 34.5063 15.658 34.5063 16.0998C34.5063 16.5416 34.8645 16.8998 35.3063 16.8998Z"
        fill={color}
      />
    </svg>
  );
};
