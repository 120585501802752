import {
  TimerIcon,
  StopwatchIcon,
  CountdownIcon,
  DatesIcon,
  CalendarsIcon,
  FunziesIcon,
  WeatherIcon,
  CalculatorsIcon,
  PregnancyIcon,
  RandomDateIcon,
  VacationIcon,
  BirthdayIcon,
  DicesIcon,
  RetirementIcon,
  WeddingIcon,
  TripIcon,
  BackgroundIcon,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  CopyIcon,
  HalloweenIcon,
  EasterIcon,
  HoliIcon,
  HanukkahIcon,
  DiwaliIcon,
  BoxingdayIcon,
  DayOfTheDeadIcon,
  July4thIcon,
  MothersDayIcon,
  NewYearIcon,
  PentecostIcon,
  ThanksgivingIcon,
  SamhainIcon,
  ValentinesDayIcon,
  LaborDayIcon,
  GoodFridayIcon,
  AscensionDayIcon,
  RamadanIcon,
} from "../../../shared/assets/icons/ToolsOasisIcons";
import {
  asertionDayBG,
  asertionDayMobileBG,
  birthdayBG,
  birthdayMobileBG,
  boxingDayBG,
  boxingDayMobileBG,
  countdownBG,
  countdownMobileBG,
  dayOfTheDeadBG,
  dayOfTheDeadMobileBG,
  diwaliBG,
  diwaliMobileBG,
  easterBG,
  easterMobileBG,
  goodFridayBG,
  goodFridayMobileBG,
  halloweenBG,
  halloweenMobileBG,
  hanukkahBG,
  hanukkahMobileBG,
  holiBG,
  holiMobileBG,
  july4thBG,
  july4thMobileBG,
  laborDayBG,
  laborMobileDayBG,
  mothersDayBG,
  mothersDayMobileBG,
  nyeBG,
  nyeMobileBG,
  pentecostBG,
  pentecostMobileBG,
  pregnancyBG,
  pregnancyMobileBG,
  ramadanBG,
  ramadanMobileBG,
  retirementBG,
  retirementMobileBG,
  smahainBG,
  samhainMobileBG,
  thanksgivinBG,
  thanksgiviMobilenBG,
  tripBG,
  tripMobileBG,
  vacationBG,
  vacationMobileBG,
  valentinesBG,
  valentinesMobileBG,
  weddingBG,
  weddingMobileBG,
  calendarBG,
  calendarMobileBG,
  onThisDayBG,
  onThisDayMobileBG,
} from "../../../shared/assets/images/toolsBgImgs";

export const TOOLS = {
  timers: {
    mainIcon: TimerIcon,
    timer: {
      toolTitle: { prefix: "", main: "Timer", postfix: "" },
      toolDescription: "",
      color: "rgb(60, 144, 189)",
      icon: TimerIcon,
      background: TimerIcon,
      backgroundSize: { width: "46vh", height: "646vhvh" },
      datePlaceHolder: "Enter date and time..",
      datePickerType: "datetime-local",
      outterLink: null,
      // backgroundImg: {
      //   pc: "https://media.istockphoto.com/id/183876874/photo/hand-with-classic-stopwatch.jpg?s=612x612&w=0&k=20&c=lcebeVhj6sYwHZ6iBM_ph75sUPkb70E1HMv-GhguDMs=",
      //   mobile:
      //     "https://media.istockphoto.com/id/183876874/photo/hand-with-classic-stopwatch.jpg?s=612x612&w=0&k=20&c=lcebeVhj6sYwHZ6iBM_ph75sUPkb70E1HMv-GhguDMs=",
      // },
    },
  },
  calculators: {
    mainIcon: CalculatorsIcon,
    pregnancy: {
      toolTitle: {
        prefix: "Pregnancy Due Date",
        main: "Calculator",
        postfix: "",
      },
      toolDescription:
        "Easily calculate your due date with our pregnancy due date calculator. Start with conception date or your last period.",
      color: "rgb(118, 73, 191)",
      icon: PregnancyIcon,
      background: PregnancyIcon,
      backgroundSize: { width: "60vh", height: "60vh" },
      datePlaceHolder: "Enter conception date...",
      datePickerType: "date",
      outterLink: {
        prefix: `Countdown to due date`,
        path: "/Countdown/pregnancy-countdown",
        toolName: "\nPregnancy Due Date Countdown",
        toolColor: "rgba(52, 191, 149, 1)",
        postfix: "",
      },
      backgroundImg: { pc: null, mobile: null },
    },
  },
  countdowns: {
    mainIcon: CountdownIcon,
    custom: {
      toolTitle: { prefix: "Online", main: "Countdown", postfix: "" },
      toolDescription:
        "Discover how much time is left until a time and date of your choosing arrives. Count with us from the days to the seconds",
      color: "rgba(52, 191, 149, 1)",
      icon: CountdownIcon,
      background: BackgroundIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: countdownBG, mobile: countdownMobileBG },
    },
    calendar: {
      toolTitle: { prefix: "Calendar", main: "Countdown", postfix: "" },
      toolDescription:
        "Countdown to you own event with our countdown calendar page. Set the date and time and see the countdown clicking down to your special event",
      color: "rgba(52, 191, 149, 1)",
      icon: CountdownIcon,
      background: BackgroundIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: calendarBG, mobile: calendarMobileBG },
    },
    wedding: {
      toolTitle: { prefix: "Wedding", main: "Countdown", postfix: "" },
      toolDescription:
        'Get ready to say "I do" with our wedding countdown page. Track the days and count the seconds until your happy day.',
      color: "rgba(52, 191, 149, 1)",
      icon: WeddingIcon,
      background: WeddingIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter wedding date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: weddingBG, mobile: weddingMobileBG },
    },
    vacation: {
      toolTitle: { prefix: "Vacation", main: "Countdown", postfix: "" },
      toolDescription:
        "Count down to your vacation with our countdown calendar page. Set the date and time and see the countdown clicking down to your special event.",
      color: "rgba(52, 191, 149, 1)",
      icon: VacationIcon,
      background: VacationIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter vacation date...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: vacationBG, mobile: vacationMobileBG },
    },
    trip: {
      toolTitle: { prefix: "Trip", main: "Countdown", postfix: "" },
      toolDescription:
        "Count down to your trip with our countdown calendar page. Set the date and time and see the countdown clicking down to your special event.",
      color: "rgba(52, 191, 149, 1)",
      icon: TripIcon,
      background: TripIcon,
      backgroundSize: { width: "49vh", height: "49vh" },
      datePlaceHolder: "Enter trip date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: tripBG, mobile: tripMobileBG },
    },
    pregnancy: {
      toolTitle: { prefix: "Pregnancy", main: "Countdown", postfix: "" },
      toolDescription:
        "Count down to your due date with our pregnancy countdown. Set the date and time and see the countdown clicking down to the little one arrival. Not sure what is your due date? just use our preganancy due date calculator",
      color: "rgba(52, 191, 149, 1)",
      icon: PregnancyIcon,
      background: PregnancyIcon,
      backgroundSize: { width: "60vh", height: "60vh" },
      datePlaceHolder: "Enter due date...",
      datePickerType: "date",
      outterLink: {
        prefix: ` Not sure when the baby is due? 
      check out: `,
        path: "/Calculators/pregnancy-calculator",
        toolName: "\nPregnancy Due Date Calculator",
        toolColor: "rgb(118, 73, 191)",
        postfix: "",
      },
      backgroundImg: { pc: pregnancyBG, mobile: pregnancyMobileBG },
    },
    retirement: {
      toolTitle: { prefix: "Retirement", main: "Countdown", postfix: "" },
      toolDescription:
        "Count down to your retirement date with our countdown calendar page. Set the date and time and see the countdown clicking down to your special event.",
      color: "rgba(52, 191, 149, 1)",
      icon: RetirementIcon,
      background: RetirementIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter retirement date...",
      datePickerType: "date",
      outterLink: null,
      backgroundImg: { pc: retirementBG, mobile: retirementMobileBG },
    },
    birthday: {
      toolTitle: { prefix: "Birthday", main: "Countdown", postfix: "" },
      toolDescription:
        "Excited for your birthday? Our countdown page will help you keep track of the days until your special day arrives. Take this countdown as a gift from us!",
      color: "rgba(52, 191, 149, 1)",
      icon: BirthdayIcon,
      background: BirthdayIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter birthday date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: birthdayBG, mobile: birthdayMobileBG },
    },
    // holidays: {
    //   toolTitle: { prefix: "Holidays", main: "Countdown", postfix: "" },
    //   toolDescription:
    //     "Get ready for Halloween! Our countdown page will show you how many days, hours, and minutes are left until the spookiest time of the year.",
    //   color: "rgba(52, 191, 149, 1)",
    //   icon: CountdownIcon,
    //   background: CountdownIcon,
    //   backgroundSize: { width: "54vh", height: "54vh" },
    //   datePlaceHolder: "Enter birthday date and time...",
    //   datePickerType: "datetime-local",
    //   outterLink: null,
    // },
    halloween: {
      toolTitle: { prefix: "Halloween", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Halloween! Our countdown page will show you how many days, hours, and minutes are left until the spookiest time of the year.",
      toolInfo:
        "Halloween is an annual holiday celebrated on the night of October 31st. The holiday, which originated with the ancient Celtic festival of Samhain, has roots in pagan and Christian traditions. It is a time when people dress up in costumes, go trick-or-treating, and decorate their homes with spooky decorations. Halloween is widely celebrated in countries around the world, including the United States, Canada, Ireland, and the United Kingdom. In some cultures, Halloween is also known as All Hallows' Eve or All Saints' Eve and is a time to honor the dead.",
      slidesInfo: [
        {
          text: "Irish immigrants popularized the celebration of Halloween in the USA, bringing with them their tradition of Jack O’ lantern carving. However rather than using turnips as they had in Ireland, they used the local pumpkins to carve scary and silly glowing faces to celebrate the spooky night. This tradition is still going strong, in 2013, New Yorker Stephen Clarke entered the Guinness world records for carving a pumpkin in only 16.47 seconds!",
          image: null,
        },
        {
          text: 'Half Moon Bay, California, holds the title of "World Pumpkin Capital" and hosts an annual Pumpkin Festival during the halloween season. The event includes giant pumpkin weigh-offs, pumpkin carvings, and various pumpkin-themed contests.',
          image: null,
        },
        {
          text: "Possibly the scariest thing about Halloween is how much candy is consumed, in the US approximately 300,000 tons of sweets are gobbled over the holiday, enough to fill the Titanic 6 times!",
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: HalloweenIcon,
      background: HalloweenIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: halloweenBG, mobile: halloweenMobileBG },
    },
    easter: {
      toolTitle: { prefix: "Easter", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Easter! Check how many days, hours, and even minutes are left until this family celebration of the resurrection of jesus.",
      toolInfo:
        "Easter is a Christian holiday that is celebrated on the first Sunday after the first full moon following the vernal equinox, which occurs on or around March 21st. This means that the date of Easter varies from year to year, falling anywhere between March 22nd and April 25th. Easter is among the most important in Christianity around the world, commemorating the resurrection of Jesus Christ and symbolizing themes of renewal, rebirth, and new beginnings.",
      slidesInfo: [
        {
          text: 'The word "Easter" is believed to have originated from the Old English word “Ēastre”,  the name the pagan goddess of spring. Eggs have long been associated with Easter due to their association with fertility and rebirth, however the tradition of giving chocolate eggs at Easter didn’t start until the 19th century when the first chocolate egg molds were made in Europe.',
          image: null,
        },
        {
          text: 'In Sweden and Finland, it is traditional on Maundy Thursday for children to dress up as "Easter witches” and go door-to-door, exchanging drawings and paintings for candy, similar to the Halloween tradition of trick-or-treating.',
          image: null,
        },
        {
          text: 'Every Easter in the town of Vrontados, on the Greek Island of Chios, a unique tradition called "Rouketopolemos" takes place, where two rival church congregations fire thousands of homemade rockets at each other in what they call a “Rocket War”. The reason for this wacky tradition is unclear but it’s thought to date back to Ottoman times.',
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: EasterIcon,
      background: EasterIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: easterBG, mobile: easterMobileBG },
    },
    boxing_day: {
      toolTitle: { prefix: "Boxing Day", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Boxing day! See how many days, hours, minutes and seconds are left until this day of great savings.",
      toolInfo:
        'Boxing Day is a holiday that is celebrated in several countries around the world on December 26th.One theory of its origins is that it was a day when servants and tradespeople would receive gifts or "boxes" from their employers as a reward for their hard work throughout the year. Today, Boxing Day is mainly known as a day of shopping and deals, with many retailers offering significant discounts on merchandise. It is a particularly popular shopping day in the UK, Canada, Australia, and New Zealand. However, it is also a day for relaxation and spending time with family and friends, as many people have the day off from work.',
      slidesInfo: [
        {
          text: "In Britain a popular tradition on Boxing Day is to go for a \"Boxing Day Swim”, hardy Brits go for a dip in the usually pretty nippy waters of their local river, lake or sea. It's certainly a good way to burn off all those chocolates and roast potatoes eaten over Christmas!",
          image: null,
        },
        {
          text: "In some countries, such as Ireland, Boxing Day is also known as St. Stephen's Day. It is named after the first Christian martyr, St. Stephen.",
          image: null,
        },
        {
          text: "While many people see boxing day as a day of shopping and discounts, it is also a day associated with acts of charity and giving back. Many people donate to organizations and volunteer their time to help those in need during the holiday season.",
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: BoxingdayIcon,
      background: BoxingdayIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: boxingDayBG, mobile: boxingDayMobileBG },
    },
    holi: {
      toolTitle: { prefix: "Holi", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready to celebrate the festival of Holi with our Countdown page! Know how many days and hours are left until the celebration of this colorful hindu holiday.",
      toolInfo:
        "Holi is a Hindu festival that celebrates the triumph of good over evil, good harvest, and fertility. It usually falls in the later part of February or March and is observed at the end of winter on the last full moon day of the Hindu lunisolar calendar month marking the spring.",
      slidesInfo: [
        {
          text: "A traditional drink associated with Holi is called “Bhang”. It is made from an infusion of cannabis, milk, and spices. Known for its intoxicating effects, it symbolizes the merry and festive spirit of this time of year.",
          image: null,
        },
        {
          text: 'As well as throwing colors, Holi is known for its lively music and dance performances. Many people come together during this celebration to sing and dance to traditional Holi songs, known as "Holi ke geet”.',
          image: null,
        },
        {
          text: 'On the eve of Holi, a bonfire called "Holika Dahan" is lit to commemorate the triumph of good over evil. People gather around the bonfire, sing and dance, and offer prayers.',
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: HoliIcon,
      background: HoliIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: holiBG, mobile: holiMobileBG },
    },
    nye: {
      toolTitle: { prefix: "New Year's Eve", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for NYE! See how many days, hours, and minutes are left until the biggest and last celebration of the year.",
      toolInfo:
        "In the Gregorian calendar, New Year's Eve, also known as Old Year's Day or Saint Sylvester's Day in many countries, is the evening or the entire day of the last day of the year, 31 December. The last day of the year is commonly referred to as “New Year's Eve”. In many countries, New Year's Eve is celebrated with dancing, eating, drinking, and watching or lighting fireworks. Some Christians attend a watchnight service. The celebrations generally go on past midnight into New Year's Day, 1 January. (source: https://en.wikipedia.org/wiki/New_Year%27s_Eve) ",
      slidesInfo: [
        {
          text: 'In Japan, New Year\'s Eve is called “Ōmisoka", on this day families come together and enjoy a special meal called "toshikoshi soba” which is made of long buckwheat noodles in a hot dash broth. This tasty meal eaten for good luck and longevity, a yummy way to slurp your way into the new year!',
          image: null,
        },
        {
          text: "In Spain, it is a tradition to eat 12 grapes at midnight on New Year's Eve, one for each stroke of the clock, symbolizing good fortune and prosperity for each month of the upcoming year.",
          image: null,
        },
        {
          text: "In Brazil, New Year's Eve is celebrated with a massive summery beach party on Copacabana Beach in Rio de Janeiro. Millions of people gather, wearing white and offering flowers to the sea goddess Iemanjá, a central figure in the Candomblé religion.",
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: NewYearIcon,
      background: NewYearIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: nyeBG, mobile: nyeMobileBG },
    },
    hanukkah: {
      toolTitle: { prefix: "Hanukkah", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Hanukkah! Learn how many days, hour and minutes are left until the 8-day festival of light and oily pastries.",
      toolInfo:
        "Hanukkah is a Jewish holiday that commemorates the Maccabean Revolt against the Romans in the 2nd century BCE. It is observed for eight nights and days, starting on the 25th day of Kislev according to the Hebrew calendar. It is a festival of light and singing, celebrated by families every evening of the 8 days.",
      slidesInfo: [
        {
          text: "Hanukkah is a time for delicious food. Traditional Hanukkah treats include latkes (potato pancakes), gelt (chocolate coins), levivot prasa (leek fritters) and sufganiyot (jelly-filled doughnuts), yum!",
          image: null,
        },
        {
          text: "While the most common type of menorah used during Hanukkah has nine branches, some menorahs can have different shapes and designs, including artistic creations which use unconventional materials like recycled bicycle parts and LEGO bricks, or even edible versions made from cookies or marshmallows.",
          image: null,
        },
        {
          text: "In recent years, Hanukkah has been celebrated with modern twists, such as Hanukkah-themed rap songs, flash mobs, and parody videos such as “Bohemian Chanukah” and “West Side Chanukah”.",
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: HanukkahIcon,
      background: HanukkahIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: hanukkahBG, mobile: hanukkahMobileBG },
    },
    diwali: {
      toolTitle: { prefix: "Diwali", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Diwali! Our countdown counts down the days, hours and minutes until the hindu festival celebrating the victory of good and knowledge.",
      toolInfo:
        "Diwali is a festival of lights, of good and of knowledge, celebrated by Hindus, Jains, and Sikhs. It is based on the lunar calendar and falls on the 15th day of Kartik, the holiest month. The date varies every year, but it is usually in October or November.",
      slidesInfo: [
        {
          text: "In 2018, Indian astronaut Rakesh Sharma celebrated Diwali aboard the Soviet space station Soyuz T-11. He carried a small oil lamp and sweets to mark the festival, making it the first Diwali celebrated in space, what a novel way to enjoy the festival of lights!",
          image: null,
        },
        {
          text: 'In some parts of India, particularly in the state of Maharashtra, there is a unique tradition of celebrating Diwali with a ritual called "Vasu Baras”, where cows and calves are worshiped and given special treats (usually made from chickpea and jaggery) as a form of gratitude for their role in agriculture and sustenance.',
          image: null,
        },
        {
          text: "Diwali is a time when many households conduct a thorough cleaning and decorate their homes with vibrant Rangoli patterns made of colored powders or rice flour, believed to bring good luck and ward off evil spirits.",
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: DiwaliIcon,
      background: DiwaliIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: diwaliBG, mobile: diwaliMobileBG },

      tabsOptions: [
        { title: "Amavasya", isSelected: true, value: "diwali_amavasya" },
        {
          title: "Krisna Chaturdasi",
          isSelected: false,
          value: "diwali_krisna_chaturdasi",
        },
      ],
    },
    day_of_the_dead: {
      toolTitle: { prefix: "Day Of The Dead", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Day Of The Dead! See how many days, hours and minutes are left until this colorful Mexican festivity commemorating thoses who passed away.",
      toolInfo:
        "Day of the Dead, or Día de los Muertos, is a Mexican holiday that is celebrated on November 1st and 2nd. The holiday is a time to honor and remember loved ones who have passed away, and it is believed that during these two days, the spirits of the dead return to the world of the living. Families build altars, or ofrendas, to honor their loved ones, with offerings of food, candles, and flowers. Day of the Dead is a colorful and festive celebration, with parades, music, and dancing, as people remember their loved ones and celebrate the cycle of life and death. While the holiday has its roots in pre-Columbian traditions, it is also influenced by Catholicism and has spread beyond Mexico to other parts of the world.",
      slidesInfo: [
        {
          text: "The Day of the Dead (Día de los Muertos) has pre-Hispanic origins, with roots in indigenous Aztec and Nahua traditions. It later merged with Catholicism after the arrival of the Spanish in Mexico in the 1500s",
          image: null,
        },
        {
          text: "Pan de Muerto (Bread of the Dead), is a tasty sweet bread shaped like bones and topped with sugar. It is traditionally consumed during the Day of the Dead, often shared among family members and offered to the spirits of those who have passed.",
          image: null,
        },
        {
          text: 'The marigold flower, known as "cempasúchil" or "flor de muertos”, is believed to guide the spirits with its vibrant color and fragrance. It is used extensively in Day of the Dead decorations and altars throughout the festival.',
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: DayOfTheDeadIcon,
      background: DayOfTheDeadIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: dayOfTheDeadBG, mobile: dayOfTheDeadMobileBG },
    },
    samhain: {
      toolTitle: { prefix: "Samhain", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Day Of The Samhain! See how many days, hours and minutes are left until this special Celtic day connected to Halloween and other traditions.",
      toolInfo:
        "Samhain is an ancient Celtic festival that is celebrated on the night of October 31st. It marks the end of the harvest season and the beginning of the darker half of the year. Samhain is believed to have been a time when the veil between the living and the dead was thinnest, and it was a time when people would honor and communicate with their ancestors. The festival involved bonfires, feasting, and ritualistic sacrifices. Over time, Samhain became intertwined with other cultural and religious traditions, such as Halloween and All Saints' Day. Today, many modern Pagans and Wiccans continue to celebrate Samhain as a time of reflection, divination, and honoring the cycles of life and death.",
      slidesInfo: [
        {
          text: "The holiday played a significant role in shaping the modern celebration of Halloween. Many Halloween customs, such as trick-or-treating, carving pumpkins, and telling ghost stories, can be traced back to Samhain traditions.",
          image: null,
        },
        {
          text: `The term "Samhain" (pronounced sah-win or sow-in) is derived from Old Irish and translates to "summer's end." It comes from the combination of two Gaelic words: "samh" meaning "summer" and "fuin" meaning "end" or "to come to an end.`,
          image: null,
        },
        {
          text: `The practice of wearing costumes and masks during Samhain has roots in ancient Celtic traditions. It was believed that disguising oneself would confuse and ward off malevolent spirits.`,
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: SamhainIcon,
      background: SamhainIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: smahainBG, mobile: samhainMobileBG },
    },
    valentines_day: {
      toolTitle: { prefix: "Valentine's Day", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Day Of The Samhain! See how many days, hours and minutes are left until this romantic day of couples and hopefuls.",
      toolInfo:
        "Valentine's Day is an annual holiday celebrated on February 14th. The holiday originated as a Christian feast day in honor of Saint Valentine, a third-century Roman saint who is associated with love and romance. Today, Valentine's Day is widely recognized as a celebration of love and affection, especially between romantic partners. It is a time when people exchange cards, flowers, chocolates, and other gifts as a token of their love and appreciation. The holiday has become a global phenomenon, celebrated in many countries around the world, and is known for its iconic heart-shaped decorations and the color red.",
      slidesInfo: [
        {
          text: `In Japan on Valentine's Day women are typically the ones who give gifts to men, often in the form of chocolates. There is also a separate day called "White Day" on March 14th when men reciprocate the gesture by giving gifts to women.`,
          image: null,
        },
        {
          text: `In Finland, Valentine's Day is known as "Friendship Day”, and it is a time to celebrate not only romantic love but also love between friends. `,
          image: null,
        },
        {
          text: `The city of Verona in Italy, famous as the setting for Shakespeare's Romeo and Juliet, receives thousands of letters addressed to Juliet on Valentine's Day. These letters are answered by volunteers from the Juliet Club.`,
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: ValentinesDayIcon,
      background: ValentinesDayIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: valentinesBG, mobile: valentinesMobileBG },
    },
    july_4th: {
      toolTitle: { prefix: "July 4th", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for The 4th Of July! See how many days, hours and minutes are left until the American day of independence.",
      toolInfo:
        "The 4th of July, also known as Independence Day, is a federal holiday in the United States that commemorates the adoption of the Declaration of Independence on July 4, 1776. It is a day when Americans come together to celebrate their country's independence and freedom. The holiday is typically marked with parades, fireworks displays, cookouts, and other patriotic festivities.",
      slidesInfo: [
        {
          text: `Bizarrely, three former U.S. presidents, John Adams, Thomas Jefferson and James Monroe, all died on July 4th. Adams and Jefferson, who were also both signers of the Declaration of Independence, died on the same day in 1826, exactly 50 years after the declaration was adopted.`,
          image: null,
        },
        {
          text: `The American flag, often associated with patriotic July 4th celebrations, originally had 13 stars representing the 13 original colonies that made up the US. Today, it has 50 stars, each representing a state in the United States.`,
          image: null,
        },
        {
          text: `The Nathan's Famous Hot Dog Eating Contest, held every year in Coney Island, New York, has been a popular July 4th tradition since 1916. Contestants compete to see who can eat the most hot dogs in a set time. The current title is held by Joey Chestnut, who managed to gobble 63 hotdogs in only 10 min in 2022, yikes! `,
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: July4thIcon,
      background: July4thIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: july4thBG, mobile: july4thMobileBG },
    },
    ascension_day: {
      toolTitle: { prefix: "Ascension Day", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Ascension Day! Learn exactly how much time is left before the day commemorating Jesus's ascension to heaven.",
      toolInfo:
        "Ascension Day is a Christian holiday that commemorates Jesus Christ’s ascension into heaven. It is officially celebrated on a Thursday on the 40th day of Easter (or 39 days after Easter Sunday).",
      slidesInfo: [
        {
          text: "In certain churches, a symbolic ritual is performed during the Ascension Day service. As part of this ritual, the Paschal candle (the large, decorated candle used at Easter) is extinguished to symbolize the conclusion of the Easter season.",
          image: null,
        },
        {
          text: "In some countries, including Germany and the Netherlands, Ascension Day is a public holiday, and people often take the opportunity to enjoy outdoor activities, such as hiking, biking, or flying kites.",
          image: null,
        },
        {
          text: 'In some parts of the British Isles, particularly in rural areas, there is a custom known as "beating the bounds" on Ascension Day. This tradition came about in a time when maps were rare, people would walk around the boundaries of their parish, often accompanied by prayers and hymns, to mark and bless their territory and create a shared mental map of local boundaries.',
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: AscensionDayIcon,
      background: AscensionDayIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: asertionDayBG, mobile: asertionDayMobileBG },
    },
    labor_day: {
      toolTitle: { prefix: "Labor Day", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for labor day!  Learn how many days, hours and minutes before this American celebration of work and workers.",
      toolInfo:
        "Labor Day is a federal holiday in the United States celebrated on the first Monday in September to honor and recognize the American labor movement and the works and contributions of laborers to the development and achievements of the United States. It has its origins in the labor movement and is a day to celebrate American workers. People celebrate Labor Day with picnics, sports events, and street parades.",
      slidesInfo: [
        {
          text: "The first Labor Day in the United States was celebrated on September 5, 1882, in New York City, organized by the Central Labor Union. The original intent of the holiday was to create a day of rest and recreation for workers and their families, as well as to advocate for better working conditions",
          image: null,
        },
        {
          text: "The Haymarket Affair, which took place in Chicago in 1886, played a significant role in the establishment of Labor Day. The event was a labor protest demanding an eight-hour workday, and it became a catalyst for labor rights movements.",
          image: null,
        },
        {
          text: "The Haymarket Affair, which took place in Chicago in 1886, played a significant role in the establishment of Labor Day. The event was a labor protest demanding an eight-hour workday, and it became a catalyst for labor rights movements.",
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: LaborDayIcon,
      background: LaborDayIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: laborDayBG, mobile: laborMobileDayBG },
    },
    pentecost: {
      toolTitle: { prefix: "Pentecost", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Countdown to Pentecost! Discover how many days remain before this important Christian holiday, which falls on a different day every year.",
      toolInfo:
        "Pentecost (or 'Whitsunday') is a Christian holiday which takes place on the 50th day (the seventh Sunday) after Easter Sunday.It commemorates the descent of the Holy Spirit upon the Apostles and other followers of Jesus Christ while they were in Jerusalem celebrating the Feast of Weeks, as described in the Acts of the Apostles",
      slidesInfo: [
        {
          text: 'The word "Pentecost" is derived from the Greek word “Pentēkostē", meaning "the fiftieth day”, as it falls 50 days after Easter.',
          image: null,
        },
        {
          text: "The color red is most associated with Pentecost, it is thought to symbolize the flames of the Holy Spirit. Many churches decorate their halls with red flowers, banners, and vestments on this day.",
          image: null,
        },
        {
          text: "Greece and Cyprus, Pentecost is associated with the custom of scattering flower petals and herbs inside churches to symbolize the Holy Spirit.",
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: PentecostIcon,
      background: PentecostIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: pentecostBG, mobile: pentecostMobileBG },
    },
    ramadan: {
      toolTitle: {
        prefix: "Beginning Of Ramadan",
        main: "Countdown",
        postfix: "",
      },
      toolFamily: "holidays",
      toolDescription:
        "Countdown to the beginning of Ramadan! Learn how many days, hours and minutes before this Muslim month of fasting, prayer and refelection.",
      toolInfo:
        "Ramadan is the ninth month of the Muslim calendar and is observed by Muslims worldwide as a month of fasting, prayer, spiritual reflection, and unity. Because the Muslim calendar year is shorter than the Gregorian calendar year, Ramadan begins 10–12 days earlier each year, allowing it to fall in every season throughout a 33-year cycle.",
      slidesInfo: [
        {
          text: `Ramadan is considered the holiest month for Muslims worldwide. Muslims observe Ramadan as a commemoration of the revelation of the first verses of the Quran to the Prophet Muhammad. `,
          image: null,
        },
        {
          text: `Muslims fast from dawn to sunset during Ramadan, abstaining from food, drink and smoking. The pre-dawn meal before the fast begins is called “suhoor", while the meal to break the fast at sunset is called "iftar." These meals often consist of dates, water, and traditional foods depending on the culture and region.`,
          image: null,
        },
        {
          text: `Charity and acts of kindness are highly central to the Muslim celebration of Ramadan. Many worshippers participate in “zakat”, the giving of alms or donations to the less fortunate, throughout the month.`,
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: RamadanIcon,
      background: RamadanIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: ramadanBG, mobile: ramadanMobileBG },
    },
    good_friday: {
      toolTitle: { prefix: "Good Friday", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Countdown to Good Friday! Learn how many days are left before this special day commemorating the crucifiction of Jesus Christ.",
      toolInfo:
        "Good Friday is a Christian holiday that commemorates the crucifixion of Jesus Christ. It is observed on the Friday before Easter Sunday. Many Christians around the world observe Good Friday with fasting and prayer. Many church services are held in the afternoon, usually around noon or midday to 3pm, to remember the hours when Jesus hung on the cross.",
      slidesInfo: [
        {
          text: `The name "Good Friday" is believed to have originated from the term "God's Friday”. The word "good" refers to the day's significance rather than the events of Jesus' crucifixion.`,
          image: null,
        },
        {
          text: `On Good Friday many churches hold special services with the goal of meditating on Jesus' journey to the cross.`,
          image: null,
        },
        {
          text: `In the Philippines, Good Friday is marked by elaborate processions and reenactments. These processions often draw large crowds and involve devotees who participate in acts of self-mortification, sometimes pretending to be crucified as a symbol of their faith and the death of Jesus Christ.          `,
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: GoodFridayIcon,
      background: GoodFridayIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: goodFridayBG, mobile: goodFridayMobileBG },
    },
    thanksgiving: {
      toolTitle: { prefix: "Thanksgiving", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Get ready for Thanksgiving! Learn how many days hours and even minutes are left before the US or Canada celebrates this day of family get-together and giving thanks.",
      toolInfo:
        "Thanksgiving is an annual federal holiday in the United States and Canada. In the US, it is celebrated on the fourth Thursday of every November and in Canada on the second Monday of October. The holiday is largely modeled on a 17th-century harvest feast shared by the English settlers and the Wampanoag tribesmen. It is a day for Americans to gather for a day of feasting, family, and giving thanks for what we have.",
      slidesInfo: [
        {
          text: "Cranberry sauce is a traditional accompaniment to Thanksgiving meals. Interestingly, cranberries are one of only three fruits native to North America (the other two being blueberries and Concord grapes).",
          image: null,
        },
        {
          text: "The date Thanksgiving was celebrated varied throughout history. It wasn't until 1941 that it was officially declared a national holiday by President Franklin D. Roosevelt, who set it as the fourth Thursday in November.",
          image: null,
        },
        {
          text: 'Sarah Josepha Hale, an influential writer and editor, campaigned for almost 17 years to establish Thanksgiving as a national holiday. She is also known for writing the nursery rhyme "Mary Had a Little Lamb.”',
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: ThanksgivingIcon,
      background: ThanksgivingIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: thanksgivinBG, mobile: thanksgiviMobilenBG },

      tabsOptions: [
        { title: "Canada", isSelected: false, value: "thanksgiving_us" },
        { title: "USA", isSelected: true, value: "thanksgiving_ca" },
      ],
    },
    mothers_day: {
      toolTitle: { prefix: "Mother's Day", main: "Countdown", postfix: "" },
      toolFamily: "holidays",
      toolDescription:
        "Don't forget Mother's Day this year! This countdown will tell you how many days and hours are left until this important day celebrating mothers.",
      toolInfo:
        "Mother’s Day is a holiday in honor of mothers that is celebrated in countries throughout the world. In its modern form, the holiday originated in the United States, where it is observed on the second Sunday in May. Many other countries also celebrate the holiday on this date, while some mark the observance at other times of the year.",
      slidesInfo: [
        {
          text: `The origins of Mother's Day can be traced back to ancient times. The ancient Greeks and Romans held festivals honoring maternal goddesses such as Rhea, the goddess of motherhood, fertility, childbirth and Cybele, the mistress of wild nature.`,
          image: null,
        },
        {
          text: `Mother's Day is celebrated on different dates around the world. In the US, it is observed on the second Sunday in May. In Thailand, it is celebrated on August 12, the birthday of Queen Sirikit, the mother of the current king. Mother’s Day is a Thai national holiday, and people often wear pink, the queen's favorite color to celebrate.`,
          image: null,
        },
        {
          text: `The largest Mother's Day card ever made was created in Mexico City in 2013. It measured over 112,000 square feet and required a team of 80 people to carry it!`,
          image: null,
        },
      ],
      color: "rgba(52, 191, 149, 1)",
      icon: MothersDayIcon,
      background: MothersDayIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      backgroundImg: { pc: mothersDayBG, mobile: mothersDayMobileBG },
    },
  },
  dates: {
    mainIcon: DatesIcon,
    randomDate: {
      toolTitle: { prefix: "Random Date Generator", main: "", postfix: "" },
      toolDescription:
        "Generate random dates between selected date range. Quickly get up to 25 randomized dates filterd by weekday. select any of our format options to generate date in a format of your choosing and learn what happened on that day in history.",
      color: "rgb(205, 97, 85)",
      icon: RandomDateIcon,
      background: RandomDateIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
      // backgroundImg: { pc: onThisDayBG, mobile: onThisDayMobileBG },
    },
    onThisDay: {
      toolTitle: { prefix: "On This Day", main: "", postfix: "" },
      toolDescription:
        "Learn which significant historical events, births and deaths occured on this day in past years. This page updates every day.",
      color: "rgb(205, 97, 85)",
      icon: DatesIcon,
      background: DatesIcon,
      backgroundSize: { width: "60vh", height: "60vh" },
      datePlaceHolder: "Enter conception date...",
      datePickerType: "date",
      outterLink: null,
      // backgroundImg: { pc: onThisDayBG, mobile: onThisDayMobileBG },
    },
  },
  stopwatches: {
    mainIcon: StopwatchIcon,
    stopwatch: {
      toolTitle: { prefix: "", main: "Stopwatch", postfix: "" },
      toolDescription:
        "Accurate and reliable stopwatch for precise timing. Includes laps. Ideal for sports, fitness, and even Rubik's cube solving! ",
      color: "rgb(180, 146, 252)",
      icon: StopwatchIcon,
      background: StopwatchIcon,
      backgroundSize: { width: "54vh", height: "54vh" },
      datePlaceHolder: "Enter date and time...",
      datePickerType: "datetime-local",
      outterLink: null,
    },
  },
};
