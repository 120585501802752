import styled from "styled-components";
import { CardExpendable } from "../Card/CardExpendable";

export const ModalOverlay = ({
  children,
  title,
  footer,
  onDismissModal,
  style,
}) => {
  return (
    <ModalOutterContainer>
      <CardExpendable>
        <ModalInnerContainer>
          <ModalHeader>
            {title}
            {""}
            <CloseButton
              onClick={onDismissModal}
              style={{ position: "absolute", right: 0 }}
            >
              x
            </CloseButton>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </ModalInnerContainer>
      </CardExpendable>
    </ModalOutterContainer>
  );
};

const ModalOutterContainer = styled.div`
  position: fixed;
  z-index: 1400;
  overflow: visible;
  top: 40%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  @media only screen and (max-width: 600px) {
    min-width: 90vw !important;
    max-width: 90vw !important;
    top: 50%;
  }
`;

const ModalInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  min-width: fit-content;
  min-height: fit-content;
  max-height: 90vh;
  max-width: 80vw;
  min-width: 100rem;
  position: relative;
  padding: 3rem;

  @media only screen and (max-width: 600px) {
    min-width: 90vw !important;
    max-width: 90vw !important;
  }
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  color: #38d6d6;
  font-size: 1.5rem;
  width: 100%;
  min-height: 5rem;
`;

const ModalBody = styled.div`
  max-height: calc(90vh - 10rem);
  max-width: calc(90vw);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`;

const ModalFooter = styled.footer`
  width: 100%;
  height: fit-content;
  min-height: 5rem;
  padding-top: 2rem;
  button {
    border: none;
    border-radius: 0.4rem;
    font-size: 1.5rem;
    color: white;
    background: transparent;
    cursor: pointer;
    hover: {
    }
  }
`;
const CloseButton = styled.button`
  padding: 0;
  color: white;
  background: none;
  outline: none;
  font-size: 2.5rem;
  border: none;
  position: absolute;
  top: 0;
  transform: translateY(-20%);
`;
