import { useContext, useState } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Main from "./Main/Main";
import Sidebar from "./Sidebar/Sidebar";
import styled from "styled-components";
import {
  SideMenusContext,
  SideMenusProvider,
} from "../contexts/side-menus-context";
const MOCK_DATA = [
  { title: "Timers", isSelected: false },
  { title: "Dices", isSelected: false },
  { title: "Weather", isSelected: false },
  { title: "Funs", isSelected: false },
  { title: "Math", isSelected: false },
];

export const Layout = ({ children }) => {


  return (
    <LayoutStyled className="__layout__styled">
      <Sidebar
      // handleSelectCategories={handleSelectCategories}
      // data={selectedCategories}
      />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </LayoutStyled>
  );
};

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;


