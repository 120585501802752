import styled from "styled-components";

export const HomePageMenuItem = ({ title }) => {
  return (
    <div>
      <HomePageMenuFeaturedHeader>
        <TitleItem>{title}</TitleItem>
        <SeeAllItem>{title}</SeeAllItem>
      </HomePageMenuFeaturedHeader>
    </div>
  );
};

const HomePageMenuFeaturedHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TitleItem = styled.div`
  font-size: 1.7rem;
  line-height: 2.2rem;
  padding: 0.8rem 0;
  color: #ffffff;
`;

const SeeAllItem = styled.a``;

