export const DayOfTheDeadIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <g clip-path="url(#clip0_221_2101)">
        <path
          d="M6.70001 26.4002C5.60001 22.9002 2.80001 11.0002 13.2 4.00016C19.2 0.000163555 26.8 -0.799836 33 2.70016C35.4 4.00016 37.7 6.00016 39.7 9.00016C39.7 9.00016 44.9 16.0002 41 26.2002C40.8 26.8002 40.7 27.6002 41 28.2002C41.9 30.1002 43.3 34.6002 37.4 36.7002C37.1 36.8002 36.9 36.8002 36.6 36.8002C36.1 36.8002 35 36.8002 34.1 36.8002C32.9 36.8002 32 37.7002 31.7 38.8002C31.5 39.6002 30.9 40.3002 29.3 40.4002C25.7 40.5002 17.4 40.4002 17.4 40.4002C17.4 40.4002 16 40.4002 15.7 38.9002C15.5 37.7002 14.5 36.9002 13.4 36.9002C11 36.9002 7.80001 36.6002 7.10001 35.2002C7.10001 35.2002 4.40001 32.4002 6.50001 28.1002C6.80001 27.6002 6.90001 26.9002 6.70001 26.4002Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.9999 47.3004C19.9999 47.3004 9.49989 49.7004 11.4999 36.9004"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7002 47.4003C27.7002 47.4003 38.1002 49.4003 36.2002 36.8003"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20 38V42.2"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 38V42.2"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7002 38V42.2"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.4 20.7C10.8 19.9 11.6 19.5 12.5 19.5H18.3C18.3 19.5 21.2 19.9 20.1 24.3C19.1 28.5 12.6 27.8 11.9 26.8C11.8 26.8 8.39999 24.3 10.4 20.7Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7002 21.9003C27.7002 21.9003 27.9002 19.9003 30.2002 19.3003C30.2002 19.3003 35.1002 19.1003 35.5002 19.3003C35.9002 19.5003 38.2002 19.9003 37.8002 22.6003C37.4002 25.3003 37.9002 27.9003 32.1002 27.9003C28.7002 27.7003 27.3002 24.3003 27.7002 21.9003Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6001 10.5999C15.6001 10.5999 13.5001 8.69995 11.6001 11.1999C11.6001 11.1999 10.3001 14.5999 14.6001 14.9999H19.2001"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.3999 14.9999H33.9999C33.9999 14.9999 36.6999 14.3999 36.3999 11.5999C36.0999 8.79993 31.6999 8.99993 31.5999 10.8999"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.7002 1.2002C19.7002 1.2002 23.4002 10.2002 28.5002 1.2002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 2.4002C16 2.4002 23.1 17.9002 32 2.2002"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.5001 28.2001L21.2001 32.3001C21.1001 32.5001 21.2001 32.8001 21.5001 32.8001H26.3001C26.5001 32.8001 26.7001 32.5001 26.6001 32.3001L24.3001 28.2001C24.1001 27.9001 23.7001 27.9001 23.5001 28.2001Z"
          stroke={color}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.7998 21.9004C24.3521 21.9004 24.7998 21.4527 24.7998 20.9004C24.7998 20.3481 24.3521 19.9004 23.7998 19.9004C23.2475 19.9004 22.7998 20.3481 22.7998 20.9004C22.7998 21.4527 23.2475 21.9004 23.7998 21.9004Z"
          fill={color}
        />
        <path
          d="M23.7998 17.9004C24.3521 17.9004 24.7998 17.4527 24.7998 16.9004C24.7998 16.3481 24.3521 15.9004 23.7998 15.9004C23.2475 15.9004 22.7998 16.3481 22.7998 16.9004C22.7998 17.4527 23.2475 17.9004 23.7998 17.9004Z"
          fill={color}
        />
        <path
          d="M23.8999 13.9004C24.4522 13.9004 24.8999 13.4527 24.8999 12.9004C24.8999 12.3481 24.4522 11.9004 23.8999 11.9004C23.3476 11.9004 22.8999 12.3481 22.8999 12.9004C22.8999 13.4527 23.3476 13.9004 23.8999 13.9004Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_221_2101">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
