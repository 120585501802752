import styled from "styled-components";
import ReactDOM from "react-dom";
import { Backdrop } from "../Backdrop/Backdrop";
import { ModalOverlay } from "./ModalOverlay";

export const BaseModal = ({
  children,
  title,
  header,
  footer,
  buttons,
  showButtons,
  onDismissModal = () => {},
  isEditMode,
  setIsEditMode,
}) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onDismissModal={onDismissModal} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={title}
          footer={footer}
          header={header}
          buttons={buttons}
          showButtons={showButtons}
          onDismissModal={onDismissModal}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        >
          {children}
        </ModalOverlay>,
        document.getElementById("modal-root")
      )}
    </>
  );
};
