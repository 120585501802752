import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../../ui/IconWrapper/Icon";
import {
  StopwatchIcon,
  BackgroundIcon,
} from "../../../shared/assets/icons/ToolsOasisIcons";
import { StopwatchBox } from "./StopwatchBox";
import { TableBody } from "@mui/material";

export const StopwatchMain = ({ time, lapTime, laps }) => {
  return (
    <StopwatchMainContainer>
      <StopwatchBox time={time} coloredColor={"rgb(180, 146, 252)"} />
      <StopwatchBox
        time={lapTime}
        style={{ transform: "scale(50%)", color: "#888" }}
        coloredColor="#888"
      />
      <LapsTable show={laps.length > 0}>
        <Table>
          <TableHead>
            <THeader>Lap</THeader>
            <THeader>Lap Time</THeader>
            <THeader>Total Time</THeader>
          </TableHead>
          <TableBody>
            {laps.map((el, index) => (
              <TRow
                bgcolor={
                  el.isWorstLap
                    ? "rgb(194, 60, 60, 1)"
                    : el.isBestLap
                    ? // ? "rgb(86, 194, 86, 1)"
                      "rgb(180, 146, 252)"
                    : "inherit"
                }
              >
                <TData>
                  <div>{el.lapNo}</div>
                </TData>
                <TData>
                  <div>{el.lapTime}</div>
                </TData>
                <TData>
                  <div>{el.totalTime}</div>
                </TData>
              </TRow>
            ))}
          </TableBody>
        </Table>
      </LapsTable>
    </StopwatchMainContainer>
  );
};

const StopwatchContainerStyled = styled.div`
  // margin-top: 4rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const StopwatchMainContainer = styled.div`
  width: 100%;
  // height: calc(100% - 27vh);
  height: 70%;
  padding-top: 1rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media only screen and (min-width: 800px) {
    min-height: calc(60% - 10rem);
    max-height: calc(60% - 10rem);
  }
`;

const LapsTable = styled.div`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  width: 90%;
  min-height: 70%;
  overflow-y: auto;
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Table = styled.table`
  width: 100%;
  padding: 1rem;
  text-align: center;
  // height: 100%;
`;

const TableHead = styled.thead``;
const TBody = styled.tbody`
  text-align: center;
`;

const THeader = styled.th`
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.5rem;
  @media only screen and (min-width: 800px) {
    /* border: 1px solid blue; */
    font-size: 2.5rem;
    line-height: 3rem;
  }
`;

const TRow = styled.tr`
  width: 100%;
  text-align: center;
  color: ${(props) => props.bgcolor};
`;

const TData = styled.td`
  text-align: center;
  div {
    font-size: 1.5rem;
    line-height: 2.5rem;
    @media only screen and (min-width: 800px) {
      /* border: 1px solid blue; */
      font-size: 2.5rem;
      line-height: 1.5;
    }
  }
`;
