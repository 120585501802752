
import { useState, useEffect } from "react";
import { ToolBase } from "../../ui/ToolBase/ToolBase";
import { TOOLS } from "../../ui/ToolBase/ToolsTypes";
import { OnThisDayMain } from "./OnThisDay/OnThisDayMain";
import { RandomDateMain } from "./RandomDate/RandomDateMain";


export const Dates = ({ typeFam = "dates", type = "" }) => {
    const [calculate, setCalculate] = useState(false);
    const [results, setResults] = useState(null);

    const [isFormValid, setIsFormValid] = useState(true);

    const handleClick = () => {
        if (isFormValid) setCalculate((prev) => (results || !prev));
    };

    return (
        <>
            <ToolBase
                toolTitle={
                    TOOLS[typeFam][type].toolTitle
                        ? `${TOOLS[typeFam][type].toolTitle.prefix} ${TOOLS[typeFam][type].toolTitle.main} ${TOOLS[typeFam][type].toolTitle.postfix} `
                        : `${type} ${TOOLS[typeFam][type].toolTitle.main}`
                }
                type={type}
                typeFam={typeFam}
                showMainIcon={type!=="onThisDay"}
                isPrimaryMainButton={!results}
                isSecondaryMainButton={false}
                primaryBtn={type !== "onThisDay" && {
                    mainTitle: "Generate",
                    secondaryTitle: "Reset",
                    clickHandler: {
                        main: handleClick,
                        secondary: handleClick,
                    },
                }}
                secondaryBtn={null
                }
                main={
                    (() => {
                        switch (type) {
                            case "randomDate":
                                return <RandomDateMain calculate={calculate} setCalculate={setCalculate} results={results} setResults={setResults} />
                            case "onThisDay":
                                return <OnThisDayMain />
                            default: return null;
                        }
                    })()
                }
            />
        </>
    );
};
