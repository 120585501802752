import styled from "styled-components";
export const TimerBox = ({
  hours,
  minutes,
  seconds,
  isExpired,
  coloredColor,
}) => {
  return (
    <TimerBoxStyled coloredColor={coloredColor}>
      <ul>
        <li key="hours" className="__date-val">
          <div className="date-val-num">
            {isExpired ? "00" : hours < 10 ? `0${hours}` : hours}
          </div>
        </li>
        <li className="time-val-txt">:</li>
        <li key="minutes" className="__date-val">
          <div className="date-val-num">
            {isExpired ? "00" : minutes < 10 ? `0${minutes}` : minutes}
          </div>
        </li>
        <li className="time-val-txt">:</li>
        <li
          key="seconds"
          className="__date-val colored"
          // style={{ color: seconds < 10 ? "rgb(194, 60, 60, 1)" : "#38d6d6" }}
        >
          <div className="date-val-num">
            {isExpired ? "00" : seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </li>
      </ul>
    </TimerBoxStyled>
  );
};

const TimerBoxStyled = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding: 3rem 6rem !important;
  max-width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .date-val {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-num {
    font-size: 10rem;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-txt {
    font-size: 3rem;
  }

  & .colored {
    color: ${(props) => props.coloredColor || "#38d6d6"};
  }
  & ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
  }

  & .time-val-txt {
    font-size: 4rem !important;
    padding: 0;
  }

  @media only screen and (max-width: 700px) {
    padding: 0;
    height: fit-content;
    padding: 3rem 6rem;
    max-width: 92.5vw;
    min-width: 90%;

    & ul {
      flex-wrap: no-wrap;
      gap: 2rem !important;
      padding-bottom: 3rem 6rem;
      width: 90vw !important;
    }
    & li {
      height: 6rem;
      width: 3rem;
    }
    & .date-val-num {
      font-size: 4rem !important;
    }

    & .date-val-txt {
      font-size: 1.5rem !important;
    }
  }
`;
