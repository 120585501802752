import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SideMenusContext } from "../../contexts/side-menus-context";
import { Icon } from "../ui/IconWrapper/Icon";
import { ChevronRight } from "../../shared/assets/icons/ToolsOasisIcons";
import styled from "styled-components";
import { ChipsFilter } from "../ui/Filters/ChipsFilter/ChipsFilter";
import { Breadcrumbs } from "../ui/Breadcrumbs/Breadcrumbs";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";


const defaultsOptions = [
  "Seconds",
  "Holidays",
  "Random",
  "Stopwatch",
  "Pregnancy",
];

export const Hub = ({ type }) => {
  const { hubData, defaultCatergories } = useContext(SideMenusContext);
  const navigate = useNavigate();
  const [filterOptions, setFilteredOptions] = useState();
  const [filteredData, setFilteredData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  let routerLocation = useLocation();

  useEffect(() => {
    document.title = defaultCatergories.find(el => el.title === type).seeAllMeta.title;
    const descEl = document.querySelector("meta[name='description']");
    descEl.setAttribute(
      "content",
      defaultCatergories.find(el => el.title === type).seeAllMeta.description
    );
    const canonicalEl = document.querySelector("link[rel='canonical']");
    canonicalEl.setAttribute(
      "href",
      window.location.href.replace("www.", "").replace("http://", "https://")
    );
  }, [defaultCatergories, routerLocation]);




  const element = defaultCatergories.find(
    (el) => el.title.toLowerCase() === type.toLowerCase()
  );

  const handleFilterSelection = (item) => {
    // let tmp = [...filteredData];
    // tmp.find((filter) => el.title === filter.subCategory).isSelected =
    //   !el.isSelected;
    // console.log();
    // setFilteredData(
    //   {...hubData[type].filter((element) => element.subCategory === el.title).data}
    // );
    setFilteredData((prev) =>
      [...prev].map((el) => {
        return {
          ...el,
          isSelected: el.subCategory === item.title,
        };
      })
    );
  };

  useEffect(() => {
    if (filteredData)
      setFilteredOptions(
        filteredData.map((el) => {
          return { title: el.subCategory, isSelected: el.isSelected };
        })
      );
  }, [filteredData]);

  useEffect(() => {
    if (hubData && hubData[type])
      setFilteredData(
        hubData[type].map((el, index) => {
          return {
            ...el,
            isSelected: searchParams.get("hval")
              ? searchParams.get("hval") === el.subCategory
              : defaultsOptions.includes(el.subCategory),
          };
        })
      );
  }, [hubData, type]);

  useEffect(() => {
    if (hubData[type])
      setFilteredOptions(
        hubData[type].map((el) => {
          return { title: el.subCategory, isSelected: el.isSelected };
        })
      );
  }, [hubData[type]]);

  return (
    <div className="__to__tool-hub">
      <Breadcrumbs
        emphasisColor={element.bgcolor}
        options={[
          { title: "Home", icon: null, path: "/" },
          { title: type, icon: element.icon, path: "/" + element.path },
        ]}
      />
      <ChipsFilter
        options={filterOptions}
        handleFilterSelection={handleFilterSelection}
        bgcolor={element.bgcolor}
      />
      {filteredData &&
        filteredData
          .filter((el) => el.isSelected)
          .map((subCat) => {
            return (
              <div key={subCat.title}>
                <CategoryHeader>
                  <CategoryTitle bgcolor={element.bgcolor}>
                    {subCat.subCategory}
                  </CategoryTitle>
                </CategoryHeader>
                <ToolsOptions>
                  {subCat.data.map((el) => (
                    <ToolsOption
                      key={el.title}
                      className="tool-option"
                      onClick={(e) => {
                        navigate(type === "Timers" ? el.path : "/" + el.path);
                      }}
                    >
                      <ToolOptionIconContainer bgcolor={element.bgcolor}>
                        <Icon
                          icon={el.icon || element.icon}
                          size={"6rem"}
                          color={element.bgcolor}
                        />
                        {el.iconText && <IconText>{el.iconText}</IconText>}
                        {+el.status === 2 && (
                          <UnderConstruction
                            bgcolor={element.bgcolor}
                            className="under-construction"
                          >
                            Under Construction
                          </UnderConstruction>
                        )}
                      </ToolOptionIconContainer>
                      <ToolOptionTitle>
                        <a
                          href={el.path}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(el.path);
                          }}
                        >
                          {el.title}
                        </a>{" "}
                      </ToolOptionTitle>
                    </ToolsOption>
                  ))}
                </ToolsOptions>
              </div>
            );
          })}
    </div>
  );
};

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CategoryTitle = styled.div`
  margin-top: 1rem;
  color: white;
  padding-left: 0.8rem;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.5rem;
  border-left: 0.5rem solid ${(props) => props.bgcolor};
`;

const ToolsOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  /* overflow-x: auto; */
  padding: 2rem 0rem;
  /* & .tool-option:first-of-type:hover {
    padding-left: 1rem;
    padding-right: 0rem;
  }

  & .tool-option:last-of-type:hover {
    padding-right: 1rem;
  } */
`;

const ToolsOption = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(115%);
  }
  &:hover .under-construction {
    display: block;
    cursor: not-allowed;
  }
`;

const ToolOptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.bgcolor};
  text-align: center;
  min-width: 10rem;
  max-width: 10rem;
  min-height: 8rem;
  max-height: 8rem;
  border-radius: 0.8rem;
  border: 0.2rem solid ${(props) => props.bgcolor};
  position: relative;
  background-color: black;
`;
const ToolOptionTitle = styled.div`
  min-width: 10rem;
  max-width: 10rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.7rem;
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  color: white;
`;

const IconText = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2rem;
  padding-bottom: 0.3rem;
  font-weight: 500;
`;

const UnderConstruction = styled.div`
  display: block;
  position: absolute;
  bottom: 50%;
  right: 50%;
  color: black;
  width: 100%;
  background-color: yellow;
  font-weight: 600;
  transform: translateX(50%) translateY(50%);
  /* border-top: 2px dashed #333;
  border-bottom: 2px dashed #333; */
  opacity: 0.8;
`;

const BreadCrumbs = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 4rem;
  width: 100%;
  gap: 1rem;
  padding: 2rem 0;
`;

const BreadCrumbItem = styled.a`
  color: white;
  font-size: 2rem;
  text-transform: capitalize;
`;

// const WeekdaysSelection = styled.div`
//   width: 100%;
//   padding: 2rem;
//   gap: 1rem;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* background-color: rgba(0, 0, 0, 0.7); */
//   border-radius: 0.8rem;
//   color: white;
//   flex-wrap: wrap;
//   div {
//     padding: 0.5rem 1rem;
//     border-radius: 10rem;
//     border: 0.1rem solid ${(props) => props.bgcolor};
//   }

//   div.selected {
//     background-color: ${(props) => props.bgcolor};
//   }
// `;
