import { Countdown } from "../components/ToolsComponents/Countdown/Countdown";
import { holidays } from "../components/ToolsComponents/Countdown/holidays";

const CountdownPage = ({ type, toolFamily }) => {
  return (
    <>
      <Countdown type={type} toolFamily={toolFamily} />
    </>
  );
};

export default CountdownPage;
