import { useEffect } from "react";
import styled from "styled-components";
export const CountdownBox = ({
  countdownObj,
  isExpired,
  data,
  style,
  coloredColor,
  isPicMode,
  showTitle = true,
  date,
}) => {
  useEffect(() => {
    countdownObj && console.log("countdownObj", countdownObj);
  }, [data]);

  return (
    <CountdownBoxStyled style={{ ...style }} coloredColor={coloredColor}>
      {showTitle && (
        <h2>
          {countdownObj.toolTitle.main + " to " + countdownObj.toolTitle.prefix}
        </h2>
      )}
      <ul>
        <li key="days" className="__date-val">
          <div className="date-val-num">{data.isExpired ? "-" : data.days}</div>
          <div className="date-val-txt">Days</div>
        </li>
        <li key="hours" className="__date-val">
          <div className="date-val-num">
            {data.isExpired ? "-" : data.hours}
          </div>
          <div className="date-val-txt">Hours</div>
        </li>
        <li key="minutes" className="__date-val">
          <div className="date-val-num">
            {data.isExpired ? "-" : data.minutes}
          </div>
          <div className="date-val-txt">Minutes</div>
        </li>
        <li key="seconds" className="__date-val colored">
          <div className="date-val-num">
            {data.isExpired ? "-" : data.seconds}
          </div>
          <div className="date-val-txt">Seconds</div>
        </li>
      </ul>
      <DateInfo>{date}</DateInfo>
    </CountdownBoxStyled>
  );
};

const CountdownBoxStyled = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding: 3rem 6rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: fit-content;
  width: 100%;

  h2 {
    color: ${(props) => props.coloredColor || "#38d6d6"};
    font-size: 3rem;
    text-align: center;
  }

  & .date-val {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-num {
    font-size: 8rem;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .date-val-txt {
    font-size: 3rem;
  }

  & .colored {
    color: ${(props) => props.coloredColor || "#38d6d6"};
  }

  & ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 3rem 5rem;
    border-radius: 1rem;
    height: 100%;
    gap: 5rem;
    @media only screen and (max-width: 800px) {
      padding: 3rem;
    }
  }

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
  }

  @media only screen and (max-width: 700px) {
    padding: 0;
    height: fit-content;
    padding: 3rem 6rem;

    h2 {
      font-size:2rem;
    }

    & ul {
      flex-wrap: wrap;
      gap: 3rem !important;
      padding-bottom: 3rem 6rem;
      width: 90vw !important;
    }
    & li {
      height: 6rem;
    }
    & .date-val-num {
      font-size: 5rem !important;
    }

    & .date-val-txt {
      font-size: 1.5rem !important;
    }
  }
`;

const DateInfo = styled.h2`
  padding: 2rem;
`;
