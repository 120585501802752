import { useState } from "react";
import { createContext } from "react";

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const dismissModalHander = (setShowModal) => {
    setShowModal((prev) => {
      return {
        ...prev,
        show: false,
      };
    });
  };

  const openModalHandler = (setShowModal) => {
    setShowModal((prev) => {
      return {
        ...prev,
        show: true,
      };
    });
  };

  const [countdownModal, setCountdownModal] = useState({
    show: false,
    data: null,
  });

  const dismissCountdownModal = () => dismissModalHander(setCountdownModal);
  const openCountdownModal = () => openModalHandler(setCountdownModal);

  return (
    <ModalContext.Provider
      value={{ countdownModal, dismissCountdownModal, openCountdownModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
