//https://simplemaps.com/data/il-cities
export const cities = {
  last_update: 2023,
  Israel: {
    Jerusalem: {
      city: "Jerusalem",
      lat: "31.7833",
      lng: "35.2167",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "primary",
      population: "919438",
      population_proper: "919438",
    },
    "Tel Aviv-Yafo": {
      city: "Tel Aviv-Yafo",
      lat: "32.0800",
      lng: "34.7800",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "admin",
      population: "451523",
      population_proper: "451523",
    },
    Haifa: {
      city: "Haifa",
      lat: "32.8000",
      lng: "34.9833",
      country: "Israel",
      iso: "IL",
      admin_name: "Haifa",
      capital: "admin",
      population: "281087",
      population_proper: "281087",
    },
    "Rishon LeẔiyyon": {
      city: "Rishon LeẔiyyon",
      lat: "31.9500",
      lng: "34.8000",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "249860",
      population_proper: "249860",
    },
    "Petaẖ Tiqwa": {
      city: "Petaẖ Tiqwa",
      lat: "32.0833",
      lng: "34.8833",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "236169",
      population_proper: "236169",
    },
    Ashdod: {
      city: "Ashdod",
      lat: "31.7978",
      lng: "34.6503",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "",
      population: "220174",
      population_proper: "220174",
    },
    Netanya: {
      city: "Netanya",
      lat: "32.3328",
      lng: "34.8600",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "217244",
      population_proper: "217244",
    },
    Beersheba: {
      city: "Beersheba",
      lat: "31.2589",
      lng: "34.7978",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "admin",
      population: "209000",
      population_proper: "209000",
    },
    "Bené Beraq": {
      city: "Bené Beraq",
      lat: "32.0807",
      lng: "34.8338",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "193774",
      population_proper: "193774",
    },
    Holon: {
      city: "Holon",
      lat: "32.0167",
      lng: "34.7667",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "188834",
      population_proper: "188834",
    },
    "Ramat Gan": {
      city: "Ramat Gan",
      lat: "32.0700",
      lng: "34.8235",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "152596",
      population_proper: "152596",
    },
    Ashqelon: {
      city: "Ashqelon",
      lat: "31.6658",
      lng: "34.5664",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "",
      population: "134454",
      population_proper: "134454",
    },
    Reẖovot: {
      city: "Reẖovot",
      lat: "31.8914",
      lng: "34.8078",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "132671",
      population_proper: "132671",
    },
    "Bat Yam": {
      city: "Bat Yam",
      lat: "32.0231",
      lng: "34.7503",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "128800",
      population_proper: "128800",
    },
    "Bet Shemesh": {
      city: "Bet Shemesh",
      lat: "31.7514",
      lng: "34.9886",
      country: "Israel",
      iso: "IL",
      admin_name: "Jerusalem",
      capital: "",
      population: "114371",
      population_proper: "114371",
    },
    "Kefar Sava": {
      city: "Kefar Sava",
      lat: "32.1858",
      lng: "34.9077",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "100800",
      population_proper: "100800",
    },
    Herẕliyya: {
      city: "Herẕliyya",
      lat: "32.1556",
      lng: "34.8422",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "93989",
      population_proper: "93989",
    },
    Hadera: {
      city: "Hadera",
      lat: "32.4500",
      lng: "34.9167",
      country: "Israel",
      iso: "IL",
      admin_name: "Haifa",
      capital: "",
      population: "91707",
      population_proper: "91707",
    },
    "Modi‘in Makkabbim Re‘ut": {
      city: "Modi‘in Makkabbim Re‘ut",
      lat: "31.9339",
      lng: "34.9856",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "90013",
      population_proper: "90013",
    },
    Nazareth: {
      city: "Nazareth",
      lat: "32.7021",
      lng: "35.2978",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "admin",
      population: "83400",
      population_proper: "83400",
    },
    Lod: {
      city: "Lod",
      lat: "31.9500",
      lng: "34.9000",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "75700",
      population_proper: "75700",
    },
    Ramla: {
      city: "Ramla",
      lat: "31.9318",
      lng: "34.8736",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "admin",
      population: "75500",
      population_proper: "75500",
    },
    "Ra‘ananna": {
      city: "Ra‘ananna",
      lat: "32.1833",
      lng: "34.8667",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "74000",
      population_proper: "74000",
    },
    Rahat: {
      city: "Rahat",
      lat: "31.3925",
      lng: "34.7544",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "",
      population: "64462",
      population_proper: "64462",
    },
    Nahariyya: {
      city: "Nahariyya",
      lat: "33.0036",
      lng: "35.0925",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "60000",
      population_proper: "60000",
    },
    Givatayim: {
      city: "Givatayim",
      lat: "32.0697",
      lng: "34.8117",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "59518",
      population_proper: "59518",
    },
    "Hod HaSharon": {
      city: "Hod HaSharon",
      lat: "32.1500",
      lng: "34.8833",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "56659",
      population_proper: "56659",
    },
    "Rosh Ha‘Ayin": {
      city: "Rosh Ha‘Ayin",
      lat: "32.0833",
      lng: "34.9500",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "56300",
      population_proper: "56300",
    },
    "Qiryat Ata": {
      city: "Qiryat Ata",
      lat: "32.8000",
      lng: "35.1000",
      country: "Israel",
      iso: "IL",
      admin_name: "Haifa",
      capital: "",
      population: "55464",
      population_proper: "55464",
    },
    "Umm el Faḥm": {
      city: "Umm el Faḥm",
      lat: "32.5158",
      lng: "35.1525",
      country: "Israel",
      iso: "IL",
      admin_name: "Haifa",
      capital: "",
      population: "55300",
      population_proper: "55300",
    },
    "Qiryat Gat": {
      city: "Qiryat Gat",
      lat: "31.6061",
      lng: "34.7717",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "",
      population: "55000",
      population_proper: "55000",
    },
    Eilat: {
      city: "Eilat",
      lat: "29.5500",
      lng: "34.9500",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "",
      population: "51935",
      population_proper: "51935",
    },
    "Nes Ẕiyyona": {
      city: "Nes Ẕiyyona",
      lat: "31.9333",
      lng: "34.8000",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "50200",
      population_proper: "50200",
    },
    "‘Akko": {
      city: "‘Akko",
      lat: "32.9261",
      lng: "35.0839",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "47675",
      population_proper: "47675",
    },
    "El‘ad": {
      city: "El‘ad",
      lat: "32.0523",
      lng: "34.9512",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "46896",
      population_proper: "46896",
    },
    "Ramat HaSharon": {
      city: "Ramat HaSharon",
      lat: "32.1461",
      lng: "34.8394",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "46700",
      population_proper: "46700",
    },
    Karmiel: {
      city: "Karmiel",
      lat: "32.9000",
      lng: "35.2833",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "45300",
      population_proper: "45300",
    },
    Afula: {
      city: "Afula",
      lat: "32.6078",
      lng: "35.2897",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "44930",
      population_proper: "44930",
    },
    Tiberias: {
      city: "Tiberias",
      lat: "32.7897",
      lng: "35.5247",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "44200",
      population_proper: "44200",
    },
    "Eṭ Ṭaiyiba": {
      city: "Eṭ Ṭaiyiba",
      lat: "32.2667",
      lng: "35.0000",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "43100",
      population_proper: "43100",
    },
    "Qiryat Yam": {
      city: "Qiryat Yam",
      lat: "32.8331",
      lng: "35.0664",
      country: "Israel",
      iso: "IL",
      admin_name: "Haifa",
      capital: "",
      population: "40700",
      population_proper: "40700",
    },
    "Qiryat Moẕqin": {
      city: "Qiryat Moẕqin",
      lat: "32.8381",
      lng: "35.0794",
      country: "Israel",
      iso: "IL",
      admin_name: "Haifa",
      capital: "",
      population: "40160",
      population_proper: "40160",
    },
    "Qiryat Bialik": {
      city: "Qiryat Bialik",
      lat: "32.8331",
      lng: "35.0664",
      country: "Israel",
      iso: "IL",
      admin_name: "Haifa",
      capital: "",
      population: "39900",
      population_proper: "39900",
    },
    "Qiryat Ono": {
      city: "Qiryat Ono",
      lat: "32.0636",
      lng: "34.8553",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "37791",
      population_proper: "37791",
    },
    "Or Yehuda": {
      city: "Or Yehuda",
      lat: "32.0333",
      lng: "34.8500",
      country: "Israel",
      iso: "IL",
      admin_name: "Tel Aviv",
      capital: "",
      population: "36706",
      population_proper: "36706",
    },
    "Ma‘alot Tarshīḥā": {
      city: "Ma‘alot Tarshīḥā",
      lat: "33.0167",
      lng: "35.2708",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "36000",
      population_proper: "36000",
    },
    Ẕefat: {
      city: "Ẕefat",
      lat: "32.9658",
      lng: "35.4983",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "35700",
      population_proper: "35700",
    },
    Dimona: {
      city: "Dimona",
      lat: "31.0700",
      lng: "35.0300",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "",
      population: "34135",
      population_proper: "34135",
    },
    Tamra: {
      city: "Tamra",
      lat: "32.8511",
      lng: "35.2071",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "34000",
      population_proper: "34000",
    },
    Netivot: {
      city: "Netivot",
      lat: "31.4167",
      lng: "34.5833",
      country: "Israel",
      iso: "IL",
      admin_name: "Southern",
      capital: "",
      population: "31314",
      population_proper: "31314",
    },
    Sakhnīn: {
      city: "Sakhnīn",
      lat: "32.8667",
      lng: "35.3000",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "",
      population: "31100",
      population_proper: "31100",
    },
    Yehud: {
      city: "Yehud",
      lat: "32.0333",
      lng: "34.8833",
      country: "Israel",
      iso: "IL",
      admin_name: "Central",
      capital: "",
      population: "29146",
      population_proper: "29146",
    },
    "Al Buţayḩah": {
      city: "Al Buţayḩah",
      lat: "32.9087",
      lng: "35.6320",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "minor",
      population: "",
      population_proper: "",
    },
    "Al Khushnīyah": {
      city: "Al Khushnīyah",
      lat: "32.9994",
      lng: "35.8108",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "minor",
      population: "",
      population_proper: "",
    },
    Fīq: {
      city: "Fīq",
      lat: "32.7793",
      lng: "35.7003",
      country: "Israel",
      iso: "IL",
      admin_name: "Northern",
      capital: "minor",
      population: "",
      population_proper: "",
    },
  },
};
