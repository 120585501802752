export const Icon = ({
  icon,
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
  strokeWidth,
}) => {
  if (!icon) return;
  const Icon = icon;
  return (
    <Icon size={size} color={color} style={style} strokeWidth={strokeWidth} />
  );
};
