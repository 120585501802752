import styled from "styled-components";

export const CardExpendable = ({
  children,
  className,
  hideShadow = false,
  customStyle,
}) => {
  return (
    <CardWrapper
      style={customStyle}
      hideShadow={hideShadow}
      className={className}
    >
      {children}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  background-color: #151515;
  box-shadow: 0px 0px 10px 2px #ccc;
  color: #f2f2f2;
  border-radius: 1rem;
  width: 100%;
`;
