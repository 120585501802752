import { CountdownBox } from "./CountdownBox";
import { useContext } from "react";
import styled from "styled-components";
import { Icon } from "../../ui/IconWrapper/Icon";
import { CalendarsIcon } from "../../../shared/assets/icons/ToolsOasisIcons";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { DAYS } from "../../DateGenerator/DateGeneratorCalendar";
import { useEffect, useState } from "react";
import { pink } from "@mui/material/colors";
import { ChipsFilter } from "../../ui/Filters/ChipsFilter/ChipsFilter";
import { FunziesIcon } from "../../../shared/assets/icons/ToolsOasisIcons";
import { Title } from "@mui/icons-material";
import { TOOLS } from "../../ui/ToolBase/ToolsTypes";
import ToolButton from "../../ui/ToolsButton/ToolButton";
import { Button } from "@mui/material";
import { ModalContext } from "../../../contexts/modal-context";

export const CountdownMain = ({
  toolFamily,
  countdownObj,
  data,
  selectedDate,
  selectedDateFormatted,
  showError,
  isPastDate,
  handleDateSelection,
  tabsOptions,
  setTabsOptions,
  isPicMode,
  type,
  hasModal,
}) => {
  const [presetVal, setPresetVal] = useState(selectedDateFormatted || "");
  const navigate = useNavigate();
  const { openCountdownModal } = useContext(ModalContext);
  useEffect(() => {
    setPresetVal(selectedDateFormatted);
  }, [selectedDate, selectedDateFormatted]);

  return (
    <>
      <CountdownInputs isPastDate={isPastDate}>
        {toolFamily !== "holidays" && (
          <DateInput
            onClick={() =>
              document.getElementById("__to__date-picker").showPicker()
            }
          >
            <input
              type="text"
              value={presetVal}
              placeholder={countdownObj.datePlaceHolder}
              className="__date-time-text"
              disabled={true}
            />
            <input
              id="__to__date-picker"
              className="__date-time-input"
              style={{ background: countdownObj.color }}
              type={countdownObj.datePickerType}
              onChange={handleDateSelection}
              value={presetVal && selectedDate.replace("T", " ")}
            />
            <Icon
              icon={CalendarsIcon}
              color="white"
              size="4rem"
              style={{
                position: "absolute",
                right: ".5rem",
                top: ".5rem",
                zIndex: 1,
              }}
            />
          </DateInput>
        )}
        {toolFamily === "holidays" && (
          <>
            {tabsOptions && (
              <ChipsFilter
                bgcolor={countdownObj.color}
                options={tabsOptions}
                handleFilterSelection={(item) => {
                  setTabsOptions(
                    tabsOptions.map((el) => {
                      return { ...el, isSelected: el.title === item.title };
                    })
                  );
                }}
              />
            )}
            {false && (
              <DateInput isPicMode={isPicMode}>
                {/* <div style={{ color: "pink" }}>{type}</div> */}
                <input
                  type="text"
                  value={
                    presetVal &&
                    "Time to " +
                      DAYS[new Date(selectedDate).getDay()] +
                      ", " +
                      presetVal
                  }
                  placeholder={selectedDate}
                  className="__date-time-text-info"
                  style={{ color: countdownObj?.color || "white" }}
                  disabled={true}
                />
              </DateInput>
            )}
          </>
        )}
        {toolFamily !== "holidays" && (
          <div
            style={{
              color: "red",
              visibility:
                (isPastDate || !selectedDate) && showError
                  ? "visible"
                  : "hidden",
              paddingTop: ".5rem",
            }}
          >
            Please Select Future Date
          </div>
        )}
      </CountdownInputs>

      <CountdownMainContainer>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CountdownBox
            countdownObj={countdownObj}
            style={isPicMode ? { background: "rgba(0,0,0,0.7)" } : {}}
            data={data}
            coloredColor={countdownObj.color}
            date={
              presetVal &&
              "Time to " +
                // DAYS[new Date(selectedDate).getDay()] +
                // ", " +
                new Date(presetVal).toDateString()
            }
          />
        </div>
        {countdownObj.outterLink && !presetVal && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              gap: "3rem",
            }}
          >
            <OutterToolLinkPrefix>
              {countdownObj.outterLink.prefix}
            </OutterToolLinkPrefix>
            <ToolsOption
              onClick={() => {
                navigate(countdownObj.outterLink.path);
              }}
            >
              <ToolOptionIconContainer
                bgcolor={countdownObj.outterLink.toolColor}
              >
                <Icon
                  icon={countdownObj.background}
                  size={"6rem"}
                  color={countdownObj.outterLink.toolColor}
                />
              </ToolOptionIconContainer>
              <ToolOptionTitle
                href={countdownObj.outterLink.path}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {" "}
                {countdownObj.outterLink.toolName}
              </ToolOptionTitle>
            </ToolsOption>
          </div>
        )}

        {/* <CountdownBtns>
          <button
            className={"start-btn"}
            onClick={isStart ? handleReset : handleStart}
          >
            {isStart ? "Reset" : "Start"}
          </button>
        </CountdownBtns> */}
        {toolFamily === "holidays" && (
          <EventsSlider isPicMode={isPicMode}>
            <TitleContainer>
              <h2>{"Did you know?"}</h2>
              {hasModal && (
                <ToolButton
                  icon={FunziesIcon}
                  bgcolor={countdownObj?.color || "white"}
                  color={"rgba(0,0,0,0.9)"}
                  text={"fun facts"}
                  size={6}
                  onClick={() => openCountdownModal()}
                  className={"btn-61"}
                />
              )}
              {/* <ToolButton color={countdownObj?.color || "white"}>
                <FunziesIcon
                  color={countdownObj?.color || "white"}
                  size={"4rem"}
                />
                Fun Fact
              </ToolButton> */}
              {/* <ToolButton /> */}
            </TitleContainer>

            <p>
              {" "}
              {/* <PaginationItem>1</PaginationItem> */}
              {countdownObj.toolInfo}
            </p>
            {/* <PaginationItems>
              <PaginationItem>1</PaginationItem>
              <PaginationItem className="active">2</PaginationItem>
              <PaginationItem>3</PaginationItem>
            </PaginationItems> */}
          </EventsSlider>
        )}
      </CountdownMainContainer>
    </>
  );
};

const CountdownMainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
`;

const CountdownInputs = styled.div`
  height: fit-content;
  padding: 2rem;
  gap: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const DateInput = styled.div`
  display: flex;
  width: 90vw;
  max-width: 78rem;
  margin-top:5rem;
  position: relative;
  & input {
    width: 100%;
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    height: 5rem;
    outline: none;
    padding: 2rem;
    border: ${(props) => (props.isPastDate ? "0.2rem solid red" : "none")};
    color: white;
    background-color: ${(props) =>
      props.isPicMode ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.7)"};
    font-size: 1.4rem;
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
  }

  & .__date-time-input {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  & .__date-time-text-info {
    border-radius: 0.8rem;
    font-size: 2.5rem;
    /* background: none; */
    text-align: center;
    position: absolute !important;
    top: 320px;
  }
`;

const OutterToolLinkage = styled.div`
  color: white;
  height: 5rem;
  font-size: 1.2rem;
  white-space: pre-wrap;
`;

const OutterToolLink = styled.a`
  color: rgb(118, 73, 191);
  font-weight: 500;
`;

const CountdownBtns = styled.div`
  max-width: 68rem;
  width: 90vw;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 3rem;

  & button {
    width: 100%;
    border-radius: 0.8rem;
    border: none;
    outline: none;
    height: 7rem;
    /* border-radius: 50%; */
    background-color: rgba(52, 191, 149, 1);
    color: white;
    font-size: 3rem;
    font-weight: 600;
  }
`;

const ToolsOption = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    transform: scale(115%);
  }
  &:hover .under-construction {
    display: block;
    cursor: not-allowed;
  }
`;

// const ToolOptionIconContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   color: ${(props) => props.bgcolor};
//   text-align: center;
//   min-width: 9rem;
//   max-width: 9rem;
//   min-height: 7rem;
//   max-height: 7rem;
//   border-radius: 0.8rem;
//   border: 0.2rem solid ${(props) => props.bgcolor};
//   position: relative;
// `;

// const ToolOptionTitle = styled.a`
//   min-width: 9rem;
//   max-width: 9rem;
//   text-align: left;
//   font-size: 1rem;
//   line-height: 1.2rem;
//   padding: 0.8rem 0.2rem;
//   font-weight: 500;
//   color: white;
//   text-transform: capitalize;
// `;

const OutterToolLinkPrefix = styled.div`
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.4rem;
  width: 100%;
  padding: 0 2rem;
  white-space: pre-wrap;
  gap: 2rem;
`;

const EventsSlider = styled.div`
  background-color: ${(props) =>
    props.isPicMode ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.7)"};
  color: white;
  min-height: ${(props) => (props.isPicMode ? "fit-content" : "30rem")};
  max-height: ${(props) => props.isPicMode && "43vh"};
  height: 100%;
  margin-bottom: 5rem;
  display: flex;
  /* flex-grow: ${(props) => (props.isPicMode ? "0" : "1")}; */
  align-items: center;
  justify-content: flex-start;
  width: 90vw;
  border-radius: 0.8rem;
  padding: 0.5rem;
  display: flex;
  height: ${(props) => (props.isPicMode ? "fit-content" : "100%")};
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 5vh;
  overflow-x: hidden;
  h2,
  p {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    font-size: 2rem;
    color: rgba(52, 191, 149, 1);
    padding: 1rem;
    @media only screen and (min-width: 600px) {
      font-size: 3rem;
    }
  }
  p {
    font-size: 1.8rem;
    color: inherit;
    line-height: 1.8;
    @media only screen and (min-width: 600px) {
      font-size: 3rem;
    }
  }

  /* border: 0.1rem solid rgb(205, 97, 85,.5); */
`;

const PaginationItems = styled.ul`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  gap: 2rem;
  padding: 1rem;
`;

const PaginationItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12rem;
  min-height: 12rem;
  max-width: 12rem;
  max-height: 12rem;
  background: rgba(0, 0, 0, 0.8);
  border: 0.3rem solid rgba(52, 191, 149, 1);
  color: black;
  color: white;
  text-align: center;
  border-radius: 50%;
  font-size: 8rem;
  font-weight: 600;
  transform: translateY(25%);
  &.active {
    background: rgba(52, 191, 149, 1);
    color: black;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ToolOptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.bgcolor};
  text-align: center;
  min-width: 12rem;
  max-width: 12rem;
  min-height: 10rem;
  max-height: 10rem;
  border-radius: 0.8rem;
  border: 0.2rem solid ${(props) => props.bgcolor};
  position: relative;
  background: rgba(0, 0, 0, 0.9);
`;
const ToolOptionTitle = styled.a`
  min-width: 9rem;
  max-width: 9rem;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.5rem;
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  color: white;
  text-transform: capitalize;
`;


