export const VacationIcon = ({
  color,
  activeColor,
  bgcolor,
  activBgColor,
  size,
  style,
  isActive,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      focusable="false"
      aria-hidden="true"
      fill="none"
      style={{ ...style, width: size }}
    >
      <path
        d="M12.5 43.9185L24 23.9999M34 6.67941C25.3736 1.69898 14.5793 3.87517 8.492 11.3658C7.89786 12.0969 7.60078 12.4625 7.51436 12.9862C7.4456 13.403 7.54747 13.9552 7.76037 14.32C8.02796 14.7785 8.50223 15.0523 9.45077 15.5999L38.5492 32.3999C39.4978 32.9476 39.972 33.2214 40.5029 33.2239C40.9252 33.2259 41.4544 33.038 41.781 32.7701C42.1913 32.4333 42.3594 31.9933 42.6955 31.1132C46.1389 22.0961 42.6264 11.6598 34 6.67941ZM34 6.67941C30.1737 4.47027 22.5946 10.4341 17.0718 19.9999M34 6.67941C37.8263 8.88855 36.4511 18.4341 30.9282 27.9999M44 43.9999H4"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
